/*
    Fonts
*/
.container {
  display: grid;
  grid-template-columns: auto 17rem 40px 17rem auto;
  grid-template-rows: auto auto;
  align-items: center; }
  .container h3 {
    justify-content: center !important; }
  .container .left {
    grid-column-start: 2;
    grid-column-end: 3;
    align-self: flex-start; }
  .container .right {
    grid-column-start: 4;
    grid-column-end: 5;
    align-self: flex-start; }
  .container .center {
    grid-column-start: 2;
    grid-column-end: 5;
    justify-self: center;
    width: 100%; }
  .container .narrow {
    max-width: 15.5rem; }
  .container .head {
    margin-top: 3rem;
    font-size: 1.25rem;
    font-weight: 900;
    color: var(--theme-main-color); }
  .container .subTitleText {
    color: var(--theme-main-color);
    font-size: 0.875rem;
    line-height: 1.71;
    font-weight: normal;
    text-align: center; }
  .container .button {
    display: flex;
    justify-content: center;
    margin-top: 4.25rem; }
  .container .fadedButton {
    margin: auto;
    opacity: 0.3; }
    .container .fadedButton:hover {
      opacity: 1; }
  @media (max-width: 40rem) {
    .container {
      grid-template-columns: auto 17rem auto;
      grid-template-rows: auto auto auto; }
      .container .left,
      .container .right,
      .container .center {
        grid-column-start: 2;
        grid-column-end: 3; } }

.loading {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; }

.commonError {
  height: 1rem;
  margin-bottom: 0.25rem;
  color: var(--theme-error-color);
  text-align: center; }

.tabButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem; }

@-webkit-keyframes autofill {
  to {
    color: var(--theme-main-color);
    background: none; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }
