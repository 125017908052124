/*
    Fonts
*/
.billboard {
  width: 4.5rem !important;
  height: 4.5rem !important;
  margin-right: 1.5rem !important;
  background-position: center center;
  background-size: auto 100%;
  background-color: rgba(0, 0, 0, 0);
  background-blend-mode: hard-light; }
  .billboard svg path,
  .billboard svg circle {
    fill: transparent !important;
    stroke: transparent !important;
    transition: 300ms ease-in-out; }
  .billboard:hover {
    background-color: black; }
    .billboard:hover svg {
      wigth: 3rem !important;
      height: 3rem !important; }
      .billboard:hover svg path {
        fill: var(--white) !important; }
      .billboard:hover svg circle {
        stroke: var(--white) !important;
        opacity: 0.5; }

.playIcon {
  height: 4rem;
  width: 4rem; }

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4rem; }
  .spinner > div {
    height: 4rem !important;
    width: 4rem !important; }
