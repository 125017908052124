/*
    Fonts
*/
.container {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 4;
  display: grid;
  grid-template-areas: "filters spacer1 tracks spacer2 sideLinks";
  grid-template-columns: 11.5rem 2.5rem 46.0625rem 2.4375rem 13rem;
  grid-template-rows: auto;
  min-height: 60rem; }
  @media (max-width: 1100px) {
    .container {
      grid-template-columns: calc((100vw - 688px - 2rem) / 2) 1rem 43rem 1rem calc( (100vw - 688px - 2rem) / 2); } }
  @media (max-width: 1100px) {
    .container {
      grid-template-areas: "spacer1 tracks spacer2  filters spacer3" "spacer1 tracks spacer2 sideLinks spacer3";
      grid-template-columns: 1rem calc(75vw - 3rem) 4rem calc(25vw - 3rem) 1rem; } }
  @media (max-width: 750px) {
    .container {
      grid-template-areas: "tracks" "spacer" "filters " "sideLinks ";
      grid-template-rows: auto 2rem auto auto;
      grid-template-columns: calc(100vw - 2rem); } }
  @media (max-width: 800px) {
    .container {
      margin-top: 5rem; } }
  .container .center {
    z-index: 1;
    grid-area: tracks;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media (max-width: 800px) {
      .container .center {
        z-index: 10; }
        .container .center h1 {
          font-size: 3rem; } }
    @media (max-width: 1100px) {
      .container .center {
        grid-area: tracks !important; } }
    @media (max-width: 700px) {
      .container .center .navbar {
        display: none !important; } }
    .container .center .search {
      margin: 0 0 1rem -1.125rem;
      background-color: transparent;
      border-radius: 2px;
      grid-area: search;
      max-width: 616px;
      height: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      justify-self: center; }
      .container .center .search .input {
        padding: 0;
        height: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .container .center .search .input form {
          width: 100%; }
          .container .center .search .input form input {
            display: flex;
            flex-grow: 1;
            margin-left: 1rem;
            padding-right: 1.5rem;
            height: 100%;
            background-color: transparent;
            width: auto;
            border: none;
            outline: none;
            font-family: var(--montserrat), sans-serif;
            font-size: 1.5rem;
            font-weight: 900;
            color: #ffffff; }
            .container .center .search .input form input::-webkit-input-placeholder {
              color: var(--theme-muffled-color); }
            .container .center .search .input form input::-moz-placeholder {
              color: var(--theme-muffled-color); }
            .container .center .search .input form input:-moz-placeholder {
              color: var(--theme-muffled-color); }
            .container .center .search .input form input:-ms-input-placeholder {
              color: var(--theme-muffled-color); }
            .container .center .search .input form input::placeholder {
              color: var(--theme-muffled-color); }
      .container .center .search .searchIcon {
        width: 16px;
        height: 16px;
        margin-right: 0.5rem; }
        .container .center .search .searchIcon path {
          fill: rgba(255, 255, 255, 0.5);
          opacity: 0.5; }
      .container .center .search .cleanIcon {
        opacity: 0.3;
        margin-top: 0.125rem;
        width: 20px;
        height: 20px; }
    .container .center .title {
      display: flex;
      justify-content: left;
      flex-direction: row; }
      .container .center .title h2 {
        max-width: 60vw;
        display: block;
        margin-bottom: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 900;
        color: var(--white); }
      .container .center .title > :nth-child(2) {
        margin: auto 1rem auto 1rem; }
    .container .center h1 {
      height: 4.5rem;
      margin-bottom: 2.0625rem;
      white-space: nowrap; }
    .container .center .tracks {
      margin-top: 2.1875rem; }
    .container .center .noTracks {
      color: var(--white);
      margin-top: 3.4375rem;
      width: 100%;
      text-align: center;
      font-size: 3rem;
      font-weight: 900; }
