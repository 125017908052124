/*
    Fonts
*/
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.25rem 1.25rem 2.5rem; }

.header {
  margin-bottom: 1rem;
  color: var(--black) !important;
  text-align: center; }

.text {
  margin-bottom: 1rem;
  text-align: center;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
  opacity: 0.6; }
