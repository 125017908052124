@mixin trackContainerMobile() {
    margin: 0 0.5rem;
    width: calc(100vw - 1rem);
    grid-template-areas: ". .        .        .      ." ". play     vers     vers   ." ". play     key      bpm    ." ". genre    released released ." ". buttons  buttons  buttons ." ". .        .       .         .";
    grid-template-columns: 0.5rem calc(100vw - 13.5rem) 10rem 1.5rem 0.5rem;
    grid-template-rows: 1rem 2rem 1.5rem 2rem 2.5rem 1rem;

    .versions {
        padding: 0 !important;
        grid-area: vers !important;
        margin-left: 0.4rem;
    }

    .index {
        display: none;
    }

    .versions,
    .key,
    .bpm,
    .buttons,
    .released {
        padding: 0 !important;
        justify-content: flex-end !important;
        text-align: right;
        align-items: center;

        & > * {
            margin-top: 0 !important;
        }
    }
    .key:after {
        content: "-";
        padding-left: 0.25rem;
    }

    .playInfo {
        height: 100%;
        max-width: 65vw !important;
        width: 65vw !important;

        position: relative;

        .nameAndArtist {
            height: 100% !important;

            justify-content: space-between !important;
        }

        & > *:first-child {
            position: absolute;
            top: calc(100% + 2rem);
            height: rem(26px) !important;
            width: rem(26px) !important;

            svg {
                margin-top: 0 !important;
                height: rem(26px) !important;
                width: rem(26px) !important;
            }
        }
    }
    .versions {
        button {
            margin: 0 !important;
            padding: 0 !important;
            border: none !important;

            span {
                font-size: 0.75rem !important;
                color: var(--theme-main-color) !important;
            }
        }

        & > *:not(:first-child) {
            display: none;
        }
    }
}
