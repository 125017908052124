/*
    Fonts
*/
.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 7rem;
  width: auto;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  outline: none;
  cursor: pointer;
  transition: 200ms ease-in-out;
  will-change: font-weight;
  padding: 0 2.5rem 0 2.5rem; }
  .main:disabled {
    cursor: initial;
    opacity: 0.8; }
  .main.s_ {
    font-size: 1rem; }
  .main.s_tiny {
    border-radius: 1.625rem;
    font-size: 0.625rem;
    height: 2.25rem;
    letter-spacing: 1.1px; }
  .main.s_small {
    font-size: 0.75rem;
    height: 2.5rem;
    letter-spacing: 1.1px; }
  .main.s_normal {
    font-size: 1rem;
    height: 2.5rem;
    letter-spacing: 1.1px; }
  .main.s_large {
    width: 296px;
    height: 40px;
    font-size: 32px;
    font-weight: 900;
    letter-spacing: normal; }
  .main.d_vertical {
    height: 2rem;
    text-transform: none !important;
    letter-spacing: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #5f6670;
    position: relative;
    justify-content: flex-start;
    margin-left: 1rem;
    border: none;
    opacity: 0.3; }
    .main.d_vertical .line {
      content: " ";
      position: absolute;
      transform: scaleX(0);
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      border: 0;
      background-color: var(--active-elem); }
    .main.d_vertical.active {
      opacity: 1;
      font-weight: bold; }
      .main.d_vertical.active .line {
        transform: scaleX(1) !important;
        transition: 300ms ease-in-out; }
      .main.d_vertical.active .line {
        height: 100%;
        width: 3px;
        margin-left: -1rem; }
  .main.white {
    background: transparent;
    border: 1px solid var(--theme-muffled-color);
    color: var(--theme-main-color); }
  .main.black {
    background: black;
    color: var(--white); }
  .main.grey {
    background: transparent;
    border: 1px solid var(--theme-muffled-color);
    color: var(--grey); }
  .main.bright {
    background: var(--active-elem);
    border: 1px solid var(--active-elem);
    color: var(--white) !important; }
    .main.bright span {
      color: var(--white) !important; }
  .main.underlined {
    position: relative;
    border: none;
    height: auto;
    opacity: 0.3; }
    .main.underlined .line {
      content: " ";
      position: absolute;
      transform: scaleX(0);
      transition: 200ms ease-in-out;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      border: 0;
      background-color: var(--active-elem); }
    .main.underlined.active {
      opacity: 1; }
      .main.underlined.active .line {
        transform: scaleX(1) !important;
        transition: 500ms ease-in-out; }
      .main.underlined.active .line {
        height: 3px;
        bottom: -0.5rem; }
  .main.fullWidth {
    width: 100%; }
  .main.rounded {
    border-radius: 2.5rem; }
  .main.withoutPadding {
    padding: 0; }
  .main.withoutBorder {
    border: none; }
  .main.isUpperCase {
    text-transform: uppercase; }
