/*
    Fonts
*/
.loader {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0rem;
  width: 100%;
  min-width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  background-color: var(--theme-background-color);
  justify-content: center; }
  .loader.autoWidth {
    width: 100%; }

.container {
  padding-top: 8.0625rem;
  padding-bottom: 10.3125rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  max-width: 100vw;
  min-height: 100vh; }
  @media (max-width: 800px) {
    .container {
      padding-bottom: 1.25rem;
      padding-top: 11.25rem; } }
  .container.background {
    background-image: linear-gradient(to bottom, var(--theme-background-transparent-color), var(--theme-background-color) 50%), url(assets/images/darkLabelsBack.png);
    background-repeat: no-repeat;
    background-size: 100% auto; }
    .container.background.light {
      background-image: linear-gradient(to bottom, var(--theme-background-highttransparent-color), var(--theme-background-color) 50%), url(assets/images/lightLabelsBack.jpg); }
    @media (max-width: 1400px) {
      .container.background {
        background-size: auto auto; } }
  .container .content {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 1260px;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap; }
    .container .content .headerContainer {
      width: 100%;
      max-width: 100vw;
      margin-left: 1.5rem;
      margin-top: 2rem;
      margin-bottom: 0; }
    .container .content .artistsList {
      margin: 32px 20px 24px 20px;
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: center;
      flex-wrap: wrap; }
    .container .content .artistBlock {
      margin: 32px 20px 24px 20px;
      color: var(--theme-main-color);
      width: 23.5rem;
      height: 25.5rem; }
      @media (max-width: 400px) {
        .container .content .artistBlock {
          margin-bottom: 6rem;
          margin-left: 1.75rem;
          width: 300px !important;
          height: 320px !important; } }
      .container .content .artistBlock .artistVideo {
        cursor: pointer;
        transition: 300ms ease-in-out;
        opacity: 1;
        height: 216px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .container .content .artistBlock .artistVideo img {
          width: auto;
          height: 100%;
          background-color: var(--theme-background-changed-color);
          cursor: pointer;
          transition: 300ms ease-in-out;
          opacity: 1; }
          @media (max-width: 400px) {
            .container .content .artistBlock .artistVideo img {
              width: auto;
              height: 172px; } }
        .container .content .artistBlock .artistVideo .play {
          width: 100%;
          height: 100%;
          position: relative;
          top: -102%;
          transition: 400ms ease-in-out;
          opacity: 0; }
          .container .content .artistBlock .artistVideo .play .playButton {
            position: relative;
            top: 4.21875rem;
            left: 9.21875rem; }
        .container .content .artistBlock .artistVideo .play:hover {
          opacity: 1; }
      .container .content .artistBlock h2 {
        font-weight: 800;
        font-size: 20px; }
      .container .content .artistBlock .bio {
        width: 376px;
        overflow: hidden;
        white-space: nowrap;
        font-size: 0.875rem;
        justify-content: flex-start;
        text-align: start;
        color: var(--theme-main-color); }
        .container .content .artistBlock .bio .learnMoreButton {
          color: var(--active-elem);
          display: inline-block;
          padding-left: 0.25rem; }
        @media (max-width: 400px) {
          .container .content .artistBlock .bio {
            height: auto; } }
      .container .content .artistBlock .artistInfo {
        position: relative;
        cursor: pointer; }
        .container .content .artistBlock .artistInfo * {
          color: var(--theme-main-color); }
      .container .content .artistBlock .socialBlock {
        margin-left: -0.5rem; }
        @media (max-width: 400px) {
          .container .content .artistBlock .socialBlock {
            margin-top: 1rem; } }

.HomeRotation .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8rem 2rem -8.75rem 1.25rem; }
  .HomeRotation .title h2 {
    font-size: 33px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: normal;
    color: var(--theme-main-color); }
  .HomeRotation .title .button {
    margin-top: 2.25rem; }

.spotlightBlock {
  border: 2px skyblue solid; }
  .spotlightBlock .artistVideo {
    display: flex; }
    .spotlightBlock .artistVideo img {
      flex-grow: 1; }
