/*
    Fonts
*/
.container {
  padding-top: 8.0625rem;
  padding-bottom: 10.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(var(--theme-background-transparent-color), var(--theme-second-color) 650px), url(assets/images/darkAuthBack.jpg);
  background-color: var(--theme-background-color);
  background-repeat: no-repeat;
  background-size: 100% auto; }
  @media (max-width: 800px) {
    .container {
      padding-top: 11.25rem; } }
  .container.light {
    background-image: linear-gradient(var(--theme-background-highttransparent-color), var(--theme-second-color) 850px), url(assets/images/lightAuthBack.jpg); }
  @media (max-width: 1400px) {
    .container {
      background-size: auto auto;
      background-position: top; } }
  @media (max-width: 40rem) {
    .container {
      padding-bottom: 3rem; } }
  .container .head {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 4rem;
    font-weight: 900; }
    @media (max-width: 500px) {
      .container .head {
        font-size: 2.5rem; } }
  .container .subTitleText {
    color: var(--theme-muffled-color);
    letter-spacing: 0.9px;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1.71;
    text-align: center; }
  .container .links {
    margin-top: 1rem;
    margin-bottom: 2.0625rem; }
    .container .links * {
      bottom: 0 !important; }
    .container .links > * {
      padding-bottom: 0.3125rem;
      line-height: 1.5rem;
      font-size: 0.75rem;
      font-weight: 600;
      letter-spacing: 0.9px;
      opacity: 0.3; }
      @media (max-width: 500px) {
        .container .links > * {
          margin-top: 1rem; } }
