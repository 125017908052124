/*
    Fonts
*/
.hide {
  display: none; }

.container {
  z-index: 1000;
  grid-column-start: 1;
  grid-column-end: 4;
  display: grid;
  grid-template-rows: 9rem auto 18rem;
  grid-template-columns: auto 7rem minmax(auto, calc(1432px - 14rem)) 7rem auto;
  overflow: hidden;
  grid-template-rows: auto auto;
  background-color: var(--theme-footer-background-color);
  overflow: initial; }
  @media (max-width: 1432px) {
    .container {
      grid-template-columns: auto 0rem minmax(auto, calc(1432px - 14rem)) 0rem auto; } }
  @media (max-width: 700px) {
    .container {
      grid-template-columns: 1rem 0rem calc(100vw - 2rem) 0rem 1rem; } }
  .container .mainFooter {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 6;
    display: grid;
    grid-template-rows: 9rem auto 18rem;
    grid-template-columns: auto 7rem minmax(auto, calc(1432px - 14rem)) 7rem auto;
    overflow: hidden;
    grid-template-rows: 3.25rem auto;
    will-change: height;
    transition: 500ms ease-in-out;
    background-color: var(--footer-background); }
    @media (max-width: 1432px) {
      .container .mainFooter {
        grid-template-columns: auto 0rem minmax(auto, calc(1432px - 14rem)) 0rem auto; } }
    @media (max-width: 700px) {
      .container .mainFooter {
        grid-template-columns: 1rem 0rem calc(100vw - 2rem) 0rem 1rem; } }
    .container .mainFooter.hidden {
      height: 0;
      overflow: hidden; }
    @media (max-width: 900px) {
      .container .mainFooter {
        grid-template-rows: 2rem auto 2rem;
        grid-template-columns: auto;
        justify-content: flex-end; } }
    @media (max-width: 720px) {
      .container .mainFooter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    .container .mainFooter .mainFooterContent {
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: 3;
      grid-column-end: 4;
      display: grid;
      grid-row-start: 2;
      grid-row-end: 2;
      grid-template-areas: "logo . . . . . . ." "logo . second . third . fourth ." "logo smallLinks smallLinks smallLinks smallLinks smallLinks smallLinks ." "bs bs bs bs bs bs bs ." ".  .  .  .  .  .  .  .";
      grid-template-columns: 16.875rem 9rem 17rem 2.5rem 13rem 2.5rem 9rem auto;
      grid-template-rows: 6rem auto auto 3rem 2rem; }
      .container .mainFooter .mainFooterContent > * {
        grid-area: first;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left; }
      .container .mainFooter .mainFooterContent .firstColumn {
        grid-area: logo; }
        .container .mainFooter .mainFooterContent .firstColumn .infoUnderLogo {
          padding-top: 1.5rem;
          padding-left: 5rem; }
          .container .mainFooter .mainFooterContent .firstColumn .infoUnderLogo > div > h4 {
            font-size: 0.675rem;
            font-weight: 700;
            margin-bottom: 0.5rem; }
          .container .mainFooter .mainFooterContent .firstColumn .infoUnderLogo > div > span {
            font-size: 0.675rem;
            font-weight: 500;
            margin-bottom: 0.75rem; }
        .container .mainFooter .mainFooterContent .firstColumn span {
          margin-bottom: 0.75rem;
          display: inline-block;
          font-size: 0.75rem;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: var(--theme-main-color); }
      .container .mainFooter .mainFooterContent .secondColumn {
        grid-area: second; }
      .container .mainFooter .mainFooterContent .thirdColumn {
        grid-area: third; }
      .container .mainFooter .mainFooterContent .fourthColumn {
        grid-area: fourth; }
      .container .mainFooter .mainFooterContent .smallLinks {
        margin: 1.75rem 0;
        grid-area: smallLinks;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center; }
        .container .mainFooter .mainFooterContent .smallLinks > a {
          margin-left: 2rem;
          height: auto; }
      .container .mainFooter .mainFooterContent .bottomSector {
        padding-left: 5rem;
        grid-area: bs;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap; }
        .container .mainFooter .mainFooterContent .bottomSector .projects {
          align-items: flex-start;
          display: flex;
          margin-left: 5rem; }
          .container .mainFooter .mainFooterContent .bottomSector .projects > span {
            font-size: 0.625rem;
            letter-spacing: 0px;
            font-weight: 700;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: var(--theme-main-color);
            margin: 0 1rem; }
        .container .mainFooter .mainFooterContent .bottomSector .rights {
          font-size: 0.625rem;
          letter-spacing: 0px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          color: var(--theme-main-color);
          font-weight: 400; }
      @media (max-width: 1130px) {
        .container .mainFooter .mainFooterContent {
          grid-template-columns: 15rem 6rem auto 2.5rem auto 2.5rem 9rem 0.5rem; }
          .container .mainFooter .mainFooterContent .firstColumn .infoUnderLogo {
            padding-left: 1rem; }
          .container .mainFooter .mainFooterContent .bottomSector {
            padding-left: 1rem; }
            .container .mainFooter .mainFooterContent .bottomSector .projects {
              margin-left: 2rem; } }
      @media (max-width: 900px) {
        .container .mainFooter .mainFooterContent {
          grid-template-columns: 13rem 3rem auto 1.5rem auto 1.5rem 9rem 0.5rem; } }
      @media (max-width: 720px) {
        .container .mainFooter .mainFooterContent {
          grid-template-areas: ".  .     ." ". second ." ". third ." ". fourth ." ". smallLinks ." ". logo ." ". bs ." ". . .";
          grid-template-columns: 1rem auto 1rem;
          grid-template-rows: 10rem auto auto auto auto auto auto 4rem;
          position: relative; }
          .container .mainFooter .mainFooterContent .firstColumn .footerLogo {
            position: absolute;
            top: 2rem;
            left: 50%;
            transform: translateX(-50%);
            height: 5.5rem !important;
            width: 13.43rem !important; }
          .container .mainFooter .mainFooterContent .firstColumn .infoUnderLogo {
            text-align: center;
            padding: 0; }
          .container .mainFooter .mainFooterContent .firstColumn,
          .container .mainFooter .mainFooterContent .secondColumn,
          .container .mainFooter .mainFooterContent .thirdColumn,
          .container .mainFooter .mainFooterContent .fourthColumn,
          .container .mainFooter .mainFooterContent .smallLinks {
            align-items: center;
            justify-content: center;
            width: calc(100vw - 2rem); }
          .container .mainFooter .mainFooterContent .bottomSector {
            padding-left: 0rem;
            justify-content: center; }
            .container .mainFooter .mainFooterContent .bottomSector .projects,
            .container .mainFooter .mainFooterContent .bottomSector .rights {
              margin-top: 2rem;
              margin-left: 0; } }
    .container .mainFooter * {
      color: var(--theme-main-color); }
  .container .footerLogo {
    height: 4.6875rem;
    width: 4.6875rem; }
  .container .logos {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 2;
    height: 6.875rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .container .logos > * {
      height: 4rem;
      width: auto; }
    @media (max-width: 700px) {
      .container .logos {
        height: 14.375rem;
        flex-wrap: wrap;
        justify-content: center; } }
  .container .bold {
    display: block;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--theme-main-color); }
  .container .slim {
    display: block;
    margin-bottom: 1rem;
    font-size: 0.625rem;
    letter-spacing: 0px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: var(--theme-main-color); }
  .container .socialIconsContainer {
    grid-area: sl;
    display: flex;
    flex-direction: row; }
    .container .socialIconsContainer a {
      margin-right: 0.375rem; }
