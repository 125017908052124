@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import "~assets/style/variables";
@import "./Common.module.scss";


@mixin paddings {
    padding: 5vw 20vw;
}

.container {
    @include common;
}

div {
    font-family: Poppins;
}

.imageButton {
    height: 4rem;
    background: none;
    outline: none;
    border: none;

    img {
        height: 100%;
        cursor: pointer;
    }
}

.load {
    grid-template-columns: 7rem 61rem !important;

    img {
        border-radius: 0 !important;
    }
}

.box {
    margin-bottom: -7rem;
    position: relative;

    .buttonsContainer {
        margin-top: rem(48px);
        max-width: calc(100vw - 2rem);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &>*:first-child {
            margin-bottom: 1.5rem;
        }

        margin-bottom: 2rem;
    }

    .boxImage {
        // background-image: url(assets/images/FrpBoxImage.png);
    }
}


@media (max-width: 1100px) {
    .box {
        display: flex;
        flex-direction: column;

        .textContainer {
            display: contents;

            >* {
                z-index: 10;
            }
        }

        h3 {
            order: 1;
        }

        .boxImage {
            order: 2;
        }

        .features {
            order: 3;
        }

        .buttonsContainer {
            order: 4;
            flex-direction: column;

            &>*:first-child {
                margin-bottom: 1.5rem;
            }
        }
    }

    .container {
        .load {
            margin: 0 1rem;
            padding-top: 1rem;
        }
    }
}


// load

.load {
    border-bottom: none !important;
}

.featureBoxWrap {
    padding-bottom: 100px;
}



// featureBoxWrap


.featureBoxWrap {
    display: flex;
    max-width: 1140px;
    padding: 0px 16px;
    padding-bottom: 50px;

    .textContainer {
        width: 47%;
        font-family: Poppins;

        h3 {
            margin-bottom: 2.5rem;
            font-family: Poppins;
        }
    }

    .boxImageWrap {
        width: 53%;
        padding-left: 75px;
        align-items: center;
        display: flex;
        padding-top: 70px;
        text-align: center;
    }

    .boxImage {
        width: 100%;
    }

    img {
        max-width: 100%;
        margin-bottom: 16px;

        @media (max-width:1199px) {
            max-width: 100%;
        }
    }

    p {
        color: #BB1D7E;
        text-align: center;
        text-transform: capitalize;
        font-weight: 600;
        text-decoration: underline;
        font-family: Poppins;
    }

    @media (max-width:1199px) {
        padding-right: 16px;
    }
}

.featuresWrap {
    margin-bottom: 50px;
}

.container .headContainer {
    height: auto;
    max-height: 100%;
}

.appBack {
    padding: 95px 0px 90px;
}

.flexContainer {
    display: flex;
    align-items: center;
    max-width: 1140px;
    padding: 0px 16px;
    margin: 0 auto;
}

.bannerTextContent {
    .headerTitle1 {
        margin-top: 0rem;
        font-family: Poppins;
        font-size: 3.5rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        margin-bottom: 30px;
        display: inline-block;
        margin-bottom: 1.5rem;
        color: #fff;
    }

    .headerText {
        color: #fff;
        font-family: Poppins;
        margin-right: calc(100% - 470px);

        @media (max-width:1100px) {
            margin-right: 0;
        }
    }
}


.appBack img {
    max-width: 600px;
    padding-left: 34px;
}

@media (max-width:1199px) and (min-width:821px) {
    .container {
        .flexContainer {
            padding-right: 16px;
        }

        .appBack {
            img {
                max-width: 550px;
            }
        }
    }
}

@media (max-width:820px) {
    .container {
        .flexContainer {
            display: block;

            .bannerImage {
                text-align: center;
            }
        }

        .load {
            padding-top: 30px;
        }

        .bannerTextContent {
            text-align: center;
            margin-bottom: 30px;

            .headerTitle1 {
                font-size: 2rem;
            }
        }

        .appBack {
            img {
                max-width: 100%;
                padding-left: 0px;
                margin-top: 40px;
            }
        }

        .headContainer {
            padding-top: 0;
        }

        .featureBoxWrap {
            display: block;
            padding-bottom: 0px;
            padding-top: 20px;

            .boxImageWrap {
                padding-left: 0;
                margin-top: 0px;
                width: 100%;
                justify-content: center;
            }

            .textContainer {
                width: 100%;

                h3 {
                    text-align: center;
                }
            }
        }

        .appBack {
            padding: 70px 0px 70px;

        }
    }
}

@media (max-width:820px) {
    .container {
        .bannerTextContent {
            margin-bottom: 0;
        }

        .featuresWrap {
            margin-bottom: 40px;
        }

        .buttonsContainer {
            display: flex;
            align-items: center;

            .imageButton {
                height: 3rem;
            }
        }

        .appBack {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .featureBoxWrap {
            .boxImageWrap {
                padding-right: 0;
                padding-top: 40px;
            }
        }
    }

    .featureBoxWrap {
        .redText {
            display: inline-block;
        }
    }
}

.redText {
    color: #BB1D7E;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: underline;
}

.icon {
    border-radius: 22px ! important;
    padding-left: 0px ! important;
    margin-left: 0px;
    // max-width: 560px ! important;
}

.ml0 {
    margin-left: 0px ! important;
}