@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
}

