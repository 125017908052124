@import "~assets/style/variables";
@import "~assets/style/mixins/backgroundGradientAnimation";
@import "./Helpers/mixins/defaultTrackContainer.module";
@import "./Helpers/mixins/versions.module";
@import "./Helpers/mixins/mainContainerForHome.module";
@import "./Helpers/mixins/displayVersion.module";
@import "./Helpers/mixins/mediaMobile.module";
@import "~assets/style/mixins/loader";

h3 {
    color: var(--theme-main-color);
    font-weight: 600;
}
body .genre{
    align-items: flex-start!important;
    line-height: 1.6!important;
}
.loader {
    @include loader(6rem);
    background-color: var(--theme-background-color);

    &.autoWidth {
        width: 100%;
    }
}

.noTracks {
    @include noTracks(7rem);

    &.autoWidth {
        width: 100%;
    }
}

.versionHeader {
    width: 100%;
    position: relative;
    font-size: rem(12px);
    color: var(--theme-muffled-color);
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .versionText {
        background-color: var(--theme-background-changed-color);
        z-index: 2;
        position: relative;
        padding: 0 2rem;
    }
    .headerLine {
        width: calc(100% - 5rem);
        height: 1px;
        position: absolute;
        top: 50%;
        left: 3.25rem;
        border-bottom: 1px solid var(--theme-muffled-color);
        z-index: 1;
    }
}

.mainContainer {
    margin-bottom: 2.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: 67rem) {
        justify-content: center;
    }

    * {
        line-height: 1;
    }

    .trackVersion {
        z-index: 1;
        & > div {
            &:nth-child(2) {
                margin: 0;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                margin-top: 0.8rem;
            }

            p:first-child {
                padding-top: 0.8rem;
            }

            margin-left: 1rem;
        }
        &:last-child{
            padding-bottom:25px !important;
        }
    }

    .tracksColumnContainer {
        width: 100%;

        position: relative;

        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .tracksColumnHeader,
        .trackContainer {
            @include defaultTrackContainerAndTrackHeader();

            @media (max-width: 720px) {
                @include trackContainerMobile();
            }
        }

        .tracksColumnHeader {
            height: rem(47px);
            width: 100%;
            grid-template-rows: 1rem rem(31px);

            @media (max-width: 720px) {
                display: none !important;
            }

            &.billboard {
                grid-template-columns: 6rem 15.8125rem 5.625rem 2rem 3rem 6rem;

                .index {
                    display: none;
                }

                .playInfo {
                    padding-left: 0;
                }
            }

            * {
                font-size: 0.625rem;
                font-weight: 900;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 1.5px;
                color: var(--active-elem);
                opacity: 1 !important;
                text-align: left;

                grid-row-start: 1;
                grid-row-end: 2;
            }

            &:not(.billboard) > div:nth-child(2) {
                padding-left: 3.5rem;
            }

            .buttons {
                padding-left: 3px;
            }

            .playInfo {
                padding-left: rem(54px);
            }
        }

        .trackContainer {
            @include defaultTrackContainer();
            @include versions();
        }
        @include displayVersion();
    }
}

@include mainContainerForHome();

.disabledButton {
    cursor: initial !important;
    opacity: 0.8;
}

.isVersionsVisiable {
    border-top: 1px var(--theme-background-changed-color) solid;
    margin-top: -1px;
    border-bottom: 0 !important;
    background-color: var(--theme-background-changed-color);
}

.smallVersionContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem !important;
}



.trackVersionColorType{
    display: flex;
    gap: 15px;
    .ColorField{
        display:flex;
        align-items: center;
        margin-bottom: 9px;
        span{
            display: inline-block;
            margin-right: 5px;
            width: 15px;
            height: 22px;
            border-radius: 4px;
            border: 1px var(--theme-track-version-color) solid;
        }
        p{
            color: #fff;
            margin-bottom: 0px;
            margin-top: 0px;
            font-size: 13px;
            opacity: .75;
        }
    }
    .ColorTypeOne{
       span{
         background-color: var(--track-active-elem) !important;
       }
    }
    .ColorTypeTwo{
        span{
            background: var(--track-new-version) !important;
        }
     }
     .ColorTypeThree{
        span{
            background: var(--track-default-elem) !important;
        }
     }.ColorTypeFour{
        span{
            background: var(--track-DIY-Versions-elem) !important;
        }
     }
     .greenButton {
        background-color: #023010 !important;
        color: #ffffff !important;
        border: none !important;
      }
      
}
.allVersions{
    // background: rgba(167, 91, 29, 0) !important;
    div span{
        // background: #2b282a !important;
    }
}
.originalVersions{
    // background: rgba(164, 148, 29, 0) !important;
    div span{
        // background: #2b342a !important;
    }
}
.introOutroVersions{
    // background: rgba(28, 98, 182, 0) !important;
    div span{
        // background: #102a48 !important;
    }
}
.remixeVersions{
    // background: rgba(39, 102, 14, 0) !important;
    div span{
        // background: #122a27 !important;
    }
}
.DownloadButtonInfo{
    position: relative;
}
.trackName{
    padding-top: 0.8rem ! important;
}
 
@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-10%);
    }
}

.openEffect {
    transition: slideDown 0.2s ease-in forwards;
}

// max height 450px
.closeEffect1 {
    transition: max-height 0.2s ease-in-out;
}

// max height 750px
.closeEffect2 {
    transition: max-height 0.3s ease-in-out;
}

// max height 1000px
.closeEffect3 {
    transition: max-height 0.4s ease-in-out;
}

// max height 1400px
.closeEffect4 {
    transition: max-height 0.5s ease-in-out;
}

// max height 2000px+
.closeEffect5 {
    transition: max-height 0.7s ease-in-out;
}

// max height 3000px
.closeEffect6 {
    transition: max-height 0.9s ease-in-out;
}

// max height 4000px
.closeEffect7 {
    transition: max-height 1.1s ease-in-out;
}

// max height 4000px+
.closeEffect8 {
    transition: max-height 1.4s ease-in-out;
}

.panel {
    max-height: 0;
    overflow-y: clip;
}
.trackContainer.billboardTracks{
    grid-template-columns: 3rem 42.875rem;
}
.trackContainer.billboardTracks .rightcolumn .trackinfonew .bpmandkeyinfo{
    margin-top: 0px !important;
}
.trackContainer.billboardTracks .rightcolumn,.trackContainer.billboardTracks .rightcolumn .dateOrbtn{
    padding-top: 0px !important;
}
.rightcolumn {
    position: absolute;
    width: 100%;
    right: 0;
    max-width: 45%;
    align-items: flex-start!important;
    height: auto!important;
    padding-top: 15px!important;
    margin-top: 0!important;
    top: 0;
}

.rightcolumn .trackinfonew {
    width: 100%;
    display: block!important; 
    text-align: right!important;
    padding: 0 12px 0 0!important;
}
.rightcolumn .trackinfonew .buttons > div  { right: -141px !important; left: unset; }
.rightcolumn .bpmandkeyinfo div{color: #646f7c !important;}
.rightcolumn .bpmandkeyinfo{justify-content: flex-end!important; font-weight: 600!important;}
.rightcolumn .bpmandkeyinfo *{ font-weight: 600!important;}

.rightcolumn .bpmandkeyinfo .bpmtxt,
.rightcolumn .bpmandkeyinfo .keytxt{color: var(--theme-third-color); font-weight: 600!important; padding-left: 5px;}

.rightcolumn .trackinfonew .moodinfo{color: #646f7c; justify-content: end; font-weight: 600!important;}
.rightcolumn .trackinfonew .genreinfo .genretxt{display: block!important; color: var(--track-new-version);line-height: 1!important; height: unset;font-weight: bold!important;}
.rightcolumn .trackinfonew .genreinfo{ font-weight: 600!important; justify-content: end; gap: 6px; color: #646f7c !important;}
.rightcolumn .trackinfonew .moodinfo .moodtxt{font-weight: 600!important; color: var(--theme-third-color); padding-left: 5px; text-transform: capitalize;}

.rightcolumn .dateOrbtn {
    flex-direction: column!important;
    height: auto!important;
    justify-content: space-between!important;
    min-width: 120px;
}

.rightcolumn .dateOrbtn .date {
    width: 100%;
    text-align: right;
    flex-direction: row-reverse;
    height: auto!important;
    padding-right: 5px;
    // padding-bottom: 24px!important;
    color: #646f7c;
    font-weight: 600!important;
}
.bpmandkeyinfo span {color: #646f7c !important; padding: 0 3px !important;}
.displayVersion .rightcolumn .dateOrbtn .date{padding-bottom:5px!important;}
.displayVersion .rightcolumn .dateOrbtn .buttons{margin: 0; margin-left: auto;}

// .displayVersion .rightcolumn .dateOrbtn.longVersion{margin-top:-23px!important;}
.albums{
    display: block;
    + .displayVersion.panel{
        padding-bottom: 30px !important;
        .trackVersion{
            grid-template-rows: 0 4rem !important;
            &:last-child{
                padding-bottom: 0 !important;
            }
        }
    }
    + .displayVersion.panel.hidden{
        padding-bottom: 0px !important;
    }
    .trackContainer{
        .buttons svg{
            width: 28px!important;
            height: 24px!important;
            border-radius: 40% !important;
        }
    }
}
.downloadAll{
    background: #8b8000;
    padding: 1px 0 0 0 ! important;
    border-radius: 4px;
    margin: 10px 4px 0px auto ! important;
    letter-spacing: 0.35px ! important;
    width: 100%;
    font-size: 0.6rem ! important;
    cursor: pointer;
    border: none;
    justify-content: center ! important;
    color: white ! important;
    max-height: 18px;
    max-width: 90px;
    font-weight: bold ! important;
    height: 18px ! important;
    line-height: 19px ! important;
}
.hidden{
    // display: none !important;
    // opacity: 0 !important;
}