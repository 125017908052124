@import "~assets/style/variables";

@mixin common() {
    display: flex;
    font-size: 14px;
    line-height: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--theme-main-color);

    .headContainer {
        height: 48rem;
        max-height: 50vw;
        width: 100%;

        @media (max-width: 800px) {
            padding-top: 9.5rem;
            max-height: 30rem;
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .appBack {
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            // height: 50vw;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @media (max-width: 800px) {
                height: 100%;
            }
        }

        .frpBack {
            background-image: url(assets/images/spins-bg-img.png);
            background-position: 70% 0;
        }

        .spinsBack {
            background-image: url(assets/images/spins-bg-image.png);
            margin-top: 100px;
        }

        .textContent {
            padding: 10rem calc(50vw - 33rem) 0;
            color: var(--white);

            @media (max-width: 1100px) {
                padding: 10rem 3rem 0;
            }

            @media (max-width: 800px) {
                padding: 3rem 1rem 0;
            }

            .headerTitle1,
            .headerTitle2 {
                font-size: 2rem;
                font-weight: 800;

                @media (min-width: 1300px) {
                    font-size: 4rem;
                }
            }

            .headerTitle2 {
                color: #e60039;
            }

            .headerText {
                width: 42%;
                margin-top: 2rem;

                @media (max-width: 1000px) {
                    font-size: 12px;
                    line-height: 14px;
                    width: 30%;
                }

                @media (max-width: 800px) {
                    font-size: 12px;
                    line-height: 14px;
                    width: 50%;
                }

                @media (min-width: 1300px) {
                    font-size: 1rem;
                }
            }
        }
    }

    .load {
        display: grid;
        grid-template-columns: 7rem 59rem;
        grid-column-gap: 0.5rem;
        width: auto;
        align-items: center;
        padding: 5rem 0;
        border-bottom: 1px solid var(--theme-muffled-color);

        span {
            white-space: pre-wrap;
            font-family: Poppins;
        }

        @media (max-width: 1100px) {
            z-index: 10;
        }

        @media (max-width: 1100px) {
            grid-template-columns: auto auto auto;
            padding-top: 0;
            margin: 0 3rem;
            padding-bottom: 0;

            img {
                margin: 3rem 0;
            }
        }

        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
        }

        .icon {
            height: 10rem;
        }
    }

    .box {
        display: grid;
        grid-template-columns: 34rem 33rem;
        grid-column-gap: 0.5rem;
        width: auto;
        align-items: center;
        padding: 6rem 0 0 0;

        @media (max-width: 800px) {
            margin: 0 3rem;
        }

        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
        }
    }

    .textContainer {
        h3 {
            margin-top: 1.5rem;
            font-family: Montserrat;
            font-size: 2rem;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            margin-bottom: 1.5rem;
        }
    }

    .boxImage {
        height: 50rem;
        width: 200%;
        transform: translate(-30%, -5%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;

        @media (max-width: 1100px) {
            height: 25rem;
            transform: translateX(-3%);
            width: 150%;
            background-size: contain;
            background-position: center;
        }

        @media (max-width: 400px) {
            background-size: 140%;
        }
    }
}

@media (max-width:767px) {
    .container {
        .headContainer {
            .spinsBack {
                margin-top: 72px;
                padding-bottom: 70px;
            }
        }
    }
}