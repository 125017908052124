/*
    Fonts
*/
.container {
  width: 100%;
  max-width: calc(100vw - 2rem);
  display: flex; }
  .container.withMegaLinks {
    flex-wrap: wrap; }
  .container .megaLinks {
    width: 100%;
    height: 3.5rem;
    border-bottom: rgba(255, 255, 255, 0.15) 1px solid;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap; }
    .container .megaLinks * {
      font-size: 1.45rem;
      font-weight: 900;
      margin-right: 1rem; }
    @media (max-width: 600px) {
      .container .megaLinks {
        margin-top: 2rem;
        height: 5.5rem;
        border-bottom: none; } }
  .container .links {
    padding-bottom: 0.9rem;
    border-bottom: rgba(255, 255, 255, 0.15) 1px solid;
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: calc(100vw - 2rem);
    transition: 0ms; }
  .container .typeButtons,
  .container .mediaTypeButtons {
    margin-right: 1rem;
    font-size: 14px;
    padding-bottom: 0.45rem;
    border-bottom: rgba(255, 255, 255, 0.15) 1px solid;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap; }
    .container .typeButtons .typeButton,
    .container .typeButtons .mediaTypeButton,
    .container .mediaTypeButtons .typeButton,
    .container .mediaTypeButtons .mediaTypeButton {
      flex-shrink: 0;
      color: var(--theme-main-color);
      width: auto;
      min-width: 0;
      padding: 0 0.75rem; }
      .container .typeButtons .typeButton > *:last-child,
      .container .typeButtons .mediaTypeButton > *:last-child,
      .container .mediaTypeButtons .typeButton > *:last-child,
      .container .mediaTypeButtons .mediaTypeButton > *:last-child {
        background-color: var(--active-elem); }
  .container .typeButtons .typeButton {
    font-size: 2rem;
    font-weight: 900; }
    .container .typeButtons .typeButton > *:last-child {
      height: 2px; }
