@mixin versions() {
    .versions {
        z-index: 1000;
        max-width: 100%;
        height: auto;
        min-height: 0.865rem;
        grid-area: vers;
        gap: 8px !important;
        &.longVersion {
            grid-area: vers;
            position: relative;

            & > p {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
        }

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-end;
        margin-top: 10px;
        padding-left: 3.1rem;
        padding-bottom: 0.2rem;

        .smallVersionContainer button {
            height: 1rem !important;

            &:hover {
                transition: 0.25s;
                transform:  scale(1.04, 1.04);
            }

            &:focus {
                outline: none;
            }

            margin-right: 0.5rem;
            margin-top: 2px;

            span {
                font-size: 0.695rem;
                // font-weight: 500;
                color: var(--theme-muffled-color);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 9rem;
                display: inline-block;
                line-height: rem(14px);
            }

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            height: 23px !important;
            padding: 4px 8px !important;
            border-radius: 4px !important;
            border: 1px solid var(--theme-track-version-color);
            cursor: pointer;
            margin: 0 !important;
            &.activeVersion {
                background-color: var(--active-elem);
                border: 1px solid var(--active-elem);

                span {
                    color: var(--white);
                }
            }
        }
    }
}
