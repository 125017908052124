.container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100vw;
    height: auto;
    max-height: 50vw;
    overflow: hidden;
    padding-top: 0rem;
    background-color: #0d1c2d;

    @media (max-width: 720px) {
        padding-top: 0rem;
        margin-top: 9.5rem;
    }

    @media (max-width: 600px) {
        margin-top: 11rem;
    }

    .child {
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        transition: 1000ms ease-in-out;
        flex-shrink: 0;
        overflow: hidden;
        will-change: opacity, transform, transition;

        img {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            height: 100%;
            width: auto;
        }
    }
    .center {
        transform: translateX(0);
        transition: 900ms ease-in-out;
    }
    .left {
        transform: translateX(-100%);
        opacity: 0;
    }
    .leftBlink {
        transition: 0ms;
    }
    .right {
        transform: translateX(100%);
        opacity: 0;
    }
    .rightBlink {
        transform: translateX(100%);
        transition: 0ms;
    }

    .controlsContainer {
        width: 100%;
        position: absolute;
        bottom: 5.25rem;
        display: grid;
        grid-template-columns: auto minmax(auto, 1208px) auto;
        .controls {
            grid-column-start: 2;
            grid-column-end: 3;

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .lines {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                & > div {
                    margin-right: 0.5rem;

                    width: 4rem;
                    height: 1px;

                    position: relative;

                    background-color: rgba(255, 255, 255, 0.3);
                    & > div {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;

                        background-color: white;

                        transform-origin: left;
                        transform: scaleX(0);
                    }
                    &.empty {
                        transform: scaleX(0);
                        transition: 0ms;
                    }
                    &.full {
                        & > div {
                            transition: 0ms;
                        }
                    }
                    &.progress {
                        & > div {
                            transform: scaleX(1);
                            transition: 14900ms;
                        }
                    }
                }
            }
        }
    }
}
