/*
    Fonts
*/
.container {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 4;
  display: grid;
  grid-template-areas: "nav  nav    nav  " "left center rigth";
  grid-template-columns: 37rem 2.5rem 37rem;
  width: auto; }
  @media (max-width: 1100px) {
    .container {
      grid-template-columns: auto auto auto; } }
  .container .right {
    grid-area: rigth; }
  .container .wide {
    grid-area: left / left / right / right;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 100vw;
    position: relative; }
  .container .navigation {
    grid-area: nav;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100vw;
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
    @media (max-width: 1100px) {
      .container .navigation {
        width: 100vw; } }
    .container .navigation > * {
      margin: 2rem 2rem 0 2rem; }
    .container .navigation > *:nth-child(2) {
      margin-top: 3rem; }
    @media (max-width: 600px) {
      .container .navigation {
        width: 100vw; }
        .container .navigation > * {
          margin: 0 2rem 0 2rem; } }
  .container .buttonContainer {
    max-width: calc(100vw - 2rem);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; }
    .container .buttonContainer > * {
      flex-shrink: 1; }
