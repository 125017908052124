/*
    Fonts
*/
.container {
  display: grid;
  grid-template-columns: auto 17rem 40px 17rem auto;
  grid-template-rows: auto auto;
  align-items: center; }
  .container .photoContainer {
    grid-column-start: 2;
    grid-column-end: 5;
    justify-self: center;
    flex-direction: row; }
  .container .photo {
    width: 7.5rem;
    height: 7.5rem;
    background-color: var(--grey);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .container .photo > img {
      height: 100%;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover; }
  .container .defaultAvatar {
    width: 100px;
    height: 100px;
    margin: 0.7rem 0 0 0.65rem; }
  .container .changePhotoButton {
    text-transform: none;
    justify-self: center;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #aeb0bd; }
  .container .left {
    grid-column-start: 2;
    grid-column-end: 3; }
  .container .right {
    grid-column-start: 4;
    grid-column-end: 5; }
  .container .center {
    grid-column-start: 2;
    grid-column-end: 5;
    justify-self: center; }
    .container .center .checked {
      background-color: rgba(0, 0, 0, 0);
      background-image: url("assets/images/filledCheckboxPink.svg");
      border: 0; }
  .container .semiWide {
    width: 21rem;
    grid-column-start: 2;
    grid-column-end: 5;
    justify-self: center; }
  .container .wide {
    grid-column-start: 2;
    grid-column-end: 5;
    justify-self: initial; }
  .container .narrow {
    max-width: 15.5rem; }
  .container .head {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 900;
    color: var(--theme-main-color); }
  .container .subTitleText {
    text-align: center;
    margin-top: 2.5rem;
    grid-column-start: 2;
    grid-column-end: 5;
    font-size: 20px;
    font-weight: 900;
    color: var(--theme-main-color); }
  .container .button {
    margin-top: 4.25rem; }
    @media (max-width: 40rem) {
      .container .button {
        margin-top: 1.5rem; } }
  .container .fadedButton {
    margin: auto;
    opacity: 0.3; }
    .container .fadedButton:hover {
      opacity: 1; }
  @media (max-width: 40rem) {
    .container {
      grid-template-columns: auto 17rem auto;
      grid-template-rows: auto auto auto; }
      .container .left,
      .container .right,
      .container .center {
        grid-column-start: 2;
        grid-column-end: 4; } }

.commonError {
  height: 1rem;
  margin-bottom: 0.25rem;
  color: var(--theme-error-color);
  text-align: center; }

.tabButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.messageUpdate {
  width: 100%;
  transition: 500ms ease-in-out;
  margin-top: 0.625rem;
  height: 1rem;
  color: var(--theme-main-color); }

@-webkit-keyframes autofill {
  to {
    color: var(--grey);
    background: none; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }
