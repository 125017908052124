/*
    Fonts
*/
.toggleGenres {
  height: 1rem; }
  .toggleGenres > svg {
    transform: rotateZ(180deg);
    will-change: trasnform;
    transition: 300ms ease-in-out; }
  .toggleGenres.showGenres > svg {
    transform: rotateZ(0deg); }
  @media (max-width: 700px) {
    .toggleGenres {
      position: absolute;
      right: 1.5rem;
      width: 3rem;
      height: 2.5rem !important; } }

.note {
  position: absolute;
  right: 1rem; }

.genreButton > span {
  font-weight: 400; }

.genreButton.active > span {
  font-weight: 800; }

.presetButton {
  width: auto;
  min-width: initial;
  position: relative;
  opacity: 1 !important;
  padding: 0.5rem 0; }
  .presetButton > span {
    width: auto;
    margin-left: 0.5rem;
    padding-right: 0.125rem;
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    opacity: 0.3; }
  @media (max-width: 700px) {
    .presetButton {
      width: calc(100vw - 2rem) !important;
      height: 3rem !important; } }
  .presetButton:disabled {
    opacity: 0.5 !important; }
  .presetButton.activeButton span {
    opacity: 1; }

.mainButtonContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.list {
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition: 300ms; }
  .list.opened {
    max-height: 100rem; }
  .list > button {
    width: auto;
    min-width: initial;
    position: relative;
    opacity: 1 !important;
    padding: 0.5rem 0;
    padding-left: 1rem !important; }
    .list > button > span {
      width: auto;
      margin-left: 0.5rem;
      padding-right: 0.125rem;
      font-size: 0.875rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      opacity: 0.3; }
    .list > button > span {
      opacity: 1; }
    @media (max-width: 700px) {
      .list > button {
        width: calc(100vw - 2rem) !important;
        height: 3rem !important; } }
