.links {
    margin-bottom: 3.5rem;
    * {
        font-size: 0.75rem;
        font-weight: 600;
    }
    & > * {
        min-width: 6rem;
        opacity: 0.3;
    }
}
