@mixin defaultTrackContainerAndTrackHeader() {
    width: 100%;
    height: auto;

    display: grid;
    grid-template-areas: ".        .       .          .    .      .        .       " "idNumber play    genre      bpm  key    released buttons " ".        vers    vers       vers vers   vers     vers    " ".        .       .          .    .      .        .       ";

    grid-template-columns: 3rem rem(296px) rem(82px) 2.5rem 3.5rem 6rem 120px;
    grid-template-rows: 0.8615rem 3.5rem auto 0.75rem;

    border-bottom: 1px solid var(--theme-muffled-color);
    position: relative;

    & > * {
        text-align: left;
        font-size: rem(10px);
    }

    .index {
        grid-area: idNumber;
        padding-bottom: 0.5rem;
        justify-self: center;
        align-items: center;
    }

    & > *:nth-child(2) {
        grid-area: play;

        & > :first-child {
            & > :first-child {
                margin-top: -1.5rem;
            }
        }

        & > :nth-child(2) {
            padding-top: rem(6px);
        }
    }
    .genre,
    .bpm,
    .key,
    .released {
        opacity: var(--theme-changed-opacity);
    }

    .genre {
        grid-area: genre;
        margin-top: 0.125rem;
    }

    .bpm {
        grid-area: bpm;
        margin-top: 0.125rem;
    }

    .key {
        grid-area: key;
        margin-top: 0.125rem;
    }

    .released {
        grid-area: released;
        margin-top: 0.125rem;
    }

    .buttons {
        margin-top: 0.125rem;
        padding-right: 0.25rem;
        grid-area: buttons;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        & > * {
            margin-left: 0.25rem;
        }
    }
}

@mixin defaultTrackContainer() {
    * {
        height: 1.5rem;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.75;
        font-size: 12px;
        letter-spacing: normal;
        color: var(--theme-main-color);
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        & :first-child {
            height: 100%;
            font-size: 0.75rem;
            font-weight: normal;
        }
    }

    .bpm,
    .key {
        color: var(--theme-third-color);
        opacity: 1;
    }

    & > :first-child {
        height: auto;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .playInfo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: fit-content;
        max-width: 412px;

        & > *:first-child {
            & > :first-child {
                height: 3rem;
            }

            width: auto;
            height: 72px;
            // line-height: 1;
            // z-index: 0;
            min-width: 50px;
        }

        .playIcon {
            height: 41px !important;
            width: 40px !important;
        }
        .nameAndArtist.album {
            height: 3.5rem !important;
            max-width: 240px;
        }
        .nameAndArtist {
            height: 4.9rem;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            & > *:first-child {
                color: var(--theme-main-color);
                font-size: 0.875rem;
                height: 1.6rem;
                line-height: 1.31;
                overflow: hidden;
                width: 100%;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 0.5rem;
            }

            & > *:last-child {
                line-height: 1;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                color: var(--theme-third-color);
                opacity: 1;
            }

            & > *:nth-child(2) {
                line-height: 1;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                color: var(--theme-third-color);
                opacity: 1;
            }

            .buttons{min-width:140px;}
        }
    }
}
