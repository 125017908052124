/*
    Fonts
*/
.loader {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 7rem;
  width: 100%;
  min-width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000; }

.container {
  position: relative;
  padding-top: 8.0625rem;
  padding-bottom: 10.3125rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  @media (max-width: 800px) {
    .container {
      padding-top: 11.25rem; } }
  .container .contentContainer {
    position: relative;
    max-width: 56.5rem;
    min-height: 20rem; }
  .container h1 {
    font-size: 3rem;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: var(--theme-main-color); }
  .container h2 {
    margin-top: 4.5rem;
    font-weight: 900;
    text-align: center; }
  .container h3 {
    color: var(--theme-main-color);
    opacity: 0.3;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center; }
  .container h4 {
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center; }
  .container .artistVideo1 {
    width: 376px;
    height: 216px;
    background-color: var(--dark-grey);
    cursor: pointer;
    transition: 300ms ease-in-out; }
    .container .artistVideo1 .play {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: relative;
      top: -102%;
      transition: 400ms ease-in-out; }
      .container .artistVideo1 .play .playButton {
        position: relative;
        top: 4.21875rem;
        left: 9.21875rem; }
    .container .artistVideo1 .play:hover {
      opacity: 1; }
  .container .photo {
    overflow: hidden;
    margin: 32px 0 24px 0;
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 6.25rem;
    background-color: var(--active-elem); }
  .container .socialBlock {
    margin: 16px 0 40px 0; }
  .container .bio {
    width: calc(100vw - 2rem);
    max-width: 784px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--theme-main-color); }
    .container .bio .bioString {
      margin: 0; }
    .container .bio .learnMore {
      letter-spacing: normal;
      font-size: 14px;
      font-weight: 500;
      height: 100%;
      display: inline;
      color: var(--active-elem);
      margin-left: -10px; }
    .container .bio .hide {
      margin: auto;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: normal;
      color: var(--active-elem); }
    @media (min-width: 800px) {
      .container .bio {
        transform: translateX(2vw); } }
  .container.background {
    background-image: linear-gradient(to bottom, var(--theme-background-transparent-color) 0%, var(--theme-background-color) 60%, var(--theme-background-color) 100%), url(assets/images/darkArtistBack.jpg);
    background-repeat: no-repeat;
    background-size: 100% auto; }
    .container.background.light {
      background-image: linear-gradient(to bottom, transparent 0%, var(--theme-background-highttransparent-color) 100%), url(assets/images/lightArtistBack.jpg); }
    @media (max-width: 1400px) {
      .container.background {
        background-size: auto auto; } }
  .container .content {
    display: flex;
    width: 1260px;
    flex-wrap: wrap; }
    .container .content > * {
      margin: 32px 20px 24px 20px; }
  .container p.videoName {
    font-weight: bold;
    line-height: 1.31;
    color: #ffffff;
    width: 372px;
    overflow: hidden;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
    text-overflow: ellipsis; }
  .container p.videoAuthor {
    margin-top: 0;
    opacity: 0.3;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--theme-main-color);
    overflow: hidden;
    text-overflow: ellipsis; }
