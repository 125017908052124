/*
    Fonts
*/
.DropDownContainer {
  display: block;
  position: absolute; }
  @media (max-width: 600px) {
    .DropDownContainer {
      left: 7.6rem !important;
      top: 4rem !important; } }
  .DropDownContainer .detail {
    position: relative; }
    @media (max-width: 800px) {
      .DropDownContainer .detail {
        margin-left: 0.6rem; } }
    @media (max-width: 600px) {
      .DropDownContainer .detail {
        left: -3rem !important; } }
  .DropDownContainer .holder {
    position: relative;
    z-index: 3;
    top: -18px;
    border-radius: 2px;
    background-color: var(--active-elem); }
    @media (max-width: 800px) {
      .DropDownContainer .holder {
        margin-left: 0.6rem; } }
    @media (max-width: 600px) {
      .DropDownContainer .holder {
        left: 0; } }
