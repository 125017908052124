@charset "UTF-8";
/*--------------
1.0 Bootstrap
----------------*/
/*--------------
2.0 Custom Variables
----------------*/
/*--------------
3.0 Theme Style
   3.1 Utilities

      3.1.1 Base
      3.1.2 Custom Shapes
      3.1.3 Custom Buttons

   3.2 Core

      3.2.1 Reset
      3.2.2 Header
      3.2.3 Preloader
      3.2.4 Footer

   3.3 Sections

   3.4 Pages

----------------*/
/*--------------
4.0 Responsive
----------------*/
:root {
  --blue: #1FC4CF;
  --indigo: #6610f2;
  --purple: #006064;
  --pink: #fa456e;
  --red: #FA0029;
  --orange: #ff4646;
  --yellow: #ff9b0b;
  --green: #10cc2f;
  --teal: #d9f762;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #7b848c;
  --gray-dark: #343a40;
  --primary: #FA0029;
  --secondary: #7b848c;
  --success: #10cc2f;
  --info: #17a2b8;
  --warning: #ff9b0b;
  --danger: #FA0029;
  --light: #f8f9fa;
  --dark: #040404;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Poppins", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(4, 4, 4, 0); }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #040404;
  text-align: left;
  background-color: #fff !important; }

.mainHomePage {
  /*--------------
2.0 Custom Variables
----------------*/
  /*=============|Custom Colors|===============*/
  /*===============|Pages Banners Common Properties|===============*/
  /**===============|Banner Overlay Color|====================*/
  /*--------------
3.0 Theme style
----------------*/
  /*--------------
3.1 Utilities
----------------*/
  /*--------------
3.1.1 Base
----------------*/
  /*=============|Custom Background Color Classes|=============*/
  /* GENERAL STYLE START */
  /* banner */
  /*--------------
3.1.3 Custom Buttons
----------------*/
  /*--------------
3.2 Core
----------------*/
  /*--------------
3.2.1 Reset
----------------*/
  /*--------------
3.2.2 Header
----------------*/
  /*===============|header section|===============*/
  /*--------------
3.2.4 Footer
----------------*/
  /*==============|Footer Sec title|===============*/
  /*--------------
3.3 Sections
----------------*/
  /*--------------
3.3.1 Banner Section
----------------*/
  /*--------------
3.3.5 Slider Elements Section
----------------*/
  /*==============|Slick Slider|==============*/
  /*==============|4.0 Responsive|===============*/ }
  .mainHomePage article, .mainHomePage aside, .mainHomePage figcaption, .mainHomePage figure, .mainHomePage footer, .mainHomePage header, .mainHomePage hgroup, .mainHomePage main, .mainHomePage nav, .mainHomePage section {
    display: block; }
  .mainHomePage [tabindex="-1"]:focus {
    outline: 0 !important; }
  .mainHomePage hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  .mainHomePage h1, .mainHomePage h2, .mainHomePage h3, .mainHomePage h4, .mainHomePage h5, .mainHomePage h6 {
    margin-top: 0;
    margin-bottom: 0.75rem; }
  .mainHomePage p {
    margin-top: 0;
    margin-bottom: 1rem; }
  .mainHomePage abbr[title],
  .mainHomePage abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none; }
  .mainHomePage address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  .mainHomePage ol,
  .mainHomePage ul,
  .mainHomePage dl {
    margin-top: 0;
    margin-bottom: 1rem; }
  .mainHomePage ol ol,
  .mainHomePage ul ul,
  .mainHomePage ol ul,
  .mainHomePage ul ol {
    margin-bottom: 0; }
  .mainHomePage dt {
    font-weight: 600; }
  .mainHomePage dd {
    margin-bottom: 0.5rem;
    margin-left: 0; }
  .mainHomePage blockquote {
    margin: 0 0 1rem; }
  .mainHomePage b,
  .mainHomePage strong {
    font-weight: bolder; }
  .mainHomePage small {
    font-size: 80%; }
  .mainHomePage sub,
  .mainHomePage sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  .mainHomePage sub {
    bottom: -0.25em; }
  .mainHomePage sup {
    top: -0.5em; }
  .mainHomePage a {
    color: #040404;
    text-decoration: none;
    background-color: transparent; }
  .mainHomePage a:hover {
    color: #ae001c;
    text-decoration: none; }
  .mainHomePage a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none; }
  .mainHomePage a:not([href]):not([tabindex]):hover, .mainHomePage a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  .mainHomePage a:not([href]):not([tabindex]):focus {
    outline: 0; }
  .mainHomePage pre,
  .mainHomePage code,
  .mainHomePage kbd,
  .mainHomePage samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }
  .mainHomePage pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto; }
  .mainHomePage figure {
    margin: 0 0 1rem; }
  .mainHomePage img {
    vertical-align: middle;
    border-style: none; }
  .mainHomePage svg {
    overflow: hidden;
    vertical-align: middle; }
  .mainHomePage table {
    border-collapse: collapse; }
  .mainHomePage caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #7b848c;
    text-align: left;
    caption-side: bottom; }
  .mainHomePage th {
    text-align: inherit; }
  .mainHomePage label {
    display: inline-block;
    margin-bottom: 0.5rem; }
  .mainHomePage button {
    border-radius: 0; }
  .mainHomePage button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .mainHomePage input,
  .mainHomePage button,
  .mainHomePage select,
  .mainHomePage optgroup,
  .mainHomePage textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  .mainHomePage button,
  .mainHomePage input {
    overflow: visible; }
  .mainHomePage button,
  .mainHomePage select {
    text-transform: none; }
  .mainHomePage select {
    word-wrap: normal; }
  .mainHomePage button,
  .mainHomePage [type=button],
  .mainHomePage [type=reset],
  .mainHomePage [type=submit] {
    -webkit-appearance: button; }
  .mainHomePage button:not(:disabled),
  .mainHomePage [type=button]:not(:disabled),
  .mainHomePage [type=reset]:not(:disabled),
  .mainHomePage [type=submit]:not(:disabled) {
    cursor: pointer; }
  .mainHomePage button::-moz-focus-inner,
  .mainHomePage [type=button]::-moz-focus-inner,
  .mainHomePage [type=reset]::-moz-focus-inner,
  .mainHomePage [type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  .mainHomePage input[type=radio],
  .mainHomePage input[type=checkbox] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0; }
  .mainHomePage input[type=date],
  .mainHomePage input[type=time],
  .mainHomePage input[type=datetime-local],
  .mainHomePage input[type=month] {
    -webkit-appearance: listbox; }
  .mainHomePage textarea {
    overflow: auto;
    resize: vertical; }
  .mainHomePage fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  .mainHomePage legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  .mainHomePage progress {
    vertical-align: baseline; }
  .mainHomePage [type=number]::-webkit-inner-spin-button,
  .mainHomePage [type=number]::-webkit-outer-spin-button {
    height: auto; }
  .mainHomePage [type=search] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  .mainHomePage [type=search]::-webkit-search-decoration {
    -webkit-appearance: none; }
  .mainHomePage ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  .mainHomePage output {
    display: inline-block; }
  .mainHomePage summary {
    display: list-item;
    cursor: pointer; }
  .mainHomePage template {
    display: none; }
  .mainHomePage [hidden] {
    display: none !important; }
  .mainHomePage h1, .mainHomePage h2, .mainHomePage h3, .mainHomePage h4, .mainHomePage h5, .mainHomePage h6,
  .mainHomePage .h1, .mainHomePage .h2, .mainHomePage .h3, .mainHomePage .h4, .mainHomePage .h5, .mainHomePage .h6 {
    margin-bottom: 0.75rem;
    font-weight: bold;
    line-height: 1.2; }
  .mainHomePage h1, .mainHomePage .h1 {
    font-size: 2.625rem; }
  .mainHomePage h2, .mainHomePage .h2 {
    font-size: 2.25rem; }
  .mainHomePage h3, .mainHomePage .h3 {
    font-size: 2rem; }
  .mainHomePage h4, .mainHomePage .h4 {
    font-size: 1.875rem; }
  .mainHomePage h5, .mainHomePage .h5 {
    font-size: 1.25rem; }
  .mainHomePage h6, .mainHomePage .h6 {
    font-size: 1.25rem; }
  .mainHomePage .lead {
    font-size: 1.25rem;
    font-weight: 300; }
  .mainHomePage .display-1 {
    font-size: 7.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .mainHomePage .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .mainHomePage .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .mainHomePage .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2; }
  .mainHomePage hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(4, 4, 4, 0.1); }
  .mainHomePage small,
  .mainHomePage .small {
    font-size: 80%;
    font-weight: 400; }
  .mainHomePage mark,
  .mainHomePage .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  .mainHomePage .list-unstyled {
    padding-left: 0;
    list-style: none; }
  .mainHomePage .list-inline {
    padding-left: 0;
    list-style: none; }
  .mainHomePage .list-inline-item {
    display: inline-block; }
  .mainHomePage .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }
  .mainHomePage .initialism {
    font-size: 90%;
    text-transform: uppercase; }
  .mainHomePage .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem; }
  .mainHomePage .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #7b848c; }
  .mainHomePage .blockquote-footer::before {
    content: "— "; }
  .mainHomePage .img-fluid {
    max-width: 100%;
    height: auto; }
  .mainHomePage .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.625rem;
    max-width: 100%;
    height: auto; }
  .mainHomePage .figure {
    display: inline-block; }
  .mainHomePage .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  .mainHomePage .figure-caption {
    font-size: 90%;
    color: #7b848c; }
  .mainHomePage code {
    font-size: 87.5%;
    color: #fa456e;
    word-break: break-word; }
  .mainHomePage a > code {
    color: inherit; }
  .mainHomePage kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #4c4f55;
    border-radius: 0.25rem; }
  .mainHomePage kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 600; }
  .mainHomePage pre {
    display: block;
    font-size: 87.5%;
    color: #4c4f55; }
  .mainHomePage pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }
  .mainHomePage .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }
  .mainHomePage .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    .mainHomePage .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .mainHomePage .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .mainHomePage .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .mainHomePage .container {
      max-width: 1140px; } }
  .mainHomePage .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  .mainHomePage .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .mainHomePage .no-gutters {
    margin-right: 0;
    margin-left: 0; }
  .mainHomePage .no-gutters > .col,
  .mainHomePage .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0; }
  .mainHomePage .col-xl,
  .mainHomePage .col-xl-auto, .mainHomePage .col-xl-12, .mainHomePage .col-xl-11, .mainHomePage .col-xl-10, .mainHomePage .col-xl-9, .mainHomePage .col-xl-8, .mainHomePage .col-xl-7, .mainHomePage .col-xl-6, .mainHomePage .col-xl-5, .mainHomePage .col-xl-4, .mainHomePage .col-xl-3, .mainHomePage .col-xl-2, .mainHomePage .col-xl-1, .mainHomePage .col-lg,
  .mainHomePage .col-lg-auto, .mainHomePage .col-lg-12, .mainHomePage .col-lg-11, .mainHomePage .col-lg-10, .mainHomePage .col-lg-9, .mainHomePage .col-lg-8, .mainHomePage .col-lg-7, .mainHomePage .col-lg-6, .mainHomePage .col-lg-5, .mainHomePage .col-lg-4, .mainHomePage .col-lg-3, .mainHomePage .col-lg-2, .mainHomePage .col-lg-1, .mainHomePage .col-md,
  .mainHomePage .col-md-auto, .mainHomePage .col-md-12, .mainHomePage .col-md-11, .mainHomePage .col-md-10, .mainHomePage .col-md-9, .mainHomePage .col-md-8, .mainHomePage .col-md-7, .mainHomePage .col-md-6, .mainHomePage .col-md-5, .mainHomePage .col-md-4, .mainHomePage .col-md-3, .mainHomePage .col-md-2, .mainHomePage .col-md-1, .mainHomePage .col-sm,
  .mainHomePage .col-sm-auto, .mainHomePage .col-sm-12, .mainHomePage .col-sm-11, .mainHomePage .col-sm-10, .mainHomePage .col-sm-9, .mainHomePage .col-sm-8, .mainHomePage .col-sm-7, .mainHomePage .col-sm-6, .mainHomePage .col-sm-5, .mainHomePage .col-sm-4, .mainHomePage .col-sm-3, .mainHomePage .col-sm-2, .mainHomePage .col-sm-1, .mainHomePage .col,
  .mainHomePage .col-auto, .mainHomePage .col-12, .mainHomePage .col-11, .mainHomePage .col-10, .mainHomePage .col-9, .mainHomePage .col-8, .mainHomePage .col-7, .mainHomePage .col-6, .mainHomePage .col-5, .mainHomePage .col-4, .mainHomePage .col-3, .mainHomePage .col-2, .mainHomePage .col-1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  .mainHomePage .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .mainHomePage .col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .mainHomePage .col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .mainHomePage .col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .mainHomePage .col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .mainHomePage .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .mainHomePage .col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .mainHomePage .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .mainHomePage .col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .mainHomePage .col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .mainHomePage .col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .mainHomePage .col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .mainHomePage .col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .mainHomePage .col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .mainHomePage .order-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .mainHomePage .order-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .mainHomePage .order-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .mainHomePage .order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .mainHomePage .order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .mainHomePage .order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .mainHomePage .order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .mainHomePage .order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .mainHomePage .order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .mainHomePage .order-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .mainHomePage .order-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .mainHomePage .order-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .mainHomePage .order-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .mainHomePage .order-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .mainHomePage .order-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .mainHomePage .offset-1 {
    margin-left: 8.3333333333%; }
  .mainHomePage .offset-2 {
    margin-left: 16.6666666667%; }
  .mainHomePage .offset-3 {
    margin-left: 25%; }
  .mainHomePage .offset-4 {
    margin-left: 33.3333333333%; }
  .mainHomePage .offset-5 {
    margin-left: 41.6666666667%; }
  .mainHomePage .offset-6 {
    margin-left: 50%; }
  .mainHomePage .offset-7 {
    margin-left: 58.3333333333%; }
  .mainHomePage .offset-8 {
    margin-left: 66.6666666667%; }
  .mainHomePage .offset-9 {
    margin-left: 75%; }
  .mainHomePage .offset-10 {
    margin-left: 83.3333333333%; }
  .mainHomePage .offset-11 {
    margin-left: 91.6666666667%; }
  @media (min-width: 576px) {
    .mainHomePage .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .mainHomePage .col-sm-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .mainHomePage .col-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.3333333333%;
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%; }
    .mainHomePage .col-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }
    .mainHomePage .col-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .mainHomePage .col-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }
    .mainHomePage .col-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6666666667%;
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%; }
    .mainHomePage .col-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .mainHomePage .col-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.3333333333%;
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%; }
    .mainHomePage .col-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.6666666667%;
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%; }
    .mainHomePage .col-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .mainHomePage .col-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.3333333333%;
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%; }
    .mainHomePage .col-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.6666666667%;
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%; }
    .mainHomePage .col-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .mainHomePage .order-sm-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .mainHomePage .order-sm-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .mainHomePage .order-sm-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .mainHomePage .order-sm-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .mainHomePage .order-sm-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .mainHomePage .order-sm-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .mainHomePage .order-sm-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .mainHomePage .order-sm-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .mainHomePage .order-sm-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .mainHomePage .order-sm-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .mainHomePage .order-sm-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .mainHomePage .order-sm-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .mainHomePage .order-sm-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .mainHomePage .order-sm-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .mainHomePage .order-sm-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .mainHomePage .offset-sm-0 {
      margin-left: 0; }
    .mainHomePage .offset-sm-1 {
      margin-left: 8.3333333333%; }
    .mainHomePage .offset-sm-2 {
      margin-left: 16.6666666667%; }
    .mainHomePage .offset-sm-3 {
      margin-left: 25%; }
    .mainHomePage .offset-sm-4 {
      margin-left: 33.3333333333%; }
    .mainHomePage .offset-sm-5 {
      margin-left: 41.6666666667%; }
    .mainHomePage .offset-sm-6 {
      margin-left: 50%; }
    .mainHomePage .offset-sm-7 {
      margin-left: 58.3333333333%; }
    .mainHomePage .offset-sm-8 {
      margin-left: 66.6666666667%; }
    .mainHomePage .offset-sm-9 {
      margin-left: 75%; }
    .mainHomePage .offset-sm-10 {
      margin-left: 83.3333333333%; }
    .mainHomePage .offset-sm-11 {
      margin-left: 91.6666666667%; } }
  @media (min-width: 768px) {
    .mainHomePage .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .mainHomePage .col-md-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .mainHomePage .col-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.3333333333%;
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%; }
    .mainHomePage .col-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }
    .mainHomePage .col-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .mainHomePage .col-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }
    .mainHomePage .col-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6666666667%;
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%; }
    .mainHomePage .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .mainHomePage .col-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.3333333333%;
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%; }
    .mainHomePage .col-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.6666666667%;
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%; }
    .mainHomePage .col-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .mainHomePage .col-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.3333333333%;
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%; }
    .mainHomePage .col-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.6666666667%;
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%; }
    .mainHomePage .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .mainHomePage .order-md-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .mainHomePage .order-md-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .mainHomePage .order-md-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .mainHomePage .order-md-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .mainHomePage .order-md-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .mainHomePage .order-md-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .mainHomePage .order-md-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .mainHomePage .order-md-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .mainHomePage .order-md-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .mainHomePage .order-md-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .mainHomePage .order-md-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .mainHomePage .order-md-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .mainHomePage .order-md-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .mainHomePage .order-md-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .mainHomePage .order-md-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .mainHomePage .offset-md-0 {
      margin-left: 0; }
    .mainHomePage .offset-md-1 {
      margin-left: 8.3333333333%; }
    .mainHomePage .offset-md-2 {
      margin-left: 16.6666666667%; }
    .mainHomePage .offset-md-3 {
      margin-left: 25%; }
    .mainHomePage .offset-md-4 {
      margin-left: 33.3333333333%; }
    .mainHomePage .offset-md-5 {
      margin-left: 41.6666666667%; }
    .mainHomePage .offset-md-6 {
      margin-left: 50%; }
    .mainHomePage .offset-md-7 {
      margin-left: 58.3333333333%; }
    .mainHomePage .offset-md-8 {
      margin-left: 66.6666666667%; }
    .mainHomePage .offset-md-9 {
      margin-left: 75%; }
    .mainHomePage .offset-md-10 {
      margin-left: 83.3333333333%; }
    .mainHomePage .offset-md-11 {
      margin-left: 91.6666666667%; } }
  @media (min-width: 992px) {
    .mainHomePage .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .mainHomePage .col-lg-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .mainHomePage .col-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.3333333333%;
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%; }
    .mainHomePage .col-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }
    .mainHomePage .col-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .mainHomePage .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }
    .mainHomePage .col-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6666666667%;
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%; }
    .mainHomePage .col-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .mainHomePage .col-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.3333333333%;
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%; }
    .mainHomePage .col-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.6666666667%;
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%; }
    .mainHomePage .col-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .mainHomePage .col-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.3333333333%;
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%; }
    .mainHomePage .col-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.6666666667%;
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%; }
    .mainHomePage .col-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .mainHomePage .order-lg-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .mainHomePage .order-lg-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .mainHomePage .order-lg-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .mainHomePage .order-lg-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .mainHomePage .order-lg-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .mainHomePage .order-lg-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .mainHomePage .order-lg-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .mainHomePage .order-lg-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .mainHomePage .order-lg-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .mainHomePage .order-lg-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .mainHomePage .order-lg-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .mainHomePage .order-lg-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .mainHomePage .order-lg-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .mainHomePage .order-lg-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .mainHomePage .order-lg-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .mainHomePage .offset-lg-0 {
      margin-left: 0; }
    .mainHomePage .offset-lg-1 {
      margin-left: 8.3333333333%; }
    .mainHomePage .offset-lg-2 {
      margin-left: 16.6666666667%; }
    .mainHomePage .offset-lg-3 {
      margin-left: 25%; }
    .mainHomePage .offset-lg-4 {
      margin-left: 33.3333333333%; }
    .mainHomePage .offset-lg-5 {
      margin-left: 41.6666666667%; }
    .mainHomePage .offset-lg-6 {
      margin-left: 50%; }
    .mainHomePage .offset-lg-7 {
      margin-left: 58.3333333333%; }
    .mainHomePage .offset-lg-8 {
      margin-left: 66.6666666667%; }
    .mainHomePage .offset-lg-9 {
      margin-left: 75%; }
    .mainHomePage .offset-lg-10 {
      margin-left: 83.3333333333%; }
    .mainHomePage .offset-lg-11 {
      margin-left: 91.6666666667%; } }
  @media (min-width: 1200px) {
    .mainHomePage .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%; }
    .mainHomePage .col-xl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .mainHomePage .col-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.3333333333%;
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%; }
    .mainHomePage .col-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%; }
    .mainHomePage .col-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .mainHomePage .col-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%; }
    .mainHomePage .col-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.6666666667%;
      flex: 0 0 41.6666666667%;
      max-width: 41.6666666667%; }
    .mainHomePage .col-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .mainHomePage .col-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.3333333333%;
      flex: 0 0 58.3333333333%;
      max-width: 58.3333333333%; }
    .mainHomePage .col-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.6666666667%;
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%; }
    .mainHomePage .col-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .mainHomePage .col-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.3333333333%;
      flex: 0 0 83.3333333333%;
      max-width: 83.3333333333%; }
    .mainHomePage .col-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.6666666667%;
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%; }
    .mainHomePage .col-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .mainHomePage .order-xl-first {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1; }
    .mainHomePage .order-xl-last {
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13; }
    .mainHomePage .order-xl-0 {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0; }
    .mainHomePage .order-xl-1 {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1; }
    .mainHomePage .order-xl-2 {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2; }
    .mainHomePage .order-xl-3 {
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3; }
    .mainHomePage .order-xl-4 {
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4; }
    .mainHomePage .order-xl-5 {
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5; }
    .mainHomePage .order-xl-6 {
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6; }
    .mainHomePage .order-xl-7 {
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7; }
    .mainHomePage .order-xl-8 {
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8; }
    .mainHomePage .order-xl-9 {
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9; }
    .mainHomePage .order-xl-10 {
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10; }
    .mainHomePage .order-xl-11 {
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11; }
    .mainHomePage .order-xl-12 {
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12; }
    .mainHomePage .offset-xl-0 {
      margin-left: 0; }
    .mainHomePage .offset-xl-1 {
      margin-left: 8.3333333333%; }
    .mainHomePage .offset-xl-2 {
      margin-left: 16.6666666667%; }
    .mainHomePage .offset-xl-3 {
      margin-left: 25%; }
    .mainHomePage .offset-xl-4 {
      margin-left: 33.3333333333%; }
    .mainHomePage .offset-xl-5 {
      margin-left: 41.6666666667%; }
    .mainHomePage .offset-xl-6 {
      margin-left: 50%; }
    .mainHomePage .offset-xl-7 {
      margin-left: 58.3333333333%; }
    .mainHomePage .offset-xl-8 {
      margin-left: 66.6666666667%; }
    .mainHomePage .offset-xl-9 {
      margin-left: 75%; }
    .mainHomePage .offset-xl-10 {
      margin-left: 83.3333333333%; }
    .mainHomePage .offset-xl-11 {
      margin-left: 91.6666666667%; } }
  .mainHomePage .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #040404; }
  .mainHomePage .table th,
  .mainHomePage .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .mainHomePage .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .mainHomePage .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .mainHomePage .table-sm th,
  .mainHomePage .table-sm td {
    padding: 0.3rem; }
  .mainHomePage .table-bordered {
    border: 1px solid #dee2e6; }
  .mainHomePage .table-bordered th,
  .mainHomePage .table-bordered td {
    border: 1px solid #dee2e6; }
  .mainHomePage .table-bordered thead th,
  .mainHomePage .table-bordered thead td {
    border-bottom-width: 2px; }
  .mainHomePage .table-borderless th,
  .mainHomePage .table-borderless td,
  .mainHomePage .table-borderless thead th,
  .mainHomePage .table-borderless tbody + tbody {
    border: 0; }
  .mainHomePage .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(4, 4, 4, 0.05); }
  .mainHomePage .table-hover tbody tr:hover {
    color: #040404;
    background-color: rgba(4, 4, 4, 0.075); }
  .mainHomePage .table-primary,
  .mainHomePage .table-primary > th,
  .mainHomePage .table-primary > td {
    background-color: #feb8c3; }
  .mainHomePage .table-primary th,
  .mainHomePage .table-primary td,
  .mainHomePage .table-primary thead th,
  .mainHomePage .table-primary tbody + tbody {
    border-color: #fc7a90; }
  .mainHomePage .table-hover .table-primary:hover {
    background-color: #fe9fae; }
  .mainHomePage .table-hover .table-primary:hover > td,
  .mainHomePage .table-hover .table-primary:hover > th {
    background-color: #fe9fae; }
  .mainHomePage .table-secondary,
  .mainHomePage .table-secondary > th,
  .mainHomePage .table-secondary > td {
    background-color: #dadddf; }
  .mainHomePage .table-secondary th,
  .mainHomePage .table-secondary td,
  .mainHomePage .table-secondary thead th,
  .mainHomePage .table-secondary tbody + tbody {
    border-color: #babfc3; }
  .mainHomePage .table-hover .table-secondary:hover {
    background-color: #ccd0d3; }
  .mainHomePage .table-hover .table-secondary:hover > td,
  .mainHomePage .table-hover .table-secondary:hover > th {
    background-color: #ccd0d3; }
  .mainHomePage .table-success,
  .mainHomePage .table-success > th,
  .mainHomePage .table-success > td {
    background-color: #bcf1c5; }
  .mainHomePage .table-success th,
  .mainHomePage .table-success td,
  .mainHomePage .table-success thead th,
  .mainHomePage .table-success tbody + tbody {
    border-color: #83e493; }
  .mainHomePage .table-hover .table-success:hover {
    background-color: #a7edb3; }
  .mainHomePage .table-hover .table-success:hover > td,
  .mainHomePage .table-hover .table-success:hover > th {
    background-color: #a7edb3; }
  .mainHomePage .table-info,
  .mainHomePage .table-info > th,
  .mainHomePage .table-info > td {
    background-color: #bee5eb; }
  .mainHomePage .table-info th,
  .mainHomePage .table-info td,
  .mainHomePage .table-info thead th,
  .mainHomePage .table-info tbody + tbody {
    border-color: #86cfda; }
  .mainHomePage .table-hover .table-info:hover {
    background-color: #abdde5; }
  .mainHomePage .table-hover .table-info:hover > td,
  .mainHomePage .table-hover .table-info:hover > th {
    background-color: #abdde5; }
  .mainHomePage .table-warning,
  .mainHomePage .table-warning > th,
  .mainHomePage .table-warning > td {
    background-color: #ffe3bb; }
  .mainHomePage .table-warning th,
  .mainHomePage .table-warning td,
  .mainHomePage .table-warning thead th,
  .mainHomePage .table-warning tbody + tbody {
    border-color: #ffcb80; }
  .mainHomePage .table-hover .table-warning:hover {
    background-color: #ffd9a2; }
  .mainHomePage .table-hover .table-warning:hover > td,
  .mainHomePage .table-hover .table-warning:hover > th {
    background-color: #ffd9a2; }
  .mainHomePage .table-danger,
  .mainHomePage .table-danger > th,
  .mainHomePage .table-danger > td {
    background-color: #feb8c3; }
  .mainHomePage .table-danger th,
  .mainHomePage .table-danger td,
  .mainHomePage .table-danger thead th,
  .mainHomePage .table-danger tbody + tbody {
    border-color: #fc7a90; }
  .mainHomePage .table-hover .table-danger:hover {
    background-color: #fe9fae; }
  .mainHomePage .table-hover .table-danger:hover > td,
  .mainHomePage .table-hover .table-danger:hover > th {
    background-color: #fe9fae; }
  .mainHomePage .table-light,
  .mainHomePage .table-light > th,
  .mainHomePage .table-light > td {
    background-color: #fdfdfe; }
  .mainHomePage .table-light th,
  .mainHomePage .table-light td,
  .mainHomePage .table-light thead th,
  .mainHomePage .table-light tbody + tbody {
    border-color: #fbfcfc; }
  .mainHomePage .table-hover .table-light:hover {
    background-color: #ececf6; }
  .mainHomePage .table-hover .table-light:hover > td,
  .mainHomePage .table-hover .table-light:hover > th {
    background-color: #ececf6; }
  .mainHomePage .table-dark,
  .mainHomePage .table-dark > th,
  .mainHomePage .table-dark > td {
    background-color: #b9b9b9; }
  .mainHomePage .table-dark th,
  .mainHomePage .table-dark td,
  .mainHomePage .table-dark thead th,
  .mainHomePage .table-dark tbody + tbody {
    border-color: #7c7c7c; }
  .mainHomePage .table-hover .table-dark:hover {
    background-color: #acacac; }
  .mainHomePage .table-hover .table-dark:hover > td,
  .mainHomePage .table-hover .table-dark:hover > th {
    background-color: #acacac; }
  .mainHomePage .table-active,
  .mainHomePage .table-active > th,
  .mainHomePage .table-active > td {
    background-color: rgba(4, 4, 4, 0.075); }
  .mainHomePage .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
  .mainHomePage .table-hover .table-active:hover > td,
  .mainHomePage .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }
  .mainHomePage .table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55; }
  .mainHomePage .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .mainHomePage .table-dark {
    color: #fff;
    background-color: #343a40; }
  .mainHomePage .table-dark th,
  .mainHomePage .table-dark td,
  .mainHomePage .table-dark thead th {
    border-color: #454d55; }
  .mainHomePage .table-dark.table-bordered {
    border: 0; }
  .mainHomePage .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .mainHomePage .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }
  @media (max-width: 575.98px) {
    .mainHomePage .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
    .mainHomePage .table-responsive-sm > .table-bordered {
      border: 0; } }
  @media (max-width: 767.98px) {
    .mainHomePage .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
    .mainHomePage .table-responsive-md > .table-bordered {
      border: 0; } }
  @media (max-width: 991.98px) {
    .mainHomePage .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
    .mainHomePage .table-responsive-lg > .table-bordered {
      border: 0; } }
  @media (max-width: 1199.98px) {
    .mainHomePage .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
    .mainHomePage .table-responsive-xl > .table-bordered {
      border: 0; } }
  .mainHomePage .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .mainHomePage .table-responsive > .table-bordered {
    border: 0; }
  .mainHomePage .form-control {
    display: block;
    width: 100%;
    height: calc( 1.5em + 2rem + 2px);
    padding: 1rem 1.9rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.625rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .form-control {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .mainHomePage .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff7b90;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .form-control::-webkit-input-placeholder {
    color: #7b848c;
    opacity: 1; }
  .mainHomePage .form-control::-moz-placeholder {
    color: #7b848c;
    opacity: 1; }
  .mainHomePage .form-control:-ms-input-placeholder {
    color: #7b848c;
    opacity: 1; }
  .mainHomePage .form-control::-ms-input-placeholder {
    color: #7b848c;
    opacity: 1; }
  .mainHomePage .form-control::placeholder {
    color: #7b848c;
    opacity: 1; }
  .mainHomePage .form-control:disabled, .mainHomePage .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .mainHomePage select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  .mainHomePage .form-control-file,
  .mainHomePage .form-control-range {
    display: block;
    width: 100%; }
  .mainHomePage .col-form-label {
    padding-top: calc(1rem + 1px);
    padding-bottom: calc(1rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  .mainHomePage .col-form-label-lg {
    padding-top: calc(1.2rem + 1px);
    padding-bottom: calc(1.2rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5; }
  .mainHomePage .col-form-label-sm {
    padding-top: calc(0.6125rem + 1px);
    padding-bottom: calc(0.6125rem + 1px);
    font-size: 0.8125rem;
    line-height: 1.5; }
  .mainHomePage .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #040404;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
  .mainHomePage .form-control-plaintext.form-control-sm, .mainHomePage .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }
  .mainHomePage .form-control-sm {
    height: calc( 1.5em + 1.225rem + 2px);
    padding: 0.6125rem 1.7rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.25rem; }
  .mainHomePage .form-control-lg {
    height: calc( 1.5em + 2.4rem + 2px);
    padding: 1.2rem 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 100%; }
  .mainHomePage select.form-control[size], .mainHomePage select.form-control[multiple] {
    height: auto; }
  .mainHomePage textarea.form-control {
    height: auto; }
  .mainHomePage .form-group {
    margin-bottom: 1rem; }
  .mainHomePage .form-text {
    display: block;
    margin-top: 0.25rem; }
  .mainHomePage .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
  .mainHomePage .form-row > .col,
  .mainHomePage .form-row > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px; }
  .mainHomePage .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }
  .mainHomePage .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
  .mainHomePage .form-check-input:disabled ~ .form-check-label {
    color: #7b848c; }
  .mainHomePage .form-check-label {
    margin-bottom: 0; }
  .mainHomePage .form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
  .mainHomePage .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }
  .mainHomePage .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #10cc2f; }
  .mainHomePage .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(16, 204, 47, 0.9);
    border-radius: 0.625rem; }
  .mainHomePage .was-validated .form-control:valid, .mainHomePage .form-control.is-valid {
    border-color: #10cc2f;
    padding-right: calc( 1.5em + 2rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310cc2f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc( 0.375em + 0.5rem);
    background-size: calc( 0.75em + 1rem) calc( 0.75em + 1rem); }
  .mainHomePage .was-validated .form-control:valid:focus, .mainHomePage .form-control.is-valid:focus {
    border-color: #10cc2f;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25); }
  .mainHomePage .was-validated .form-control:valid ~ .valid-feedback,
  .mainHomePage .was-validated .form-control:valid ~ .valid-tooltip, .mainHomePage .form-control.is-valid ~ .valid-feedback,
  .mainHomePage .form-control.is-valid ~ .valid-tooltip {
    display: block; }
  .mainHomePage .was-validated textarea.form-control:valid, .mainHomePage textarea.form-control.is-valid {
    padding-right: calc( 1.5em + 2rem);
    background-position: top calc( 0.375em + 0.5rem) right calc( 0.375em + 0.5rem); }
  .mainHomePage .was-validated .custom-select:valid, .mainHomePage .custom-select.is-valid {
    border-color: #10cc2f;
    padding-right: calc( (1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310cc2f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc( 	0.75em + 1rem) calc( 	0.75em + 1rem); }
  .mainHomePage .was-validated .custom-select:valid:focus, .mainHomePage .custom-select.is-valid:focus {
    border-color: #10cc2f;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25); }
  .mainHomePage .was-validated .custom-select:valid ~ .valid-feedback,
  .mainHomePage .was-validated .custom-select:valid ~ .valid-tooltip, .mainHomePage .custom-select.is-valid ~ .valid-feedback,
  .mainHomePage .custom-select.is-valid ~ .valid-tooltip {
    display: block; }
  .mainHomePage .was-validated .form-control-file:valid ~ .valid-feedback,
  .mainHomePage .was-validated .form-control-file:valid ~ .valid-tooltip, .mainHomePage .form-control-file.is-valid ~ .valid-feedback,
  .mainHomePage .form-control-file.is-valid ~ .valid-tooltip {
    display: block; }
  .mainHomePage .was-validated .form-check-input:valid ~ .form-check-label, .mainHomePage .form-check-input.is-valid ~ .form-check-label {
    color: #10cc2f; }
  .mainHomePage .was-validated .form-check-input:valid ~ .valid-feedback,
  .mainHomePage .was-validated .form-check-input:valid ~ .valid-tooltip, .mainHomePage .form-check-input.is-valid ~ .valid-feedback,
  .mainHomePage .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }
  .mainHomePage .was-validated .custom-control-input:valid ~ .custom-control-label, .mainHomePage .custom-control-input.is-valid ~ .custom-control-label {
    color: #10cc2f; }
  .mainHomePage .was-validated .custom-control-input:valid ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #10cc2f; }
  .mainHomePage .was-validated .custom-control-input:valid ~ .valid-feedback,
  .mainHomePage .was-validated .custom-control-input:valid ~ .valid-tooltip, .mainHomePage .custom-control-input.is-valid ~ .valid-feedback,
  .mainHomePage .custom-control-input.is-valid ~ .valid-tooltip {
    display: block; }
  .mainHomePage .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #21ee43;
    background-color: #21ee43; }
  .mainHomePage .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25); }
  .mainHomePage .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #10cc2f; }
  .mainHomePage .was-validated .custom-file-input:valid ~ .custom-file-label, .mainHomePage .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #10cc2f; }
  .mainHomePage .was-validated .custom-file-input:valid ~ .valid-feedback,
  .mainHomePage .was-validated .custom-file-input:valid ~ .valid-tooltip, .mainHomePage .custom-file-input.is-valid ~ .valid-feedback,
  .mainHomePage .custom-file-input.is-valid ~ .valid-tooltip {
    display: block; }
  .mainHomePage .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .mainHomePage .custom-file-input.is-valid:focus ~ .custom-file-label {
    border-color: #10cc2f;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.25); }
  .mainHomePage .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #FA0029; }
  .mainHomePage .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(250, 0, 41, 0.9);
    border-radius: 0.625rem; }
  .mainHomePage .was-validated .form-control:invalid, .mainHomePage .form-control.is-invalid {
    border-color: #FA0029;
    padding-right: calc( 1.5em + 2rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FA0029' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FA0029' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc( 0.375em + 0.5rem);
    background-size: calc( 0.75em + 1rem) calc( 0.75em + 1rem); }
  .mainHomePage .was-validated .form-control:invalid:focus, .mainHomePage .form-control.is-invalid:focus {
    border-color: #FA0029;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .was-validated .form-control:invalid ~ .invalid-feedback,
  .mainHomePage .was-validated .form-control:invalid ~ .invalid-tooltip, .mainHomePage .form-control.is-invalid ~ .invalid-feedback,
  .mainHomePage .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }
  .mainHomePage .was-validated textarea.form-control:invalid, .mainHomePage textarea.form-control.is-invalid {
    padding-right: calc( 1.5em + 2rem);
    background-position: top calc( 0.375em + 0.5rem) right calc( 0.375em + 0.5rem); }
  .mainHomePage .was-validated .custom-select:invalid, .mainHomePage .custom-select.is-invalid {
    border-color: #FA0029;
    padding-right: calc( (1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FA0029' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FA0029' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc( 	0.75em + 1rem) calc( 	0.75em + 1rem); }
  .mainHomePage .was-validated .custom-select:invalid:focus, .mainHomePage .custom-select.is-invalid:focus {
    border-color: #FA0029;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .was-validated .custom-select:invalid ~ .invalid-feedback,
  .mainHomePage .was-validated .custom-select:invalid ~ .invalid-tooltip, .mainHomePage .custom-select.is-invalid ~ .invalid-feedback,
  .mainHomePage .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }
  .mainHomePage .was-validated .form-control-file:invalid ~ .invalid-feedback,
  .mainHomePage .was-validated .form-control-file:invalid ~ .invalid-tooltip, .mainHomePage .form-control-file.is-invalid ~ .invalid-feedback,
  .mainHomePage .form-control-file.is-invalid ~ .invalid-tooltip {
    display: block; }
  .mainHomePage .was-validated .form-check-input:invalid ~ .form-check-label, .mainHomePage .form-check-input.is-invalid ~ .form-check-label {
    color: #FA0029; }
  .mainHomePage .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .mainHomePage .was-validated .form-check-input:invalid ~ .invalid-tooltip, .mainHomePage .form-check-input.is-invalid ~ .invalid-feedback,
  .mainHomePage .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .mainHomePage .was-validated .custom-control-input:invalid ~ .custom-control-label, .mainHomePage .custom-control-input.is-invalid ~ .custom-control-label {
    color: #FA0029; }
  .mainHomePage .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #FA0029; }
  .mainHomePage .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  .mainHomePage .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .mainHomePage .custom-control-input.is-invalid ~ .invalid-feedback,
  .mainHomePage .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .mainHomePage .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    border-color: #ff2e50;
    background-color: #ff2e50; }
  .mainHomePage .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .mainHomePage .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #FA0029; }
  .mainHomePage .was-validated .custom-file-input:invalid ~ .custom-file-label, .mainHomePage .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #FA0029; }
  .mainHomePage .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  .mainHomePage .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .mainHomePage .custom-file-input.is-invalid ~ .invalid-feedback,
  .mainHomePage .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block; }
  .mainHomePage .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .mainHomePage .custom-file-input.is-invalid:focus ~ .custom-file-label {
    border-color: #FA0029;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .mainHomePage .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .mainHomePage .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0; }
    .mainHomePage .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0; }
    .mainHomePage .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .mainHomePage .form-inline .form-control-plaintext {
      display: inline-block; }
    .mainHomePage .form-inline .input-group,
    .mainHomePage .form-inline .custom-select {
      width: auto; }
    .mainHomePage .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .mainHomePage .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .mainHomePage .form-inline .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .mainHomePage .form-inline .custom-control-label {
      margin-bottom: 0; } }
  .mainHomePage .btn {
    display: inline-block;
    font-weight: 400;
    color: #040404;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 1rem 1.9rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.625rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .btn {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .btn:hover {
    color: #040404;
    text-decoration: none; }
  .mainHomePage .btn:focus, .mainHomePage .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .btn.disabled, .mainHomePage .btn:disabled {
    opacity: 0.65; }
  .mainHomePage a.btn.disabled,
  .mainHomePage fieldset:disabled a.btn {
    pointer-events: none; }
  .mainHomePage .btn-primary {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-primary:hover {
    color: #fff;
    background-color: #d40023;
    border-color: #c70021; }
  .mainHomePage .btn-primary:focus, .mainHomePage .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5); }
  .mainHomePage .btn-primary.disabled, .mainHomePage .btn-primary:disabled {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-primary:not(:disabled):not(.disabled):active, .mainHomePage .btn-primary:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #c70021;
    border-color: #ba001f; }
  .mainHomePage .btn-primary:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-primary:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5); }
  .mainHomePage .btn-secondary {
    color: #fff;
    background-color: #7b848c;
    border-color: #7b848c; }
  .mainHomePage .btn-secondary:hover {
    color: #fff;
    background-color: #697178;
    border-color: #636a71; }
  .mainHomePage .btn-secondary:focus, .mainHomePage .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 150, 157, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(143, 150, 157, 0.5); }
  .mainHomePage .btn-secondary.disabled, .mainHomePage .btn-secondary:disabled {
    color: #fff;
    background-color: #7b848c;
    border-color: #7b848c; }
  .mainHomePage .btn-secondary:not(:disabled):not(.disabled):active, .mainHomePage .btn-secondary:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #636a71;
    border-color: #5d646a; }
  .mainHomePage .btn-secondary:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-secondary:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(143, 150, 157, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(143, 150, 157, 0.5); }
  .mainHomePage .btn-success {
    color: #fff;
    background-color: #10cc2f;
    border-color: #10cc2f; }
  .mainHomePage .btn-success:hover {
    color: #fff;
    background-color: #0da927;
    border-color: #0c9d24; }
  .mainHomePage .btn-success:focus, .mainHomePage .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 212, 78, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 212, 78, 0.5); }
  .mainHomePage .btn-success.disabled, .mainHomePage .btn-success:disabled {
    color: #fff;
    background-color: #10cc2f;
    border-color: #10cc2f; }
  .mainHomePage .btn-success:not(:disabled):not(.disabled):active, .mainHomePage .btn-success:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0c9d24;
    border-color: #0b9121; }
  .mainHomePage .btn-success:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-success:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 212, 78, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 212, 78, 0.5); }
  .mainHomePage .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .mainHomePage .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .mainHomePage .btn-info:focus, .mainHomePage .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .mainHomePage .btn-info.disabled, .mainHomePage .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .mainHomePage .btn-info:not(:disabled):not(.disabled):active, .mainHomePage .btn-info:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
  .mainHomePage .btn-info:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-info:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .mainHomePage .btn-warning {
    color: #4c4f55;
    background-color: #ff9b0b;
    border-color: #ff9b0b; }
  .mainHomePage .btn-warning:hover {
    color: #fff;
    background-color: #e48600;
    border-color: #d77f00; }
  .mainHomePage .btn-warning:focus, .mainHomePage .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(228, 144, 22, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(228, 144, 22, 0.5); }
  .mainHomePage .btn-warning.disabled, .mainHomePage .btn-warning:disabled {
    color: #4c4f55;
    background-color: #ff9b0b;
    border-color: #ff9b0b; }
  .mainHomePage .btn-warning:not(:disabled):not(.disabled):active, .mainHomePage .btn-warning:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d77f00;
    border-color: #ca7700; }
  .mainHomePage .btn-warning:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-warning:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(228, 144, 22, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(228, 144, 22, 0.5); }
  .mainHomePage .btn-danger {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-danger:hover {
    color: #fff;
    background-color: #d40023;
    border-color: #c70021; }
  .mainHomePage .btn-danger:focus, .mainHomePage .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5); }
  .mainHomePage .btn-danger.disabled, .mainHomePage .btn-danger:disabled {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-danger:not(:disabled):not(.disabled):active, .mainHomePage .btn-danger:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c70021;
    border-color: #ba001f; }
  .mainHomePage .btn-danger:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-danger:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(251, 38, 73, 0.5); }
  .mainHomePage .btn-light {
    color: #4c4f55;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .mainHomePage .btn-light:hover {
    color: #4c4f55;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .mainHomePage .btn-light:focus, .mainHomePage .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 224, 225, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(222, 224, 225, 0.5); }
  .mainHomePage .btn-light.disabled, .mainHomePage .btn-light:disabled {
    color: #4c4f55;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .mainHomePage .btn-light:not(:disabled):not(.disabled):active, .mainHomePage .btn-light:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-light.dropdown-toggle {
    color: #4c4f55;
    background-color: #dae0e5;
    border-color: #d3d9df; }
  .mainHomePage .btn-light:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-light:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 224, 225, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(222, 224, 225, 0.5); }
  .mainHomePage .btn-dark {
    color: #fff;
    background-color: #040404;
    border-color: #040404; }
  .mainHomePage .btn-dark:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .mainHomePage .btn-dark:focus, .mainHomePage .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5); }
  .mainHomePage .btn-dark.disabled, .mainHomePage .btn-dark:disabled {
    color: #fff;
    background-color: #040404;
    border-color: #040404; }
  .mainHomePage .btn-dark:not(:disabled):not(.disabled):active, .mainHomePage .btn-dark:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
  .mainHomePage .btn-dark:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-dark:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(42, 42, 42, 0.5); }
  .mainHomePage .btn-outline-primary {
    color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-outline-primary:hover {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-outline-primary:focus, .mainHomePage .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5); }
  .mainHomePage .btn-outline-primary.disabled, .mainHomePage .btn-outline-primary:disabled {
    color: #FA0029;
    background-color: transparent; }
  .mainHomePage .btn-outline-primary:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-primary:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5); }
  .mainHomePage .btn-outline-secondary {
    color: #7b848c;
    border-color: #7b848c; }
  .mainHomePage .btn-outline-secondary:hover {
    color: #fff;
    background-color: #7b848c;
    border-color: #7b848c; }
  .mainHomePage .btn-outline-secondary:focus, .mainHomePage .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(123, 132, 140, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(123, 132, 140, 0.5); }
  .mainHomePage .btn-outline-secondary.disabled, .mainHomePage .btn-outline-secondary:disabled {
    color: #7b848c;
    background-color: transparent; }
  .mainHomePage .btn-outline-secondary:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-secondary:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7b848c;
    border-color: #7b848c; }
  .mainHomePage .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(123, 132, 140, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(123, 132, 140, 0.5); }
  .mainHomePage .btn-outline-success {
    color: #10cc2f;
    border-color: #10cc2f; }
  .mainHomePage .btn-outline-success:hover {
    color: #fff;
    background-color: #10cc2f;
    border-color: #10cc2f; }
  .mainHomePage .btn-outline-success:focus, .mainHomePage .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.5); }
  .mainHomePage .btn-outline-success.disabled, .mainHomePage .btn-outline-success:disabled {
    color: #10cc2f;
    background-color: transparent; }
  .mainHomePage .btn-outline-success:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-success:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #10cc2f;
    border-color: #10cc2f; }
  .mainHomePage .btn-outline-success:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-success:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.5); }
  .mainHomePage .btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8; }
  .mainHomePage .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .mainHomePage .btn-outline-info:focus, .mainHomePage .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .mainHomePage .btn-outline-info.disabled, .mainHomePage .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .mainHomePage .btn-outline-info:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-info:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .mainHomePage .btn-outline-info:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-info:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .mainHomePage .btn-outline-warning {
    color: #ff9b0b;
    border-color: #ff9b0b; }
  .mainHomePage .btn-outline-warning:hover {
    color: #4c4f55;
    background-color: #ff9b0b;
    border-color: #ff9b0b; }
  .mainHomePage .btn-outline-warning:focus, .mainHomePage .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 155, 11, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 155, 11, 0.5); }
  .mainHomePage .btn-outline-warning.disabled, .mainHomePage .btn-outline-warning:disabled {
    color: #ff9b0b;
    background-color: transparent; }
  .mainHomePage .btn-outline-warning:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-warning:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-warning.dropdown-toggle {
    color: #4c4f55;
    background-color: #ff9b0b;
    border-color: #ff9b0b; }
  .mainHomePage .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 155, 11, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 155, 11, 0.5); }
  .mainHomePage .btn-outline-danger {
    color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-outline-danger:hover {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-outline-danger:focus, .mainHomePage .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5); }
  .mainHomePage .btn-outline-danger.disabled, .mainHomePage .btn-outline-danger:disabled {
    color: #FA0029;
    background-color: transparent; }
  .mainHomePage .btn-outline-danger:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-danger:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5); }
  .mainHomePage .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa; }
  .mainHomePage .btn-outline-light:hover {
    color: #4c4f55;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .mainHomePage .btn-outline-light:focus, .mainHomePage .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .mainHomePage .btn-outline-light.disabled, .mainHomePage .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .mainHomePage .btn-outline-light:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-light:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-light.dropdown-toggle {
    color: #4c4f55;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .mainHomePage .btn-outline-light:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-light:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .mainHomePage .btn-outline-dark {
    color: #040404;
    border-color: #040404; }
  .mainHomePage .btn-outline-dark:hover {
    color: #fff;
    background-color: #040404;
    border-color: #040404; }
  .mainHomePage .btn-outline-dark:focus, .mainHomePage .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 4, 4, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(4, 4, 4, 0.5); }
  .mainHomePage .btn-outline-dark.disabled, .mainHomePage .btn-outline-dark:disabled {
    color: #040404;
    background-color: transparent; }
  .mainHomePage .btn-outline-dark:not(:disabled):not(.disabled):active, .mainHomePage .btn-outline-dark:not(:disabled):not(.disabled).active, .mainHomePage .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #040404;
    border-color: #040404; }
  .mainHomePage .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .mainHomePage .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .mainHomePage .show > .btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 4, 4, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(4, 4, 4, 0.5); }
  .mainHomePage .btn-link {
    font-weight: 400;
    color: #040404;
    text-decoration: none; }
  .mainHomePage .btn-link:hover {
    color: #ae001c;
    text-decoration: none; }
  .mainHomePage .btn-link:focus, .mainHomePage .btn-link.focus {
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .mainHomePage .btn-link:disabled, .mainHomePage .btn-link.disabled {
    color: #7b848c;
    pointer-events: none; }
  .mainHomePage .btn-lg, .mainHomePage .btn-group-lg > .btn {
    padding: 1.2rem 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 100%; }
  .mainHomePage .btn-sm, .mainHomePage .btn-group-sm > .btn {
    padding: 0.6125rem 1.7rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.625rem; }
  .mainHomePage .btn-block {
    display: block;
    width: 100%; }
  .mainHomePage .btn-block + .btn-block {
    margin-top: 0.5rem; }
  .mainHomePage input[type=submit].btn-block,
  .mainHomePage input[type=reset].btn-block,
  .mainHomePage input[type=button].btn-block {
    width: 100%; }
  .mainHomePage .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .fade {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .fade:not(.show) {
    opacity: 0; }
  .mainHomePage .collapse:not(.show) {
    display: none; }
  .mainHomePage .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .collapsing {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .dropup,
  .mainHomePage .dropright,
  .mainHomePage .dropdown,
  .mainHomePage .dropleft {
    position: relative; }
  .mainHomePage .dropdown-toggle {
    white-space: nowrap; }
  .mainHomePage .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .mainHomePage .dropdown-toggle:empty::after {
    margin-left: 0; }
  .mainHomePage .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #040404;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(4, 4, 4, 0.15);
    border-radius: 0.625rem; }
  .mainHomePage .dropdown-menu-left {
    right: auto;
    left: 0; }
  .mainHomePage .dropdown-menu-right {
    right: 0;
    left: auto; }
  @media (min-width: 576px) {
    .mainHomePage .dropdown-menu-sm-left {
      right: auto;
      left: 0; }
    .mainHomePage .dropdown-menu-sm-right {
      right: 0;
      left: auto; } }
  @media (min-width: 768px) {
    .mainHomePage .dropdown-menu-md-left {
      right: auto;
      left: 0; }
    .mainHomePage .dropdown-menu-md-right {
      right: 0;
      left: auto; } }
  @media (min-width: 992px) {
    .mainHomePage .dropdown-menu-lg-left {
      right: auto;
      left: 0; }
    .mainHomePage .dropdown-menu-lg-right {
      right: 0;
      left: auto; } }
  @media (min-width: 1200px) {
    .mainHomePage .dropdown-menu-xl-left {
      right: auto;
      left: 0; }
    .mainHomePage .dropdown-menu-xl-right {
      right: 0;
      left: auto; } }
  .mainHomePage .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  .mainHomePage .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  .mainHomePage .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  .mainHomePage .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  .mainHomePage .dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  .mainHomePage .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }
  .mainHomePage .dropright .dropdown-toggle::after {
    vertical-align: 0; }
  .mainHomePage .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  .mainHomePage .dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  .mainHomePage .dropleft .dropdown-toggle::after {
    display: none; }
  .mainHomePage .dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  .mainHomePage .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }
  .mainHomePage .dropleft .dropdown-toggle::before {
    vertical-align: 0; }
  .mainHomePage .dropdown-menu[x-placement^=top], .mainHomePage .dropdown-menu[x-placement^=right], .mainHomePage .dropdown-menu[x-placement^=bottom], .mainHomePage .dropdown-menu[x-placement^=left] {
    right: auto;
    bottom: auto; }
  .mainHomePage .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef; }
  .mainHomePage .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #4c4f55;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  .mainHomePage .dropdown-item:hover, .mainHomePage .dropdown-item:focus {
    color: #404248;
    text-decoration: none;
    background-color: #f8f9fa; }
  .mainHomePage .dropdown-item.active, .mainHomePage .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #FA0029; }
  .mainHomePage .dropdown-item.disabled, .mainHomePage .dropdown-item:disabled {
    color: #7b848c;
    pointer-events: none;
    background-color: transparent; }
  .mainHomePage .dropdown-menu.show {
    display: block; }
  .mainHomePage .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.8125rem;
    color: #7b848c;
    white-space: nowrap; }
  .mainHomePage .dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #4c4f55; }
  .mainHomePage .btn-group,
  .mainHomePage .btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle; }
  .mainHomePage .btn-group > .btn,
  .mainHomePage .btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; }
  .mainHomePage .btn-group > .btn:hover,
  .mainHomePage .btn-group-vertical > .btn:hover {
    z-index: 1; }
  .mainHomePage .btn-group > .btn:focus, .mainHomePage .btn-group > .btn:active, .mainHomePage .btn-group > .btn.active,
  .mainHomePage .btn-group-vertical > .btn:focus,
  .mainHomePage .btn-group-vertical > .btn:active,
  .mainHomePage .btn-group-vertical > .btn.active {
    z-index: 1; }
  .mainHomePage .btn-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .mainHomePage .btn-toolbar .input-group {
    width: auto; }
  .mainHomePage .btn-group > .btn:not(:first-child),
  .mainHomePage .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .mainHomePage .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .mainHomePage .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .mainHomePage .btn-group > .btn:not(:first-child),
  .mainHomePage .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .mainHomePage .dropdown-toggle-split {
    padding-right: 1.425rem;
    padding-left: 1.425rem; }
  .mainHomePage .dropdown-toggle-split::after, .mainHomePage .dropup .dropdown-toggle-split::after, .mainHomePage .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .mainHomePage .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }
  .mainHomePage .btn-sm + .dropdown-toggle-split, .mainHomePage .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 1.275rem;
    padding-left: 1.275rem; }
  .mainHomePage .btn-lg + .dropdown-toggle-split, .mainHomePage .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 1.875rem;
    padding-left: 1.875rem; }
  .mainHomePage .btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .mainHomePage .btn-group-vertical > .btn,
  .mainHomePage .btn-group-vertical > .btn-group {
    width: 100%; }
  .mainHomePage .btn-group-vertical > .btn:not(:first-child),
  .mainHomePage .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .mainHomePage .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .mainHomePage .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .mainHomePage .btn-group-vertical > .btn:not(:first-child),
  .mainHomePage .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .mainHomePage .btn-group-toggle > .btn,
  .mainHomePage .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
  .mainHomePage .btn-group-toggle > .btn input[type=radio],
  .mainHomePage .btn-group-toggle > .btn input[type=checkbox],
  .mainHomePage .btn-group-toggle > .btn-group > .btn input[type=radio],
  .mainHomePage .btn-group-toggle > .btn-group > .btn input[type=checkbox] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }
  .mainHomePage .input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%; }
  .mainHomePage .input-group > .form-control,
  .mainHomePage .input-group > .form-control-plaintext,
  .mainHomePage .input-group > .custom-select,
  .mainHomePage .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
  .mainHomePage .input-group > .form-control + .form-control,
  .mainHomePage .input-group > .form-control + .custom-select,
  .mainHomePage .input-group > .form-control + .custom-file,
  .mainHomePage .input-group > .form-control-plaintext + .form-control,
  .mainHomePage .input-group > .form-control-plaintext + .custom-select,
  .mainHomePage .input-group > .form-control-plaintext + .custom-file,
  .mainHomePage .input-group > .custom-select + .form-control,
  .mainHomePage .input-group > .custom-select + .custom-select,
  .mainHomePage .input-group > .custom-select + .custom-file,
  .mainHomePage .input-group > .custom-file + .form-control,
  .mainHomePage .input-group > .custom-file + .custom-select,
  .mainHomePage .input-group > .custom-file + .custom-file {
    margin-left: -1px; }
  .mainHomePage .input-group > .form-control:focus,
  .mainHomePage .input-group > .custom-select:focus,
  .mainHomePage .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .mainHomePage .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .mainHomePage .input-group > .form-control:not(:last-child),
  .mainHomePage .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .mainHomePage .input-group > .form-control:not(:first-child),
  .mainHomePage .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .mainHomePage .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .mainHomePage .input-group > .custom-file:not(:last-child) .custom-file-label, .mainHomePage .input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .mainHomePage .input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .mainHomePage .input-group-prepend,
  .mainHomePage .input-group-append {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .mainHomePage .input-group-prepend .btn,
  .mainHomePage .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .mainHomePage .input-group-prepend .btn:focus,
  .mainHomePage .input-group-append .btn:focus {
    z-index: 3; }
  .mainHomePage .input-group-prepend .btn + .btn,
  .mainHomePage .input-group-prepend .btn + .input-group-text,
  .mainHomePage .input-group-prepend .input-group-text + .input-group-text,
  .mainHomePage .input-group-prepend .input-group-text + .btn,
  .mainHomePage .input-group-append .btn + .btn,
  .mainHomePage .input-group-append .btn + .input-group-text,
  .mainHomePage .input-group-append .input-group-text + .input-group-text,
  .mainHomePage .input-group-append .input-group-text + .btn {
    margin-left: -1px; }
  .mainHomePage .input-group-prepend {
    margin-right: -1px; }
  .mainHomePage .input-group-append {
    margin-left: -1px; }
  .mainHomePage .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 1.9rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.625rem; }
  .mainHomePage .input-group-text input[type=radio],
  .mainHomePage .input-group-text input[type=checkbox] {
    margin-top: 0; }
  .mainHomePage .input-group-lg > .form-control:not(textarea),
  .mainHomePage .input-group-lg > .custom-select {
    height: calc( 1.5em + 2.4rem + 2px); }
  .mainHomePage .input-group-lg > .form-control,
  .mainHomePage .input-group-lg > .custom-select,
  .mainHomePage .input-group-lg > .input-group-prepend > .input-group-text,
  .mainHomePage .input-group-lg > .input-group-append > .input-group-text,
  .mainHomePage .input-group-lg > .input-group-prepend > .btn,
  .mainHomePage .input-group-lg > .input-group-append > .btn {
    padding: 1.2rem 2.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 100%; }
  .mainHomePage .input-group-sm > .form-control:not(textarea),
  .mainHomePage .input-group-sm > .custom-select {
    height: calc( 1.5em + 1.225rem + 2px); }
  .mainHomePage .input-group-sm > .form-control,
  .mainHomePage .input-group-sm > .custom-select,
  .mainHomePage .input-group-sm > .input-group-prepend > .input-group-text,
  .mainHomePage .input-group-sm > .input-group-append > .input-group-text,
  .mainHomePage .input-group-sm > .input-group-prepend > .btn,
  .mainHomePage .input-group-sm > .input-group-append > .btn {
    padding: 0.6125rem 1.7rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.25rem; }
  .mainHomePage .input-group-lg > .custom-select,
  .mainHomePage .input-group-sm > .custom-select {
    padding-right: 1.75rem; }
  .mainHomePage .input-group > .input-group-prepend > .btn,
  .mainHomePage .input-group > .input-group-prepend > .input-group-text,
  .mainHomePage .input-group > .input-group-append:not(:last-child) > .btn,
  .mainHomePage .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .mainHomePage .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .mainHomePage .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .mainHomePage .input-group > .input-group-append > .btn,
  .mainHomePage .input-group > .input-group-append > .input-group-text,
  .mainHomePage .input-group > .input-group-prepend:not(:first-child) > .btn,
  .mainHomePage .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .mainHomePage .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .mainHomePage .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .mainHomePage .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 2.5rem; }
  .mainHomePage .custom-control-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem; }
  .mainHomePage .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .mainHomePage .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #FA0029;
    background-color: #FA0029; }
  .mainHomePage .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ff7b90; }
  .mainHomePage .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #ffaebb;
    border-color: #ffaebb; }
  .mainHomePage .custom-control-input:disabled ~ .custom-control-label {
    color: #7b848c; }
  .mainHomePage .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef; }
  .mainHomePage .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top; }
  .mainHomePage .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #dee2e6;
    border: #f5f5f7 solid 1px; }
  .mainHomePage .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%; }
  .mainHomePage .custom-checkbox .custom-control-label::before {
    border-radius: 0.625rem; }
  .mainHomePage .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }
  .mainHomePage .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #FA0029;
    background-color: #FA0029; }
  .mainHomePage .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
  .mainHomePage .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(250, 0, 41, 0.5); }
  .mainHomePage .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(250, 0, 41, 0.5); }
  .mainHomePage .custom-radio .custom-control-label::before {
    border-radius: 50%; }
  .mainHomePage .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .mainHomePage .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(250, 0, 41, 0.5); }
  .mainHomePage .custom-switch {
    padding-left: 3.25rem; }
  .mainHomePage .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .mainHomePage .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-3.25rem + 2px);
    width: calc( 1rem - 4px);
    height: calc( 1rem - 4px);
    background-color: #f5f5f7;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .custom-switch .custom-control-label::after {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #dee2e6;
    -webkit-transform: translateX(0.75rem);
    transform: translateX(0.75rem); }
  .mainHomePage .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(250, 0, 41, 0.5); }
  .mainHomePage .custom-select {
    display: inline-block;
    width: 100%;
    height: calc( 1.5em + 2rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.625rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .mainHomePage .custom-select:focus {
    border-color: #ff7b90;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff; }
  .mainHomePage .custom-select[multiple], .mainHomePage .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .mainHomePage .custom-select:disabled {
    color: #7b848c;
    background-color: #e9ecef; }
  .mainHomePage .custom-select::-ms-expand {
    display: none; }
  .mainHomePage .custom-select-sm {
    height: calc( 1.5em + 1.225rem + 2px);
    padding-top: 0.6125rem;
    padding-bottom: 0.6125rem;
    padding-left: 1.7rem;
    font-size: 0.8125rem; }
  .mainHomePage .custom-select-lg {
    height: calc( 1.5em + 2.4rem + 2px);
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 2.5rem;
    font-size: 1.25rem; }
  .mainHomePage .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc( 1.5em + 2rem + 2px);
    margin-bottom: 0; }
  .mainHomePage .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc( 1.5em + 2rem + 2px);
    margin: 0;
    opacity: 0; }
  .mainHomePage .custom-file-input:focus ~ .custom-file-label {
    border-color: #ff7b90;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .mainHomePage .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .mainHomePage .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }
  .mainHomePage .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc( 1.5em + 2rem + 2px);
    padding: 1rem 1.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.625rem; }
  .mainHomePage .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc( 1.5em + 2rem);
    padding: 1rem 1.9rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.625rem 0.625rem 0; }
  .mainHomePage .custom-range {
    width: 100%;
    height: calc(1rem + 0.4rem);
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .mainHomePage .custom-range:focus {
    outline: none; }
  .mainHomePage .custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .custom-range::-moz-focus-outer {
    border: 0; }
  .mainHomePage .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #FA0029;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .custom-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .custom-range::-webkit-slider-thumb:active {
    background-color: #ffaebb; }
  .mainHomePage .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .mainHomePage .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #FA0029;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .custom-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none; } }
  .mainHomePage .custom-range::-moz-range-thumb:active {
    background-color: #ffaebb; }
  .mainHomePage .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .mainHomePage .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #FA0029;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .custom-range::-ms-thumb {
      -ms-transition: none;
      transition: none; } }
  .mainHomePage .custom-range::-ms-thumb:active {
    background-color: #ffaebb; }
  .mainHomePage .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .mainHomePage .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .mainHomePage .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .mainHomePage .custom-range:disabled::-webkit-slider-thumb {
    background-color: #f5f5f7; }
  .mainHomePage .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .mainHomePage .custom-range:disabled::-moz-range-thumb {
    background-color: #f5f5f7; }
  .mainHomePage .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .mainHomePage .custom-range:disabled::-ms-thumb {
    background-color: #f5f5f7; }
  .mainHomePage .custom-control-label::before,
  .mainHomePage .custom-file-label,
  .mainHomePage .custom-select {
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .custom-control-label::before,
    .mainHomePage .custom-file-label,
    .mainHomePage .custom-select {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .mainHomePage .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
  .mainHomePage .nav-link:hover, .mainHomePage .nav-link:focus {
    text-decoration: none; }
  .mainHomePage .nav-link.disabled {
    color: #7b848c;
    pointer-events: none;
    cursor: default; }
  .mainHomePage .nav-tabs {
    border-bottom: 1px solid #dee2e6; }
  .mainHomePage .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .mainHomePage .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem; }
  .mainHomePage .nav-tabs .nav-link:hover, .mainHomePage .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6; }
  .mainHomePage .nav-tabs .nav-link.disabled {
    color: #7b848c;
    background-color: transparent;
    border-color: transparent; }
  .mainHomePage .nav-tabs .nav-link.active,
  .mainHomePage .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .mainHomePage .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .mainHomePage .nav-pills .nav-link {
    border-radius: 0.625rem; }
  .mainHomePage .nav-pills .nav-link.active,
  .mainHomePage .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #FA0029; }
  .mainHomePage .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center; }
  .mainHomePage .nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center; }
  .mainHomePage .tab-content > .tab-pane {
    display: none; }
  .mainHomePage .tab-content > .active {
    display: block; }
  .mainHomePage .navbar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
  .mainHomePage .navbar > .container,
  .mainHomePage .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .mainHomePage .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
  .mainHomePage .navbar-brand:hover, .mainHomePage .navbar-brand:focus {
    text-decoration: none; }
  .mainHomePage .navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .mainHomePage .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .mainHomePage .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .mainHomePage .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .mainHomePage .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .mainHomePage .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.625rem; }
  .mainHomePage .navbar-toggler:hover, .mainHomePage .navbar-toggler:focus {
    text-decoration: none; }
  .mainHomePage .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }
  @media (max-width: 575.98px) {
    .mainHomePage .navbar-expand-sm > .container,
    .mainHomePage .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 576px) {
    .mainHomePage .navbar-expand-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .mainHomePage .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute; }
    .mainHomePage .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .mainHomePage .navbar-expand-sm > .container,
    .mainHomePage .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .mainHomePage .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .mainHomePage .navbar-expand-sm .navbar-toggler {
      display: none; } }
  @media (max-width: 767.98px) {
    .mainHomePage .navbar-expand-md > .container,
    .mainHomePage .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 768px) {
    .mainHomePage .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .mainHomePage .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute; }
    .mainHomePage .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .mainHomePage .navbar-expand-md > .container,
    .mainHomePage .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .mainHomePage .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .mainHomePage .navbar-expand-md .navbar-toggler {
      display: none; } }
  @media (max-width: 991.98px) {
    .mainHomePage .navbar-expand-lg > .container,
    .mainHomePage .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 992px) {
    .mainHomePage .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .mainHomePage .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute; }
    .mainHomePage .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .mainHomePage .navbar-expand-lg > .container,
    .mainHomePage .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .mainHomePage .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .mainHomePage .navbar-expand-lg .navbar-toggler {
      display: none; } }
  @media (max-width: 1199.98px) {
    .mainHomePage .navbar-expand-xl > .container,
    .mainHomePage .navbar-expand-xl > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 1200px) {
    .mainHomePage .navbar-expand-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .mainHomePage .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute; }
    .mainHomePage .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .mainHomePage .navbar-expand-xl > .container,
    .mainHomePage .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .mainHomePage .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .mainHomePage .navbar-expand-xl .navbar-toggler {
      display: none; } }
  .mainHomePage .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .mainHomePage .navbar-expand > .container,
  .mainHomePage .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .mainHomePage .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .mainHomePage .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
  .mainHomePage .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .mainHomePage .navbar-expand > .container,
  .mainHomePage .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .mainHomePage .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .mainHomePage .navbar-expand .navbar-toggler {
    display: none; }
  .mainHomePage .navbar-light .navbar-brand {
    color: rgba(4, 4, 4, 0.9); }
  .mainHomePage .navbar-light .navbar-brand:hover, .mainHomePage .navbar-light .navbar-brand:focus {
    color: rgba(4, 4, 4, 0.9); }
  .mainHomePage .navbar-light .navbar-nav .nav-link {
    color: rgba(4, 4, 4, 0.5); }
  .mainHomePage .navbar-light .navbar-nav .nav-link:hover, .mainHomePage .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(4, 4, 4, 0.7); }
  .mainHomePage .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(4, 4, 4, 0.3); }
  .mainHomePage .navbar-light .navbar-nav .show > .nav-link,
  .mainHomePage .navbar-light .navbar-nav .active > .nav-link,
  .mainHomePage .navbar-light .navbar-nav .nav-link.show,
  .mainHomePage .navbar-light .navbar-nav .nav-link.active {
    color: rgba(4, 4, 4, 0.9); }
  .mainHomePage .navbar-light .navbar-toggler {
    color: rgba(4, 4, 4, 0.5);
    border-color: rgba(4, 4, 4, 0.1); }
  .mainHomePage .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(4, 4, 4, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  .mainHomePage .navbar-light .navbar-text {
    color: rgba(4, 4, 4, 0.5); }
  .mainHomePage .navbar-light .navbar-text a {
    color: rgba(4, 4, 4, 0.9); }
  .mainHomePage .navbar-light .navbar-text a:hover, .mainHomePage .navbar-light .navbar-text a:focus {
    color: rgba(4, 4, 4, 0.9); }
  .mainHomePage .navbar-dark .navbar-brand {
    color: #fff; }
  .mainHomePage .navbar-dark .navbar-brand:hover, .mainHomePage .navbar-dark .navbar-brand:focus {
    color: #fff; }
  .mainHomePage .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
  .mainHomePage .navbar-dark .navbar-nav .nav-link:hover, .mainHomePage .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .mainHomePage .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
  .mainHomePage .navbar-dark .navbar-nav .show > .nav-link,
  .mainHomePage .navbar-dark .navbar-nav .active > .nav-link,
  .mainHomePage .navbar-dark .navbar-nav .nav-link.show,
  .mainHomePage .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }
  .mainHomePage .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }
  .mainHomePage .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  .mainHomePage .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5); }
  .mainHomePage .navbar-dark .navbar-text a {
    color: #fff; }
  .mainHomePage .navbar-dark .navbar-text a:hover, .mainHomePage .navbar-dark .navbar-text a:focus {
    color: #fff; }
  .mainHomePage .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(4, 4, 4, 0.125);
    border-radius: 0; }
  .mainHomePage .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .mainHomePage .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .mainHomePage .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .mainHomePage .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem; }
  .mainHomePage .card-title {
    margin-bottom: 0.75rem; }
  .mainHomePage .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }
  .mainHomePage .card-text:last-child {
    margin-bottom: 0; }
  .mainHomePage .card-link:hover {
    text-decoration: none; }
  .mainHomePage .card-link + .card-link {
    margin-left: 1.25rem; }
  .mainHomePage .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(4, 4, 4, 0.03);
    border-bottom: 0 solid rgba(4, 4, 4, 0.125); }
  .mainHomePage .card-header:first-child {
    border-radius: calc( 0 - 0) calc( 0 - 0) 0 0; }
  .mainHomePage .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }
  .mainHomePage .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(4, 4, 4, 0.03);
    border-top: 0 solid rgba(4, 4, 4, 0.125); }
  .mainHomePage .card-footer:last-child {
    border-radius: 0 0 calc( 0 - 0) calc( 0 - 0); }
  .mainHomePage .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0; }
  .mainHomePage .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
  .mainHomePage .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem; }
  .mainHomePage .card-img {
    width: 100%;
    border-radius: calc( 0 - 0); }
  .mainHomePage .card-img-top {
    width: 100%;
    border-top-left-radius: calc( 0 - 0);
    border-top-right-radius: calc( 0 - 0); }
  .mainHomePage .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc( 0 - 0);
    border-bottom-left-radius: calc( 0 - 0); }
  .mainHomePage .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .mainHomePage .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .mainHomePage .card-deck {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
    .mainHomePage .card-deck .card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }
  .mainHomePage .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .mainHomePage .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .mainHomePage .card-group {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
    .mainHomePage .card-group > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0; }
    .mainHomePage .card-group > .card + .card {
      margin-left: 0;
      border-left: 0; }
    .mainHomePage .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .mainHomePage .card-group > .card:not(:last-child) .card-img-top,
    .mainHomePage .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0; }
    .mainHomePage .card-group > .card:not(:last-child) .card-img-bottom,
    .mainHomePage .card-group > .card:not(:last-child) .card-footer {
      border-bottom-right-radius: 0; }
    .mainHomePage .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .mainHomePage .card-group > .card:not(:first-child) .card-img-top,
    .mainHomePage .card-group > .card:not(:first-child) .card-header {
      border-top-left-radius: 0; }
    .mainHomePage .card-group > .card:not(:first-child) .card-img-bottom,
    .mainHomePage .card-group > .card:not(:first-child) .card-footer {
      border-bottom-left-radius: 0; } }
  .mainHomePage .card-columns .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    .mainHomePage .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1; }
    .mainHomePage .card-columns .card {
      display: inline-block;
      width: 100%; } }
  .mainHomePage .accordion > .card {
    overflow: hidden; }
  .mainHomePage .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .mainHomePage .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .mainHomePage .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .mainHomePage .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .mainHomePage .accordion > .card .card-header {
    margin-bottom: 0; }
  .mainHomePage .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.625rem; }
  .mainHomePage .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
  .mainHomePage .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #7b848c;
    content: "/"; }
  .mainHomePage .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .mainHomePage .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .mainHomePage .breadcrumb-item.active {
    color: #7b848c; }
  .mainHomePage .pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.625rem; }
  .mainHomePage .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #040404;
    background-color: #fff;
    border: 1px solid #dee2e6; }
  .mainHomePage .page-link:hover {
    z-index: 2;
    color: #ae001c;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .mainHomePage .page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.25); }
  .mainHomePage .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem; }
  .mainHomePage .page-item:last-child .page-link {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem; }
  .mainHomePage .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .page-item.disabled .page-link {
    color: #7b848c;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6; }
  .mainHomePage .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5; }
  .mainHomePage .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%; }
  .mainHomePage .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%; }
  .mainHomePage .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.8125rem;
    line-height: 1.5; }
  .mainHomePage .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .mainHomePage .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  .mainHomePage .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.625rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .badge {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage a.badge:hover, .mainHomePage a.badge:focus {
    text-decoration: none; }
  .mainHomePage .badge:empty {
    display: none; }
  .mainHomePage .btn .badge {
    position: relative;
    top: -1px; }
  .mainHomePage .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem; }
  .mainHomePage .badge-primary {
    color: #fff;
    background-color: #FA0029; }
  .mainHomePage a.badge-primary:hover, .mainHomePage a.badge-primary:focus {
    color: #fff;
    background-color: #c70021; }
  .mainHomePage a.badge-primary:focus, .mainHomePage a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5); }
  .mainHomePage .badge-secondary {
    color: #fff;
    background-color: #7b848c; }
  .mainHomePage a.badge-secondary:hover, .mainHomePage a.badge-secondary:focus {
    color: #fff;
    background-color: #636a71; }
  .mainHomePage a.badge-secondary:focus, .mainHomePage a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(123, 132, 140, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(123, 132, 140, 0.5); }
  .mainHomePage .badge-success {
    color: #fff;
    background-color: #10cc2f; }
  .mainHomePage a.badge-success:hover, .mainHomePage a.badge-success:focus {
    color: #fff;
    background-color: #0c9d24; }
  .mainHomePage a.badge-success:focus, .mainHomePage a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(16, 204, 47, 0.5); }
  .mainHomePage .badge-info {
    color: #fff;
    background-color: #17a2b8; }
  .mainHomePage a.badge-info:hover, .mainHomePage a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  .mainHomePage a.badge-info:focus, .mainHomePage a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .mainHomePage .badge-warning {
    color: #4c4f55;
    background-color: #ff9b0b; }
  .mainHomePage a.badge-warning:hover, .mainHomePage a.badge-warning:focus {
    color: #4c4f55;
    background-color: #d77f00; }
  .mainHomePage a.badge-warning:focus, .mainHomePage a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 155, 11, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 155, 11, 0.5); }
  .mainHomePage .badge-danger {
    color: #fff;
    background-color: #FA0029; }
  .mainHomePage a.badge-danger:hover, .mainHomePage a.badge-danger:focus {
    color: #fff;
    background-color: #c70021; }
  .mainHomePage a.badge-danger:focus, .mainHomePage a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(250, 0, 41, 0.5); }
  .mainHomePage .badge-light {
    color: #4c4f55;
    background-color: #f8f9fa; }
  .mainHomePage a.badge-light:hover, .mainHomePage a.badge-light:focus {
    color: #4c4f55;
    background-color: #dae0e5; }
  .mainHomePage a.badge-light:focus, .mainHomePage a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .mainHomePage .badge-dark {
    color: #fff;
    background-color: #040404; }
  .mainHomePage a.badge-dark:hover, .mainHomePage a.badge-dark:focus {
    color: #fff;
    background-color: black; }
  .mainHomePage a.badge-dark:focus, .mainHomePage a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(4, 4, 4, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(4, 4, 4, 0.5); }
  .mainHomePage .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 100%; }
  @media (min-width: 576px) {
    .mainHomePage .jumbotron {
      padding: 4rem 2rem; } }
  .mainHomePage .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0; }
  .mainHomePage .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.625rem; }
  .mainHomePage .alert-heading {
    color: inherit; }
  .mainHomePage .alert-link {
    font-weight: 600; }
  .mainHomePage .alert-dismissible {
    padding-right: 4rem; }
  .mainHomePage .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }
  .mainHomePage .alert-primary {
    color: #840217;
    background-color: #feccd4;
    border-color: #feb8c3; }
  .mainHomePage .alert-primary hr {
    border-top-color: #fe9fae; }
  .mainHomePage .alert-primary .alert-link {
    color: #52010e; }
  .mainHomePage .alert-secondary {
    color: #42474b;
    background-color: #e5e6e8;
    border-color: #dadddf; }
  .mainHomePage .alert-secondary hr {
    border-top-color: #ccd0d3; }
  .mainHomePage .alert-secondary .alert-link {
    color: #2a2d30; }
  .mainHomePage .alert-success {
    color: #0a6c1a;
    background-color: #cff5d5;
    border-color: #bcf1c5; }
  .mainHomePage .alert-success hr {
    border-top-color: #a7edb3; }
  .mainHomePage .alert-success .alert-link {
    color: #063d0f; }
  .mainHomePage .alert-info {
    color: #0e5662;
    background-color: #d1ecf1;
    border-color: #bee5eb; }
  .mainHomePage .alert-info hr {
    border-top-color: #abdde5; }
  .mainHomePage .alert-info .alert-link {
    color: #082f35; }
  .mainHomePage .alert-warning {
    color: #875308;
    background-color: #ffebce;
    border-color: #ffe3bb; }
  .mainHomePage .alert-warning hr {
    border-top-color: #ffd9a2; }
  .mainHomePage .alert-warning .alert-link {
    color: #573505; }
  .mainHomePage .alert-danger {
    color: #840217;
    background-color: #feccd4;
    border-color: #feb8c3; }
  .mainHomePage .alert-danger hr {
    border-top-color: #fe9fae; }
  .mainHomePage .alert-danger .alert-link {
    color: #52010e; }
  .mainHomePage .alert-light {
    color: #838384;
    background-color: #fefefe;
    border-color: #fdfdfe; }
  .mainHomePage .alert-light hr {
    border-top-color: #ececf6; }
  .mainHomePage .alert-light .alert-link {
    color: #6a6a6a; }
  .mainHomePage .alert-dark {
    color: #040404;
    background-color: #cdcdcd;
    border-color: #b9b9b9; }
  .mainHomePage .alert-dark hr {
    border-top-color: #acacac; }
  .mainHomePage .alert-dark .alert-link {
    color: black; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
  .mainHomePage .progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.625rem; }
  .mainHomePage .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #FA0029;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .progress-bar {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  .mainHomePage .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .progress-bar-animated {
      -webkit-animation: none;
      animation: none; } }
  .mainHomePage .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }
  .mainHomePage .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .mainHomePage .list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0; }
  .mainHomePage .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit; }
  .mainHomePage .list-group-item-action:hover, .mainHomePage .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .mainHomePage .list-group-item-action:active {
    color: #040404;
    background-color: #e9ecef; }
  .mainHomePage .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(4, 4, 4, 0.125); }
  .mainHomePage .list-group-item:first-child {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem; }
  .mainHomePage .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem; }
  .mainHomePage .list-group-item.disabled, .mainHomePage .list-group-item:disabled {
    color: #7b848c;
    pointer-events: none;
    background-color: #fff; }
  .mainHomePage .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #FA0029;
    border-color: #FA0029; }
  .mainHomePage .list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .mainHomePage .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
  .mainHomePage .list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    border-top-right-radius: 0; }
  .mainHomePage .list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-bottom-left-radius: 0; }
  @media (min-width: 576px) {
    .mainHomePage .list-group-horizontal-sm {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
    .mainHomePage .list-group-horizontal-sm .list-group-item:first-child {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .mainHomePage .list-group-horizontal-sm .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0; } }
  @media (min-width: 768px) {
    .mainHomePage .list-group-horizontal-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
    .mainHomePage .list-group-horizontal-md .list-group-item:first-child {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .mainHomePage .list-group-horizontal-md .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0; } }
  @media (min-width: 992px) {
    .mainHomePage .list-group-horizontal-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
    .mainHomePage .list-group-horizontal-lg .list-group-item:first-child {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .mainHomePage .list-group-horizontal-lg .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0; } }
  @media (min-width: 1200px) {
    .mainHomePage .list-group-horizontal-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
    .mainHomePage .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
    .mainHomePage .list-group-horizontal-xl .list-group-item:first-child {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      border-top-right-radius: 0; }
    .mainHomePage .list-group-horizontal-xl .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0; } }
  .mainHomePage .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0; }
  .mainHomePage .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }
  .mainHomePage .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0; }
  .mainHomePage .list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0; }
  .mainHomePage .list-group-item-primary {
    color: #840217;
    background-color: #feb8c3; }
  .mainHomePage .list-group-item-primary.list-group-item-action:hover, .mainHomePage .list-group-item-primary.list-group-item-action:focus {
    color: #840217;
    background-color: #fe9fae; }
  .mainHomePage .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #840217;
    border-color: #840217; }
  .mainHomePage .list-group-item-secondary {
    color: #42474b;
    background-color: #dadddf; }
  .mainHomePage .list-group-item-secondary.list-group-item-action:hover, .mainHomePage .list-group-item-secondary.list-group-item-action:focus {
    color: #42474b;
    background-color: #ccd0d3; }
  .mainHomePage .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #42474b;
    border-color: #42474b; }
  .mainHomePage .list-group-item-success {
    color: #0a6c1a;
    background-color: #bcf1c5; }
  .mainHomePage .list-group-item-success.list-group-item-action:hover, .mainHomePage .list-group-item-success.list-group-item-action:focus {
    color: #0a6c1a;
    background-color: #a7edb3; }
  .mainHomePage .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0a6c1a;
    border-color: #0a6c1a; }
  .mainHomePage .list-group-item-info {
    color: #0e5662;
    background-color: #bee5eb; }
  .mainHomePage .list-group-item-info.list-group-item-action:hover, .mainHomePage .list-group-item-info.list-group-item-action:focus {
    color: #0e5662;
    background-color: #abdde5; }
  .mainHomePage .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0e5662;
    border-color: #0e5662; }
  .mainHomePage .list-group-item-warning {
    color: #875308;
    background-color: #ffe3bb; }
  .mainHomePage .list-group-item-warning.list-group-item-action:hover, .mainHomePage .list-group-item-warning.list-group-item-action:focus {
    color: #875308;
    background-color: #ffd9a2; }
  .mainHomePage .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #875308;
    border-color: #875308; }
  .mainHomePage .list-group-item-danger {
    color: #840217;
    background-color: #feb8c3; }
  .mainHomePage .list-group-item-danger.list-group-item-action:hover, .mainHomePage .list-group-item-danger.list-group-item-action:focus {
    color: #840217;
    background-color: #fe9fae; }
  .mainHomePage .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #840217;
    border-color: #840217; }
  .mainHomePage .list-group-item-light {
    color: #838384;
    background-color: #fdfdfe; }
  .mainHomePage .list-group-item-light.list-group-item-action:hover, .mainHomePage .list-group-item-light.list-group-item-action:focus {
    color: #838384;
    background-color: #ececf6; }
  .mainHomePage .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #838384;
    border-color: #838384; }
  .mainHomePage .list-group-item-dark {
    color: #040404;
    background-color: #b9b9b9; }
  .mainHomePage .list-group-item-dark.list-group-item-action:hover, .mainHomePage .list-group-item-dark.list-group-item-action:focus {
    color: #040404;
    background-color: #acacac; }
  .mainHomePage .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #040404;
    border-color: #040404; }
  .mainHomePage .toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.25rem 0.75rem rgba(4, 4, 4, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(4, 4, 4, 0.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem; }
  .mainHomePage .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .mainHomePage .toast.showing {
    opacity: 1; }
  .mainHomePage .toast.show {
    display: block;
    opacity: 1; }
  .mainHomePage .toast.hide {
    display: none; }
  .mainHomePage .toast-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #7b848c;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .mainHomePage .toast-body {
    padding: 0.75rem; }
  .mainHomePage .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    color: #040404;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5; }
  .mainHomePage .close:hover {
    color: #040404;
    text-decoration: none; }
  .mainHomePage .close:not(:disabled):not(.disabled):hover, .mainHomePage .close:not(:disabled):not(.disabled):focus {
    opacity: 0.75; }
  .mainHomePage button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .mainHomePage a.close.disabled {
    pointer-events: none; }
  .mainHomePage .modal-open {
    overflow: hidden; }
  .mainHomePage .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }
  .mainHomePage .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  .mainHomePage .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
  .mainHomePage .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px); }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .modal.fade .modal-dialog {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none; }
  .mainHomePage .modal-dialog-scrollable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem); }
  .mainHomePage .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .mainHomePage .modal-dialog-scrollable .modal-header,
  .mainHomePage .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .mainHomePage .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }
  .mainHomePage .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem); }
  .mainHomePage .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .mainHomePage .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%; }
  .mainHomePage .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none; }
  .mainHomePage .modal-dialog-centered.modal-dialog-scrollable::before {
    content: none; }
  .mainHomePage .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(4, 4, 4, 0.2);
    border-radius: 100%;
    outline: 0; }
  .mainHomePage .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #040404; }
  .mainHomePage .modal-backdrop.fade {
    opacity: 0; }
  .mainHomePage .modal-backdrop.show {
    opacity: 0.5; }
  .mainHomePage .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%; }
  .mainHomePage .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }
  .mainHomePage .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .mainHomePage .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem; }
  .mainHomePage .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%; }
  .mainHomePage .modal-footer > :not(:first-child) {
    margin-left: 0.25rem; }
  .mainHomePage .modal-footer > :not(:last-child) {
    margin-right: 0.25rem; }
  .mainHomePage .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  @media (min-width: 576px) {
    .mainHomePage .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .mainHomePage .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); }
    .mainHomePage .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
    .mainHomePage .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
    .mainHomePage .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
    .mainHomePage .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    .mainHomePage .modal-lg,
    .mainHomePage .modal-xl {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    .mainHomePage .modal-xl {
      max-width: 1140px; } }
  .mainHomePage .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.8125rem;
    word-wrap: break-word;
    opacity: 0; }
  .mainHomePage .tooltip.show {
    opacity: 0.9; }
  .mainHomePage .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
  .mainHomePage .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid; }
  .mainHomePage .bs-tooltip-top, .mainHomePage .bs-tooltip-auto[x-placement^=top] {
    padding: 0.4rem 0; }
  .mainHomePage .bs-tooltip-top .arrow, .mainHomePage .bs-tooltip-auto[x-placement^=top] .arrow {
    bottom: 0; }
  .mainHomePage .bs-tooltip-top .arrow::before, .mainHomePage .bs-tooltip-auto[x-placement^=top] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #040404; }
  .mainHomePage .bs-tooltip-right, .mainHomePage .bs-tooltip-auto[x-placement^=right] {
    padding: 0 0.4rem; }
  .mainHomePage .bs-tooltip-right .arrow, .mainHomePage .bs-tooltip-auto[x-placement^=right] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .mainHomePage .bs-tooltip-right .arrow::before, .mainHomePage .bs-tooltip-auto[x-placement^=right] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #040404; }
  .mainHomePage .bs-tooltip-bottom, .mainHomePage .bs-tooltip-auto[x-placement^=bottom] {
    padding: 0.4rem 0; }
  .mainHomePage .bs-tooltip-bottom .arrow, .mainHomePage .bs-tooltip-auto[x-placement^=bottom] .arrow {
    top: 0; }
  .mainHomePage .bs-tooltip-bottom .arrow::before, .mainHomePage .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #040404; }
  .mainHomePage .bs-tooltip-left, .mainHomePage .bs-tooltip-auto[x-placement^=left] {
    padding: 0 0.4rem; }
  .mainHomePage .bs-tooltip-left .arrow, .mainHomePage .bs-tooltip-auto[x-placement^=left] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .mainHomePage .bs-tooltip-left .arrow::before, .mainHomePage .bs-tooltip-auto[x-placement^=left] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #040404; }
  .mainHomePage .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #040404;
    border-radius: 0.625rem; }
  .mainHomePage .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.8125rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(4, 4, 4, 0.2);
    border-radius: 100%; }
  .mainHomePage .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 100%; }
  .mainHomePage .popover .arrow::before, .mainHomePage .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid; }
  .mainHomePage .bs-popover-top, .mainHomePage .bs-popover-auto[x-placement^=top] {
    margin-bottom: 0.5rem; }
  .mainHomePage .bs-popover-top > .arrow, .mainHomePage .bs-popover-auto[x-placement^=top] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .mainHomePage .bs-popover-top > .arrow::before, .mainHomePage .bs-popover-auto[x-placement^=top] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(4, 4, 4, 0.25); }
  .mainHomePage .bs-popover-top > .arrow::after, .mainHomePage .bs-popover-auto[x-placement^=top] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }
  .mainHomePage .bs-popover-right, .mainHomePage .bs-popover-auto[x-placement^=right] {
    margin-left: 0.5rem; }
  .mainHomePage .bs-popover-right > .arrow, .mainHomePage .bs-popover-auto[x-placement^=right] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 100% 0; }
  .mainHomePage .bs-popover-right > .arrow::before, .mainHomePage .bs-popover-auto[x-placement^=right] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(4, 4, 4, 0.25); }
  .mainHomePage .bs-popover-right > .arrow::after, .mainHomePage .bs-popover-auto[x-placement^=right] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }
  .mainHomePage .bs-popover-bottom, .mainHomePage .bs-popover-auto[x-placement^=bottom] {
    margin-top: 0.5rem; }
  .mainHomePage .bs-popover-bottom > .arrow, .mainHomePage .bs-popover-auto[x-placement^=bottom] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .mainHomePage .bs-popover-bottom > .arrow::before, .mainHomePage .bs-popover-auto[x-placement^=bottom] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(4, 4, 4, 0.25); }
  .mainHomePage .bs-popover-bottom > .arrow::after, .mainHomePage .bs-popover-auto[x-placement^=bottom] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }
  .mainHomePage .bs-popover-bottom .popover-header::before, .mainHomePage .bs-popover-auto[x-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }
  .mainHomePage .bs-popover-left, .mainHomePage .bs-popover-auto[x-placement^=left] {
    margin-right: 0.5rem; }
  .mainHomePage .bs-popover-left > .arrow, .mainHomePage .bs-popover-auto[x-placement^=left] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 100% 0; }
  .mainHomePage .bs-popover-left > .arrow::before, .mainHomePage .bs-popover-auto[x-placement^=left] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(4, 4, 4, 0.25); }
  .mainHomePage .bs-popover-left > .arrow::after, .mainHomePage .bs-popover-auto[x-placement^=left] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }
  .mainHomePage .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(100% - 1px);
    border-top-right-radius: calc(100% - 1px); }
  .mainHomePage .popover-header:empty {
    display: none; }
  .mainHomePage .popover-body {
    padding: 0.5rem 0.75rem;
    color: #040404; }
  .mainHomePage .carousel {
    position: relative; }
  .mainHomePage .carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y; }
  .mainHomePage .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
  .mainHomePage .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }
  .mainHomePage .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .carousel-item {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .carousel-item.active,
  .mainHomePage .carousel-item-next,
  .mainHomePage .carousel-item-prev {
    display: block; }
  .mainHomePage .carousel-item-next:not(.carousel-item-left),
  .mainHomePage .active.carousel-item-right {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  .mainHomePage .carousel-item-prev:not(.carousel-item-right),
  .mainHomePage .active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  .mainHomePage .carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    transform: none; }
  .mainHomePage .carousel-fade .carousel-item.active,
  .mainHomePage .carousel-fade .carousel-item-next.carousel-item-left,
  .mainHomePage .carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1; }
  .mainHomePage .carousel-fade .active.carousel-item-left,
  .mainHomePage .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: 0s 0.6s opacity;
    transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .carousel-fade .active.carousel-item-left,
    .mainHomePage .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .carousel-control-prev,
  .mainHomePage .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .carousel-control-prev,
    .mainHomePage .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .carousel-control-prev:hover, .mainHomePage .carousel-control-prev:focus,
  .mainHomePage .carousel-control-next:hover,
  .mainHomePage .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }
  .mainHomePage .carousel-control-prev {
    left: 0; }
  .mainHomePage .carousel-control-next {
    right: 0; }
  .mainHomePage .carousel-control-prev-icon,
  .mainHomePage .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%; }
  .mainHomePage .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }
  .mainHomePage .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }
  .mainHomePage .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none; }
  .mainHomePage .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .mainHomePage .carousel-indicators li {
      -webkit-transition: none;
      transition: none; } }
  .mainHomePage .carousel-indicators .active {
    opacity: 1; }
  .mainHomePage .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
  .mainHomePage .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite; }
  .mainHomePage .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }
  .mainHomePage .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite; }
  .mainHomePage .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  .mainHomePage .align-baseline {
    vertical-align: baseline !important; }
  .mainHomePage .align-top {
    vertical-align: top !important; }
  .mainHomePage .align-middle {
    vertical-align: middle !important; }
  .mainHomePage .align-bottom {
    vertical-align: bottom !important; }
  .mainHomePage .align-text-bottom {
    vertical-align: text-bottom !important; }
  .mainHomePage .align-text-top {
    vertical-align: text-top !important; }
  .mainHomePage .bg-primary {
    background-color: #FA0029 !important; }
  .mainHomePage a.bg-primary:hover, .mainHomePage a.bg-primary:focus,
  .mainHomePage button.bg-primary:hover,
  .mainHomePage button.bg-primary:focus {
    background-color: #c70021 !important; }
  .mainHomePage .bg-secondary {
    background-color: #7b848c !important; }
  .mainHomePage a.bg-secondary:hover, .mainHomePage a.bg-secondary:focus,
  .mainHomePage button.bg-secondary:hover,
  .mainHomePage button.bg-secondary:focus {
    background-color: #636a71 !important; }
  .mainHomePage .bg-success {
    background-color: #10cc2f !important; }
  .mainHomePage a.bg-success:hover, .mainHomePage a.bg-success:focus,
  .mainHomePage button.bg-success:hover,
  .mainHomePage button.bg-success:focus {
    background-color: #0c9d24 !important; }
  .mainHomePage .bg-info {
    background-color: #17a2b8 !important; }
  .mainHomePage a.bg-info:hover, .mainHomePage a.bg-info:focus,
  .mainHomePage button.bg-info:hover,
  .mainHomePage button.bg-info:focus {
    background-color: #117a8b !important; }
  .mainHomePage .bg-warning {
    background-color: #ff9b0b !important; }
  .mainHomePage a.bg-warning:hover, .mainHomePage a.bg-warning:focus,
  .mainHomePage button.bg-warning:hover,
  .mainHomePage button.bg-warning:focus {
    background-color: #d77f00 !important; }
  .mainHomePage .bg-danger {
    background-color: #FA0029 !important; }
  .mainHomePage a.bg-danger:hover, .mainHomePage a.bg-danger:focus,
  .mainHomePage button.bg-danger:hover,
  .mainHomePage button.bg-danger:focus {
    background-color: #c70021 !important; }
  .mainHomePage .bg-light {
    background-color: #f8f9fa !important; }
  .mainHomePage a.bg-light:hover, .mainHomePage a.bg-light:focus,
  .mainHomePage button.bg-light:hover,
  .mainHomePage button.bg-light:focus {
    background-color: #dae0e5 !important; }
  .mainHomePage .bg-dark {
    background-color: #040404 !important; }
  .mainHomePage a.bg-dark:hover, .mainHomePage a.bg-dark:focus,
  .mainHomePage button.bg-dark:hover,
  .mainHomePage button.bg-dark:focus {
    background-color: black !important; }
  .mainHomePage .bg-white {
    background-color: #fff !important; }
  .mainHomePage .bg-transparent {
    background-color: transparent !important; }
  .mainHomePage .border {
    border: 1px solid #dee2e6 !important; }
  .mainHomePage .border-top {
    border-top: 1px solid #dee2e6 !important; }
  .mainHomePage .border-right {
    border-right: 1px solid #dee2e6 !important; }
  .mainHomePage .border-bottom {
    border-bottom: 1px solid #dee2e6 !important; }
  .mainHomePage .border-left {
    border-left: 1px solid #dee2e6 !important; }
  .mainHomePage .border-0 {
    border: 0 !important; }
  .mainHomePage .border-top-0 {
    border-top: 0 !important; }
  .mainHomePage .border-right-0 {
    border-right: 0 !important; }
  .mainHomePage .border-bottom-0 {
    border-bottom: 0 !important; }
  .mainHomePage .border-left-0 {
    border-left: 0 !important; }
  .mainHomePage .border-primary {
    border-color: #FA0029 !important; }
  .mainHomePage .border-secondary {
    border-color: #7b848c !important; }
  .mainHomePage .border-success {
    border-color: #10cc2f !important; }
  .mainHomePage .border-info {
    border-color: #17a2b8 !important; }
  .mainHomePage .border-warning {
    border-color: #ff9b0b !important; }
  .mainHomePage .border-danger {
    border-color: #FA0029 !important; }
  .mainHomePage .border-light {
    border-color: #f8f9fa !important; }
  .mainHomePage .border-dark {
    border-color: #040404 !important; }
  .mainHomePage .border-white {
    border-color: #fff !important; }
  .mainHomePage .rounded-sm {
    border-radius: 0.25rem !important; }
  .mainHomePage .rounded {
    border-radius: 0.625rem !important; }
  .mainHomePage .rounded-top {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important; }
  .mainHomePage .rounded-right {
    border-top-right-radius: 0.625rem !important;
    border-bottom-right-radius: 0.625rem !important; }
  .mainHomePage .rounded-bottom {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important; }
  .mainHomePage .rounded-left {
    border-top-left-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important; }
  .mainHomePage .rounded-lg {
    border-radius: 100% !important; }
  .mainHomePage .rounded-circle {
    border-radius: 50% !important; }
  .mainHomePage .rounded-pill {
    border-radius: 50rem !important; }
  .mainHomePage .rounded-0 {
    border-radius: 0 !important; }
  .mainHomePage .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  .mainHomePage .d-none {
    display: none !important; }
  .mainHomePage .d-inline {
    display: inline !important; }
  .mainHomePage .d-inline-block {
    display: inline-block !important; }
  .mainHomePage .d-block {
    display: block !important; }
  .mainHomePage .d-table {
    display: table !important; }
  .mainHomePage .d-table-row {
    display: table-row !important; }
  .mainHomePage .d-table-cell {
    display: table-cell !important; }
  .mainHomePage .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .mainHomePage .d-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; }
  @media (min-width: 576px) {
    .mainHomePage .d-sm-none {
      display: none !important; }
    .mainHomePage .d-sm-inline {
      display: inline !important; }
    .mainHomePage .d-sm-inline-block {
      display: inline-block !important; }
    .mainHomePage .d-sm-block {
      display: block !important; }
    .mainHomePage .d-sm-table {
      display: table !important; }
    .mainHomePage .d-sm-table-row {
      display: table-row !important; }
    .mainHomePage .d-sm-table-cell {
      display: table-cell !important; }
    .mainHomePage .d-sm-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .mainHomePage .d-sm-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media (min-width: 768px) {
    .mainHomePage .d-md-none {
      display: none !important; }
    .mainHomePage .d-md-inline {
      display: inline !important; }
    .mainHomePage .d-md-inline-block {
      display: inline-block !important; }
    .mainHomePage .d-md-block {
      display: block !important; }
    .mainHomePage .d-md-table {
      display: table !important; }
    .mainHomePage .d-md-table-row {
      display: table-row !important; }
    .mainHomePage .d-md-table-cell {
      display: table-cell !important; }
    .mainHomePage .d-md-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .mainHomePage .d-md-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media (min-width: 992px) {
    .mainHomePage .d-lg-none {
      display: none !important; }
    .mainHomePage .d-lg-inline {
      display: inline !important; }
    .mainHomePage .d-lg-inline-block {
      display: inline-block !important; }
    .mainHomePage .d-lg-block {
      display: block !important; }
    .mainHomePage .d-lg-table {
      display: table !important; }
    .mainHomePage .d-lg-table-row {
      display: table-row !important; }
    .mainHomePage .d-lg-table-cell {
      display: table-cell !important; }
    .mainHomePage .d-lg-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .mainHomePage .d-lg-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media (min-width: 1200px) {
    .mainHomePage .d-xl-none {
      display: none !important; }
    .mainHomePage .d-xl-inline {
      display: inline !important; }
    .mainHomePage .d-xl-inline-block {
      display: inline-block !important; }
    .mainHomePage .d-xl-block {
      display: block !important; }
    .mainHomePage .d-xl-table {
      display: table !important; }
    .mainHomePage .d-xl-table-row {
      display: table-row !important; }
    .mainHomePage .d-xl-table-cell {
      display: table-cell !important; }
    .mainHomePage .d-xl-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .mainHomePage .d-xl-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  @media print {
    .mainHomePage .d-print-none {
      display: none !important; }
    .mainHomePage .d-print-inline {
      display: inline !important; }
    .mainHomePage .d-print-inline-block {
      display: inline-block !important; }
    .mainHomePage .d-print-block {
      display: block !important; }
    .mainHomePage .d-print-table {
      display: table !important; }
    .mainHomePage .d-print-table-row {
      display: table-row !important; }
    .mainHomePage .d-print-table-cell {
      display: table-cell !important; }
    .mainHomePage .d-print-flex {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important; }
    .mainHomePage .d-print-inline-flex {
      display: -webkit-inline-box !important;
      display: -ms-inline-flexbox !important;
      display: inline-flex !important; } }
  .mainHomePage .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden; }
  .mainHomePage .embed-responsive::before {
    display: block;
    content: ""; }
  .mainHomePage .embed-responsive .embed-responsive-item,
  .mainHomePage .embed-responsive iframe,
  .mainHomePage .embed-responsive embed,
  .mainHomePage .embed-responsive object,
  .mainHomePage .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }
  .mainHomePage .embed-responsive-21by9::before {
    padding-top: 42.8571428571%; }
  .mainHomePage .embed-responsive-16by9::before {
    padding-top: 56.25%; }
  .mainHomePage .embed-responsive-4by3::before {
    padding-top: 75%; }
  .mainHomePage .embed-responsive-1by1::before {
    padding-top: 100%; }
  .mainHomePage .embed-responsive-21by9::before {
    padding-top: 42.8571428571%; }
  .mainHomePage .embed-responsive-16by9::before {
    padding-top: 56.25%; }
  .mainHomePage .embed-responsive-4by3::before {
    padding-top: 75%; }
  .mainHomePage .embed-responsive-1by1::before {
    padding-top: 100%; }
  .mainHomePage .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .mainHomePage .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .mainHomePage .flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .mainHomePage .flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .mainHomePage .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .mainHomePage .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .mainHomePage .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .mainHomePage .flex-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .mainHomePage .flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .mainHomePage .flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .mainHomePage .flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .mainHomePage .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .mainHomePage .justify-content-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .mainHomePage .justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .mainHomePage .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .mainHomePage .justify-content-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .mainHomePage .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .mainHomePage .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .mainHomePage .align-items-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .mainHomePage .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .mainHomePage .align-items-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .mainHomePage .align-items-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .mainHomePage .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .mainHomePage .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .mainHomePage .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .mainHomePage .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .mainHomePage .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .mainHomePage .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .mainHomePage .align-self-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important; }
  .mainHomePage .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .mainHomePage .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .mainHomePage .align-self-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important; }
  .mainHomePage .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .mainHomePage .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important; }
  @media (min-width: 576px) {
    .mainHomePage .flex-sm-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .mainHomePage .flex-sm-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .mainHomePage .flex-sm-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .mainHomePage .flex-sm-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .mainHomePage .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .mainHomePage .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .mainHomePage .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .mainHomePage .flex-sm-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }
    .mainHomePage .flex-sm-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }
    .mainHomePage .flex-sm-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }
    .mainHomePage .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }
    .mainHomePage .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }
    .mainHomePage .justify-content-sm-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .mainHomePage .justify-content-sm-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .mainHomePage .justify-content-sm-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .mainHomePage .justify-content-sm-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .mainHomePage .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .mainHomePage .align-items-sm-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .mainHomePage .align-items-sm-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .mainHomePage .align-items-sm-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .mainHomePage .align-items-sm-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .mainHomePage .align-items-sm-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .mainHomePage .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .mainHomePage .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .mainHomePage .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .mainHomePage .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .mainHomePage .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .mainHomePage .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .mainHomePage .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important; }
    .mainHomePage .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .mainHomePage .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .mainHomePage .align-self-sm-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important; }
    .mainHomePage .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .mainHomePage .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important; } }
  @media (min-width: 768px) {
    .mainHomePage .flex-md-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .mainHomePage .flex-md-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .mainHomePage .flex-md-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .mainHomePage .flex-md-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .mainHomePage .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .mainHomePage .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .mainHomePage .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .mainHomePage .flex-md-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }
    .mainHomePage .flex-md-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }
    .mainHomePage .flex-md-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }
    .mainHomePage .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }
    .mainHomePage .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }
    .mainHomePage .justify-content-md-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .mainHomePage .justify-content-md-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .mainHomePage .justify-content-md-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .mainHomePage .justify-content-md-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .mainHomePage .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .mainHomePage .align-items-md-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .mainHomePage .align-items-md-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .mainHomePage .align-items-md-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .mainHomePage .align-items-md-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .mainHomePage .align-items-md-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .mainHomePage .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .mainHomePage .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .mainHomePage .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .mainHomePage .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .mainHomePage .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .mainHomePage .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .mainHomePage .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important; }
    .mainHomePage .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .mainHomePage .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .mainHomePage .align-self-md-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important; }
    .mainHomePage .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .mainHomePage .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important; } }
  @media (min-width: 992px) {
    .mainHomePage .flex-lg-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .mainHomePage .flex-lg-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .mainHomePage .flex-lg-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .mainHomePage .flex-lg-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .mainHomePage .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .mainHomePage .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .mainHomePage .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .mainHomePage .flex-lg-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }
    .mainHomePage .flex-lg-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }
    .mainHomePage .flex-lg-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }
    .mainHomePage .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }
    .mainHomePage .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }
    .mainHomePage .justify-content-lg-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .mainHomePage .justify-content-lg-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .mainHomePage .justify-content-lg-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .mainHomePage .justify-content-lg-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .mainHomePage .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .mainHomePage .align-items-lg-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .mainHomePage .align-items-lg-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .mainHomePage .align-items-lg-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .mainHomePage .align-items-lg-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .mainHomePage .align-items-lg-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .mainHomePage .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .mainHomePage .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .mainHomePage .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .mainHomePage .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .mainHomePage .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .mainHomePage .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .mainHomePage .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important; }
    .mainHomePage .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .mainHomePage .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .mainHomePage .align-self-lg-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important; }
    .mainHomePage .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .mainHomePage .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important; } }
  @media (min-width: 1200px) {
    .mainHomePage .flex-xl-row {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
      flex-direction: row !important; }
    .mainHomePage .flex-xl-column {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important; }
    .mainHomePage .flex-xl-row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }
    .mainHomePage .flex-xl-column-reverse {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }
    .mainHomePage .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }
    .mainHomePage .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }
    .mainHomePage .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }
    .mainHomePage .flex-xl-fill {
      -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }
    .mainHomePage .flex-xl-grow-0 {
      -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }
    .mainHomePage .flex-xl-grow-1 {
      -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }
    .mainHomePage .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }
    .mainHomePage .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }
    .mainHomePage .justify-content-xl-start {
      -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
      justify-content: flex-start !important; }
    .mainHomePage .justify-content-xl-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important; }
    .mainHomePage .justify-content-xl-center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important; }
    .mainHomePage .justify-content-xl-between {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important; }
    .mainHomePage .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }
    .mainHomePage .align-items-xl-start {
      -webkit-box-align: start !important;
      -ms-flex-align: start !important;
      align-items: flex-start !important; }
    .mainHomePage .align-items-xl-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .mainHomePage .align-items-xl-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .mainHomePage .align-items-xl-baseline {
      -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
      align-items: baseline !important; }
    .mainHomePage .align-items-xl-stretch {
      -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
      align-items: stretch !important; }
    .mainHomePage .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }
    .mainHomePage .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .mainHomePage .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important; }
    .mainHomePage .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }
    .mainHomePage .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }
    .mainHomePage .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }
    .mainHomePage .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important; }
    .mainHomePage .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important; }
    .mainHomePage .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important; }
    .mainHomePage .align-self-xl-center {
      -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important; }
    .mainHomePage .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }
    .mainHomePage .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important; } }
  .mainHomePage .float-left {
    float: left !important; }
  .mainHomePage .float-right {
    float: right !important; }
  .mainHomePage .float-none {
    float: none !important; }
  @media (min-width: 576px) {
    .mainHomePage .float-sm-left {
      float: left !important; }
    .mainHomePage .float-sm-right {
      float: right !important; }
    .mainHomePage .float-sm-none {
      float: none !important; } }
  @media (min-width: 768px) {
    .mainHomePage .float-md-left {
      float: left !important; }
    .mainHomePage .float-md-right {
      float: right !important; }
    .mainHomePage .float-md-none {
      float: none !important; } }
  @media (min-width: 992px) {
    .mainHomePage .float-lg-left {
      float: left !important; }
    .mainHomePage .float-lg-right {
      float: right !important; }
    .mainHomePage .float-lg-none {
      float: none !important; } }
  @media (min-width: 1200px) {
    .mainHomePage .float-xl-left {
      float: left !important; }
    .mainHomePage .float-xl-right {
      float: right !important; }
    .mainHomePage .float-xl-none {
      float: none !important; } }
  .mainHomePage .overflow-auto {
    overflow: auto !important; }
  .mainHomePage .overflow-hidden {
    overflow: hidden !important; }
  .mainHomePage .position-static {
    position: static !important; }
  .mainHomePage .position-relative {
    position: relative !important; }
  .mainHomePage .position-absolute {
    position: absolute !important; }
  .mainHomePage .position-fixed {
    position: fixed !important; }
  .mainHomePage .position-sticky {
    position: sticky !important; }
  .mainHomePage .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  .mainHomePage .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  @supports (position: sticky) {
    .mainHomePage .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  .mainHomePage .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }
  .mainHomePage .sr-only-focusable:active, .mainHomePage .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; }
  .mainHomePage .shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(4, 4, 4, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(4, 4, 4, 0.075) !important; }
  .mainHomePage .shadow {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(4, 4, 4, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(4, 4, 4, 0.15) !important; }
  .mainHomePage .shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(4, 4, 4, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(4, 4, 4, 0.175) !important; }
  .mainHomePage .shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .mainHomePage .w-25 {
    width: 25% !important; }
  .mainHomePage .w-50 {
    width: 50% !important; }
  .mainHomePage .w-75 {
    width: 75% !important; }
  .mainHomePage .w-100 {
    width: 100% !important; }
  .mainHomePage .w-auto {
    width: auto !important; }
  .mainHomePage .h-25 {
    height: 25% !important; }
  .mainHomePage .h-50 {
    height: 50% !important; }
  .mainHomePage .h-75 {
    height: 75% !important; }
  .mainHomePage .h-100 {
    height: 100% !important; }
  .mainHomePage .h-auto {
    height: auto !important; }
  .mainHomePage .mw-100 {
    max-width: 100% !important; }
  .mainHomePage .mh-100 {
    max-height: 100% !important; }
  .mainHomePage .min-vw-100 {
    min-width: 100vw !important; }
  .mainHomePage .min-vh-100 {
    min-height: 100vh !important; }
  .mainHomePage .vw-100 {
    width: 100vw !important; }
  .mainHomePage .vh-100 {
    height: 100vh !important; }
  .mainHomePage .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0); }
  .mainHomePage .m-0 {
    margin: 0 !important; }
  .mainHomePage .mt-0,
  .mainHomePage .my-0 {
    margin-top: 0 !important; }
  .mainHomePage .mr-0,
  .mainHomePage .mx-0 {
    margin-right: 0 !important; }
  .mainHomePage .mb-0,
  .mainHomePage .my-0 {
    margin-bottom: 0 !important; }
  .mainHomePage .ml-0,
  .mainHomePage .mx-0 {
    margin-left: 0 !important; }
  .mainHomePage .m-1 {
    margin: 0.25rem !important; }
  .mainHomePage .mt-1,
  .mainHomePage .my-1 {
    margin-top: 0.25rem !important; }
  .mainHomePage .mr-1,
  .mainHomePage .mx-1 {
    margin-right: 0.25rem !important; }
  .mainHomePage .mb-1,
  .mainHomePage .my-1 {
    margin-bottom: 0.25rem !important; }
  .mainHomePage .ml-1,
  .mainHomePage .mx-1 {
    margin-left: 0.25rem !important; }
  .mainHomePage .m-2 {
    margin: 0.5rem !important; }
  .mainHomePage .mt-2,
  .mainHomePage .my-2 {
    margin-top: 0.5rem !important; }
  .mainHomePage .mr-2,
  .mainHomePage .mx-2 {
    margin-right: 0.5rem !important; }
  .mainHomePage .mb-2,
  .mainHomePage .my-2 {
    margin-bottom: 0.5rem !important; }
  .mainHomePage .ml-2,
  .mainHomePage .mx-2 {
    margin-left: 0.5rem !important; }
  .mainHomePage .m-3 {
    margin: 1rem !important; }
  .mainHomePage .mt-3,
  .mainHomePage .my-3 {
    margin-top: 1rem !important; }
  .mainHomePage .mr-3,
  .mainHomePage .mx-3 {
    margin-right: 1rem !important; }
  .mainHomePage .mb-3,
  .mainHomePage .my-3 {
    margin-bottom: 1rem !important; }
  .mainHomePage .ml-3,
  .mainHomePage .mx-3 {
    margin-left: 1rem !important; }
  .mainHomePage .m-4 {
    margin: 1.5rem !important; }
  .mainHomePage .mt-4,
  .mainHomePage .my-4 {
    margin-top: 1.5rem !important; }
  .mainHomePage .mr-4,
  .mainHomePage .mx-4 {
    margin-right: 1.5rem !important; }
  .mainHomePage .mb-4,
  .mainHomePage .my-4 {
    margin-bottom: 1.5rem !important; }
  .mainHomePage .ml-4,
  .mainHomePage .mx-4 {
    margin-left: 1.5rem !important; }
  .mainHomePage .m-5 {
    margin: 3rem !important; }
  .mainHomePage .mt-5,
  .mainHomePage .my-5 {
    margin-top: 3rem !important; }
  .mainHomePage .mr-5,
  .mainHomePage .mx-5 {
    margin-right: 3rem !important; }
  .mainHomePage .mb-5,
  .mainHomePage .my-5 {
    margin-bottom: 3rem !important; }
  .mainHomePage .ml-5,
  .mainHomePage .mx-5 {
    margin-left: 3rem !important; }
  .mainHomePage .p-0 {
    padding: 0 !important; }
  .mainHomePage .pt-0,
  .mainHomePage .py-0 {
    padding-top: 0 !important; }
  .mainHomePage .pr-0,
  .mainHomePage .px-0 {
    padding-right: 0 !important; }
  .mainHomePage .pb-0,
  .mainHomePage .py-0 {
    padding-bottom: 0 !important; }
  .mainHomePage .pl-0,
  .mainHomePage .px-0 {
    padding-left: 0 !important; }
  .mainHomePage .p-1 {
    padding: 0.25rem !important; }
  .mainHomePage .pt-1,
  .mainHomePage .py-1 {
    padding-top: 0.25rem !important; }
  .mainHomePage .pr-1,
  .mainHomePage .px-1 {
    padding-right: 0.25rem !important; }
  .mainHomePage .pb-1,
  .mainHomePage .py-1 {
    padding-bottom: 0.25rem !important; }
  .mainHomePage .pl-1,
  .mainHomePage .px-1 {
    padding-left: 0.25rem !important; }
  .mainHomePage .p-2 {
    padding: 0.5rem !important; }
  .mainHomePage .pt-2,
  .mainHomePage .py-2 {
    padding-top: 0.5rem !important; }
  .mainHomePage .pr-2,
  .mainHomePage .px-2 {
    padding-right: 0.5rem !important; }
  .mainHomePage .pb-2,
  .mainHomePage .py-2 {
    padding-bottom: 0.5rem !important; }
  .mainHomePage .pl-2,
  .mainHomePage .px-2 {
    padding-left: 0.5rem !important; }
  .mainHomePage .p-3 {
    padding: 1rem !important; }
  .mainHomePage .pt-3,
  .mainHomePage .py-3 {
    padding-top: 1rem !important; }
  .mainHomePage .pr-3,
  .mainHomePage .px-3 {
    padding-right: 1rem !important; }
  .mainHomePage .pb-3,
  .mainHomePage .py-3 {
    padding-bottom: 1rem !important; }
  .mainHomePage .pl-3,
  .mainHomePage .px-3 {
    padding-left: 1rem !important; }
  .mainHomePage .p-4 {
    padding: 1.5rem !important; }
  .mainHomePage .pt-4,
  .mainHomePage .py-4 {
    padding-top: 1.5rem !important; }
  .mainHomePage .pr-4,
  .mainHomePage .px-4 {
    padding-right: 1.5rem !important; }
  .mainHomePage .pb-4,
  .mainHomePage .py-4 {
    padding-bottom: 1.5rem !important; }
  .mainHomePage .pl-4,
  .mainHomePage .px-4 {
    padding-left: 1.5rem !important; }
  .mainHomePage .p-5 {
    padding: 3rem !important; }
  .mainHomePage .pt-5,
  .mainHomePage .py-5 {
    padding-top: 3rem !important; }
  .mainHomePage .pr-5,
  .mainHomePage .px-5 {
    padding-right: 3rem !important; }
  .mainHomePage .pb-5,
  .mainHomePage .py-5 {
    padding-bottom: 3rem !important; }
  .mainHomePage .pl-5,
  .mainHomePage .px-5 {
    padding-left: 3rem !important; }
  .mainHomePage .m-n1 {
    margin: -0.25rem !important; }
  .mainHomePage .mt-n1,
  .mainHomePage .my-n1 {
    margin-top: -0.25rem !important; }
  .mainHomePage .mr-n1,
  .mainHomePage .mx-n1 {
    margin-right: -0.25rem !important; }
  .mainHomePage .mb-n1,
  .mainHomePage .my-n1 {
    margin-bottom: -0.25rem !important; }
  .mainHomePage .ml-n1,
  .mainHomePage .mx-n1 {
    margin-left: -0.25rem !important; }
  .mainHomePage .m-n2 {
    margin: -0.5rem !important; }
  .mainHomePage .mt-n2,
  .mainHomePage .my-n2 {
    margin-top: -0.5rem !important; }
  .mainHomePage .mr-n2,
  .mainHomePage .mx-n2 {
    margin-right: -0.5rem !important; }
  .mainHomePage .mb-n2,
  .mainHomePage .my-n2 {
    margin-bottom: -0.5rem !important; }
  .mainHomePage .ml-n2,
  .mainHomePage .mx-n2 {
    margin-left: -0.5rem !important; }
  .mainHomePage .m-n3 {
    margin: -1rem !important; }
  .mainHomePage .mt-n3,
  .mainHomePage .my-n3 {
    margin-top: -1rem !important; }
  .mainHomePage .mr-n3,
  .mainHomePage .mx-n3 {
    margin-right: -1rem !important; }
  .mainHomePage .mb-n3,
  .mainHomePage .my-n3 {
    margin-bottom: -1rem !important; }
  .mainHomePage .ml-n3,
  .mainHomePage .mx-n3 {
    margin-left: -1rem !important; }
  .mainHomePage .m-n4 {
    margin: -1.5rem !important; }
  .mainHomePage .mt-n4,
  .mainHomePage .my-n4 {
    margin-top: -1.5rem !important; }
  .mainHomePage .mr-n4,
  .mainHomePage .mx-n4 {
    margin-right: -1.5rem !important; }
  .mainHomePage .mb-n4,
  .mainHomePage .my-n4 {
    margin-bottom: -1.5rem !important; }
  .mainHomePage .ml-n4,
  .mainHomePage .mx-n4 {
    margin-left: -1.5rem !important; }
  .mainHomePage .m-n5 {
    margin: -3rem !important; }
  .mainHomePage .mt-n5,
  .mainHomePage .my-n5 {
    margin-top: -3rem !important; }
  .mainHomePage .mr-n5,
  .mainHomePage .mx-n5 {
    margin-right: -3rem !important; }
  .mainHomePage .mb-n5,
  .mainHomePage .my-n5 {
    margin-bottom: -3rem !important; }
  .mainHomePage .ml-n5,
  .mainHomePage .mx-n5 {
    margin-left: -3rem !important; }
  .mainHomePage .m-auto {
    margin: auto !important; }
  .mainHomePage .mt-auto,
  .mainHomePage .my-auto {
    margin-top: auto !important; }
  .mainHomePage .mr-auto,
  .mainHomePage .mx-auto {
    margin-right: auto !important; }
  .mainHomePage .mb-auto,
  .mainHomePage .my-auto {
    margin-bottom: auto !important; }
  .mainHomePage .ml-auto,
  .mainHomePage .mx-auto {
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .mainHomePage .m-sm-0 {
      margin: 0 !important; }
    .mainHomePage .mt-sm-0,
    .mainHomePage .my-sm-0 {
      margin-top: 0 !important; }
    .mainHomePage .mr-sm-0,
    .mainHomePage .mx-sm-0 {
      margin-right: 0 !important; }
    .mainHomePage .mb-sm-0,
    .mainHomePage .my-sm-0 {
      margin-bottom: 0 !important; }
    .mainHomePage .ml-sm-0,
    .mainHomePage .mx-sm-0 {
      margin-left: 0 !important; }
    .mainHomePage .m-sm-1 {
      margin: 0.25rem !important; }
    .mainHomePage .mt-sm-1,
    .mainHomePage .my-sm-1 {
      margin-top: 0.25rem !important; }
    .mainHomePage .mr-sm-1,
    .mainHomePage .mx-sm-1 {
      margin-right: 0.25rem !important; }
    .mainHomePage .mb-sm-1,
    .mainHomePage .my-sm-1 {
      margin-bottom: 0.25rem !important; }
    .mainHomePage .ml-sm-1,
    .mainHomePage .mx-sm-1 {
      margin-left: 0.25rem !important; }
    .mainHomePage .m-sm-2 {
      margin: 0.5rem !important; }
    .mainHomePage .mt-sm-2,
    .mainHomePage .my-sm-2 {
      margin-top: 0.5rem !important; }
    .mainHomePage .mr-sm-2,
    .mainHomePage .mx-sm-2 {
      margin-right: 0.5rem !important; }
    .mainHomePage .mb-sm-2,
    .mainHomePage .my-sm-2 {
      margin-bottom: 0.5rem !important; }
    .mainHomePage .ml-sm-2,
    .mainHomePage .mx-sm-2 {
      margin-left: 0.5rem !important; }
    .mainHomePage .m-sm-3 {
      margin: 1rem !important; }
    .mainHomePage .mt-sm-3,
    .mainHomePage .my-sm-3 {
      margin-top: 1rem !important; }
    .mainHomePage .mr-sm-3,
    .mainHomePage .mx-sm-3 {
      margin-right: 1rem !important; }
    .mainHomePage .mb-sm-3,
    .mainHomePage .my-sm-3 {
      margin-bottom: 1rem !important; }
    .mainHomePage .ml-sm-3,
    .mainHomePage .mx-sm-3 {
      margin-left: 1rem !important; }
    .mainHomePage .m-sm-4 {
      margin: 1.5rem !important; }
    .mainHomePage .mt-sm-4,
    .mainHomePage .my-sm-4 {
      margin-top: 1.5rem !important; }
    .mainHomePage .mr-sm-4,
    .mainHomePage .mx-sm-4 {
      margin-right: 1.5rem !important; }
    .mainHomePage .mb-sm-4,
    .mainHomePage .my-sm-4 {
      margin-bottom: 1.5rem !important; }
    .mainHomePage .ml-sm-4,
    .mainHomePage .mx-sm-4 {
      margin-left: 1.5rem !important; }
    .mainHomePage .m-sm-5 {
      margin: 3rem !important; }
    .mainHomePage .mt-sm-5,
    .mainHomePage .my-sm-5 {
      margin-top: 3rem !important; }
    .mainHomePage .mr-sm-5,
    .mainHomePage .mx-sm-5 {
      margin-right: 3rem !important; }
    .mainHomePage .mb-sm-5,
    .mainHomePage .my-sm-5 {
      margin-bottom: 3rem !important; }
    .mainHomePage .ml-sm-5,
    .mainHomePage .mx-sm-5 {
      margin-left: 3rem !important; }
    .mainHomePage .p-sm-0 {
      padding: 0 !important; }
    .mainHomePage .pt-sm-0,
    .mainHomePage .py-sm-0 {
      padding-top: 0 !important; }
    .mainHomePage .pr-sm-0,
    .mainHomePage .px-sm-0 {
      padding-right: 0 !important; }
    .mainHomePage .pb-sm-0,
    .mainHomePage .py-sm-0 {
      padding-bottom: 0 !important; }
    .mainHomePage .pl-sm-0,
    .mainHomePage .px-sm-0 {
      padding-left: 0 !important; }
    .mainHomePage .p-sm-1 {
      padding: 0.25rem !important; }
    .mainHomePage .pt-sm-1,
    .mainHomePage .py-sm-1 {
      padding-top: 0.25rem !important; }
    .mainHomePage .pr-sm-1,
    .mainHomePage .px-sm-1 {
      padding-right: 0.25rem !important; }
    .mainHomePage .pb-sm-1,
    .mainHomePage .py-sm-1 {
      padding-bottom: 0.25rem !important; }
    .mainHomePage .pl-sm-1,
    .mainHomePage .px-sm-1 {
      padding-left: 0.25rem !important; }
    .mainHomePage .p-sm-2 {
      padding: 0.5rem !important; }
    .mainHomePage .pt-sm-2,
    .mainHomePage .py-sm-2 {
      padding-top: 0.5rem !important; }
    .mainHomePage .pr-sm-2,
    .mainHomePage .px-sm-2 {
      padding-right: 0.5rem !important; }
    .mainHomePage .pb-sm-2,
    .mainHomePage .py-sm-2 {
      padding-bottom: 0.5rem !important; }
    .mainHomePage .pl-sm-2,
    .mainHomePage .px-sm-2 {
      padding-left: 0.5rem !important; }
    .mainHomePage .p-sm-3 {
      padding: 1rem !important; }
    .mainHomePage .pt-sm-3,
    .mainHomePage .py-sm-3 {
      padding-top: 1rem !important; }
    .mainHomePage .pr-sm-3,
    .mainHomePage .px-sm-3 {
      padding-right: 1rem !important; }
    .mainHomePage .pb-sm-3,
    .mainHomePage .py-sm-3 {
      padding-bottom: 1rem !important; }
    .mainHomePage .pl-sm-3,
    .mainHomePage .px-sm-3 {
      padding-left: 1rem !important; }
    .mainHomePage .p-sm-4 {
      padding: 1.5rem !important; }
    .mainHomePage .pt-sm-4,
    .mainHomePage .py-sm-4 {
      padding-top: 1.5rem !important; }
    .mainHomePage .pr-sm-4,
    .mainHomePage .px-sm-4 {
      padding-right: 1.5rem !important; }
    .mainHomePage .pb-sm-4,
    .mainHomePage .py-sm-4 {
      padding-bottom: 1.5rem !important; }
    .mainHomePage .pl-sm-4,
    .mainHomePage .px-sm-4 {
      padding-left: 1.5rem !important; }
    .mainHomePage .p-sm-5 {
      padding: 3rem !important; }
    .mainHomePage .pt-sm-5,
    .mainHomePage .py-sm-5 {
      padding-top: 3rem !important; }
    .mainHomePage .pr-sm-5,
    .mainHomePage .px-sm-5 {
      padding-right: 3rem !important; }
    .mainHomePage .pb-sm-5,
    .mainHomePage .py-sm-5 {
      padding-bottom: 3rem !important; }
    .mainHomePage .pl-sm-5,
    .mainHomePage .px-sm-5 {
      padding-left: 3rem !important; }
    .mainHomePage .m-sm-n1 {
      margin: -0.25rem !important; }
    .mainHomePage .mt-sm-n1,
    .mainHomePage .my-sm-n1 {
      margin-top: -0.25rem !important; }
    .mainHomePage .mr-sm-n1,
    .mainHomePage .mx-sm-n1 {
      margin-right: -0.25rem !important; }
    .mainHomePage .mb-sm-n1,
    .mainHomePage .my-sm-n1 {
      margin-bottom: -0.25rem !important; }
    .mainHomePage .ml-sm-n1,
    .mainHomePage .mx-sm-n1 {
      margin-left: -0.25rem !important; }
    .mainHomePage .m-sm-n2 {
      margin: -0.5rem !important; }
    .mainHomePage .mt-sm-n2,
    .mainHomePage .my-sm-n2 {
      margin-top: -0.5rem !important; }
    .mainHomePage .mr-sm-n2,
    .mainHomePage .mx-sm-n2 {
      margin-right: -0.5rem !important; }
    .mainHomePage .mb-sm-n2,
    .mainHomePage .my-sm-n2 {
      margin-bottom: -0.5rem !important; }
    .mainHomePage .ml-sm-n2,
    .mainHomePage .mx-sm-n2 {
      margin-left: -0.5rem !important; }
    .mainHomePage .m-sm-n3 {
      margin: -1rem !important; }
    .mainHomePage .mt-sm-n3,
    .mainHomePage .my-sm-n3 {
      margin-top: -1rem !important; }
    .mainHomePage .mr-sm-n3,
    .mainHomePage .mx-sm-n3 {
      margin-right: -1rem !important; }
    .mainHomePage .mb-sm-n3,
    .mainHomePage .my-sm-n3 {
      margin-bottom: -1rem !important; }
    .mainHomePage .ml-sm-n3,
    .mainHomePage .mx-sm-n3 {
      margin-left: -1rem !important; }
    .mainHomePage .m-sm-n4 {
      margin: -1.5rem !important; }
    .mainHomePage .mt-sm-n4,
    .mainHomePage .my-sm-n4 {
      margin-top: -1.5rem !important; }
    .mainHomePage .mr-sm-n4,
    .mainHomePage .mx-sm-n4 {
      margin-right: -1.5rem !important; }
    .mainHomePage .mb-sm-n4,
    .mainHomePage .my-sm-n4 {
      margin-bottom: -1.5rem !important; }
    .mainHomePage .ml-sm-n4,
    .mainHomePage .mx-sm-n4 {
      margin-left: -1.5rem !important; }
    .mainHomePage .m-sm-n5 {
      margin: -3rem !important; }
    .mainHomePage .mt-sm-n5,
    .mainHomePage .my-sm-n5 {
      margin-top: -3rem !important; }
    .mainHomePage .mr-sm-n5,
    .mainHomePage .mx-sm-n5 {
      margin-right: -3rem !important; }
    .mainHomePage .mb-sm-n5,
    .mainHomePage .my-sm-n5 {
      margin-bottom: -3rem !important; }
    .mainHomePage .ml-sm-n5,
    .mainHomePage .mx-sm-n5 {
      margin-left: -3rem !important; }
    .mainHomePage .m-sm-auto {
      margin: auto !important; }
    .mainHomePage .mt-sm-auto,
    .mainHomePage .my-sm-auto {
      margin-top: auto !important; }
    .mainHomePage .mr-sm-auto,
    .mainHomePage .mx-sm-auto {
      margin-right: auto !important; }
    .mainHomePage .mb-sm-auto,
    .mainHomePage .my-sm-auto {
      margin-bottom: auto !important; }
    .mainHomePage .ml-sm-auto,
    .mainHomePage .mx-sm-auto {
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .mainHomePage .m-md-0 {
      margin: 0 !important; }
    .mainHomePage .mt-md-0,
    .mainHomePage .my-md-0 {
      margin-top: 0 !important; }
    .mainHomePage .mr-md-0,
    .mainHomePage .mx-md-0 {
      margin-right: 0 !important; }
    .mainHomePage .mb-md-0,
    .mainHomePage .my-md-0 {
      margin-bottom: 0 !important; }
    .mainHomePage .ml-md-0,
    .mainHomePage .mx-md-0 {
      margin-left: 0 !important; }
    .mainHomePage .m-md-1 {
      margin: 0.25rem !important; }
    .mainHomePage .mt-md-1,
    .mainHomePage .my-md-1 {
      margin-top: 0.25rem !important; }
    .mainHomePage .mr-md-1,
    .mainHomePage .mx-md-1 {
      margin-right: 0.25rem !important; }
    .mainHomePage .mb-md-1,
    .mainHomePage .my-md-1 {
      margin-bottom: 0.25rem !important; }
    .mainHomePage .ml-md-1,
    .mainHomePage .mx-md-1 {
      margin-left: 0.25rem !important; }
    .mainHomePage .m-md-2 {
      margin: 0.5rem !important; }
    .mainHomePage .mt-md-2,
    .mainHomePage .my-md-2 {
      margin-top: 0.5rem !important; }
    .mainHomePage .mr-md-2,
    .mainHomePage .mx-md-2 {
      margin-right: 0.5rem !important; }
    .mainHomePage .mb-md-2,
    .mainHomePage .my-md-2 {
      margin-bottom: 0.5rem !important; }
    .mainHomePage .ml-md-2,
    .mainHomePage .mx-md-2 {
      margin-left: 0.5rem !important; }
    .mainHomePage .m-md-3 {
      margin: 1rem !important; }
    .mainHomePage .mt-md-3,
    .mainHomePage .my-md-3 {
      margin-top: 1rem !important; }
    .mainHomePage .mr-md-3,
    .mainHomePage .mx-md-3 {
      margin-right: 1rem !important; }
    .mainHomePage .mb-md-3,
    .mainHomePage .my-md-3 {
      margin-bottom: 1rem !important; }
    .mainHomePage .ml-md-3,
    .mainHomePage .mx-md-3 {
      margin-left: 1rem !important; }
    .mainHomePage .m-md-4 {
      margin: 1.5rem !important; }
    .mainHomePage .mt-md-4,
    .mainHomePage .my-md-4 {
      margin-top: 1.5rem !important; }
    .mainHomePage .mr-md-4,
    .mainHomePage .mx-md-4 {
      margin-right: 1.5rem !important; }
    .mainHomePage .mb-md-4,
    .mainHomePage .my-md-4 {
      margin-bottom: 1.5rem !important; }
    .mainHomePage .ml-md-4,
    .mainHomePage .mx-md-4 {
      margin-left: 1.5rem !important; }
    .mainHomePage .m-md-5 {
      margin: 3rem !important; }
    .mainHomePage .mt-md-5,
    .mainHomePage .my-md-5 {
      margin-top: 3rem !important; }
    .mainHomePage .mr-md-5,
    .mainHomePage .mx-md-5 {
      margin-right: 3rem !important; }
    .mainHomePage .mb-md-5,
    .mainHomePage .my-md-5 {
      margin-bottom: 3rem !important; }
    .mainHomePage .ml-md-5,
    .mainHomePage .mx-md-5 {
      margin-left: 3rem !important; }
    .mainHomePage .p-md-0 {
      padding: 0 !important; }
    .mainHomePage .pt-md-0,
    .mainHomePage .py-md-0 {
      padding-top: 0 !important; }
    .mainHomePage .pr-md-0,
    .mainHomePage .px-md-0 {
      padding-right: 0 !important; }
    .mainHomePage .pb-md-0,
    .mainHomePage .py-md-0 {
      padding-bottom: 0 !important; }
    .mainHomePage .pl-md-0,
    .mainHomePage .px-md-0 {
      padding-left: 0 !important; }
    .mainHomePage .p-md-1 {
      padding: 0.25rem !important; }
    .mainHomePage .pt-md-1,
    .mainHomePage .py-md-1 {
      padding-top: 0.25rem !important; }
    .mainHomePage .pr-md-1,
    .mainHomePage .px-md-1 {
      padding-right: 0.25rem !important; }
    .mainHomePage .pb-md-1,
    .mainHomePage .py-md-1 {
      padding-bottom: 0.25rem !important; }
    .mainHomePage .pl-md-1,
    .mainHomePage .px-md-1 {
      padding-left: 0.25rem !important; }
    .mainHomePage .p-md-2 {
      padding: 0.5rem !important; }
    .mainHomePage .pt-md-2,
    .mainHomePage .py-md-2 {
      padding-top: 0.5rem !important; }
    .mainHomePage .pr-md-2,
    .mainHomePage .px-md-2 {
      padding-right: 0.5rem !important; }
    .mainHomePage .pb-md-2,
    .mainHomePage .py-md-2 {
      padding-bottom: 0.5rem !important; }
    .mainHomePage .pl-md-2,
    .mainHomePage .px-md-2 {
      padding-left: 0.5rem !important; }
    .mainHomePage .p-md-3 {
      padding: 1rem !important; }
    .mainHomePage .pt-md-3,
    .mainHomePage .py-md-3 {
      padding-top: 1rem !important; }
    .mainHomePage .pr-md-3,
    .mainHomePage .px-md-3 {
      padding-right: 1rem !important; }
    .mainHomePage .pb-md-3,
    .mainHomePage .py-md-3 {
      padding-bottom: 1rem !important; }
    .mainHomePage .pl-md-3,
    .mainHomePage .px-md-3 {
      padding-left: 1rem !important; }
    .mainHomePage .p-md-4 {
      padding: 1.5rem !important; }
    .mainHomePage .pt-md-4,
    .mainHomePage .py-md-4 {
      padding-top: 1.5rem !important; }
    .mainHomePage .pr-md-4,
    .mainHomePage .px-md-4 {
      padding-right: 1.5rem !important; }
    .mainHomePage .pb-md-4,
    .mainHomePage .py-md-4 {
      padding-bottom: 1.5rem !important; }
    .mainHomePage .pl-md-4,
    .mainHomePage .px-md-4 {
      padding-left: 1.5rem !important; }
    .mainHomePage .p-md-5 {
      padding: 3rem !important; }
    .mainHomePage .pt-md-5,
    .mainHomePage .py-md-5 {
      padding-top: 3rem !important; }
    .mainHomePage .pr-md-5,
    .mainHomePage .px-md-5 {
      padding-right: 3rem !important; }
    .mainHomePage .pb-md-5,
    .mainHomePage .py-md-5 {
      padding-bottom: 3rem !important; }
    .mainHomePage .pl-md-5,
    .mainHomePage .px-md-5 {
      padding-left: 3rem !important; }
    .mainHomePage .m-md-n1 {
      margin: -0.25rem !important; }
    .mainHomePage .mt-md-n1,
    .mainHomePage .my-md-n1 {
      margin-top: -0.25rem !important; }
    .mainHomePage .mr-md-n1,
    .mainHomePage .mx-md-n1 {
      margin-right: -0.25rem !important; }
    .mainHomePage .mb-md-n1,
    .mainHomePage .my-md-n1 {
      margin-bottom: -0.25rem !important; }
    .mainHomePage .ml-md-n1,
    .mainHomePage .mx-md-n1 {
      margin-left: -0.25rem !important; }
    .mainHomePage .m-md-n2 {
      margin: -0.5rem !important; }
    .mainHomePage .mt-md-n2,
    .mainHomePage .my-md-n2 {
      margin-top: -0.5rem !important; }
    .mainHomePage .mr-md-n2,
    .mainHomePage .mx-md-n2 {
      margin-right: -0.5rem !important; }
    .mainHomePage .mb-md-n2,
    .mainHomePage .my-md-n2 {
      margin-bottom: -0.5rem !important; }
    .mainHomePage .ml-md-n2,
    .mainHomePage .mx-md-n2 {
      margin-left: -0.5rem !important; }
    .mainHomePage .m-md-n3 {
      margin: -1rem !important; }
    .mainHomePage .mt-md-n3,
    .mainHomePage .my-md-n3 {
      margin-top: -1rem !important; }
    .mainHomePage .mr-md-n3,
    .mainHomePage .mx-md-n3 {
      margin-right: -1rem !important; }
    .mainHomePage .mb-md-n3,
    .mainHomePage .my-md-n3 {
      margin-bottom: -1rem !important; }
    .mainHomePage .ml-md-n3,
    .mainHomePage .mx-md-n3 {
      margin-left: -1rem !important; }
    .mainHomePage .m-md-n4 {
      margin: -1.5rem !important; }
    .mainHomePage .mt-md-n4,
    .mainHomePage .my-md-n4 {
      margin-top: -1.5rem !important; }
    .mainHomePage .mr-md-n4,
    .mainHomePage .mx-md-n4 {
      margin-right: -1.5rem !important; }
    .mainHomePage .mb-md-n4,
    .mainHomePage .my-md-n4 {
      margin-bottom: -1.5rem !important; }
    .mainHomePage .ml-md-n4,
    .mainHomePage .mx-md-n4 {
      margin-left: -1.5rem !important; }
    .mainHomePage .m-md-n5 {
      margin: -3rem !important; }
    .mainHomePage .mt-md-n5,
    .mainHomePage .my-md-n5 {
      margin-top: -3rem !important; }
    .mainHomePage .mr-md-n5,
    .mainHomePage .mx-md-n5 {
      margin-right: -3rem !important; }
    .mainHomePage .mb-md-n5,
    .mainHomePage .my-md-n5 {
      margin-bottom: -3rem !important; }
    .mainHomePage .ml-md-n5,
    .mainHomePage .mx-md-n5 {
      margin-left: -3rem !important; }
    .mainHomePage .m-md-auto {
      margin: auto !important; }
    .mainHomePage .mt-md-auto,
    .mainHomePage .my-md-auto {
      margin-top: auto !important; }
    .mainHomePage .mr-md-auto,
    .mainHomePage .mx-md-auto {
      margin-right: auto !important; }
    .mainHomePage .mb-md-auto,
    .mainHomePage .my-md-auto {
      margin-bottom: auto !important; }
    .mainHomePage .ml-md-auto,
    .mainHomePage .mx-md-auto {
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .mainHomePage .m-lg-0 {
      margin: 0 !important; }
    .mainHomePage .mt-lg-0,
    .mainHomePage .my-lg-0 {
      margin-top: 0 !important; }
    .mainHomePage .mr-lg-0,
    .mainHomePage .mx-lg-0 {
      margin-right: 0 !important; }
    .mainHomePage .mb-lg-0,
    .mainHomePage .my-lg-0 {
      margin-bottom: 0 !important; }
    .mainHomePage .ml-lg-0,
    .mainHomePage .mx-lg-0 {
      margin-left: 0 !important; }
    .mainHomePage .m-lg-1 {
      margin: 0.25rem !important; }
    .mainHomePage .mt-lg-1,
    .mainHomePage .my-lg-1 {
      margin-top: 0.25rem !important; }
    .mainHomePage .mr-lg-1,
    .mainHomePage .mx-lg-1 {
      margin-right: 0.25rem !important; }
    .mainHomePage .mb-lg-1,
    .mainHomePage .my-lg-1 {
      margin-bottom: 0.25rem !important; }
    .mainHomePage .ml-lg-1,
    .mainHomePage .mx-lg-1 {
      margin-left: 0.25rem !important; }
    .mainHomePage .m-lg-2 {
      margin: 0.5rem !important; }
    .mainHomePage .mt-lg-2,
    .mainHomePage .my-lg-2 {
      margin-top: 0.5rem !important; }
    .mainHomePage .mr-lg-2,
    .mainHomePage .mx-lg-2 {
      margin-right: 0.5rem !important; }
    .mainHomePage .mb-lg-2,
    .mainHomePage .my-lg-2 {
      margin-bottom: 0.5rem !important; }
    .mainHomePage .ml-lg-2,
    .mainHomePage .mx-lg-2 {
      margin-left: 0.5rem !important; }
    .mainHomePage .m-lg-3 {
      margin: 1rem !important; }
    .mainHomePage .mt-lg-3,
    .mainHomePage .my-lg-3 {
      margin-top: 1rem !important; }
    .mainHomePage .mr-lg-3,
    .mainHomePage .mx-lg-3 {
      margin-right: 1rem !important; }
    .mainHomePage .mb-lg-3,
    .mainHomePage .my-lg-3 {
      margin-bottom: 1rem !important; }
    .mainHomePage .ml-lg-3,
    .mainHomePage .mx-lg-3 {
      margin-left: 1rem !important; }
    .mainHomePage .m-lg-4 {
      margin: 1.5rem !important; }
    .mainHomePage .mt-lg-4,
    .mainHomePage .my-lg-4 {
      margin-top: 1.5rem !important; }
    .mainHomePage .mr-lg-4,
    .mainHomePage .mx-lg-4 {
      margin-right: 1.5rem !important; }
    .mainHomePage .mb-lg-4,
    .mainHomePage .my-lg-4 {
      margin-bottom: 1.5rem !important; }
    .mainHomePage .ml-lg-4,
    .mainHomePage .mx-lg-4 {
      margin-left: 1.5rem !important; }
    .mainHomePage .m-lg-5 {
      margin: 3rem !important; }
    .mainHomePage .mt-lg-5,
    .mainHomePage .my-lg-5 {
      margin-top: 3rem !important; }
    .mainHomePage .mr-lg-5,
    .mainHomePage .mx-lg-5 {
      margin-right: 3rem !important; }
    .mainHomePage .mb-lg-5,
    .mainHomePage .my-lg-5 {
      margin-bottom: 3rem !important; }
    .mainHomePage .ml-lg-5,
    .mainHomePage .mx-lg-5 {
      margin-left: 3rem !important; }
    .mainHomePage .p-lg-0 {
      padding: 0 !important; }
    .mainHomePage .pt-lg-0,
    .mainHomePage .py-lg-0 {
      padding-top: 0 !important; }
    .mainHomePage .pr-lg-0,
    .mainHomePage .px-lg-0 {
      padding-right: 0 !important; }
    .mainHomePage .pb-lg-0,
    .mainHomePage .py-lg-0 {
      padding-bottom: 0 !important; }
    .mainHomePage .pl-lg-0,
    .mainHomePage .px-lg-0 {
      padding-left: 0 !important; }
    .mainHomePage .p-lg-1 {
      padding: 0.25rem !important; }
    .mainHomePage .pt-lg-1,
    .mainHomePage .py-lg-1 {
      padding-top: 0.25rem !important; }
    .mainHomePage .pr-lg-1,
    .mainHomePage .px-lg-1 {
      padding-right: 0.25rem !important; }
    .mainHomePage .pb-lg-1,
    .mainHomePage .py-lg-1 {
      padding-bottom: 0.25rem !important; }
    .mainHomePage .pl-lg-1,
    .mainHomePage .px-lg-1 {
      padding-left: 0.25rem !important; }
    .mainHomePage .p-lg-2 {
      padding: 0.5rem !important; }
    .mainHomePage .pt-lg-2,
    .mainHomePage .py-lg-2 {
      padding-top: 0.5rem !important; }
    .mainHomePage .pr-lg-2,
    .mainHomePage .px-lg-2 {
      padding-right: 0.5rem !important; }
    .mainHomePage .pb-lg-2,
    .mainHomePage .py-lg-2 {
      padding-bottom: 0.5rem !important; }
    .mainHomePage .pl-lg-2,
    .mainHomePage .px-lg-2 {
      padding-left: 0.5rem !important; }
    .mainHomePage .p-lg-3 {
      padding: 1rem !important; }
    .mainHomePage .pt-lg-3,
    .mainHomePage .py-lg-3 {
      padding-top: 1rem !important; }
    .mainHomePage .pr-lg-3,
    .mainHomePage .px-lg-3 {
      padding-right: 1rem !important; }
    .mainHomePage .pb-lg-3,
    .mainHomePage .py-lg-3 {
      padding-bottom: 1rem !important; }
    .mainHomePage .pl-lg-3,
    .mainHomePage .px-lg-3 {
      padding-left: 1rem !important; }
    .mainHomePage .p-lg-4 {
      padding: 1.5rem !important; }
    .mainHomePage .pt-lg-4,
    .mainHomePage .py-lg-4 {
      padding-top: 1.5rem !important; }
    .mainHomePage .pr-lg-4,
    .mainHomePage .px-lg-4 {
      padding-right: 1.5rem !important; }
    .mainHomePage .pb-lg-4,
    .mainHomePage .py-lg-4 {
      padding-bottom: 1.5rem !important; }
    .mainHomePage .pl-lg-4,
    .mainHomePage .px-lg-4 {
      padding-left: 1.5rem !important; }
    .mainHomePage .p-lg-5 {
      padding: 3rem !important; }
    .mainHomePage .pt-lg-5,
    .mainHomePage .py-lg-5 {
      padding-top: 3rem !important; }
    .mainHomePage .pr-lg-5,
    .mainHomePage .px-lg-5 {
      padding-right: 3rem !important; }
    .mainHomePage .pb-lg-5,
    .mainHomePage .py-lg-5 {
      padding-bottom: 3rem !important; }
    .mainHomePage .pl-lg-5,
    .mainHomePage .px-lg-5 {
      padding-left: 3rem !important; }
    .mainHomePage .m-lg-n1 {
      margin: -0.25rem !important; }
    .mainHomePage .mt-lg-n1,
    .mainHomePage .my-lg-n1 {
      margin-top: -0.25rem !important; }
    .mainHomePage .mr-lg-n1,
    .mainHomePage .mx-lg-n1 {
      margin-right: -0.25rem !important; }
    .mainHomePage .mb-lg-n1,
    .mainHomePage .my-lg-n1 {
      margin-bottom: -0.25rem !important; }
    .mainHomePage .ml-lg-n1,
    .mainHomePage .mx-lg-n1 {
      margin-left: -0.25rem !important; }
    .mainHomePage .m-lg-n2 {
      margin: -0.5rem !important; }
    .mainHomePage .mt-lg-n2,
    .mainHomePage .my-lg-n2 {
      margin-top: -0.5rem !important; }
    .mainHomePage .mr-lg-n2,
    .mainHomePage .mx-lg-n2 {
      margin-right: -0.5rem !important; }
    .mainHomePage .mb-lg-n2,
    .mainHomePage .my-lg-n2 {
      margin-bottom: -0.5rem !important; }
    .mainHomePage .ml-lg-n2,
    .mainHomePage .mx-lg-n2 {
      margin-left: -0.5rem !important; }
    .mainHomePage .m-lg-n3 {
      margin: -1rem !important; }
    .mainHomePage .mt-lg-n3,
    .mainHomePage .my-lg-n3 {
      margin-top: -1rem !important; }
    .mainHomePage .mr-lg-n3,
    .mainHomePage .mx-lg-n3 {
      margin-right: -1rem !important; }
    .mainHomePage .mb-lg-n3,
    .mainHomePage .my-lg-n3 {
      margin-bottom: -1rem !important; }
    .mainHomePage .ml-lg-n3,
    .mainHomePage .mx-lg-n3 {
      margin-left: -1rem !important; }
    .mainHomePage .m-lg-n4 {
      margin: -1.5rem !important; }
    .mainHomePage .mt-lg-n4,
    .mainHomePage .my-lg-n4 {
      margin-top: -1.5rem !important; }
    .mainHomePage .mr-lg-n4,
    .mainHomePage .mx-lg-n4 {
      margin-right: -1.5rem !important; }
    .mainHomePage .mb-lg-n4,
    .mainHomePage .my-lg-n4 {
      margin-bottom: -1.5rem !important; }
    .mainHomePage .ml-lg-n4,
    .mainHomePage .mx-lg-n4 {
      margin-left: -1.5rem !important; }
    .mainHomePage .m-lg-n5 {
      margin: -3rem !important; }
    .mainHomePage .mt-lg-n5,
    .mainHomePage .my-lg-n5 {
      margin-top: -3rem !important; }
    .mainHomePage .mr-lg-n5,
    .mainHomePage .mx-lg-n5 {
      margin-right: -3rem !important; }
    .mainHomePage .mb-lg-n5,
    .mainHomePage .my-lg-n5 {
      margin-bottom: -3rem !important; }
    .mainHomePage .ml-lg-n5,
    .mainHomePage .mx-lg-n5 {
      margin-left: -3rem !important; }
    .mainHomePage .m-lg-auto {
      margin: auto !important; }
    .mainHomePage .mt-lg-auto,
    .mainHomePage .my-lg-auto {
      margin-top: auto !important; }
    .mainHomePage .mr-lg-auto,
    .mainHomePage .mx-lg-auto {
      margin-right: auto !important; }
    .mainHomePage .mb-lg-auto,
    .mainHomePage .my-lg-auto {
      margin-bottom: auto !important; }
    .mainHomePage .ml-lg-auto,
    .mainHomePage .mx-lg-auto {
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .mainHomePage .m-xl-0 {
      margin: 0 !important; }
    .mainHomePage .mt-xl-0,
    .mainHomePage .my-xl-0 {
      margin-top: 0 !important; }
    .mainHomePage .mr-xl-0,
    .mainHomePage .mx-xl-0 {
      margin-right: 0 !important; }
    .mainHomePage .mb-xl-0,
    .mainHomePage .my-xl-0 {
      margin-bottom: 0 !important; }
    .mainHomePage .ml-xl-0,
    .mainHomePage .mx-xl-0 {
      margin-left: 0 !important; }
    .mainHomePage .m-xl-1 {
      margin: 0.25rem !important; }
    .mainHomePage .mt-xl-1,
    .mainHomePage .my-xl-1 {
      margin-top: 0.25rem !important; }
    .mainHomePage .mr-xl-1,
    .mainHomePage .mx-xl-1 {
      margin-right: 0.25rem !important; }
    .mainHomePage .mb-xl-1,
    .mainHomePage .my-xl-1 {
      margin-bottom: 0.25rem !important; }
    .mainHomePage .ml-xl-1,
    .mainHomePage .mx-xl-1 {
      margin-left: 0.25rem !important; }
    .mainHomePage .m-xl-2 {
      margin: 0.5rem !important; }
    .mainHomePage .mt-xl-2,
    .mainHomePage .my-xl-2 {
      margin-top: 0.5rem !important; }
    .mainHomePage .mr-xl-2,
    .mainHomePage .mx-xl-2 {
      margin-right: 0.5rem !important; }
    .mainHomePage .mb-xl-2,
    .mainHomePage .my-xl-2 {
      margin-bottom: 0.5rem !important; }
    .mainHomePage .ml-xl-2,
    .mainHomePage .mx-xl-2 {
      margin-left: 0.5rem !important; }
    .mainHomePage .m-xl-3 {
      margin: 1rem !important; }
    .mainHomePage .mt-xl-3,
    .mainHomePage .my-xl-3 {
      margin-top: 1rem !important; }
    .mainHomePage .mr-xl-3,
    .mainHomePage .mx-xl-3 {
      margin-right: 1rem !important; }
    .mainHomePage .mb-xl-3,
    .mainHomePage .my-xl-3 {
      margin-bottom: 1rem !important; }
    .mainHomePage .ml-xl-3,
    .mainHomePage .mx-xl-3 {
      margin-left: 1rem !important; }
    .mainHomePage .m-xl-4 {
      margin: 1.5rem !important; }
    .mainHomePage .mt-xl-4,
    .mainHomePage .my-xl-4 {
      margin-top: 1.5rem !important; }
    .mainHomePage .mr-xl-4,
    .mainHomePage .mx-xl-4 {
      margin-right: 1.5rem !important; }
    .mainHomePage .mb-xl-4,
    .mainHomePage .my-xl-4 {
      margin-bottom: 1.5rem !important; }
    .mainHomePage .ml-xl-4,
    .mainHomePage .mx-xl-4 {
      margin-left: 1.5rem !important; }
    .mainHomePage .m-xl-5 {
      margin: 3rem !important; }
    .mainHomePage .mt-xl-5,
    .mainHomePage .my-xl-5 {
      margin-top: 3rem !important; }
    .mainHomePage .mr-xl-5,
    .mainHomePage .mx-xl-5 {
      margin-right: 3rem !important; }
    .mainHomePage .mb-xl-5,
    .mainHomePage .my-xl-5 {
      margin-bottom: 3rem !important; }
    .mainHomePage .ml-xl-5,
    .mainHomePage .mx-xl-5 {
      margin-left: 3rem !important; }
    .mainHomePage .p-xl-0 {
      padding: 0 !important; }
    .mainHomePage .pt-xl-0,
    .mainHomePage .py-xl-0 {
      padding-top: 0 !important; }
    .mainHomePage .pr-xl-0,
    .mainHomePage .px-xl-0 {
      padding-right: 0 !important; }
    .mainHomePage .pb-xl-0,
    .mainHomePage .py-xl-0 {
      padding-bottom: 0 !important; }
    .mainHomePage .pl-xl-0,
    .mainHomePage .px-xl-0 {
      padding-left: 0 !important; }
    .mainHomePage .p-xl-1 {
      padding: 0.25rem !important; }
    .mainHomePage .pt-xl-1,
    .mainHomePage .py-xl-1 {
      padding-top: 0.25rem !important; }
    .mainHomePage .pr-xl-1,
    .mainHomePage .px-xl-1 {
      padding-right: 0.25rem !important; }
    .mainHomePage .pb-xl-1,
    .mainHomePage .py-xl-1 {
      padding-bottom: 0.25rem !important; }
    .mainHomePage .pl-xl-1,
    .mainHomePage .px-xl-1 {
      padding-left: 0.25rem !important; }
    .mainHomePage .p-xl-2 {
      padding: 0.5rem !important; }
    .mainHomePage .pt-xl-2,
    .mainHomePage .py-xl-2 {
      padding-top: 0.5rem !important; }
    .mainHomePage .pr-xl-2,
    .mainHomePage .px-xl-2 {
      padding-right: 0.5rem !important; }
    .mainHomePage .pb-xl-2,
    .mainHomePage .py-xl-2 {
      padding-bottom: 0.5rem !important; }
    .mainHomePage .pl-xl-2,
    .mainHomePage .px-xl-2 {
      padding-left: 0.5rem !important; }
    .mainHomePage .p-xl-3 {
      padding: 1rem !important; }
    .mainHomePage .pt-xl-3,
    .mainHomePage .py-xl-3 {
      padding-top: 1rem !important; }
    .mainHomePage .pr-xl-3,
    .mainHomePage .px-xl-3 {
      padding-right: 1rem !important; }
    .mainHomePage .pb-xl-3,
    .mainHomePage .py-xl-3 {
      padding-bottom: 1rem !important; }
    .mainHomePage .pl-xl-3,
    .mainHomePage .px-xl-3 {
      padding-left: 1rem !important; }
    .mainHomePage .p-xl-4 {
      padding: 1.5rem !important; }
    .mainHomePage .pt-xl-4,
    .mainHomePage .py-xl-4 {
      padding-top: 1.5rem !important; }
    .mainHomePage .pr-xl-4,
    .mainHomePage .px-xl-4 {
      padding-right: 1.5rem !important; }
    .mainHomePage .pb-xl-4,
    .mainHomePage .py-xl-4 {
      padding-bottom: 1.5rem !important; }
    .mainHomePage .pl-xl-4,
    .mainHomePage .px-xl-4 {
      padding-left: 1.5rem !important; }
    .mainHomePage .p-xl-5 {
      padding: 3rem !important; }
    .mainHomePage .pt-xl-5,
    .mainHomePage .py-xl-5 {
      padding-top: 3rem !important; }
    .mainHomePage .pr-xl-5,
    .mainHomePage .px-xl-5 {
      padding-right: 3rem !important; }
    .mainHomePage .pb-xl-5,
    .mainHomePage .py-xl-5 {
      padding-bottom: 3rem !important; }
    .mainHomePage .pl-xl-5,
    .mainHomePage .px-xl-5 {
      padding-left: 3rem !important; }
    .mainHomePage .m-xl-n1 {
      margin: -0.25rem !important; }
    .mainHomePage .mt-xl-n1,
    .mainHomePage .my-xl-n1 {
      margin-top: -0.25rem !important; }
    .mainHomePage .mr-xl-n1,
    .mainHomePage .mx-xl-n1 {
      margin-right: -0.25rem !important; }
    .mainHomePage .mb-xl-n1,
    .mainHomePage .my-xl-n1 {
      margin-bottom: -0.25rem !important; }
    .mainHomePage .ml-xl-n1,
    .mainHomePage .mx-xl-n1 {
      margin-left: -0.25rem !important; }
    .mainHomePage .m-xl-n2 {
      margin: -0.5rem !important; }
    .mainHomePage .mt-xl-n2,
    .mainHomePage .my-xl-n2 {
      margin-top: -0.5rem !important; }
    .mainHomePage .mr-xl-n2,
    .mainHomePage .mx-xl-n2 {
      margin-right: -0.5rem !important; }
    .mainHomePage .mb-xl-n2,
    .mainHomePage .my-xl-n2 {
      margin-bottom: -0.5rem !important; }
    .mainHomePage .ml-xl-n2,
    .mainHomePage .mx-xl-n2 {
      margin-left: -0.5rem !important; }
    .mainHomePage .m-xl-n3 {
      margin: -1rem !important; }
    .mainHomePage .mt-xl-n3,
    .mainHomePage .my-xl-n3 {
      margin-top: -1rem !important; }
    .mainHomePage .mr-xl-n3,
    .mainHomePage .mx-xl-n3 {
      margin-right: -1rem !important; }
    .mainHomePage .mb-xl-n3,
    .mainHomePage .my-xl-n3 {
      margin-bottom: -1rem !important; }
    .mainHomePage .ml-xl-n3,
    .mainHomePage .mx-xl-n3 {
      margin-left: -1rem !important; }
    .mainHomePage .m-xl-n4 {
      margin: -1.5rem !important; }
    .mainHomePage .mt-xl-n4,
    .mainHomePage .my-xl-n4 {
      margin-top: -1.5rem !important; }
    .mainHomePage .mr-xl-n4,
    .mainHomePage .mx-xl-n4 {
      margin-right: -1.5rem !important; }
    .mainHomePage .mb-xl-n4,
    .mainHomePage .my-xl-n4 {
      margin-bottom: -1.5rem !important; }
    .mainHomePage .ml-xl-n4,
    .mainHomePage .mx-xl-n4 {
      margin-left: -1.5rem !important; }
    .mainHomePage .m-xl-n5 {
      margin: -3rem !important; }
    .mainHomePage .mt-xl-n5,
    .mainHomePage .my-xl-n5 {
      margin-top: -3rem !important; }
    .mainHomePage .mr-xl-n5,
    .mainHomePage .mx-xl-n5 {
      margin-right: -3rem !important; }
    .mainHomePage .mb-xl-n5,
    .mainHomePage .my-xl-n5 {
      margin-bottom: -3rem !important; }
    .mainHomePage .ml-xl-n5,
    .mainHomePage .mx-xl-n5 {
      margin-left: -3rem !important; }
    .mainHomePage .m-xl-auto {
      margin: auto !important; }
    .mainHomePage .mt-xl-auto,
    .mainHomePage .my-xl-auto {
      margin-top: auto !important; }
    .mainHomePage .mr-xl-auto,
    .mainHomePage .mx-xl-auto {
      margin-right: auto !important; }
    .mainHomePage .mb-xl-auto,
    .mainHomePage .my-xl-auto {
      margin-bottom: auto !important; }
    .mainHomePage .ml-xl-auto,
    .mainHomePage .mx-xl-auto {
      margin-left: auto !important; } }
  .mainHomePage .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }
  .mainHomePage .text-justify {
    text-align: justify !important; }
  .mainHomePage .text-wrap {
    white-space: normal !important; }
  .mainHomePage .text-nowrap {
    white-space: nowrap !important; }
  .mainHomePage .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .mainHomePage .text-left {
    text-align: left !important; }
  .mainHomePage .text-right {
    text-align: right !important; }
  .mainHomePage .text-center {
    text-align: center !important; }
  @media (min-width: 576px) {
    .mainHomePage .text-sm-left {
      text-align: left !important; }
    .mainHomePage .text-sm-right {
      text-align: right !important; }
    .mainHomePage .text-sm-center {
      text-align: center !important; } }
  @media (min-width: 768px) {
    .mainHomePage .text-md-left {
      text-align: left !important; }
    .mainHomePage .text-md-right {
      text-align: right !important; }
    .mainHomePage .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    .mainHomePage .text-lg-left {
      text-align: left !important; }
    .mainHomePage .text-lg-right {
      text-align: right !important; }
    .mainHomePage .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    .mainHomePage .text-xl-left {
      text-align: left !important; }
    .mainHomePage .text-xl-right {
      text-align: right !important; }
    .mainHomePage .text-xl-center {
      text-align: center !important; } }
  .mainHomePage .text-lowercase {
    text-transform: lowercase !important; }
  .mainHomePage .text-uppercase {
    text-transform: uppercase !important; }
  .mainHomePage .text-capitalize {
    text-transform: capitalize !important; }
  .mainHomePage .font-weight-light {
    font-weight: 300 !important; }
  .mainHomePage .font-weight-lighter {
    font-weight: lighter !important; }
  .mainHomePage .font-weight-normal {
    font-weight: 400 !important; }
  .mainHomePage .font-weight-bold {
    font-weight: 600 !important; }
  .mainHomePage .font-weight-bolder {
    font-weight: bolder !important; }
  .mainHomePage .font-italic {
    font-style: italic !important; }
  .mainHomePage .text-white {
    color: #fff !important; }
  .mainHomePage .text-primary {
    color: #FA0029 !important; }
  .mainHomePage a.text-primary:hover, .mainHomePage a.text-primary:focus {
    color: #ae001c !important; }
  .mainHomePage .text-secondary {
    color: #7b848c !important; }
  .mainHomePage a.text-secondary:hover, .mainHomePage a.text-secondary:focus {
    color: #575e64 !important; }
  .mainHomePage .text-success {
    color: #10cc2f !important; }
  .mainHomePage a.text-success:hover, .mainHomePage a.text-success:focus {
    color: #0a851f !important; }
  .mainHomePage .text-info {
    color: #17a2b8 !important; }
  .mainHomePage a.text-info:hover, .mainHomePage a.text-info:focus {
    color: #0f6674 !important; }
  .mainHomePage .text-warning {
    color: #ff9b0b !important; }
  .mainHomePage a.text-warning:hover, .mainHomePage a.text-warning:focus {
    color: #be7000 !important; }
  .mainHomePage .text-danger {
    color: #FA0029 !important; }
  .mainHomePage a.text-danger:hover, .mainHomePage a.text-danger:focus {
    color: #ae001c !important; }
  .mainHomePage .text-light {
    color: #f8f9fa !important; }
  .mainHomePage a.text-light:hover, .mainHomePage a.text-light:focus {
    color: #cbd3da !important; }
  .mainHomePage .text-dark {
    color: #040404 !important; }
  .mainHomePage a.text-dark:hover, .mainHomePage a.text-dark:focus {
    color: black !important; }
  .mainHomePage .text-body {
    color: #040404 !important; }
  .mainHomePage .text-muted {
    color: #7b848c !important; }
  .mainHomePage .text-black-50 {
    color: rgba(4, 4, 4, 0.5) !important; }
  .mainHomePage .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }
  .mainHomePage .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }
  .mainHomePage .text-decoration-none {
    text-decoration: none !important; }
  .mainHomePage .text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important; }
  .mainHomePage .text-reset {
    color: inherit !important; }
  .mainHomePage .visible {
    visibility: visible !important; }
  .mainHomePage .invisible {
    visibility: hidden !important; }
  @media print {
    .mainHomePage *,
    .mainHomePage *::before,
    .mainHomePage *::after {
      text-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important; }
    .mainHomePage a:not(.btn) {
      text-decoration: underline; }
    .mainHomePage abbr[title]::after {
      content: " (" attr(title) ")"; }
    .mainHomePage pre {
      white-space: pre-wrap !important; }
    .mainHomePage pre,
    .mainHomePage blockquote {
      border: 1px solid #f5f5f7;
      page-break-inside: avoid; }
    .mainHomePage thead {
      display: table-header-group; }
    .mainHomePage tr,
    .mainHomePage img {
      page-break-inside: avoid; }
    .mainHomePage p,
    .mainHomePage h2,
    .mainHomePage h3 {
      orphans: 3;
      widows: 3; }
    .mainHomePage h2,
    .mainHomePage h3 {
      page-break-after: avoid; }
    @page {
      .mainHomePage {
        size: a3; } }
    .mainHomePage body {
      min-width: 992px !important; }
    .mainHomePage .container {
      min-width: 992px !important; }
    .mainHomePage .navbar {
      display: none; }
    .mainHomePage .badge {
      border: 1px solid #040404; }
    .mainHomePage .table {
      border-collapse: collapse !important; }
    .mainHomePage .table td,
    .mainHomePage .table th {
      background-color: #fff !important; }
    .mainHomePage .table-bordered th,
    .mainHomePage .table-bordered td {
      border: 1px solid #dee2e6 !important; }
    .mainHomePage .table-dark {
      color: inherit; }
    .mainHomePage .table-dark th,
    .mainHomePage .table-dark td,
    .mainHomePage .table-dark thead th,
    .mainHomePage .table-dark tbody + tbody {
      border-color: #dee2e6; }
    .mainHomePage .table .thead-dark th {
      color: inherit;
      border-color: #dee2e6; } }
  .mainHomePage body,
  .mainHomePage p,
  .mainHomePage ul,
  .mainHomePage li,
  .mainHomePage strong {
    color: #212121;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 36px; }
  @media (max-width: 767px) {
    .mainHomePage body,
    .mainHomePage p,
    .mainHomePage ul,
    .mainHomePage li,
    .mainHomePage strong {
      font-size: 14px;
      line-height: 30px; } }
  .mainHomePage h1,
  .mainHomePage h2,
  .mainHomePage h3 {
    font-family: "Poppins", sans-serif;
    font-weight: normal; }
  .mainHomePage h1,
  .mainHomePage h2,
  .mainHomePage h3, .mainHomePage h4, .mainHomePage h5, .mainHomePage h6 {
    color: #040404; }
  @media (max-width: 767px) {
    .mainHomePage .header-v1.header-transparent .header-wrap .theme-navbar .navbar-toggler {
      border: 0 none !important; } }
  .mainHomePage #button {
    display: inline-block;
    background-image: linear-gradient(269.7deg, #FA0029 0.26%, #ff6e86 99.77%);
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    -webkit-transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000; }
  .mainHomePage #button::after {
    content: "\f077";
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    font-size: 20px;
    line-height: 50px;
    color: #fff; }
  .mainHomePage #button:hover {
    cursor: pointer;
    background-color: #FA0029; }
  .mainHomePage #button:active {
    background-color: #FA0029; }
  .mainHomePage #button.show {
    opacity: 1;
    visibility: visible; }
  .mainHomePage .sec-heading-title {
    text-align: center; }
  .mainHomePage .sec-heading-title .sec-title {
    color: #fff;
    font-weight: 700;
    font-size: 36px;
    text-align: center; }
  @media (max-width: 991px) {
    .mainHomePage .sec-heading-title .sec-title {
      font-size: 26px; } }
  .mainHomePage html body .slick-slider a:not([href]):not([tabindex]).slick-arrow.slick-prev, .mainHomePage body .slick-slider .slick-arrow.slick-prev {
    left: 63px;
    background: transparent; }
  .mainHomePage html body .slick-slider a:not([href]):not([tabindex]).slick-arrow, .mainHomePage body .slick-slider .slick-arrow {
    position: absolute;
    top: calc(91% - 38px);
    z-index: 2;
    cursor: pointer;
    width: 40px; }
  .mainHomePage html body .slick-slider a:not([href]):not([tabindex]).slick-arrow.slick-next, .mainHomePage body .slick-slider .slick-arrow.slick-next {
    left: 100px;
    background: transparent; }
  .mainHomePage body .header-btn-radius {
    border-radius: 100px;
    padding: 6px 30px; }
  .mainHomePage .benefits-sec-wrap {
    background-color: #0C1C2C;
    padding: 80px 0px 60px 0px;
    position: relative; }
  @media (max-width: 767px) {
    .mainHomePage .benefits-sec-wrap {
      padding: 50px 0px 30px 0px; } }
  .mainHomePage .benefits-sec-wrap .sec-heading-title {
    margin-bottom: 100px; }
  .mainHomePage .benefits-sec-wrap:after {
    content: "";
    display: block;
    position: absolute;
    left: -44px;
    bottom: -23px;
    width: 106%;
    height: 71px;
    background: #0c1c2c;
    border-radius: 0% 0% 67% 67%; }
  @media (max-width: 767px) {
    .mainHomePage .benefits-sec-wrap:after {
      display: none; } }
  .mainHomePage .benefits-sec-wrap .benefits-sec-content {
    text-align: center; }
  .mainHomePage .benefits-sec-wrap .benefits-sec-content .image-icon {
    display: inline-block;
    text-align: center;
    margin-bottom: 24px;
    position: relative; }
  .mainHomePage .benefits-sec-wrap .benefits-sec-content .sec-title, .mainHomePage .benefits-sec-wrap .benefits-sec-content .sec-content {
    color: #fff; }
  .mainHomePage .benefits-sec-wrap .benefits-sec-col .col-12 {
    margin-bottom: 30px; }
  .mainHomePage .music-app-sec-wrap {
    background: #1B2A43;
    padding: 80px 0px 60px 0px; }
  .mainHomePage .music-app-sec-wrap .sec-heading-title {
    margin-bottom: 60px; }
  .mainHomePage .music-app-sec-wrap .app-sec-content {
    max-width: 950px;
    margin: 0 auto;
    margin-top: 50px; }
  .mainHomePage .music-app-sec-wrap .app-sec-content .sec-content {
    color: #fff;
    text-align: center;
    font-size: 21px;
    line-height: 39px; }
  @media (max-width: 991px) {
    .mainHomePage .music-app-sec-wrap .app-sec-content .sec-content {
      font-size: 18px; } }
  .mainHomePage .music-list-sec {
    background: #0C1C2C;
    padding: 70px 0px 150px 0px;
    position: relative; }
  @media (max-width: 767px) {
    .mainHomePage .music-list-sec {
      padding: 50px 0px 40px 0px; } }
  .mainHomePage .music-list-sec:before {
    content: "";
    display: block;
    position: absolute;
    left: -44px;
    top: -26px;
    width: 106%;
    height: 90px;
    background: #0c1c2c;
    border-radius: 44% 45% 0% 0%; }
  @media (max-width: 767px) {
    .mainHomePage .music-list-sec:before {
      display: none; } }
  .mainHomePage .music-list-sec .sec-heading-title {
    margin-bottom: 80px; }
  .mainHomePage .music-list-sec .music-sec-col .image-wrap .overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .mainHomePage .music-list-sec .music-sec-col .image-wrap .overlay-text .sec-title {
    color: #fff;
    text-transform: uppercase; }
  .mainHomePage .music-list-sec .music-sec-col .col-12 {
    margin-bottom: 30px; }
  .mainHomePage .music-list-sec .music-sec-content .image-wrap img {
    width: 100%; }
  .mainHomePage .industry-sec-wrap {
    padding: 10px 0px 80px 0px;
    position: relative;
    background-image: url("../../assets/images/connect_with_bg.svg"); }
  @media (max-width: 767px) {
    .mainHomePage .industry-sec-wrap {
      padding: 60px 0px 60px 0px; } }
  .mainHomePage .industry-sec-wrap:before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    top: -85px;
    width: 100%;
    height: 93px;
    background: #fff;
    border-radius: 100% 100% 18% 18%; }
  @media (max-width: 767px) {
    .mainHomePage .industry-sec-wrap:before {
      display: none; } }
  .mainHomePage .industry-sec-wrap .sec-heading-title {
    margin-bottom: 80px; }
  .mainHomePage .industry-sec-wrap .sec-heading-title .sec-title {
    color: #040404; }
  .mainHomePage .industry-sec-wrap .industry-sec-col .industry-sec-content {
    text-align: center; }
  .mainHomePage .industry-sec-wrap .industry-sec-col .industry-sec-content .sec-title {
    color: #FA0029;
    font-weight: 600; }
  .mainHomePage section.benefits-sec-wrap {
    position: relative; }
  .mainHomePage section.benefits-sec-wrap:before {
    position: absolute;
    background-image: url("../../assets/images/top_shape.svg");
    height: 200px;
    content: "";
    bottom: 0; }
  .mainHomePage .btn-primary-overlay {
    border: 2px solid #FA0029;
    color: #FA0029;
    border-radius: 100px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .mainHomePage .btn-primary-overlay:hover {
    color: #fff;
    background-color: #FA0029; }
  .mainHomePage .header-btn-radius {
    border-radius: 100px;
    padding: 6px 40px; }
  .mainHomePage .btn-primary {
    font-weight: 600;
    font-size: 18px; }
  .mainHomePage .btn-outline-primary.header-btn-radius.head-overlay {
    border: 2px solid #1B2A43;
    color: #1B2A43;
    border-radius: 100px;
    font-weight: 600;
    font-size: 16px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .mainHomePage .btn-outline-primary.header-btn-radius.head-overlay:hover {
    color: #fff;
    background-color: #1B2A43;
    border: 2px solid #1B2A43; }
  .mainHomePage html {
    font-size: 16px; }
  .mainHomePage html,
  .mainHomePage body {
    scroll-behavior: smooth;
    overflow-x: hidden;
    min-height: 100%;
    width: 100%;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .mainHomePage .content {
    padding: 65px 0;
    position: relative;
    overflow: hidden; }
  .mainHomePage img {
    display: block;
    max-width: 100%;
    height: auto; }
  .mainHomePage h1,
  .mainHomePage h1 a,
  .mainHomePage h2,
  .mainHomePage h2 a,
  .mainHomePage h3,
  .mainHomePage h3 a,
  .mainHomePage h4,
  .mainHomePage h4 a,
  .mainHomePage h5,
  .mainHomePage h5 a,
  .mainHomePage h6 h6 a {
    color: #040404; }
  .mainHomePage p {
    font-size: 1rem;
    line-height: 27px;
    color: #212121; }
  .mainHomePage a {
    font-size: 1rem;
    line-height: 36px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    display: inline-block; }
  .mainHomePage a:hover {
    color: #fff; }
  .mainHomePage .btn {
    font-family: "Poppins", sans-serif; }
  .mainHomePage .flex-end {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .mainHomePage .primary-text-color {
    color: #FA0029; }
  .mainHomePage a.nav-link.active,
  .mainHomePage body .header-fixed .header-wrap .nav-item a.active {
    color: #fa0029 !important; }
  .mainHomePage .corner-bg {
    height: 100px;
    background: #0c1c2c;
    position: relative; }
  @media (max-width: 767px) {
    .mainHomePage .corner-bg {
      display: none; } }
  .mainHomePage .corner-bg:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -100px;
    width: 50%;
    height: 190px;
    background: #FFF;
    border-radius: 0 0 0 50%; }
  .mainHomePage .corner-bg:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: -100px;
    width: 50%;
    height: 190px;
    background: #FFF;
    border-radius: 0 0 50% 0; }
  @media (max-width: 991px) {
    .mainHomePage .header-v1 {
      height: 80px; } }
  .mainHomePage .header-v1 .theme-navbar .navbar-toggler {
    color: rgba(4, 4, 4, 0.5);
    border-color: rgba(4, 4, 4, 0.1);
    outline: none; }
  .mainHomePage .header-v1.header-transparent .theme-navbar .navbar-toggler {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.1); }
  .mainHomePage .header-v1 body .theme-navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  .mainHomePage header .menu-item-has-child > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .mainHomePage header .menu-item-has-child > a::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid #040404;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .mainHomePage header .navbar-nav .sub-menu .menu-item-has-child > a::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .mainHomePage header .navbar-nav .sub-menu .sub-menu {
    left: 100%;
    top: 0;
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem); }
  .mainHomePage header .navbar-nav .nav-item {
    position: relative; }
  .mainHomePage header .navbar-nav > li:nth-child(n+3) .sub-menu .sub-menu {
    display: none;
    left: auto;
    right: 100%;
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem); }
  .mainHomePage header .navbar-nav > li:nth-child(n+3) .sub-menu > li:hover .sub-menu {
    -webkit-transform: translateX(0rem);
    transform: translateX(0rem); }
  @media (min-width: 992px) {
    .mainHomePage body .header-fixed .header-wrap {
      position: fixed;
      background: #fff;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.18);
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.18); }
    .mainHomePage body .header-fixed .header-wrap .nav-item a {
      color: #000 !important; }
    .mainHomePage .header-v1 .theme-navbar .nav-link {
      padding: 0.4rem 1.25rem; }
    .mainHomePage .header-v1 {
      background-color: transparent; }
    .mainHomePage .header-v1 .navbar-nav > .nav-item > .nav-link {
      text-transform: capitalize;
      line-height: 5.2rem;
      font-size: 1rem;
      padding: 0 1.5rem;
      font-weight: 500; }
    .mainHomePage body .header-v1 .sub-menu {
      display: block !important;
      border-radius: 0px;
      border: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      -webkit-transform: translateY(1rem);
      transform: translateY(1rem);
      min-width: 230px;
      padding: 0;
      margin: 0;
      position: absolute;
      background-color: #fff;
      padding: 16px 0px;
      border-radius: 0px 0px 10px 10px; }
    .mainHomePage body .header-v1 .sub-menu li > a {
      line-height: 1.9; }
    .mainHomePage body .header-v1 .sub-menu li > a:hover {
      background: linear-gradient(269.7deg, #0DA2E1 0.26%, #30E8BC 99.77%);
      color: #fff !important; }
    .mainHomePage body .header-v1 .nav-item:hover > .sub-menu {
      -webkit-transform: translate(0);
      transform: translate(0);
      opacity: 1;
      visibility: visible;
      pointer-events: all; } }
  .mainHomePage .header-transparent .header-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  .mainHomePage .header-transparent .header-wrap .btn-translucent {
    border: 2px solid #fff;
    background-color: rgba(255, 255, 255, 0);
    color: #fff; }
  .mainHomePage .header-transparent .header-wrap .theme-navbar .navbar-nav .nav-link:hover,
  .mainHomePage .header-transparent .header-wrap .theme-navbar .navbar-nav .nav-link:focus,
  .mainHomePage .header-transparent .header-wrap .theme-navbar .navbar-nav .nav-link {
    color: #040404;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .mainHomePage .header-transparent .header-wrap .theme-navbar .navbar-nav .nav-link:hover {
    color: #FA0029; }
  .mainHomePage .header-transparent .header-wrap .navbar-nav > .menu-item-has-child > a::after {
    border-top-color: #040404; }
  .mainHomePage .header-transparent .header-wrap .navbar-dark .navbar-nav .sub-menu .nav-link {
    color: #040404; }
  @media (max-width: 1199px) and (min-width: 992px) {
    .mainHomePage .header-v1 .navbar-nav > .nav-item > .nav-link {
      padding: 0 1rem; } }
  @media (max-width: 991px) {
    .mainHomePage header.header-v1 .icon-list .icon {
      color: #fff; }
    .mainHomePage .navbar-brand {
      padding-top: 0.5125rem;
      padding-bottom: 0.5125rem;
      vertical-align: middle; }
    .mainHomePage header .menu-item-has-child > a::after {
      margin-left: 0.255em;
      margin-right: 0.75rem;
      border-top-color: #fff !important; }
    .mainHomePage app-theme-dark-logo {
      line-height: 0; }
    .mainHomePage .navbar.navbar-expand-lg .navbar-collapse {
      padding-bottom: 3rem;
      padding-top: 0px; }
    .mainHomePage .navbar-nav {
      margin-bottom: 1.5rem;
      z-index: 9999;
      padding: 16px 0px;
      padding-bottom: 15px;
      border-radius: 8px; }
    .mainHomePage .header-transparent .header-wrap {
      background-color: rgba(0, 0, 0, 0.7);
      padding: 0px 0px; }
    .mainHomePage .header-v1 {
      margin-bottom: 73px; }
    .mainHomePage .header-transparent {
      margin-bottom: 0; }
    .mainHomePage .header-v1 .header-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff; }
    .mainHomePage .header-v1.header-transparent .header-wrap {
      background-color: #fff; }
    .mainHomePage .header-v1.header-transparent .navbar-dark .navbar-nav .sub-menu .nav-link,
    .mainHomePage .header-v1.header-transparent .header-wrap .theme-navbar .navbar-nav .sub-menu .nav-link:hover {
      color: #fff; }
    .mainHomePage header.header-transparent .menu-item-has-child a::after,
    .mainHomePage .header-v1.header-transparent .header-wrap .menu-item-has-child .sub-menu > li > a:hover::after {
      border-top-color: #fff; }
    .mainHomePage .header-v1.header-transparent .header-wrap .theme-navbar .navbar-toggler {
      color: rgba(4, 4, 4, 0.8);
      border-color: rgba(4, 4, 4, 0.8); }
    .mainHomePage .header-v1.header-transparent .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
    .mainHomePage .header-v1 .header-wrap {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07); }
    .mainHomePage .header-v1 .header-wrap .menu-item-has-child > .nav-link::after {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    .mainHomePage .header-v1 .header-wrap .menu-item-has-child .sub-menu {
      opacity: 0;
      max-height: 0;
      visibility: hidden;
      padding-left: 1rem; }
    .mainHomePage .header-v1 .header-wrap .menu-item-has-child.opened-submenu > .nav-link::after {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg); }
    .mainHomePage .header-v1 .header-wrap .menu-item-has-child.opened-submenu > .sub-menu {
      opacity: 1;
      visibility: visible;
      max-height: 10000px; }
    .mainHomePage .header-v1 .header-wrap .theme-navbar .navbar-nav .sub-menu .nav-link:hover {
      color: #040404; }
    .mainHomePage .header-v1 .header-wrap .menu-item-has-child .sub-menu > li > a:hover::after {
      border-top-color: #040404; }
    .mainHomePage .resp-menu-opened .navbar-collapse {
      height: calc(100vh - 66px);
      -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
      overflow: hidden;
      overflow-y: auto; }
    .mainHomePage .resp-menu-opened .navbar-collapse li {
      padding-right: 1rem; }
    .mainHomePage .resp-menu-opened .navbar-collapse li > a {
      border-bottom: 1px solid rgba(222, 226, 230, 0.6);
      padding: 0.8rem 0;
      color: #040404 !important; }
    .mainHomePage .resp-menu-opened .navbar-collapse .lets-talk-link {
      margin-bottom: 2rem; } }
  .mainHomePage .btn-primary.header-btn-radius {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  .mainHomePage .btn-primary.header-btn-radius:hover {
    color: #FA0029;
    background-color: #fff;
    border-color: #FA0029; }
  .mainHomePage .footer-section {
    background-color: #f5f5f7; }
  .mainHomePage body #footerWrapper {
    display: block; }
  .mainHomePage .footer-wrap {
    background: #0C1C2C;
    padding: 80px 0px 0px 0px; }
  @media (max-width: 767px) {
    .mainHomePage .footer-wrap {
      padding: 50px 0px 0px 0px; } }
  .mainHomePage .footer-wrap .copyright-info {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 30px;
    border-top: 1px solid #182D42;
    margin-top: 40px;
    display: block; }
  .mainHomePage .footer-wrap .copyright-info p {
    font-size: 14px;
    line-height: 1.8;
    color: rgba(255, 255, 255, 0.87); }
  .mainHomePage .footer-wrap .footer-top-space {
    padding-top: 0.5rem; }
  .mainHomePage .footer-wrap .footer-widget-area .footer-sec-title h6 {
    font-weight: 600;
    font-size: 1.125rem;
    color: white; }
  .mainHomePage .footer-wrap .footer-widget-area .footer-menu-list li > a {
    display: block;
    font-size: 0.875rem;
    line-height: 2rem;
    font-family: "Poppins", sans-serif;
    color: rgba(255, 255, 255, 0.87); }
  .mainHomePage .footer-wrap .footer-widget-area .footer-menu-list .text-content .info-text {
    color: #fff;
    font-size: 14px; }
  .mainHomePage .footer-wrap .footer-widget-area .footer-menu-list .input-text-field {
    position: relative; }
  .mainHomePage .footer-wrap .footer-widget-area .footer-menu-list .input-text-field .arrow-icon {
    position: absolute;
    right: 0px;
    top: 20px; }
  .mainHomePage .footer-wrap .footer-logo {
    margin-bottom: 20px;
    display: inline-block; }
  .mainHomePage .footer-social-icon ul {
    display: flex; }
  .mainHomePage .footer-social-icon li {
    margin-right: 10px; }
  .mainHomePage .footer-social-icon img {
    width: 24px; }
  .mainHomePage .footer-social-icon .twitter-icon img {
    width: 28px;
    margin: -2px 0 0 0; }
  .mainHomePage .working-wrap {
    max-width: 290px; }
  .mainHomePage .banner-wrap {
    background-repeat: no-repeat;
    background-size: cover; }
  .mainHomePage .banner-title {
    font-size: 2.375rem;
    margin-bottom: 1.25rem; }
  .mainHomePage .banner-sec-wrap {
    margin-bottom: 40px; }
  .mainHomePage .banner-sec-wrap .banner-content {
    color: #212121;
    margin-bottom: 30px; }
  .mainHomePage .banner-sec-wrap .banner-btn-wrap .btn-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 184px;
    color: #fff; }
  .mainHomePage .banner-sec-wrap .banner-btn-wrap .btn-wrap i {
    font-size: 28px; }
  .mainHomePage .banner-image-item.banner-wrap:before {
    content: "";
    display: block;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 173, 96, 0.27)), to(rgba(255, 173, 96, 0.1134)));
    background: linear-gradient(180deg, rgba(255, 173, 96, 0.27) 0%, rgba(255, 173, 96, 0.1134) 100%);
    width: 467px;
    height: 1233.79px;
    position: absolute;
    top: -166.59px;
    left: 0;
    -webkit-filter: blur(150px);
    filter: blur(150px);
    border-radius: 233.5px;
    -webkit-transform: rotate(-54.37deg);
    transform: rotate(-54.37deg);
    z-index: 1; }
  .mainHomePage body .overlay-slider-elem .banner-wrap {
    background-position: 50%; }
  .mainHomePage .banner-overlay {
    position: relative;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 150px 0px 0px 0px; }
  .mainHomePage .banner-overlay .sec-title > .banner-title {
    font-weight: 700; }
  .mainHomePage .banner-overlay .banner-btn {
    margin-top: 2.5rem; }
  .mainHomePage .banner-overlay a.btn {
    font-size: 1.05rem;
    padding: 0.8rem 2rem; }
  .mainHomePage .banner-overlay .container {
    z-index: 9;
    position: relative; }
  @media (max-width: 767px) {
    .mainHomePage .banner-overlay {
      height: 100%;
      padding: 60px 0px; }
    .mainHomePage .banner-title {
      font-size: 2rem; } }
  .mainHomePage body .slick-slider {
    z-index: 1;
    margin-bottom: 0px !important; }
  .mainHomePage body .slick-slider a:not([href]):not([tabindex]).slick-arrow, .mainHomePage body .slick-slider .slick-arrow {
    position: absolute;
    top: calc(50% - 38px);
    z-index: 2;
    cursor: pointer;
    width: 40px; }
  .mainHomePage body .slick-slider a:not([href]):not([tabindex]).slick-arrow.slick-prev, .mainHomePage body .slick-slider .slick-arrow.slick-prev {
    left: -25px;
    background: white;
    border-radius: 100px;
    font-size: 18px;
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .mainHomePage body .slick-slider a:not([href]):not([tabindex]).slick-arrow.slick-next, .mainHomePage body .slick-slider .slick-arrow.slick-next {
    right: -10px;
    background: white;
    border-radius: 100px;
    font-size: 18px;
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .mainHomePage body .slick-slider a:not([href]):not([tabindex]).slick-arrow::before, .mainHomePage body .slick-slider .slick-arrow::before {
    color: #000;
    font-size: 38px; }
  .mainHomePage body .slick-slider .slick-dots {
    list-style-type: none;
    text-align: center;
    padding: 0; }
  .mainHomePage body .slick-slider .slick-dots li {
    display: inline-block;
    margin: 0 2.5px;
    width: auto; }
  .mainHomePage body .slick-slider .slick-dots li button {
    cursor: pointer;
    font: 0/0 a;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background-color: #FA0029;
    border-radius: 6.5px;
    width: 13px;
    height: 13px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  .mainHomePage body .slick-slider .slick-dots li button:focus {
    outline: none; }
  .mainHomePage body .slick-slider .slick-dots li button::before {
    content: none; }
  .mainHomePage body .slick-slider .slick-dots li.slick-active button {
    width: 28px;
    background-color: #FA0029; }
  .mainHomePage .overlay-slider-elem {
    position: relative; }
  .mainHomePage .overlay-slider-elem .banner-wrap {
    background-position: center center; }
  .mainHomePage .overlay-slider-elem .slick-slider .slick-arrow {
    position: absolute;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.7rem;
    text-align: center;
    background-color: rgba(4, 4, 4, 0.3); }
  .mainHomePage .overlay-slider-elem .slick-slider .slick-arrow.slick-prev {
    left: 4%; }
  .mainHomePage .overlay-slider-elem .slick-slider .slick-arrow.slick-next {
    right: 4%; }
  .mainHomePage .overlay-slider-elem .slick-dots {
    position: absolute;
    left: 4%;
    bottom: 3rem;
    display: inline-block;
    width: auto; }
  .mainHomePage .overlay-slider-elem .slick-slider .slick-dots li.slick-active button {
    background-color: rgba(13, 162, 225, 0.1); }
  @media (min-width: 992px) {
    .mainHomePage .overlay-slider-elem .slick-dots {
      bottom: 5.8rem; } }
  @media (min-width: 1460px) {
    .mainHomePage .container {
      max-width: 1380px; } }
  @media (max-width: 767px) {
    .mainHomePage .music-app-sec-wrap {
      padding-bottom: 50px; }
    .mainHomePage .music-app-sec-wrap .music-sec-content {
      margin-bottom: 30px; }
    .mainHomePage .music-app-sec-wrap .music-sec-content .image-wrap img {
      width: 100%; }
    .mainHomePage .benefits-sec-wrap .sec-heading-title {
      margin-bottom: 40px; }
    .mainHomePage .music-app-sec-wrap .app-sec-content {
      margin-top: 0px; }
    .mainHomePage .music-app-sec-wrap {
      padding-top: 50px; }
    .mainHomePage .music-list-sec .sec-heading-title,
    .mainHomePage .industry-sec-wrap .sec-heading-title {
      margin-bottom: 50px; } }
  @media (max-width: 992px) {
    .mainHomePage h4, .mainHomePage .h4 {
      font-size: 1.475rem; }
    .mainHomePage h3, .mainHomePage .h3 {
      font-size: 1.575rem; }
    .mainHomePage h2, .mainHomePage .h2,
    .mainHomePage .sec-heading-title .sec-title {
      font-size: 1.675rem; } }
  @media (max-width: 991px) {
    .mainHomePage h5, .mainHomePage .h5 {
      font-size: 1.125rem; } }
  .mainHomePage .navbar-rightbar > div {
    display: flex;
    align-items: center; }
  .mainHomePage .navbar-rightbar > div span {
    font-weight: 500;
    padding-right: 10px; }
  .mainHomePage .navbar-rightbar img {
    width: 72px;
    height: 72px;
    border-radius: 100px;
    cursor: pointer; }
  .mainHomePage .navbar-rightbar .DropDownMenu_DropDownContainer__3isxf {
    top: 90% !important; }
  .mainHomePage .navbar-rightbar .DropDownMenu_DropDownContainer__3isxf .DropDownMenu_holder__1y-tZ {
    background: #1B2A43; }
  .mainHomePage .navbar-rightbar .DropDownMenu_DropDownContainer__3isxf svg path {
    fill: #1B2A43; }
  .mainHomePage .navbar-rightbar .DropDownMenu_DropDownContainer__3isxf button {
    padding: 0px 15px !important;
    outline: none;
    border: none;
    background: #1B2A43; }
  .mainHomePage .navbar-rightbar .DropDownMenu_DropDownContainer__3isxf hr {
    margin-bottom: 6px;
    border-top: 1px solid #485b7a; }
