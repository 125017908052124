/*
    Fonts
*/
.field {
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: 100%;
  font-size: 0.8125rem;
  text-align: left;
  position: relative; }
  .field.fullWidth {
    width: 100% !important; }
  .field .inputContainer {
    position: relative; }
  .field .input {
    display: block;
    padding: 0;
    width: 100%;
    height: 3.5rem;
    color: var(--theme-main-color);
    font-weight: bold;
    background: transparent;
    font-size: 1rem;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--theme-muffled-color);
    transition: 100ms;
    line-height: 2; }
    @media (max-height: 500px) {
      .field .input {
        height: 2rem; } }
    .field .input::placeholder {
      opacity: 0;
      transition: 100ms; }
    .field .input::-webkit-datetime-edit {
      transition: 100ms;
      color: var(--theme-second-color); }
    .field .input:-webkit-autofill {
      border-bottom: 1px solid var(--theme-muffled-color); }
      .field .input:-webkit-autofill::placeholder {
        opacity: 1;
        transition: 500ms; }
      .field .input:-webkit-autofill ~ span {
        transform: translateY(-0.75rem);
        font-size: 0.75rem; }
      .field .input:-webkit-autofill::-webkit-datetime-edit {
        opacity: 1;
        transition: 500ms; }
    .field .input:autofill {
      border-bottom: 1px solid var(--theme-muffled-color); }
      .field .input:autofill::placeholder {
        opacity: 1;
        transition: 500ms; }
      .field .input:autofill ~ span {
        transform: translateY(-0.75rem);
        font-size: 0.75rem; }
      .field .input:autofill::-webkit-datetime-edit {
        opacity: 1;
        transition: 500ms; }
    .field .input.filled {
      border-bottom: 1px solid var(--theme-muffled-color); }
      .field .input.filled::placeholder {
        opacity: 1;
        transition: 500ms; }
      .field .input.filled ~ span {
        transform: translateY(-0.75rem);
        font-size: 0.75rem; }
      .field .input.filled::-webkit-datetime-edit {
        opacity: 1;
        transition: 500ms; }
    .field .input:disabled {
      opacity: 0.8; }
    .field .input::placeholder {
      color: var(--theme-second-color); }
  .field .label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(1rem);
    transition: 300ms;
    margin-bottom: 0rem;
    display: block;
    font-weight: 100;
    font-size: 1.0625rem;
    color: var(--theme-muffled-color);
    font-weight: 500;
    white-space: nowrap;
    pointer-events: none; }
  .field .error {
    margin-top: 0.3125rem;
    height: 1rem;
    color: var(--theme-error-color); }
  .field.hasError .input {
    color: var(--theme-error-color); }
  .field.lightMode .input {
    color: #aeb0bd;
    border-bottom: 1px solid var(--theme-main-color); }
    .field.lightMode .input::placeholder {
      opacity: 0;
      color: var(--theme-main-color); }
    .field.lightMode .input::-webkit-datetime-edit {
      transition: 100ms;
      color: var(--theme-main-color); }
    .field.lightMode .input:focus, .field.lightMode .input:not([value=""]), .field.lightMode .input:-webkit-autofill {
      border-bottom: 1px solid var(--theme-main-color); }
  .field.lightMode .label {
    color: rgba(var(--grey), 0.8); }
  .field.lightMode .error {
    margin-top: 0.3125rem;
    height: 1rem;
    color: var(--theme-error-color);
    filter: grayscale(50%); }
  .field.lightMode.hasError .input {
    color: var(--theme-error-color);
    filter: grayscale(50%); }
