/*
    Fonts
*/
.container {
  flex-direction: column !important;
  position: absolute !important;
  top: 100%;
  width: 200px;
  height: 60px !important;
  background-color: var(--active-elem);
  z-index: 20;
  overflow: visible;
  opacity: 1;
  border-radius: 2px; }
  .container > :first-child {
    height: 20px !important;
    position: absolute;
    top: -10px; }
  .container .text {
    padding-bottom: 0.5rem !important;
    height: auto !important;
    width: auto; }
    .container .text p {
      text-align: center; }
  .container .stars {
    margin: 0 !important;
    width: 116px; }
    @media (max-width: 720px) {
      .container .stars {
        margin: 1rem auto 0 auto !important; } }
  .container p {
    padding-top: 0.25rem !important;
    color: white !important; }
  .container.versionOffset {
    left: calc(50% - 0.25rem);
    transform: translateX(-50%);
    top: 125%; }
    @media (max-width: 720px) {
      .container.versionOffset {
        transform: translateX(-85%); }
        .container.versionOffset > :first-child {
          right: 10px; } }
  .container.downloadButton {
    left: calc(50% - 0.25rem);
    transform: translateX(-37%);
    top: 125%; }
    @media (max-width: 720px) {
      .container.downloadButton {
        transform: translateX(-56%); }
        .container.downloadButton > :first-child {
          right: 10px; } }
  .container.superShort {
    left: initial !important;
    transform: initial !important;
    top: -0.5rem;
    right: 0.125rem;
    padding: 0 1rem;
    height: 3.5rem !important;
    width: 305px;
    flex-direction: row !important;
    justify-content: space-around !important; }
    .container.superShort .text {
      padding-top: 0rem !important;
      padding-bottom: 0.25rem !important; }
      .container.superShort .text p {
        padding-top: 0rem !important; }
    .container.superShort > * {
      margin: 0 !important;
      padding: 0 !important;
      height: auto !important; }
    .container.superShort > *:first-child {
      display: none !important; }
    @media (max-width: 720px) {
      .container.superShort {
        transform: initial !important; }
        .container.superShort > :first-child {
          right: initial !important; } }

.download {
  right: 4.95rem; }
  @media (max-width: 1240px) {
    .download {
      right: 5.75rem; } }

.add {
  right: 3rem; }
  @media (max-width: 1240px) {
    .add {
      right: 5.75rem; } }

.player.container.downloadButton {
  top: -4px;
  transform: translateX(-37%) translateY(-100%);
  height: 60px !important;
  left: 72px;
  display: flex;
  align-items: center; }

.player.container .text p {
  margin: 0;
  height: 100%;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.75; }

.player.container > :first-child {
  top: auto;
  bottom: 12px;
  transform: translateY(100%) rotate(180deg);
  left: 25px; }

.player.container .customstar {
  height: 1.5rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  font-size: 12px; }

.player.container .customstar a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  height: 100%; }
