/*
    Fonts
*/
.container {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1432px auto;
  grid-template-rows: 5rem auto auto;
  background-color: var(--theme-background-color); }
  @media (max-width: 1432px) {
    .container {
      grid-template-columns: auto 100vw auto; } }
  @media (max-width: 800px) {
    .container {
      grid-template-rows: 8rem auto auto; } }
  .container > *:first-child {
    order: 2; }
  .container > *:nth-child(2) {
    order: 1; }
  .container > *:nth-child(3) {
    order: 2; }
  .container .header {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    order: 2;
    z-index: 25000; }
  .container .content {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    order: 1;
    overflow: hidden; }
    @media (max-width: 600px) {
      .container .content {
        margin-top: -6rem; } }
  .container .footer {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 3;
    order: 2; }
