/*
    Fonts
*/
.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem;
  max-width: calc(100vw - 2rem);
  transition: 0ms; }
  @media (max-width: 600px) {
    .links {
      flex-wrap: wrap; } }
  .links .link {
    position: relative;
    text-decoration: none;
    color: var(--theme-main-color);
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal !important;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase;
    padding: 0 0.5rem;
    transition: 300ms ease-in-out; }
    .links .link.extraMargin {
      margin-left: 1.75rem; }
    .links .link.activeLink {
      opacity: 1 !important; }
  .links .underline {
    position: relative; }
    .links .underline .line {
      content: " ";
      position: absolute;
      transform: scaleX(0);
      transition: 200ms ease-in-out;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      border: 0;
      background-color: var(--active-elem); }
    .links .underline .line {
      height: 2px; }
    .links .underline.activeLink .line {
      transform: scaleX(1) !important;
      transition: 500ms ease-in-out; }
