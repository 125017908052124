/*
    Fonts
*/
/*
    Fonts
*/
.loader {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 6rem;
  width: 100%;
  min-width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  background-color: var(--theme-background-color); }
  .loader.autoWidth {
    width: 100%; }

.container {
  grid-area: filters;
  height: 0;
  position: inherit;
  justify-content: flex-end;
  z-index: 10; }
  @media (max-width: 1100px) {
    .container {
      height: auto;
      z-index: 15; } }
  .container .filterContent {
    position: relative; }
    @media (max-width: 1100px) {
      .container .filterContent {
        width: 100%;
        top: 0;
        height: auto; } }
    .container .filterContent .filterContainer {
      width: calc((100vw - 766px) / 2);
      max-width: 11.5rem;
      position: relative;
      z-index: 2; }
      @media (max-width: 1100px) {
        .container .filterContent .filterContainer {
          width: 11.5rem; } }
      @media (max-width: 700px) {
        .container .filterContent .filterContainer {
          width: calc(100vw - 2rem);
          max-width: calc(100vw - 2rem); } }
      .container .filterContent .filterContainer > * {
        margin-bottom: 1px;
        padding: 1.5rem;
        background-color: #bb1d7e; }
      .container .filterContent .filterContainer .header {
        cursor: pointer;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 4rem;
        z-index: 1; }
        .container .filterContent .filterContainer .header p {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: bold;
          line-height: 21px;
          color: var(--white); }
      .container .filterContent .filterContainer .filter {
        position: relative;
        max-height: 3rem;
        padding-top: 1rem;
        padding-bottom: 0;
        overflow: hidden;
        transition: 500ms ease-in-out;
        will-change: max-height, padding-top, padding-bottom; }
        .container .filterContent .filterContainer .filter.opened {
          max-height: 21rem;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem; }
          .container .filterContent .filterContainer .filter.opened.withButton {
            padding-bottom: 2rem; }
          .container .filterContent .filterContainer .filter.opened h3 {
            margin-bottom: 0.5rem; }
          .container .filterContent .filterContainer .filter.opened .showAllButton {
            bottom: 0;
            color: var(--active-elem); }
          .container .filterContent .filterContainer .filter.opened.showAll {
            max-height: 200rem; }
    .container .filterContent .mainFilterContainer {
      z-index: 17; }
    .container .filterContent .mainFilterContainer,
    .container .filterContent .links {
      width: calc((100vw - 766px) / 2);
      max-width: 11.5rem;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 1100px) {
        .container .filterContent .mainFilterContainer,
        .container .filterContent .links {
          width: 11.5rem; } }
      @media (max-width: 700px) {
        .container .filterContent .mainFilterContainer,
        .container .filterContent .links {
          width: calc(100vw - 2rem);
          max-width: calc(100vw - 2rem); } }
      .container .filterContent .mainFilterContainer > *,
      .container .filterContent .links > * {
        border-radius: 2px;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 1px;
        height: 3rem;
        background-color: var(--theme-background-changed-color);
        margin-left: 1px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap; }
      .container .filterContent .mainFilterContainer > :first-child,
      .container .filterContent .links > :first-child {
        padding-left: 0.5rem;
        width: 68%;
        margin-left: 0; }
      .container .filterContent .mainFilterContainer > :nth-child(2),
      .container .filterContent .links > :nth-child(2) {
        width: 32%; }
    .container .filterContent .mainFilterContainer > :first-child {
      flex: 1;
      display: flex;
      padding-left: 1.5rem;
      align-items: center;
      justify-content: flex-start;
      color: var(--theme-main-color); }
    @media (max-width: 700px) {
      .container .filterContent {
        display: flex;
        flex-wrap: wrap; }
        .container .filterContent .mainFilterContainer {
          width: unset; }
          .container .filterContent .mainFilterContainer > :nth-child(2) {
            width: 25vw; }
          .container .filterContent .mainFilterContainer > :first-child {
            display: none; }
        .container .filterContent .links {
          order: -1;
          flex: 1;
          max-width: unset; } }
    .container .filterContent .links > :first-child {
      width: 100%; }
    @media (min-width: 700px) {
      .container .filterContent .links {
        display: none; } }
    .container .filterContent .presetContainer {
      width: calc((100vw - 766px) / 2);
      max-width: 11.5rem;
      padding: 1rem 0 1rem 0;
      border-radius: 2px;
      height: auto;
      display: flex;
      flex-direction: column;
      background-color: var(--theme-background-changed-color);
      margin-bottom: 1rem; }
      @media (max-width: 1100px) {
        .container .filterContent .presetContainer {
          width: 11.5rem; } }
      @media (max-width: 700px) {
        .container .filterContent .presetContainer {
          width: calc(100vw - 2rem);
          max-width: calc(100vw - 2rem); } }
      .container .filterContent .presetContainer .presetButton {
        position: relative;
        opacity: 1 !important; }
        .container .filterContent .presetContainer .presetButton span {
          width: 100%;
          margin-left: 0.5rem;
          padding-right: 1.25rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .container .filterContent .presetContainer .presetButton span span {
            opacity: 0.3; }
        .container .filterContent .presetContainer .presetButton.activeButton span span {
          opacity: 1; }
      @media (max-width: 700px) {
        .container .filterContent .presetContainer {
          margin-top: 0;
          padding: 0;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          background-color: transparent; }
          .container .filterContent .presetContainer button {
            margin: 0;
            margin-top: 2px;
            background-color: var(--theme-background-changed-color);
            border-radius: 0; } }
  .container.blocked {
    display: none;
    opacity: 0.6 !important;
    pointer-events: none !important; }

.zeroOpacity {
  opacity: 0;
  pointer-events: none; }

.fullOpacity {
  opacity: 1;
  pointer-events: initial; }

.allFiltersContainer {
  position: absolute;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto 8rem;
  border-radius: 2px;
  width: 70vw;
  max-width: 1020px;
  min-width: 280px;
  height: auto;
  background-color: var(--theme-background-changed-color) !important;
  left: 200px;
  bottom: auto;
  top: 0;
  z-index: 10;
  padding: 1.5rem;
  overflow: auto; }
  .allFiltersContainer::-webkit-scrollbar {
    height: 7px; }
  .allFiltersContainer::-webkit-scrollbar-track {
    background: transparent; }
  .allFiltersContainer::-webkit-scrollbar-thumb {
    background-color: var(--active-elem); }
  @media (max-width: 800px) {
    .allFiltersContainer {
      max-height: 80vh;
      position: fixed;
      z-index: 100;
      top: 6rem;
      left: 1rem;
      right: 1rem;
      width: calc(100vw - 2rem);
      height: auto;
      overflow: scroll;
      grid-template-rows: auto 5rem 8rem; } }
  @media (max-width: 1100px) {
    .allFiltersContainer {
      grid-template-columns: 50% 50%;
      left: initial;
      right: 200px;
      bottom: -130px; } }
  @media (max-width: 800px) {
    .allFiltersContainer {
      width: 100vw;
      left: initial;
      right: 0px;
      bottom: -30px;
      grid-template-rows: auto auto auto 8rem; } }
  .allFiltersContainer .topBlock {
    grid-row-start: 1;
    grid-row-end: 2;
    display: grid;
    grid-template-columns: calc(100% / 3.75) calc(100% / 3.1) calc(100% / 2.2);
    grid-template-rows: 2rem auto;
    max-width: 100%; }
    @media (max-width: 1140px) {
      .allFiltersContainer .topBlock {
        grid-template-columns: 50% 50%; } }
    @media (max-width: 700px) {
      .allFiltersContainer .topBlock {
        grid-template-columns: 100%; } }
    .allFiltersContainer .topBlock .subHeader {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      color: var(--theme-main-color); }
    .allFiltersContainer .topBlock .column {
      grid-row-start: 2;
      grid-row-end: 3; }
    .allFiltersContainer .topBlock .checkbox {
      padding: 1rem 0 0.25rem 0;
      height: 1rem; }
      .allFiltersContainer .topBlock .checkbox * {
        height: 1rem;
        font-size: 0.75rem;
        font-weight: 500;
        justify-content: flex-start;
        margin-right: 0.5rem; }
      .allFiltersContainer .topBlock .checkbox .checkboxInput {
        min-width: 1rem;
        width: 1rem;
        background-color: transparent !important;
        background-image: none; }
        .allFiltersContainer .topBlock .checkbox .checkboxInput + span {
          padding-right: 20px; }
        .allFiltersContainer .topBlock .checkbox .checkboxInput * {
          color: var(--theme-main-color) !important;
          fill: var(--white) !important; }
      .allFiltersContainer .topBlock .checkbox .checkboxInputChecked {
        border: 1px solid var(--active-elem);
        background-color: var(--active-elem) !important;
        background-image: url(assets/images/filledCheckbox.svg); }
        .allFiltersContainer .topBlock .checkbox .checkboxInputChecked path {
          color: var(--theme-main-color) !important; }
  .allFiltersContainer .genresBlock {
    grid-column-start: 1;
    grid-column-end: 2; }
  .allFiltersContainer .versionsBlock {
    grid-column-start: 2;
    grid-column-end: 3; }
  .allFiltersContainer .keysBlock {
    margin-top: 2rem;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative; }
    .allFiltersContainer .keysBlock .keysRows {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap; }
      .allFiltersContainer .keysBlock .keysRows .checkbox {
        max-width: 5rem; }
  .allFiltersContainer .bpmBlock {
    margin: 2rem 0 2rem 0;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%; }
    @media (max-width: 800px) {
      .allFiltersContainer .bpmBlock {
        grid-column-end: 3; } }
    .allFiltersContainer .bpmBlock .subHeader {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: bold;
      color: var(--theme-main-color); }
    .allFiltersContainer .bpmBlock .sliderContainer {
      margin: 1rem 0 1rem 0;
      height: 2.5rem;
      width: 100%;
      max-width: 296px;
      position: relative;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 32px;
      border: 1px solid rgba(255, 255, 255, 0.2); }
      .allFiltersContainer .bpmBlock .sliderContainer * {
        color: var(--theme-main-color);
        font-size: 0.75rem; }
      .allFiltersContainer .bpmBlock .sliderContainer .range {
        display: flex;
        position: absolute;
        justify-content: space-between;
        width: 85%; }
      .allFiltersContainer .bpmBlock .sliderContainer > *:last-child {
        width: calc((100vw - 766px) / 2);
        max-width: 11.5rem;
        margin: auto;
        margin-left: 26px;
        margin-top: -3px; }
        @media (max-width: 1100px) {
          .allFiltersContainer .bpmBlock .sliderContainer > *:last-child {
            width: 11.5rem; } }
        @media (max-width: 700px) {
          .allFiltersContainer .bpmBlock .sliderContainer > *:last-child {
            width: calc(100vw - 2rem);
            max-width: calc(100vw - 2rem); } }
  .allFiltersContainer .buttonsBlock {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 4.2rem; }
    .allFiltersContainer .buttonsBlock * {
      margin: 0 1rem 0 1rem;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.9px;
      text-align: center;
      color: var(--theme-main-color); }
    @media (max-width: 800px) {
      .allFiltersContainer .buttonsBlock {
        padding-top: 1.2rem;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
        width: 80vw;
        margin-left: -1rem; } }
  .allFiltersContainer .closeButton {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    padding: 0 !important;
    margin: 0 !important; }
    .allFiltersContainer .closeButton * {
      padding: 0 !important;
      margin: 0 !important; }
