/*
    Fonts
*/
.container {
  padding-top: 2.5625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--theme-main-color); }
  @media (max-width: 40rem) {
    .container {
      padding-top: 0.5rem;
      padding-bottom: 2.5rem; } }
  .container .reviveInfo {
    margin-top: 1rem;
    width: 40rem;
    max-width: 100vw;
    font-family: Montserrat;
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--active-elem); }
    .container .reviveInfo .important {
      margin: 0 0.5rem;
      font-size: 1rem;
      font-weight: 900;
      color: var(--theme-main-color); }
  .container .specific-case {
    display: flex;
    width: 700px; }
    @media (max-width: 700px) {
      .container .specific-case {
        display: contents; } }
    .container .specific-case .specific-case-option-wrapper {
      margin: auto;
      width: 320px;
      height: 150px; }
    .container .specific-case .specific-case-button-wrapper {
      display: flex;
      justify-content: center; }
  .container .billingHistory {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 112px 80px 68px;
    grid-template-rows: 3rem 2rem auto;
    width: 260px; }
    .container .billingHistory h3 {
      opacity: 1;
      font-size: 14px;
      font-weight: bold; }
    .container .billingHistory .header {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2; }
    .container .billingHistory .titles {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
      display: grid;
      grid-template-columns: 112px 80px 68px; }
      .container .billingHistory .titles .left {
        grid-column-start: 1;
        grid-column-end: 2; }
      .container .billingHistory .titles .center {
        grid-column-start: 2;
        grid-column-end: 3; }
      .container .billingHistory .titles .right {
        padding-left: 0.3rem;
        grid-column-start: 3;
        grid-column-end: 4; }
      .container .billingHistory .titles p {
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        opacity: 0.3;
        letter-spacing: 1.5px;
        text-transform: uppercase; }
    .container .billingHistory .row {
      grid-column-start: 1;
      grid-column-end: 4;
      display: grid;
      grid-template-columns: 112px 80px 68px; }
      .container .billingHistory .row .left {
        grid-column-start: 1;
        grid-column-end: 2; }
      .container .billingHistory .row .center {
        grid-column-start: 2;
        grid-column-end: 3; }
      .container .billingHistory .row .right {
        grid-column-start: 3;
        grid-column-end: 4;
        display: flex;
        justify-content: flex-end; }
      .container .billingHistory .row p {
        font-size: 12px;
        font-weight: 500;
        line-height: 1; }
  .container .statusLabel {
    font-size: 0.75rem;
    line-height: 2;
    height: 1.5rem;
    margin-bottom: 0.5rem; }
  .container .status {
    font-size: 1.5rem;
    margin: 8px; }
  .container .explonation {
    font-size: 0.75rem;
    line-height: 1.75;
    font-weight: 500;
    color: var(--theme-muffled-color); }
    .container .explonation .brigth {
      display: inline-block;
      color: var(--theme-main-color) !important;
      padding-left: 0.25rem;
      padding-right: 0.25rem; }
  .container .paymentMethod {
    margin-top: 2.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .container .paymentMethod .paymentInfo {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem; }
      .container .paymentMethod .paymentInfo .changeMethodButton {
        margin: 0;
        height: 0.75rem;
        min-width: initial;
        padding: 0;
        font-size: 0.75rem;
        font-weight: 500;
        color: var(--active-elem); }
      .container .paymentMethod .paymentInfo .digits {
        margin: 0 0.25rem; }
    .container .paymentMethod .methodLabel {
      margin-bottom: 0.5rem;
      height: 2rem;
      line-height: 2.125;
      font-size: 0.875rem;
      font-weight: bold; }
  .container .registerButton {
    margin-top: 2.5rem; }
  .container.canceled h3 {
    font-weight: 900; }
  .container.canceled .explonation {
    color: var(--active-elem); }
  .container .loader {
    background-color: var(--active-elem);
    font-size: 0.75rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    height: 2rem;
    letter-spacing: 1.1px;
    padding: 0px 40px;
    border-radius: 100px;
    line-height: 32px;
    border: 1px solid var(--active-elem); }
