/*
    Fonts
*/
.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .container .button,
  .container .spacer {
    margin-right: 1px;
    height: 2.5rem;
    min-width: 2.4375rem;
    width: auto;
    max-width: 5rem;
    padding: 0;
    overflow: hidden;
    border: none;
    outline: none;
    background-color: var(--theme-background-changed-color);
    cursor: pointer;
    color: var(--theme-main-color);
    transition: 300ms ease-in-out; }
    .container .button.bright,
    .container .spacer.bright {
      color: var(--active-elem); }
    @media (max-width: 700px) {
      .container .button,
      .container .spacer {
        min-width: 1.875rem;
        width: auto;
        flex: 1; } }
  .container .spacer {
    pointer-events: none;
    cursor: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .container .activeButton {
    background-color: var(--active-elem);
    color: var(--white); }

.limitChoice {
  width: 220px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0; }
  .limitChoice span {
    width: 160px;
    font-family: Montserrat;
    padding-top: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #565b64; }

.limitContainer {
  z-index: 4;
  position: relative;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 600px) {
    .limitContainer {
      flex-direction: column; }
      .limitContainer > :nth-child(2) {
        margin: 1rem 0 0 auto; } }

.selectLimit {
  cursor: pointer;
  padding: 0;
  width: 56px;
  height: 40px;
  border-radius: 2px;
  background-color: var(--theme-background-changed-color);
  display: grid;
  grid-template-columns: 28px 28px; }
  .selectLimit > :first-child {
    margin: auto 0 auto 0.75rem;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: var(--theme-main-color); }
  .selectLimit > :last-child {
    margin: auto 0 auto 0;
    color: var(--theme-main-color); }

.limitDropdown {
  display: flex;
  flex-direction: column;
  width: 56px;
  height: auto; }
  .limitDropdown > * {
    cursor: pointer;
    border-top: 2px solid var(--theme-background-light-color);
    padding: 0.75rem 0.75rem 0.5rem 1.25rem;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: var(--theme-main-color);
    background-color: var(--theme-background-changed-color);
    width: 56px;
    height: 40px;
    border-radius: 2px; }
