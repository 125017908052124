/*
    Fonts
*/
.header {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--black) !important;
  text-align: center; }
  @media (max-height: 500px) {
    .header {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem; } }

.cancelHeader {
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: var(--black) !important;
  text-align: center;
  max-width: 85%; }

.cancelText {
  margin-bottom: 1rem;
  text-align: center;
  max-width: 85%;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
  opacity: 0.6; }

.termsLink {
  margin-bottom: 1rem;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: var(--active-elem); }

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 23.5rem;
  margin: auto; }
  @media (max-width: 500px) {
    .container {
      max-width: calc(100vw - 3rem); } }
  .container input {
    border-bottom: 1px solid var(--black) !important; }
  .container .center, .container .name-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .container .center .left, .container .name-group .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
  .container .name-group {
    margin-top: 12px; }
  .container .checkbox-group {
    width: 100%; }
  .container .checked {
    background-color: var(--active-elem);
    background-image: url("assets/images/filledCheckboxPink.svg");
    border: 0; }
  .container .input {
    border: 1px solid var(--active-elem); }
  .container .root {
    width: auto;
    height: auto;
    padding-top: 0;
    padding-bottom: 0; }
    @media (max-height: 568px) {
      .container .root {
        margin-bottom: 0.3rem; } }
    .container .root label > *:nth-child(1) {
      width: 100%;
      color: rgba(var(--grey), 0.8);
      white-space: normal;
      font-size: 13px; }
      @media (max-height: 736px) {
        .container .root label > *:nth-child(1) {
          font-size: 9px; } }
      @media (max-height: 568px) {
        .container .root label > *:nth-child(1) {
          font-size: 8px; } }
  .container .textfield-common input, .container .short input, .container .normal input, .container .wide input {
    height: 2rem; }
    @media (max-height: 736px) {
      .container .textfield-common input, .container .short input, .container .normal input, .container .wide input {
        height: 1.2rem; } }
    @media (max-height: 568px) {
      .container .textfield-common input, .container .short input, .container .normal input, .container .wide input {
        height: 1rem; } }
  .container .textfield-common span, .container .short span, .container .normal span, .container .wide span {
    transform: translateY(0.4rem); }
    @media (max-height: 736px) {
      .container .textfield-common span, .container .short span, .container .normal span, .container .wide span {
        transform: translateY(-0.3rem); } }
    @media (max-height: 568px) {
      .container .textfield-common span, .container .short span, .container .normal span, .container .wide span {
        transform: translateY(-0.6rem); } }
  @media (max-height: 568px) {
    .container .textfield-common div, .container .short div, .container .normal div, .container .wide div {
      font-size: 10px; } }
  .container .short {
    max-width: 4.625rem; }
  .container .normal {
    max-width: 10.5rem; }
  .container .wide {
    max-width: 100%; }
  .container > *:last-child {
    margin-top: 0.5rem; }
    @media (max-height: 568px) {
      .container > *:last-child {
        margin-top: 0.2rem; } }

.commonError {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  min-height: 1rem;
  margin-bottom: 0.25rem;
  color: var(--theme-error-color);
  filter: grayscale(50%);
  text-align: center; }

.tabButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.subscriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 85%;
  margin: 1rem auto; }
  .subscriptionContainer .cancelText {
    max-width: 100%;
    text-align: justify; }

.actionsContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px; }
  @media (max-width: 568px) {
    .actionsContainer {
      flex-direction: column;
      align-items: center; }
      .actionsContainer button:last-child {
        margin-top: 20px; } }

.giveawayImage img {
  width: 100%;
  height: 462px; }
  @media (max-width: 568px) {
    .giveawayImage img {
      height: 399px; } }
