.container {
    path {
        transform: rotateY(0deg);
        transform-origin: center;
        transition: 50ms;
        opacity: 0.7;
        will-change: transform, opacity;
    }
    &.active {
        path {
            transform: rotateY(180deg);
            opacity: 1;
        }
    }
}
