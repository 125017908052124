/*
    Fonts
*/
.container {
  transition: 300ms ease-in-out;
  padding-top: 1rem;
  position: fixed;
  display: grid;
  grid-template-areas: "leftP  logo  spacer center spacer2 downloadList spacer3 buttons rightP";
  grid-template-columns: calc(50vw - 37.75rem) 11.5625rem 2.5rem auto 1rem 5.5rem 2.5rem 13rem calc(50vw - 37.75rem);
  grid-template-rows: auto;
  width: 100%;
  max-width: 100vw;
  height: auto; }
  @media (max-width: 1432px) {
    .container {
      width: 100%;
      grid-template-columns: 1rem 15.75rem 0 auto 0.5rem 7rem 0.5rem 13rem 1rem; } }
  @media (max-width: 800px) {
    .container {
      grid-template-areas: "leftP logo downloadList buttons rightP" "leftP center center center rightP";
      grid-template-columns: 1rem auto auto auto 1rem;
      grid-template-rows: 3rem 5rem; } }
  @media (max-width: 600px) {
    .container {
      background-color: var(--theme-background-color);
      position: fixed;
      height: auto;
      grid-template-areas: ". buttons      toggle        .  " ". downloadList themeSwitcher .  " ". links        links         .  " ". search       search        .  ";
      grid-template-columns: 1rem calc(75% - 1rem) calc(25% - 1rem) 1rem;
      grid-template-rows: 4rem 3rem 17rem 3rem; }
      .container.hidden {
        border-bottom: 0;
        height: 4.5rem;
        grid-template-areas: ". buttons  toggle .";
        grid-template-rows: "3rem"; }
        .container.hidden .links,
        .container.hidden .searchRow,
        .container.hidden .themeSwitcher,
        .container.hidden .downloadList {
          display: none; }
      .container .links {
        grid-area: links;
        display: flex;
        flex-direction: column;
        transform: translateX(0.5rem); }
        .container .links a {
          margin-bottom: 1rem; }
        .container .links span {
          font-size: 14px !important; }
      .container .searchRow {
        border-radius: 2px;
        grid-area: search;
        max-width: 584px;
        width: 100%;
        height: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        background-color: var(--theme-background-light-color); }
        .container .searchRow .input {
          height: 100%;
          display: flex;
          flex-grow: 1;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center; }
          .container .searchRow .input form {
            width: 100%; }
            .container .searchRow .input form input {
              display: flex;
              flex-grow: 1;
              margin-left: 1rem;
              padding-right: 1.5rem;
              height: 100%;
              background-color: transparent;
              width: 100%;
              border: none;
              outline: none;
              font-family: var(--montserrat), sans-serif;
              font-size: 0.875rem;
              font-weight: 600;
              color: var(--theme-main-color); }
        .container .searchRow .searchIcon {
          width: 16px;
          height: 16px;
          margin-right: 0.5rem; }
          .container .searchRow .searchIcon path {
            fill: rgba(255, 255, 255, 0.5);
            opacity: 0.5; }
        .container .searchRow .cleanIcon {
          opacity: 0.3;
          margin-top: 0.125rem;
          width: 14px;
          height: 14px; } }
  .container button {
    align-self: center; }
  .container .toggleButton {
    grid-area: toggle;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem; }
  .container .themeSwitcher {
    grid-area: themeSwitcher; }
    @media (max-width: 600px) {
      .container .themeSwitcher {
        height: 50px;
        padding-top: 0.25rem;
        justify-content: flex-end;
        border-bottom: 2px solid rgba(61, 73, 87, 0.5);
        border-top: 2px solid rgba(61, 73, 87, 0.5); } }
  .container .searchRow {
    grid-area: search; }
  .container .logButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    grid-area: downloadList / buttons; }
    @media (max-width: 600px) {
      .container .logButtonsContainer {
        justify-content: flex-start;
        grid-area: buttons;
        margin-bottom: 1rem; } }
  .container .center {
    grid-area: center;
    display: grid;
    grid-template: ". ." "links themeSwitcher" ". .";
    grid-template-rows: auto 2rem auto;
    grid-template-columns: auto 5.5rem; }
    .container .center .themeSwitcher {
      grid-area: themeSwitcher; }
    .container .center .links {
      justify-content: flex-end;
      grid-area: links; }
    .container .center .search {
      border-radius: 2px;
      grid-area: search;
      max-width: 584px;
      width: 100%;
      height: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background-color: var(--theme-background-light-color); }
      .container .center .search .input {
        height: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center; }
        .container .center .search .input form {
          width: 100%; }
          .container .center .search .input form input {
            display: flex;
            flex-grow: 1;
            margin-left: 1rem;
            padding-right: 1.5rem;
            height: 100%;
            background-color: transparent;
            width: 100%;
            border: none;
            outline: none;
            font-family: var(--montserrat), sans-serif;
            font-size: 0.875rem;
            font-weight: 600;
            color: var(--theme-main-color); }
      .container .center .search .searchIcon {
        width: 16px;
        height: 16px;
        margin-right: 0.5rem; }
        .container .center .search .searchIcon path {
          fill: rgba(255, 255, 255, 0.5);
          opacity: 0.5; }
      .container .center .search .cleanIcon {
        opacity: 0.3;
        margin-top: 0.125rem;
        width: 14px;
        height: 14px; }
    .container .center .downloadListExtra {
      grid-area: downloadList;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translate(-0.25rem, -0.75rem); }
      @media (min-width: 501px) {
        .container .center .downloadListExtra {
          display: none; } }
    @media (max-width: 800px) {
      .container .center {
        grid-template-rows: 3rem 0.5rem auto; }
        .container .center .links {
          grid-area: links;
          margin-top: 0.75rem; } }
  .container.loggedUser .center {
    grid-template: "links links" ". ." "search themeSwitcher";
    grid-template-rows: 1rem 0.75rem auto;
    grid-template-columns: auto 5.5rem; }
    .container.loggedUser .center .links {
      justify-content: center;
      margin-top: -0.5rem; }
  .container.bottomBorder {
    padding-bottom: 0.5rem; }
  .container > *:first-child {
    grid-area: logo; }
    @media (max-width: 800px) {
      .container > *:first-child {
        padding-bottom: 0.175rem; } }
  .container .downloadList {
    grid-area: downloadList;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .container .downloadList > div > svg {
      margin: auto 0.25rem;
      height: 0.9375rem;
      width: 0.9375rem; }
    @media (max-width: 600px) {
      .container .downloadList {
        height: 50px;
        flex-direction: row;
        justify-content: flex-start;
        border-bottom: 2px solid rgba(61, 73, 87, 0.5);
        border-top: 2px solid rgba(61, 73, 87, 0.5); } }
  .container .avatarContainer {
    position: relative;
    grid-area: buttons;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 4.5rem; }
    @media (max-width: 800px) {
      .container .avatarContainer {
        height: 3rem; } }
    @media (max-width: 600px) {
      .container .avatarContainer {
        justify-content: flex-start; } }
    .container .avatarContainer > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end; }
    .container .avatarContainer .djName {
      margin: 0 0.5rem;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: right;
      color: var(--theme-main-color);
      white-space: nowrap;
      max-width: 8rem;
      overflow: hidden;
      text-overflow: ellipsis; }
    .container .avatarContainer .imageContainer {
      width: 4.5rem;
      height: 4.5rem;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background-color: var(--active-elem); }
      @media (max-width: 800px) {
        .container .avatarContainer .imageContainer {
          width: 3rem;
          height: 3rem; } }
      .container .avatarContainer .imageContainer .avatar {
        height: 100%;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: cover; }

.withOpacity {
  background-color: var(--theme-background-color); }

.withoutOpacity {
  background-color: transparent; }
