/*
    Fonts
*/
.container {
  display: grid;
  grid-template-rows: 9rem auto 18rem;
  grid-template-columns: auto 7rem minmax(auto, calc(1432px - 14rem)) 7rem auto;
  overflow: hidden; }
  @media (max-width: 1432px) {
    .container {
      grid-template-columns: auto 0rem minmax(auto, calc(1432px - 14rem)) 0rem auto; } }
  @media (max-width: 700px) {
    .container {
      grid-template-columns: 1rem 0rem calc(100vw - 2rem) 0rem 1rem; } }
  @media (max-width: 700px) {
    .container {
      grid-template-rows: 8.75rem auto 8rem; } }
