@mixin displayVersion() {
    .displayVersion {
        h4 {
            color: var(--grey);
            margin-left: 1rem;
        }

        background-color: var(--theme-background-changed-color);
        width: 100%;
        display: grid !important;
        position: relative;

        @media (max-width: 720px) {
            margin: 0 0.5rem;
            width: calc(100vw - 1rem);
            padding: 0 0.5rem;
            box-sizing: border-box;
        }

        .genre {
            display: none;
        }

        .trackContainer {
            display: grid;
            grid-template-areas:
                ".        .       .    .          .    .      .        .       "
                "idNumber play    vers    vers      bpm  key    released buttons "
                ".        .       .    .          .    .      .        .       ";

            grid-template-columns: 3rem 3.5rem 23.525rem 0.25rem 2.5rem 3.5rem 5rem 76px;
            grid-template-rows: 0.8615rem 3.5rem auto 0.75rem;

            @media (max-width: 720px) {
                margin: 0 !important;
                width: calc(100vw - 2rem);
                padding: 0 !important;
                grid-template-areas: ".    .       .    " "play vers buttons " ".    .        .   " !important;

                grid-template-columns: 3rem auto 82px;
                grid-template-rows: 1rem auto 1rem;
                display: grid;

                .index,
                .bpm,
                .key,
                .genre,
                .released {
                    display: none;
                }

                .playInfo {
                    height: 100%;
                    max-width: 100% !important;
                    width: 100% !important;
                    justify-content: flex-start;

                    .nameAndArtist {
                        display: none;
                    }

                    & > *:first-child {
                        position: initial !important;

                        svg {
                            margin: 0 !important;
                        }
                    }
                }
                .versions {
                    p {
                        height: 100%;
                        width: 100%;
                        text-align: left;
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                }
                .buttons {
                    height: 100%;
                    margin: 0 !important;
                }
            }

            padding-bottom: 0;
            border: 0;
            display: grid;
            grid-template-rows: 0.5rem 5rem;

            .index {
                display: none;
            }

            .playInfo {
                .nameAndArtist {
                    display: none;
                }
            }

            .versions {
                grid-area: vers;
                margin: 0;
                padding: 0;

                p {
                    color: var(--theme-main-color);

                    border: 0;
                    margin: auto 0 auto 0;
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: rem(21px);
                    white-space: nowrap;
                    cursor: default;
                }
            }
        }
    }
}
