/*
    Fonts
*/
.field {
  margin-bottom: 0.75rem;
  height: 4.8125rem;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  width: 20.625rem;
  max-width: 100%;
  font-size: 0.8125rem;
  text-align: left; }
  .field.fullWidth {
    width: 100% !important; }
  .field .inputContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .field .input {
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid var(--theme-muffled-color);
    border-radius: 2px;
    transition: 100ms;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-positon: center center; }
    .field .input input {
      opacity: 0;
      position: absolute;
      left: 0;
      z-index: 9999; }
  .field checkIcon {
    position: absolute;
    right: 0; }
  .field .label {
    font-size: 1.0625rem;
    color: var(--theme-main-color);
    transition: 100ms;
    opacity: 0.3;
    font-weight: bold;
    white-space: nowrap; }
  .field.checked .label {
    opacity: 1; }
  .field.checked .input {
    background-image: url("assets/images/filledCheckbox.svg");
    background-color: var(--active-elem);
    background-size: 100% 100%; }
    .field.checked .input path {
      color: var(--white); }
  .field .error {
    margin-top: 0.3125rem;
    height: 1rem;
    color: var(--theme-error-color); }
  .field .checkboxTitle {
    overflow: hidden;
    white-space: nowrap; }
