@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import "~assets/style/variables";
@import "./Common.module.scss";

.container {
    @include common;
    margin-bottom: 1rem;

    div {
        font-family: Poppins;
    }



    .headContainer {
        padding-top: 0;
        height: auto;
        max-height: max-content;

        .textContent {
            padding-top: 13rem;
        }

        .flexContainer {
            padding-right: 16px;
            padding-left: 16px;
        }
    }

    .spinLogo {
        height: 4rem;
    }

    .textContainer {
        z-index: 10;
    }

    .buttonsContainer {
        width: 50%;
        max-width: calc(100vw - 2rem);
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        margin-top: 0rem;

        a {
            width: rem(180px);
            padding-left: rem(5px);
            padding-right: rem(5px);
            height: 3.3rem;
            margin: 0px 4px;
            background: none;
            outline: none;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid var(--active-elem);
            border-radius: 100px;
            background-color: transparent;
            border: 2px solid #465C74;
            font-family: Poppins;

            &:hover {
                border-radius: 100px;
                background-color: #BB1D7E;
                border: 2px solid transparent;
                color: #fff;
            }

            &.dark.active,
            &.dark:hover {
                border-radius: 100px;
                background-color: #BB1D7E;
                border: 2px solid transparent;
            }

            &.dark {
                border-radius: 100px;
                background-color: transparent;
                border: 2px solid #465C74;
            }

            color: var(--theme-main-color);
            white-space: nowrap;
            letter-spacing: 0.41px;
            font-size: 9.5px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    .boxImage {
        background-image: url(assets/images/SpinsBoxImage.png);
    }

    .imageTextContainerMob,
    .btnMobile {
        display: none;
    }

    .flexContainer {
        display: flex;
        align-items: center;
        max-width: 1140px;
        padding: 0px 16px;
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;

        @media (max-width:820px) {
            display: block;
            text-align: center;
        }
    }

    .appBack {
        padding: 85px 0px 85px;
    }

    .appBack img {
        max-width: 545px;
        padding-left: 51px;
        padding-right: 92px;

        @media (max-width:1100px) {
            padding-right: 0;
        }
    }

    .bannerTextContent {
        .headerTitle1 {
            margin-top: 0rem;
            font-family: Poppins;
            font-size: 3.5rem;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            margin-bottom: 30px;
            display: inline-block;
            margin-bottom: 1.5rem;
            color: #fff;
        }

        .headerText {
            color: #fff;
            font-family: Poppins;
            margin-right: calc(100% - 400px);

            @media (max-width:1100px) {
                margin-right: 0;
            }
        }
    }

    // featureBoxWrap
    .featureBoxWrap {
        padding: 45px 0px 70px;
        max-width: 1140px;
        padding-left: 16px;
        padding-right: 16px;

        .textContainer {
            width: 50%;
            padding-right: 10px;

            h3 {
                margin-bottom: 2.5rem;
                font-family: Poppins;
            }
        }

        .boxImageWrap {
            width: 50%;
            padding-left: 30px;
            align-items: center;
            display: flex;
            padding-top: 50px;

            .boxImage {
                width: 100%;
            }

            img {
                max-width: 100%;

                @media (max-width:1199px) {
                    max-width: 100%;
                }
            }

            @media (max-width:1199px) {
                padding-right: 16px;
            }
        }

        .featuresWrap {
            margin-bottom: 50px;
            font-family: Poppins;
        }

        .flexContainer {
            display: flex;
        }

        .flexContainerBtn {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .imageTextContainer {
                p {
                    color: #BB1D7E;
                    text-align: center;
                    text-transform: capitalize;
                    font-weight: 600;
                    text-decoration: underline;
                    font-family: Poppins;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .box {
        display: flex;
        flex-direction: column;

        .textContainer {
            display: contents;
        }

        .spinLogo {
            order: 2;
        }

        h3 {
            order: 1;
        }

        .boxImage {
            order: 2;
        }

        .features {
            order: 3;
        }

        .buttonsContainer {
            order: 4;
            flex-direction: column;

            a {
                width: 100%;
                max-width: rem(220px);
            }

            &>*:first-child {
                margin-bottom: 1.5rem;
            }

            &>*:last-child {
                margin-top: 1.5rem;
            }
        }
    }

    .flexContainerBtn {
        .imageTextContainer {
            text-align: center;
        }
    }

}

@media (max-width:1199px) and (min-width:821px) {
    .container {
        .flexContainer {
            padding-right: 16px;
            padding-left: 16px;

        }

        .appBack {
            img {
                max-width: 550px;
            }
        }
    }
}

@media (max-width:820px) {
    .bannerTextContent {
        margin-bottom: 30px;
    }

    .container {
        .flexContainer {
            padding-right: 16px;
            padding-left: 16px;
        }

        .imageTextContainerMob,
        .btnMobile {
            display: block;
        }

        .featureBoxWrap {
            .flexContainer {
                display: block;
            }
        }
    }

    .boxImageWrap {
        .imageTextContainer {
            margin-top: 20px;
        }
    }
}

@media (max-width:820px) {
    .container {
        .appBack {
            img {
                max-width: 100%;
                padding-left: 0;
            }
        }

        .flexContainerBtn {
            display: none !important;
        }

        .featureBoxWrap {
            .featuresWrap {
                text-align: left;
            }

            .textContainer {
                width: 100% !important;
            }

            .boxImageWrap {
                padding-left: 0;
                margin-top: 0px;
                width: 100%;
                display: block;
            }
        }

        .buttonsContainer {
            display: flex;
            width: 100%;

            a {
                width: 100% !important;
                margin-bottom: 16px !important;
            }
        }
    }
}

@media (max-width:768px) {
    .bannerTextContent {
        margin-top: 40px;
    }

    .container {
        .featureBoxWrap {
            display: block;
            padding-bottom: 30px;
        }


        .flexContainer {
            display: block;
        }

        .appBack img {
            max-width: 100%;
            padding-left: 0px;
            margin-top: 20px;
        }
    }


}

@media (max-width:767px) {
    .container {
        .buttonsContainer {
            display: block;
            width: 100%;
        }

        .featureBoxWrap {
            .boxImageWrap {
                padding-right: 0;
                padding-top: 40px;
            }

            .flexContainer {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.icon {
    border-radius: 13px;
}

.redText {
    color: #BB1D7E;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: underline;
}