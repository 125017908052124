/*
    Fonts
*/
.trasactionsTable {
  display: block; }

.ColumnContainer {
  width: 100%;
  position: relative;
  flex-shrink: 0;
  display: block;
  overflow-x: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }

.trasactionsTablebody {
  height: auto;
  padding: 20px 0px;
  width: 950px;
  grid-template-rows: 1rem 1.9375rem;
  display: flex;
  border-bottom: 1px solid #383838; }
  .trasactionsTablebody .dText {
    height: 1.5rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    font-size: 12px;
    letter-spacing: normal;
    color: var(--theme-main-color);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    opacity: 0.6; }
  .trasactionsTablebody .transId {
    width: 225px; }
  .trasactionsTablebody .submitDate {
    width: 184px; }
  .trasactionsTablebody .email {
    width: 400px; }
  .trasactionsTablebody .type {
    width: 200px; }
  .trasactionsTablebody .amount {
    width: 100px; }
  .trasactionsTablebody .invoice {
    width: 100px; }

.trasactionsTableHeader {
  height: 2.9375rem;
  grid-template-rows: 1rem 1.9375rem;
  display: flex;
  border-bottom: 1px solid #383838;
  width: 950px; }
  .trasactionsTableHeader .dHeading {
    margin: 0;
    display: flex;
    align-items: center;
    padding-left: 0rem;
    grid-area: play;
    font-size: 0.625rem;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.5px;
    color: var(--active-elem);
    opacity: 1 !important;
    text-align: left;
    grid-row-start: 1;
    grid-row-end: 2; }
  .trasactionsTableHeader .transId {
    width: 225px; }
  .trasactionsTableHeader .submitDate {
    width: 184px; }
  .trasactionsTableHeader .email {
    width: 400px; }
  .trasactionsTableHeader .type {
    width: 200px; }
  .trasactionsTableHeader .amount {
    width: 100px; }
  .trasactionsTableHeader .invoice {
    width: 100px; }

.loader {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%; }

.capturehide {
  position: fixed;
  left: 100%; }

.container {
  margin: 0px 40px 0px 24px;
  color: var(--theme-main-color); }
  .container .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    max-width: 100vw; }
    .container .content .video {
      position: relative;
      z-index: 2;
      width: 100vw;
      max-width: 100vw;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: top; }
      @media (max-width: 1530px) {
        .container .content .video {
          background-size: auto 100%; } }
      .container .content .video .backgroundVideo {
        z-index: 0;
        position: absolute;
        max-height: 130%;
        top: -20%;
        opacity: 1;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column; }
      .container .content .video .player {
        z-index: 2;
        width: 100vw;
        max-width: 100vw;
        background-color: var(--background-transparent-color);
        object-fit: contain;
        background-image: linear-gradient(to top, var(--theme-background-color) 20%, var(--theme-background-transparent-color));
        background-blend-mode: color;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        @media (max-width: 900px) {
          .container .content .video .player {
            padding-top: 8rem; } }
        .container .content .video .player > *:first-child {
          height: 70vw;
          max-height: 560px;
          margin-bottom: 2.5rem;
          margin-top: 9rem; }
          .container .content .video .player > *:first-child iframe {
            height: 70vw !important;
            max-height: 560px !important; }
        .container .content .video .player.playingVideo {
          background-color: rgba(var(--theme-background-color), 1); }
        .container .content .video .player .videoInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .container .content .video .player .videoInfo h1 {
            margin-top: 3rem;
            margin-bottom: 0.5rem;
            font-size: 3rem;
            line-height: 1.17; }
          .container .content .video .player .videoInfo h3 {
            margin: 0;
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 1.67; }
        .container .content .video .player .videoGallery {
          z-index: 2;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          max-width: 75.5rem; }
          .container .content .video .player .videoGallery .fullOpacity {
            opacity: 1 !important; }
          .container .content .video .player .videoGallery .localVideoCover {
            height: 112px; }
          .container .content .video .player .videoGallery .coverContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            opacity: 0.6;
            transition: 300ms ease-in-out;
            will-change: opacity;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            flex-wrap: wrap; }
            .container .content .video .player .videoGallery .coverContainer .trackName {
              margin-top: 0.75rem;
              width: 100%;
              max-width: 200px; }
            .container .content .video .player .videoGallery .coverContainer span {
              margin-left: -16px;
              width: 100%;
              max-width: 200px; }
          .container .content .video .player .videoGallery > div {
            width: 12.5rem;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            opacity: 0.6;
            transition: 300ms ease-in-out;
            will-change: opacity; }
            @media (max-width: 900px) {
              .container .content .video .player .videoGallery > div {
                width: 11.5rem; } }
            .container .content .video .player .videoGallery > div img {
              margin-bottom: 1rem;
              width: 10.5rem;
              height: 7rem; }
            .container .content .video .player .videoGallery > div button {
              position: absolute;
              left: 50%;
              top: 3.5rem;
              transform: translate(-50%, -50%);
              opacity: 0;
              transition: 300ms ease-in-out;
              will-change: opacity; }
            .container .content .video .player .videoGallery > div span {
              display: block;
              height: 1.5rem;
              width: 100%;
              padding: 0 1rem;
              text-align: left;
              font-size: 1rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis; }
              .container .content .video .player .videoGallery > div span.active {
                color: white;
                opacity: 1; }
                .container .content .video .player .videoGallery > div span.active button {
                  opacity: 1; }
            .container .content .video .player .videoGallery > div span:last-child {
              font-size: 0.75rem;
              color: var(--theme-muffled-color); }
            .container .content .video .player .videoGallery > div:hover {
              opacity: 1; }
              .container .content .video .player .videoGallery > div:hover button {
                opacity: 1; }
      .container .content .video .gallery {
        max-width: 75.5rem; }
    .container .content .info {
      margin-top: 14rem;
      width: 100%;
      max-width: 89.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background: linear-gradient(to bottom, var(--theme-background-color) 0%, var(--theme-background-color) 30%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, var(--theme-background-color) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(-90deg, var(--theme-background-color) 0%, rgba(255, 255, 255, 0) 100%), url(assets/images/splashSection2.jpg) no-repeat;
      background-size: 100% auto;
      padding-bottom: 25.5rem; }
      @media (max-width: 1000px) {
        .container .content .info {
          background-size: 1000px auto;
          background-position: center; } }
      @media (max-width: 700px) {
        .container .content .info {
          background-size: 1000px auto;
          padding-bottom: 6rem; } }
      .container .content .info .features {
        margin-top: 3.5rem;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center; }
        .container .content .info .features h3 {
          height: 2rem;
          margin-bottom: 0.5rem;
          font-size: 1.25rem;
          font-weight: 900; }
        .container .content .info .features * {
          margin: 0 1.25rem;
          max-width: 17.5rem;
          padding: 0.25rem; }
      .container .content .info .enterButton {
        width: 170px; }
      .container .content .info .enterButtonSpan {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.1px; }
  .container * {
    text-align: center; }
  .container h2 {
    margin: 0;
    height: 3.5rem;
    font-size: 2.0625rem;
    line-height: 1.7; }
  .container span {
    font-size: 0.875rem;
    line-height: 1.71;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center; }
