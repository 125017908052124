.container {
    display: grid;
    grid-template-columns: 1.5rem auto;
    row-gap: 1rem;
    line-height: 1.5rem;
    svg {
        height: 0.7rem;
        margin-top: 0.4rem;
        width: 0.7rem;
    }
}
