/*
    Fonts
*/
.container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12.5rem;
  padding-bottom: 50px !important;
  padding: 16rem calc(50vw - 35rem) 0;
  color: var(--white);
  background-image: url(/static/media/splashBack1.986cb1f7.png), url(/static/media/splashBack2.fc1da8d8.png), url(/static/media/splashBack2.fc1da8d8.png), url(/static/media/splashBack3.6e417b13.png), url(/static/media/splashBack2.fc1da8d8.png);
  background-position: -300px 300px, -188px -99px, calc(50vw - 350px) 400px, calc(100vw - 400px) 80px, calc(50vw - 450px) calc(100% + 550px);
  background-size: 600px 400px, 690px 690px, 700px 700px, 600px 600px, 900px 900px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-blend-mode: hard-light; }
  @media (max-width: 1024px) and (min-width: 767px) {
    .container {
      padding-top: 200px; } }
  @media (max-width: 767px) {
    .container {
      padding-bottom: 20px;
      padding-top: 220px; } }
  .container .content {
    padding: 0px 20px; }
    .container .content * {
      color: var(--theme-main-color); }
    .container .content h1 {
      font-size: 2rem;
      margin-bottom: 1.67rem;
      color: #fff; }
    .container .content h2 {
      font-size: 2.5rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal; }
      @media (max-width: 767px) {
        .container .content h2 {
          font-size: 1.8rem; } }
    .container .content h3 {
      font-size: 1.8rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal;
      margin-bottom: 10px; }
      @media (max-width: 767px) {
        .container .content h3 {
          font-size: 1.5rem; } }
    .container .content h4 {
      font-size: 1.4rem;
      margin-top: 0px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.14;
      letter-spacing: normal; }
    .container .content span {
      display: inline-block; }
    .container .content .headingTitle {
      text-align: center; }
    .container .content .bannerContentWrap {
      line-height: 1.67;
      font-size: 1.125rem;
      text-align: center;
      margin-bottom: 120px; }
      @media (max-width: 1024px) and (min-width: 767px) {
        .container .content .bannerContentWrap {
          margin-bottom: 60px; } }
      @media (max-width: 767px) {
        .container .content .bannerContentWrap {
          margin-bottom: 60px; }
          .container .content .bannerContentWrap .mainTitle {
            font-size: 28px;
            line-height: 42px; } }
      .container .content .bannerContentWrap * {
        color: var(--theme-main-color); }
      .container .content .bannerContentWrap .mainTitle {
        text-align: center;
        font-weight: 700; }
      .container .content .bannerContentWrap .bannerTextField {
        font-size: 16px; }
    .container .content .stepListTopSection {
      display: flex;
      align-items: center;
      margin-bottom: 80px; }
      .container .content .stepListTopSection img {
        width: 100%; }
      .container .content .stepListTopSection .leftSection {
        padding-right: 40px;
        width: 50%; }
      .container .content .stepListTopSection .rightSection {
        width: 50%;
        padding-left: 40px; }
    .container .content .stepListSection {
      display: flex;
      align-items: center;
      margin-bottom: 80px; }
      .container .content .stepListSection img {
        width: 100%; }
      .container .content .stepListSection .imgWrap {
        max-width: 300px; }
      @media (max-width: 1024px) and (min-width: 767px) {
        .container .content .stepListSection .leftSection {
          padding-right: 0px;
          align-items: center; }
        .container .content .stepListSection .rightSection {
          padding-left: 0px; }
          .container .content .stepListSection .rightSection img {
            max-width: 300px; } }
      .container .content .stepListSection .secList {
        font-size: 14px; }
        .container .content .stepListSection .secList .secTitle {
          margin-bottom: 20px; }
      .container .content .stepListSection .leftSection {
        width: 50%; }
        @media (max-width: 767px) {
          .container .content .stepListSection .leftSection {
            margin-bottom: 30px; } }
      .container .content .stepListSection .rightSection {
        width: 50%;
        text-align: right; }
    .container .content .stepListRightSection {
      display: flex;
      align-items: center;
      margin-bottom: 80px; }
      .container .content .stepListRightSection img {
        max-width: 300px; }
      .container .content .stepListRightSection .leftSection {
        width: 50%; }
        @media (max-width: 767px) {
          .container .content .stepListRightSection .leftSection {
            margin-bottom: 30px; } }
      .container .content .stepListRightSection .rightSection {
        width: 50%;
        align-items: center;
        display: flex; }
      @media (max-width: 767px) {
        .container .content .stepListRightSection {
          display: flex !important;
          flex-wrap: wrap;
          margin-bottom: 40px !important; }
          .container .content .stepListRightSection .rightSection {
            order: 2; } }
    @media (max-width: 767px) {
      .container .content .stepListTopSection, .container .content .stepListRightSection, .container .content .stepListSection {
        display: block; }
      .container .content .content {
        padding: 0px 16px !important; }
      .container .content .stepListRightSection .rightSection, .container .content .stepListSection .rightSection {
        text-align: center;
        justify-content: center;
        margin-bottom: 40px; }
      .container .content img {
        width: 100% !important; }
      .container .content .leftSection {
        max-width: 100% !important;
        padding-right: 0px !important;
        margin-bottom: 40px;
        width: 100% !important; }
      .container .content .rightSection {
        max-width: 100% !important;
        padding-left: 0px !important;
        width: 100% !important; } }

.faqSecWrap {
  background-color: #172E47;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px; }
  .faqSecWrap .headingTitle .secTitle {
    color: var(--theme-white-color); }
  .faqSecWrap .container {
    background: transparent;
    padding-top: 100px; }
  .faqSecWrap .faqSectionTitle {
    margin-bottom: 30px; }
  @media (max-width: 767px) {
    .faqSecWrap {
      padding-bottom: 40px; }
      .faqSecWrap .container {
        padding-top: 40px; }
      .faqSecWrap .faqSectionTitle {
        margin-bottom: 10px; } }
  .faqSecWrap .faqSectionContent {
    display: flex;
    align-items: flex-start; }
    .faqSecWrap .faqSectionContent .secContent {
      margin-bottom: 57px; }
      .faqSecWrap .faqSectionContent .secContent .secTitle {
        color: var(--theme-white-color); }
    .faqSecWrap .faqSectionContent .leftSection {
      padding-right: 40px;
      width: 50%; }
      .faqSecWrap .faqSectionContent .leftSection .secTitle {
        color: var(--theme-white-color); }
    .faqSecWrap .faqSectionContent .rightSection {
      width: 50%;
      padding-left: 40px; }
    @media (max-width: 767px) {
      .faqSecWrap .faqSectionContent {
        display: block; }
        .faqSecWrap .faqSectionContent .leftSection {
          padding-right: 0px;
          width: 100%; }
        .faqSecWrap .faqSectionContent .rightSection {
          width: 100%;
          padding-left: 0px; } }

.trackListSectionWrap .worksListSection {
  justify-content: space-between; }
