.activeVersion {
    background-color: var(--track-active-elem) !important;
    span {
        color: var(--white) !important;
    }
}
.newVersion {
    background: var(--track-new-version) !important;
    background-color: #694020 !important;
    span {
        color: var(--white) !important;
    }
}
.defaultVersion {
    background: var(--track-default-elem) !important;
    span {
        color: var(--white) !important;
    }
}
.tooltip {
    position: relative;
    display: inline-block !important;
    span.tooltiptext {
        visibility: hidden;
        width: max-content;
        background-color: #2e2b2b;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        top: -25px;
        margin: 0 auto !important;
        display:inline-block !important;
        right: -35px;
        padding: 0 9px ! important;
        line-height: 2.0 ! important;
    }

    &:hover span.tooltiptext {
        visibility: visible;
    }
}
.tooltip svg {
    width: 19px!important;
    height: 19px!important;
    border-radius: 100%!important;
}
.frpButton {
  background-color: var(--track-DIY-Versions-elem) !important;
  color: #ffffff !important;
  border: none !important;
  padding: 8px 16px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font-weight: 500 !important;

  &:hover {
    background-color: #034018 !important;
  }
  &:active {
    background-color: #012008 !important;
  }
}
.frpButton_green{
    background-color: var(--track-DIY-Versions-elem) !important;
    border: 1px solid var(--theme-track-version-color) !important;
}