/*
    Fonts
*/
.field {
  margin-bottom: 0.75rem;
  width: 100%;
  max-width: 100%;
  font-size: 0.8125rem;
  text-align: left; }
  .field.fullWidth {
    width: 100% !important; }
  .field .inputContainer {
    position: relative; }
  .field .input {
    position: relative;
    display: block;
    width: 100%;
    min-height: 3rem;
    height: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    color: var(--theme-main-color);
    font-weight: 500;
    background: transparent;
    font-size: 14px;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--theme-main-color);
    transition: 100ms;
    line-height: 1.5rem;
    overflow: hidden;
    resize: none; }
    .field .input::placeholder {
      opacity: 0;
      transition: 100ms; }
    .field .input::-webkit-datetime-edit {
      transition: 100ms;
      color: black; }
    .field .input:focus, .field .input:not([value=""]), .field .input:-webkit-autofill {
      border-bottom: 1px solid var(--theme-main-color); }
      .field .input:focus::placeholder, .field .input:not([value=""])::placeholder, .field .input:-webkit-autofill::placeholder {
        opacity: 1;
        transition: 500ms; }
      .field .input:focus ~ span, .field .input:not([value=""]) ~ span, .field .input:-webkit-autofill ~ span {
        transform: translateY(-3.25 rem);
        font-size: 0.75rem; }
      .field .input:focus::-webkit-datetime-edit, .field .input:not([value=""])::-webkit-datetime-edit, .field .input:-webkit-autofill::-webkit-datetime-edit {
        opacity: 1;
        transition: 500ms; }
    .field .input:disabled {
      opacity: 0.8; }
    .field .input::placeholder {
      color: var(--theme-main-color); }
  .field .label {
    display: block;
    position: relative;
    top: 100%;
    transform: translateY(-3rem);
    transition: 300ms;
    margin-bottom: 0rem;
    font-size: 1.0625rem;
    color: var(--theme-muffled-color);
    font-weight: 500;
    white-space: nowrap; }
  .field .error {
    margin-top: 0.3125rem;
    height: 1rem;
    color: var(--theme-error-color); }
  .field.hasError .input {
    color: var(--theme-error-color); }
  .field.lightMode .input {
    color: #0e1521;
    border-bottom: 1px solid #0e1521; }
    .field.lightMode .input::placeholder {
      opacity: 0;
      color: #aeb0bd; }
    .field.lightMode .input::-webkit-datetime-edit {
      transition: 100ms;
      color: #0e1521; }
    .field.lightMode .input:focus, .field.lightMode .input:not([value=""]), .field.lightMode .input:-webkit-autofill {
      border-bottom: 1px solid #0e1521; }
      .field.lightMode .input:focus::placeholder, .field.lightMode .input:not([value=""])::placeholder, .field.lightMode .input:-webkit-autofill::placeholder {
        opacity: 1; }
  .field.lightMode .labelOuter {
    color: #aeb0bd; }
  .field.lightMode .error {
    margin-top: 0.3125rem;
    height: 1rem; }
  .field.lightMode.hasError .input {
    color: var(--theme-error-color);
    filter: grayscale(50%); }

.labelOuter {
  display: block;
  transform: translateY(0);
  transition: 300ms;
  margin-bottom: 0rem;
  font-size: 0.8125rem;
  color: var(--theme-muffled-color);
  font-weight: 500;
  white-space: nowrap; }
