body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (max-width: 720px) {
  body #customRateStyle{-webkit-transform: translateX(-74%)!important;
     transform: translateX(-74%);}
     body #customRateStyle .customstar {margin-top: 0!important;}
body .customratingpopup {z-index: 98 !important;}
   }
.customstar {
  height: 1.5rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  font-size: 12px;
}

.customstar a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  height: 100%;
}
.slick-slide.slick-active.slick-center.slick-current {
  z-index: 99;
}