/*
    Fonts
*/
.container {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10.625rem;
  padding-bottom: 3.125rem; }
  @media (max-width: 800px) {
    .container {
      padding-top: 11.25rem;
      padding-bottom: 3.125rem; } }
  .container.labelsSection {
    background-image: linear-gradient(to bottom, var(--theme-background-transparent-color), var(--theme-background-color) 50%), url(assets/images/darkLabelsBack.png);
    background-repeat: no-repeat;
    background-size: 100% auto; }
    .container.labelsSection.light {
      background-image: linear-gradient(to bottom, var(--theme-background-highttransparent-color), var(--theme-background-color) 50%), url(assets/images/lightLabelsBack.jpg); }
  .container.promoSection {
    padding: 0;
    padding-bottom: 4rem;
    background-color: var(--theme-background-color);
    background-image: linear-gradient(var(--theme-background-color), var(--theme-background-transparent-color) 40%, var(--theme-background-color)), url(assets/images/darkPromoBack.png);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% auto; }
    .container.promoSection.light {
      background-image: linear-gradient(var(--theme-background-color), transparent 20%, transparent 80%, var(--theme-background-color)), url(assets/images/lightPromoBack.jpg); }
    @media (max-width: 1400px) {
      .container.promoSection {
        background-size: auto auto;
        background-position: center; } }
  .container.aboutUsBack, .container.contactUsBack {
    background-image: url(assets/images/darkAboutUsBack.jpg);
    background-color: var(--theme-background-color);
    background-repeat: no-repeat;
    background-size: 100% auto; }
    .container.aboutUsBack.light, .container.contactUsBack.light {
      background-image: url(assets/images/lightAboutAsBack.jpg); }
    @media (max-width: 1400px) {
      .container.aboutUsBack, .container.contactUsBack {
        background-size: auto auto; } }
  .container.contactUsBack {
    background-image: url(assets/images/darkContactUsBack.jpg);
    background-size: 100% 100%; }
    .container.contactUsBack.light {
      background-image: url(assets/images/lightContactUsBack.jpg); }
    .container.contactUsBack h2 {
      max-width: 740px;
      font-weight: 900 !important; }
  .container h1 {
    font-size: 4rem;
    margin-bottom: 1rem; }
    @media (max-width: 700px) {
      .container h1 {
        margin-bottom: 1rem; } }
  .container .labelsContainer {
    display: flex;
    flex-direction: column; }
    .container .labelsContainer > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .container .labelsContainer > div > img {
        width: 202px;
        height: 202px;
        margin: 28px; }
      @media (max-width: 700px) {
        .container .labelsContainer > div {
          justify-content: center;
          align-items: center; }
          .container .labelsContainer > div > img {
            width: 150px;
            height: 150px;
            margin: 15px; } }
  .container .content {
    max-width: 62.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center; }
    .container .content h1 {
      max-width: 100vw;
      font-size: 4rem;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: var(--theme-main-color); }
      @media (max-width: 720px) {
        .container .content h1 {
          font-size: 2.5rem; } }
    .container .content h2 {
      font-weight: 500; }
    .container .content h3 {
      text-transform: uppercase;
      color: var(--theme-main-color);
      opacity: 0.3;
      font-size: 0.75rem;
      font-weight: 600; }
    .container .content h4 {
      font-size: 1.25rem;
      font-weight: 900;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center; }
    .container .content .promoText {
      font-size: 0.875rem;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: var(--theme-main-color); }
    .container .content .promo {
      max-width: 48rem; }
      .container .content .promo h2 {
        max-width: calc(100vw - 2rem);
        font-size: 1.125rem;
        margin: 1.9375rem 0 0 0;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: var(--theme-main-color); }
    .container .content .clickHerelink {
      color: var(--theme-main-color);
      text-decoration: underline; }
    .container .content .links {
      margin-top: 3rem; }
      .container .content .links > * {
        padding-bottom: 0.3125rem;
        line-height: 1.5rem;
        font-size: 0.75rem;
        font-weight: 600;
        letter-spacing: 0.9px;
        opacity: 0.3; }
        .container .content .links > * * {
          bottom: 0 !important; }
    .container .content .innerContent {
      display: flex;
      flex-direction: column;
      width: 376px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: center;
      color: var(--theme-main-color);
      margin-top: 6rem; }
      .container .content .innerContent .icon {
        margin: auto;
        width: 72px;
        height: 72px;
        opacity: 1;
        color: var(--theme-second-color); }
      .container .content .innerContent .buttonDiv {
        margin-top: 4rem;
        margin-bottom: 10.5rem; }
      .container .content .innerContent .promoButton {
        margin: auto; }
    .container .content .text {
      max-width: 100vw;
      white-space: pre-line;
      margin-top: 2.5rem;
      color: var(--theme-main-color);
      font-size: 0.875rem;
      text-align: left;
      line-height: 2; }
      .container .content .text span {
        display: block;
        text-align: left;
        margin-bottom: 1.5rem;
        max-width: calc(100vw - 2rem); }
      .container .content .text p {
        display: inline-block;
        text-align: left;
        margin-top: 0; }
      .container .content .text.contactUs {
        width: 37.75rem;
        margin-top: 5.5rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        height: 35rem; }
        .container .content .text.contactUs > * {
          margin-bottom: 4rem;
          width: 17rem; }
          .container .content .text.contactUs > * span {
            white-space: pre-line;
            margin-bottom: 0;
            display: block;
            text-align: left; }
            .container .content .text.contactUs > * span:first-child {
              font-size: 1rem;
              font-weight: bold;
              height: 1.5rem;
              line-height: 1.5;
              margin-bottom: 0.5rem; }
            .container .content .text.contactUs > * span:last-child {
              font-size: 0.75rem;
              color: var(--theme-main-color);
              line-height: 1.71; }
