/*
    Fonts
*/
.container {
  padding-top: 8.0625rem;
  height: auto;
  min-height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(var(--theme-background-transparent-color), var(--theme-second-color) 650px), url(assets/images/darkAuthBack.jpg);
  background-color: var(--theme-background-color);
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .container.light {
    background-image: linear-gradient(var(--theme-background-highttransparent-color), var(--theme-second-color) 850px), url(assets/images/lightAuthBack.jpg); }
  @media (max-width: 1400px) {
    .container {
      background-size: auto auto;
      background-position: top; } }
  .container .content {
    width: 100%;
    padding-bottom: 5rem; }
  .container h5 {
    margin: 0;
    height: 1.5rem;
    flex-shrink: 0;
    opacity: 0.5;
    font-size: 0.875rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: center;
    color: var(--theme-main-color); }
  .container h1 {
    flex-shrink: 0;
    margin: 0 0 2rem 0; }
