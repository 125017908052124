/*
    Fonts
*/
.artistVideo {
  border-radius: 4px;
  background-color: var(--dark-grey);
  cursor: pointer; }
  .artistVideo .play {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: relative;
    top: -102%;
    transition: 400ms ease-in-out;
    display: flex;
    justify-content: center; }
    .artistVideo .play.small .playButton {
      position: relative;
      top: 6px; }
    .artistVideo .play.medium .playButton {
      position: relative;
      top: 4.21875rem; }
    .artistVideo .play:hover {
      opacity: 1; }

.artistVideoThumbnail {
  border-radius: 4px;
  background-color: var(--dark-grey);
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; }
  .artistVideoThumbnail img {
    height: 100%;
    width: auto; }

.videoDialog {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  height: 70vh !important;
  max-width: 100vw;
  width: auto; }
  .videoDialog video {
    height: 100%;
    width: auto;
    max-width: 100%; }

.videoFrame {
  max-height: 100vh;
  max-width: 100vw; }

.small {
  width: 170px;
  height: 96px; }

.medium {
  width: 376px;
  height: 212px; }
  @media (max-width: 400px) {
    .medium {
      width: 300px;
      height: 172px; } }
