.container {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &.activeStars {
        & > * {
            transform: scale(1);
            transition: 300ms ease-in-out;
            will-change: transform;
            cursor: pointer;

            &:hover {
                transform: scale(1.5);
            }
            * {
                transition: 300ms ease-in-out;
            }
        }
    }
}
