.logoContainer {
    height: 4.75rem !important;
    width: 11.45rem !important;
    @media (max-width: 800px) {
        height: 3.5rem !important;
        width: 8.43rem !important;
    }
    img {
        width: 100%;
        height: 100%;
    }
}
