/*
    Fonts
*/
.loader {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 6rem;
  width: 100%;
  min-width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  background-color: var(--theme-background-color); }
  .loader.autoWidth {
    width: 100%; }

.container {
  grid-area: filters;
  height: 0;
  position: inherit;
  justify-content: flex-end;
  z-index: 10; }
  @media (max-width: 1100px) {
    .container {
      height: auto;
      z-index: 15; } }
  .container .filterContent {
    position: relative; }
    @media (max-width: 1100px) {
      .container .filterContent {
        width: 100%;
        top: 0;
        height: auto; } }
    .container .filterContent .filterContainer {
      width: calc((100vw - 766px) / 2);
      max-width: 11.5rem;
      position: relative;
      z-index: 2; }
      @media (max-width: 1100px) {
        .container .filterContent .filterContainer {
          width: 11.5rem; } }
      @media (max-width: 700px) {
        .container .filterContent .filterContainer {
          width: calc(100vw - 2rem);
          max-width: calc(100vw - 2rem); } }
      .container .filterContent .filterContainer > * {
        margin-bottom: 1px;
        padding: 1.5rem;
        background-color: #bb1d7e; }
      .container .filterContent .filterContainer .header {
        cursor: pointer;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 4rem;
        z-index: 1; }
        .container .filterContent .filterContainer .header p {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: bold;
          line-height: 21px;
          color: var(--white); }
      .container .filterContent .filterContainer .filter {
        position: relative;
        max-height: 3rem;
        padding-top: 1rem;
        padding-bottom: 0;
        overflow: hidden;
        transition: 500ms ease-in-out;
        will-change: max-height, padding-top, padding-bottom; }
        .container .filterContent .filterContainer .filter.opened {
          max-height: 21rem;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem; }
          .container .filterContent .filterContainer .filter.opened.withButton {
            padding-bottom: 2rem; }
          .container .filterContent .filterContainer .filter.opened h3 {
            margin-bottom: 0.5rem; }
          .container .filterContent .filterContainer .filter.opened .showAllButton {
            bottom: 0;
            color: var(--active-elem); }
          .container .filterContent .filterContainer .filter.opened.showAll {
            max-height: 200rem; }
    .container .filterContent .mainFilterContainer {
      z-index: 17; }
    .container .filterContent .mainFilterContainer,
    .container .filterContent .links {
      width: calc((100vw - 766px) / 2);
      max-width: 11.5rem;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 1100px) {
        .container .filterContent .mainFilterContainer,
        .container .filterContent .links {
          width: 11.5rem; } }
      @media (max-width: 700px) {
        .container .filterContent .mainFilterContainer,
        .container .filterContent .links {
          width: calc(100vw - 2rem);
          max-width: calc(100vw - 2rem); } }
      .container .filterContent .mainFilterContainer > *,
      .container .filterContent .links > * {
        border-radius: 2px;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 1px;
        height: 3rem;
        background-color: var(--theme-background-changed-color);
        margin-left: 1px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: nowrap; }
      .container .filterContent .mainFilterContainer > :first-child,
      .container .filterContent .links > :first-child {
        padding-left: 0.5rem;
        width: 68%;
        margin-left: 0; }
      .container .filterContent .mainFilterContainer > :nth-child(2),
      .container .filterContent .links > :nth-child(2) {
        width: 32%; }
    .container .filterContent .mainFilterContainer > :first-child {
      flex: 1;
      display: flex;
      padding-left: 1.5rem;
      align-items: center;
      justify-content: flex-start;
      color: var(--theme-main-color); }
    @media (max-width: 700px) {
      .container .filterContent {
        display: flex;
        flex-wrap: wrap; }
        .container .filterContent .mainFilterContainer {
          width: unset; }
          .container .filterContent .mainFilterContainer > :nth-child(2) {
            width: 25vw; }
          .container .filterContent .mainFilterContainer > :first-child {
            display: none; }
        .container .filterContent .links {
          order: -1;
          flex: 1;
          max-width: unset; } }
    .container .filterContent .links > :first-child {
      width: 100%; }
    @media (min-width: 700px) {
      .container .filterContent .links {
        display: none; } }
    .container .filterContent .presetContainer {
      width: calc((100vw - 766px) / 2);
      max-width: 11.5rem;
      padding: 1rem 0 1rem 0;
      border-radius: 2px;
      height: auto;
      display: flex;
      flex-direction: column;
      background-color: var(--theme-background-changed-color);
      margin-bottom: 1rem; }
      @media (max-width: 1100px) {
        .container .filterContent .presetContainer {
          width: 11.5rem; } }
      @media (max-width: 700px) {
        .container .filterContent .presetContainer {
          width: calc(100vw - 2rem);
          max-width: calc(100vw - 2rem); } }
      .container .filterContent .presetContainer .presetButton {
        position: relative;
        opacity: 1 !important; }
        .container .filterContent .presetContainer .presetButton span {
          width: 100%;
          margin-left: 0.5rem;
          padding-right: 1.25rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .container .filterContent .presetContainer .presetButton span span {
            opacity: 0.3; }
        .container .filterContent .presetContainer .presetButton.activeButton span span {
          opacity: 1; }
      @media (max-width: 700px) {
        .container .filterContent .presetContainer {
          margin-top: 0;
          padding: 0;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          background-color: transparent; }
          .container .filterContent .presetContainer button {
            margin: 0;
            margin-top: 2px;
            background-color: var(--theme-background-changed-color);
            border-radius: 0; } }
  .container.blocked {
    display: none;
    opacity: 0.6 !important;
    pointer-events: none !important; }

.zeroOpacity {
  opacity: 0;
  pointer-events: none; }

.fullOpacity {
  opacity: 1;
  pointer-events: initial; }
