/*
    Fonts
*/
.header {
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: var(--black) !important;
  text-align: center; }

.cancelHeader {
  color: var(--black) !important;
  text-align: center;
  max-width: 85%; }

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 4rem 2rem 0 2rem; }
  .container .buttonContainer {
    display: flex;
    justify-content: center; }
    .container .buttonContainer > * {
      margin: 0 0 1rem 1rem; }
  .container input[type="file"] {
    display: none; }
  .container .label {
    cursor: pointer;
    width: 14.5rem;
    height: 2.5rem;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding-top: 0.73rem; }
  .container .center {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    .container .center .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
  .container .short {
    max-width: 4.625rem; }
  .container .normal {
    max-width: 10.5rem; }
  .container > *:last-child {
    margin-top: 1.5rem; }

.commonError {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  height: 1rem;
  margin-bottom: 0.25rem;
  color: var(--theme-error-color);
  filter: grayscale(50%);
  text-align: center; }

.tabButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.button {
  margin-top: -2.5rem;
  margin-bottom: 1rem; }
