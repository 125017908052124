@mixin loader($margin) {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: $margin;
    width: 100%;
    min-width: 6rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1000;
}

@mixin noTracks($margin) {
    margin: $margin auto;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
