.container {
    background: none;
    border: none;
    outline: none;
    transform: scale(1, 1);
    // transition: 200ms ease-in-out;
    will-change: transform;
    cursor: pointer;
    &:hover {
        transform: scale(1.1, 1.1);
    }
    &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
    }
    &:active {
        transform: scale(1, 1);
    }
    &.withoutResize {
        &:hover {
            transform: scale(1, 1);
        }
    }
}
