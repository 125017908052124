/*
    Fonts
*/
.container {
  width: 100%;
  position: relative; }
  .container .mainBackground {
    background-color: var(--theme-background-highttransparent-color);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  .container h1 {
    max-width: 100%;
    font-size: 48px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: var(--theme-main-color); }
  .container h3 {
    opacity: 0.5;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.9px; }
  .container p {
    display: flex;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    white-space: nowrap;
    letter-spacing: normal;
    color: var(--theme-main-color); }
  @media (max-width: 500px) {
    .container h1 {
      font-size: 32px; }
    .container p {
      font-size: 16px; } }
  .container .videoContainer {
    margin-top: 6.75rem;
    display: grid;
    grid-template-columns: 10% 35% 10% 35% 10%;
    grid-template-rows: 45% 15% 40%;
    background-size: cover;
    background-position: center;
    height: 86vh; }
    @media (max-width: 1220px) {
      .container .videoContainer {
        height: 86vh;
        grid-template-rows: 15% 55% 30%;
        grid-template-columns: 5% 85% 10%; } }
    .container .videoContainer .wide {
      grid-column-start: 1;
      grid-row-start: 1;
      grid-column-end: 6;
      grid-row-end: 4; }
      .container .videoContainer .wide .videoBackground {
        position: absolute;
        top: -10px;
        width: 100%;
        height: 121.5vh; }
    .container .videoContainer .leftTop {
      z-index: 1;
      align-self: flex-end;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2; }
    .container .videoContainer .left {
      z-index: 1;
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3; }
      .container .videoContainer .left .socialLinkBlock {
        margin-left: -8px; }
    .container .videoContainer .center {
      z-index: 0;
      justify-self: center;
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3; }
      @media (max-width: 1220px) {
        .container .videoContainer .center {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 4; } }

.videoCover {
  position: absolute;
  background-size: 100% auto;
  background-position: center;
  width: 100vw; }
  @media (max-width: 1220px) {
    .videoCover {
      background-size: auto auto; } }
