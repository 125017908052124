/*
    Fonts
*/
.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: calc(100vw - 2rem);
  transition: 0ms; }
  @media (max-width: 600px) {
    .links {
      flex-wrap: wrap; } }
  .links.below {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: var(--theme-main-color);
    letter-spacing: 0.5px; }
  .links.above {
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal !important;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: uppercase; }
  .links .link {
    opacity: 0.3;
    position: relative;
    text-decoration: none;
    color: var(--theme-main-color);
    margin-right: 1rem;
    transition: 300ms ease-in-out; }
    .links .link.extraMargin {
      margin-left: 1.75rem; }
    .links .link.activeLink {
      opacity: 1 !important; }
  .links .underline {
    position: relative; }
    .links .underline .line {
      content: " ";
      position: absolute;
      transform: scaleX(0);
      transition: 200ms ease-in-out;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      border: 0;
      background-color: var(--active-elem); }
    .links .underline .below {
      bottom: -0.65rem;
      height: 3px; }
    .links .underline .above {
      height: 3px;
      bottom: 1.5rem; }
      @media (max-width: 800px) {
        .links .underline .above {
          bottom: 0.875rem; } }
    .links .underline.activeLink .line {
      transform: scaleX(1) !important;
      transition: 500ms ease-in-out; }
