/*
    Fonts
*/
.container {
  position: relative;
  grid-area: sideLinks;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  @media (max-width: 700px) {
    .container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%; }
      .container > * {
        margin: 1rem; } }
  .container .frpExclusivePopup {
    position: fixed;
    text-align: center;
    inset: 0;
    z-index: 99999999;
    background: #0000008f;
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; }
    .container .frpExclusivePopup .inner_modal {
      background-color: #ffffffcf;
      width: 100%;
      position: relative;
      max-width: 680px;
      border-radius: 6px;
      padding: 40px 40px; }
    .container .frpExclusivePopup span {
      position: absolute;
      right: 14px;
      top: 6px;
      font-size: 20px;
      opacity: 1;
      color: var(--theme-modal-title);
      cursor: pointer;
      width: 10px;
      font-family: 'azonix'; }
    .container .frpExclusivePopup h3 {
      color: var(--theme-modal-title);
      position: relative;
      font-size: clamp(32px, 6dvw, 46px);
      font-weight: 700;
      margin: 0;
      line-height: 1.3em; }
  .container .links {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .container .links a {
      width: 100%;
      max-width: 13rem;
      height: 3.25625rem;
      padding-left: 1rem;
      border-radius: 0.25rem;
      background-color: var(--theme-background-changed-color);
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .container .links a.active {
        background-color: var(--active-elem); }
      .container .links a span {
        color: var(--theme-main-color);
        margin-left: 0.875rem;
        font-family: Montserrat;
        font-size: 0.8125rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal; }
      @media (max-width: 500px) {
        .container .links a {
          max-width: calc(100vw - 2rem);
          width: calc(100vw - 2rem); } }
    @media (max-width: 900px) and (min-width: 500px) {
      .container .links {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap; }
        .container .links a {
          margin: 1rem; } }
