/*
    Fonts
*/
@keyframes PlaceholderAnimation {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

/*
    Fonts
*/
@keyframes PlaceholderAnimation {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

h3 {
  color: var(--theme-main-color);
  font-weight: 600; }

body .genre {
  align-items: flex-start !important;
  line-height: 1.6 !important; }

.loader {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 6rem;
  width: 100%;
  min-width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  background-color: var(--theme-background-color); }
  .loader.autoWidth {
    width: 100%; }

.noTracks {
  margin: 7rem auto;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center; }
  .noTracks.autoWidth {
    width: 100%; }

.versionHeader {
  width: 100%;
  position: relative;
  font-size: 0.75rem;
  color: var(--theme-muffled-color);
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .versionHeader .versionText {
    background-color: var(--theme-background-changed-color);
    z-index: 2;
    position: relative;
    padding: 0 2rem; }
  .versionHeader .headerLine {
    width: calc(100% - 5rem);
    height: 1px;
    position: absolute;
    top: 50%;
    left: 3.25rem;
    border-bottom: 1px solid var(--theme-muffled-color);
    z-index: 1; }

.mainContainer {
  margin-bottom: 2.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media (max-width: 67rem) {
    .mainContainer {
      justify-content: center; } }
  .mainContainer * {
    line-height: 1; }
  .mainContainer .trackVersion {
    z-index: 1; }
    .mainContainer .trackVersion > div {
      margin-left: 1rem; }
      .mainContainer .trackVersion > div:nth-child(2) {
        margin: 0; }
      .mainContainer .trackVersion > div:nth-child(3), .mainContainer .trackVersion > div:nth-child(4), .mainContainer .trackVersion > div:nth-child(5), .mainContainer .trackVersion > div:nth-child(6), .mainContainer .trackVersion > div:nth-child(7) {
        margin-top: 0.8rem; }
      .mainContainer .trackVersion > div p:first-child {
        padding-top: 0.8rem; }
    .mainContainer .trackVersion:last-child {
      padding-bottom: 25px !important; }
  .mainContainer .tracksColumnContainer {
    width: 100%;
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .mainContainer .tracksColumnContainer .tracksColumnHeader,
    .mainContainer .tracksColumnContainer .trackContainer {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-areas: ".        .       .          .    .      .        .       " "idNumber play    genre      bpm  key    released buttons " ".        vers    vers       vers vers   vers     vers    " ".        .       .          .    .      .        .       ";
      grid-template-columns: 3rem 18.5rem 5.125rem 2.5rem 3.5rem 6rem 120px;
      grid-template-rows: 0.8615rem 3.5rem auto 0.75rem;
      border-bottom: 1px solid var(--theme-muffled-color);
      position: relative; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader > *,
      .mainContainer .tracksColumnContainer .trackContainer > * {
        text-align: left;
        font-size: 0.625rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .index,
      .mainContainer .tracksColumnContainer .trackContainer .index {
        grid-area: idNumber;
        padding-bottom: 0.5rem;
        justify-self: center;
        align-items: center; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader > *:nth-child(2),
      .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(2) {
        grid-area: play; }
        .mainContainer .tracksColumnContainer .tracksColumnHeader > *:nth-child(2) > :first-child > :first-child,
        .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(2) > :first-child > :first-child {
          margin-top: -1.5rem; }
        .mainContainer .tracksColumnContainer .tracksColumnHeader > *:nth-child(2) > :nth-child(2),
        .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(2) > :nth-child(2) {
          padding-top: 0.375rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .genre,
      .mainContainer .tracksColumnContainer .tracksColumnHeader .bpm,
      .mainContainer .tracksColumnContainer .tracksColumnHeader .key,
      .mainContainer .tracksColumnContainer .tracksColumnHeader .released,
      .mainContainer .tracksColumnContainer .trackContainer .genre,
      .mainContainer .tracksColumnContainer .trackContainer .bpm,
      .mainContainer .tracksColumnContainer .trackContainer .key,
      .mainContainer .tracksColumnContainer .trackContainer .released {
        opacity: var(--theme-changed-opacity); }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .genre,
      .mainContainer .tracksColumnContainer .trackContainer .genre {
        grid-area: genre;
        margin-top: 0.125rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .bpm,
      .mainContainer .tracksColumnContainer .trackContainer .bpm {
        grid-area: bpm;
        margin-top: 0.125rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .key,
      .mainContainer .tracksColumnContainer .trackContainer .key {
        grid-area: key;
        margin-top: 0.125rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .released,
      .mainContainer .tracksColumnContainer .trackContainer .released {
        grid-area: released;
        margin-top: 0.125rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .buttons,
      .mainContainer .tracksColumnContainer .trackContainer .buttons {
        margin-top: 0.125rem;
        padding-right: 0.25rem;
        grid-area: buttons;
        display: flex;
        justify-content: flex-end;
        width: 100%; }
        .mainContainer .tracksColumnContainer .tracksColumnHeader .buttons > *,
        .mainContainer .tracksColumnContainer .trackContainer .buttons > * {
          margin-left: 0.25rem; }
      @media (max-width: 720px) {
        .mainContainer .tracksColumnContainer .tracksColumnHeader,
        .mainContainer .tracksColumnContainer .trackContainer {
          margin: 0 0.5rem;
          width: calc(100vw - 1rem);
          grid-template-areas: ". .        .        .      ." ". play     vers     vers   ." ". play     key      bpm    ." ". genre    released released ." ". buttons  buttons  buttons ." ". .        .       .         .";
          grid-template-columns: 0.5rem calc(100vw - 13.5rem) 10rem 1.5rem 0.5rem;
          grid-template-rows: 1rem 2rem 1.5rem 2rem 2.5rem 1rem; }
          .mainContainer .tracksColumnContainer .tracksColumnHeader .versions,
          .mainContainer .tracksColumnContainer .trackContainer .versions {
            padding: 0 !important;
            grid-area: vers !important;
            margin-left: 0.4rem; }
          .mainContainer .tracksColumnContainer .tracksColumnHeader .index,
          .mainContainer .tracksColumnContainer .trackContainer .index {
            display: none; }
          .mainContainer .tracksColumnContainer .tracksColumnHeader .versions,
          .mainContainer .tracksColumnContainer .tracksColumnHeader .key,
          .mainContainer .tracksColumnContainer .tracksColumnHeader .bpm,
          .mainContainer .tracksColumnContainer .tracksColumnHeader .buttons,
          .mainContainer .tracksColumnContainer .tracksColumnHeader .released,
          .mainContainer .tracksColumnContainer .trackContainer .versions,
          .mainContainer .tracksColumnContainer .trackContainer .key,
          .mainContainer .tracksColumnContainer .trackContainer .bpm,
          .mainContainer .tracksColumnContainer .trackContainer .buttons,
          .mainContainer .tracksColumnContainer .trackContainer .released {
            padding: 0 !important;
            justify-content: flex-end !important;
            text-align: right;
            align-items: center; }
            .mainContainer .tracksColumnContainer .tracksColumnHeader .versions > *,
            .mainContainer .tracksColumnContainer .tracksColumnHeader .key > *,
            .mainContainer .tracksColumnContainer .tracksColumnHeader .bpm > *,
            .mainContainer .tracksColumnContainer .tracksColumnHeader .buttons > *,
            .mainContainer .tracksColumnContainer .tracksColumnHeader .released > *,
            .mainContainer .tracksColumnContainer .trackContainer .versions > *,
            .mainContainer .tracksColumnContainer .trackContainer .key > *,
            .mainContainer .tracksColumnContainer .trackContainer .bpm > *,
            .mainContainer .tracksColumnContainer .trackContainer .buttons > *,
            .mainContainer .tracksColumnContainer .trackContainer .released > * {
              margin-top: 0 !important; }
          .mainContainer .tracksColumnContainer .tracksColumnHeader .key:after,
          .mainContainer .tracksColumnContainer .trackContainer .key:after {
            content: "-";
            padding-left: 0.25rem; }
          .mainContainer .tracksColumnContainer .tracksColumnHeader .playInfo,
          .mainContainer .tracksColumnContainer .trackContainer .playInfo {
            height: 100%;
            max-width: 65vw !important;
            width: 65vw !important;
            position: relative; }
            .mainContainer .tracksColumnContainer .tracksColumnHeader .playInfo .nameAndArtist,
            .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist {
              height: 100% !important;
              justify-content: space-between !important; }
            .mainContainer .tracksColumnContainer .tracksColumnHeader .playInfo > *:first-child,
            .mainContainer .tracksColumnContainer .trackContainer .playInfo > *:first-child {
              position: absolute;
              top: calc(100% + 2rem);
              height: 1.625rem !important;
              width: 1.625rem !important; }
              .mainContainer .tracksColumnContainer .tracksColumnHeader .playInfo > *:first-child svg,
              .mainContainer .tracksColumnContainer .trackContainer .playInfo > *:first-child svg {
                margin-top: 0 !important;
                height: 1.625rem !important;
                width: 1.625rem !important; }
          .mainContainer .tracksColumnContainer .tracksColumnHeader .versions button,
          .mainContainer .tracksColumnContainer .trackContainer .versions button {
            margin: 0 !important;
            padding: 0 !important;
            border: none !important; }
            .mainContainer .tracksColumnContainer .tracksColumnHeader .versions button span,
            .mainContainer .tracksColumnContainer .trackContainer .versions button span {
              font-size: 0.75rem !important;
              color: var(--theme-main-color) !important; }
          .mainContainer .tracksColumnContainer .tracksColumnHeader .versions > *:not(:first-child),
          .mainContainer .tracksColumnContainer .trackContainer .versions > *:not(:first-child) {
            display: none; } }
    .mainContainer .tracksColumnContainer .tracksColumnHeader {
      height: 2.9375rem;
      width: 100%;
      grid-template-rows: 1rem 1.9375rem; }
      @media (max-width: 720px) {
        .mainContainer .tracksColumnContainer .tracksColumnHeader {
          display: none !important; } }
      .mainContainer .tracksColumnContainer .tracksColumnHeader.billboard {
        grid-template-columns: 6rem 15.8125rem 5.625rem 2rem 3rem 6rem; }
        .mainContainer .tracksColumnContainer .tracksColumnHeader.billboard .index {
          display: none; }
        .mainContainer .tracksColumnContainer .tracksColumnHeader.billboard .playInfo {
          padding-left: 0; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader * {
        font-size: 0.625rem;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: var(--active-elem);
        opacity: 1 !important;
        text-align: left;
        grid-row-start: 1;
        grid-row-end: 2; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader:not(.billboard) > div:nth-child(2) {
        padding-left: 3.5rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .buttons {
        padding-left: 3px; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader .playInfo {
        padding-left: 3.375rem; }
    .mainContainer .tracksColumnContainer .trackContainer * {
      height: 1.5rem;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.75;
      font-size: 12px;
      letter-spacing: normal;
      color: var(--theme-main-color);
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .mainContainer .tracksColumnContainer .trackContainer * :first-child {
        height: 100%;
        font-size: 0.75rem;
        font-weight: normal; }
    .mainContainer .tracksColumnContainer .trackContainer .bpm,
    .mainContainer .tracksColumnContainer .trackContainer .key {
      color: var(--theme-third-color);
      opacity: 1; }
    .mainContainer .tracksColumnContainer .trackContainer > :first-child {
      height: auto;
      font-size: 0.75rem;
      font-weight: bold; }
    .mainContainer .tracksColumnContainer .trackContainer .playInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: fit-content;
      max-width: 412px; }
      .mainContainer .tracksColumnContainer .trackContainer .playInfo > *:first-child {
        width: auto;
        height: 72px;
        min-width: 50px; }
        .mainContainer .tracksColumnContainer .trackContainer .playInfo > *:first-child > :first-child {
          height: 3rem; }
      .mainContainer .tracksColumnContainer .trackContainer .playInfo .playIcon {
        height: 41px !important;
        width: 40px !important; }
      .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist.album {
        height: 3.5rem !important;
        max-width: 240px; }
      .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist {
        height: 4.9rem;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; }
        .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist > *:first-child {
          color: var(--theme-main-color);
          font-size: 0.875rem;
          height: 1.6rem;
          line-height: 1.31;
          overflow: hidden;
          width: 100%;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 0.5rem; }
        .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist > *:last-child {
          line-height: 1;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          color: var(--theme-third-color);
          opacity: 1; }
        .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist > *:nth-child(2) {
          line-height: 1;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          color: var(--theme-third-color);
          opacity: 1; }
        .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist .buttons {
          min-width: 140px; }
    .mainContainer .tracksColumnContainer .trackContainer .versions {
      z-index: 1000;
      max-width: 100%;
      height: auto;
      min-height: 0.865rem;
      grid-area: vers;
      gap: 8px !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
      margin-top: 10px;
      padding-left: 3.1rem;
      padding-bottom: 0.2rem; }
      .mainContainer .tracksColumnContainer .trackContainer .versions.longVersion {
        grid-area: vers;
        position: relative; }
        .mainContainer .tracksColumnContainer .trackContainer .versions.longVersion > p {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block; }
      .mainContainer .tracksColumnContainer .trackContainer .versions .smallVersionContainer button {
        height: 1rem !important;
        margin-right: 0.5rem;
        margin-top: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 23px !important;
        padding: 4px 8px !important;
        border-radius: 4px !important;
        border: 1px solid var(--theme-track-version-color);
        cursor: pointer;
        margin: 0 !important; }
        .mainContainer .tracksColumnContainer .trackContainer .versions .smallVersionContainer button:hover {
          transition: 0.25s;
          transform: scale(1.04, 1.04); }
        .mainContainer .tracksColumnContainer .trackContainer .versions .smallVersionContainer button:focus {
          outline: none; }
        .mainContainer .tracksColumnContainer .trackContainer .versions .smallVersionContainer button span {
          font-size: 0.695rem;
          color: var(--theme-muffled-color);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 9rem;
          display: inline-block;
          line-height: 0.875rem; }
        .mainContainer .tracksColumnContainer .trackContainer .versions .smallVersionContainer button.activeVersion {
          background-color: var(--active-elem);
          border: 1px solid var(--active-elem); }
          .mainContainer .tracksColumnContainer .trackContainer .versions .smallVersionContainer button.activeVersion span {
            color: var(--white); }
    .mainContainer .tracksColumnContainer .displayVersion {
      background-color: var(--theme-background-changed-color);
      width: 100%;
      display: grid !important;
      position: relative; }
      .mainContainer .tracksColumnContainer .displayVersion h4 {
        color: var(--grey);
        margin-left: 1rem; }
      @media (max-width: 720px) {
        .mainContainer .tracksColumnContainer .displayVersion {
          margin: 0 0.5rem;
          width: calc(100vw - 1rem);
          padding: 0 0.5rem;
          box-sizing: border-box; } }
      .mainContainer .tracksColumnContainer .displayVersion .genre {
        display: none; }
      .mainContainer .tracksColumnContainer .displayVersion .trackContainer {
        display: grid;
        grid-template-areas: ".        .       .    .          .    .      .        .       " "idNumber play    vers    vers      bpm  key    released buttons " ".        .       .    .          .    .      .        .       ";
        grid-template-columns: 3rem 3.5rem 23.525rem 0.25rem 2.5rem 3.5rem 5rem 76px;
        grid-template-rows: 0.8615rem 3.5rem auto 0.75rem;
        padding-bottom: 0;
        border: 0;
        display: grid;
        grid-template-rows: 0.5rem 5rem; }
        @media (max-width: 720px) {
          .mainContainer .tracksColumnContainer .displayVersion .trackContainer {
            margin: 0 !important;
            width: calc(100vw - 2rem);
            padding: 0 !important;
            grid-template-areas: ".    .       .    " "play vers buttons " ".    .        .   " !important;
            grid-template-columns: 3rem auto 82px;
            grid-template-rows: 1rem auto 1rem;
            display: grid; }
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .index,
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .bpm,
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .key,
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .genre,
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .released {
              display: none; }
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .playInfo {
              height: 100%;
              max-width: 100% !important;
              width: 100% !important;
              justify-content: flex-start; }
              .mainContainer .tracksColumnContainer .displayVersion .trackContainer .playInfo .nameAndArtist {
                display: none; }
              .mainContainer .tracksColumnContainer .displayVersion .trackContainer .playInfo > *:first-child {
                position: initial !important; }
                .mainContainer .tracksColumnContainer .displayVersion .trackContainer .playInfo > *:first-child svg {
                  margin: 0 !important; }
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .versions p {
              height: 100%;
              width: 100%;
              text-align: left;
              margin: 0 !important;
              padding: 0 !important; }
            .mainContainer .tracksColumnContainer .displayVersion .trackContainer .buttons {
              height: 100%;
              margin: 0 !important; } }
        .mainContainer .tracksColumnContainer .displayVersion .trackContainer .index {
          display: none; }
        .mainContainer .tracksColumnContainer .displayVersion .trackContainer .playInfo .nameAndArtist {
          display: none; }
        .mainContainer .tracksColumnContainer .displayVersion .trackContainer .versions {
          grid-area: vers;
          margin: 0;
          padding: 0; }
          .mainContainer .tracksColumnContainer .displayVersion .trackContainer .versions p {
            color: var(--theme-main-color);
            border: 0;
            margin: auto 0 auto 0;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.3125rem;
            white-space: nowrap;
            cursor: default; }

.mainContainer_forHome {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 592px;
  max-width: 100vw; }
  @media (max-width: 67rem) {
    .mainContainer_forHome {
      justify-content: center; } }
  .mainContainer_forHome * {
    line-height: 1; }
  .mainContainer_forHome:first-child {
    padding-right: 1.25rem; }
  .mainContainer_forHome:last-child {
    padding-left: 1.25rem; }
  .mainContainer_forHome .tracksColumnContainer {
    overflow: visible;
    width: 100%;
    max-width: 100vw;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-startTrack; }
    .mainContainer_forHome .tracksColumnContainer .trackContainer {
      max-width: 100vw;
      width: 100%;
      display: grid;
      grid-template-areas: ".        .       .          .    .      .         " "idNumber play    genre      bpm  key    released " ".        vers    vers       vers vers    vers ";
      grid-template-columns: 1.5rem calc(550px - 7.5rem - 184px) 5rem 2.25rem 3.5rem 6rem !important;
      grid-template-rows: 1rem 3rem auto; }
      .mainContainer_forHome .tracksColumnContainer .trackContainer * {
        font-size: 11px; }
      .mainContainer_forHome .tracksColumnContainer .trackContainer > *:first-child {
        display: flex !important;
        padding-bottom: 0 !important; }
      @media (max-width: 600px) {
        .mainContainer_forHome .tracksColumnContainer .trackContainer {
          grid-template-areas: ".       .    .    . " "play    play play play" "genre   bpm  key  released" "vers    vers     vers           vers" ".       .    .    . " !important;
          grid-template-columns: calc(100vw - 12rem) 2rem 3rem !important;
          grid-template-rows: 1.5rem 4rem 2rem 2rem 1rem !important; }
          .mainContainer_forHome .tracksColumnContainer .trackContainer > *:first-child {
            display: none !important; } }
      .mainContainer_forHome .tracksColumnContainer .trackContainer > *:nth-child(2) * {
        font-size: 12px !important;
        overflow: hidden; }
      @media (max-width: 600px) {
        .mainContainer_forHome .tracksColumnContainer .trackContainer > *:nth-child(2) {
          width: 90vw; } }
      .mainContainer_forHome .tracksColumnContainer .trackContainer > *:nth-child(2) .nameAndArtist {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .mainContainer_forHome .tracksColumnContainer .trackContainer .versions {
        width: 100%;
        height: 2.75rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        grid-area: vers !important;
        padding-left: 3.25rem !important; }
        @media (max-width: 600px) {
          .mainContainer_forHome .tracksColumnContainer .trackContainer .versions {
            padding-left: 0 !important; } }
        @media (max-width: 600px) {
          .mainContainer_forHome .tracksColumnContainer .trackContainer .versions {
            grid-area: vers !important; } }
    @media (max-width: 600px) {
      .mainContainer_forHome .tracksColumnContainer .tracksColumnHeader {
        display: none !important; } }

.disabledButton {
  cursor: initial !important;
  opacity: 0.8; }

.isVersionsVisiable {
  border-top: 1px var(--theme-background-changed-color) solid;
  margin-top: -1px;
  border-bottom: 0 !important;
  background-color: var(--theme-background-changed-color); }

.smallVersionContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem !important; }

.trackVersionColorType {
  display: flex;
  gap: 15px; }
  .trackVersionColorType .ColorField {
    display: flex;
    align-items: center;
    margin-bottom: 9px; }
    .trackVersionColorType .ColorField span {
      display: inline-block;
      margin-right: 5px;
      width: 15px;
      height: 22px;
      border-radius: 4px;
      border: 1px var(--theme-track-version-color) solid; }
    .trackVersionColorType .ColorField p {
      color: #fff;
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 13px;
      opacity: .75; }
  .trackVersionColorType .ColorTypeOne span {
    background-color: var(--track-active-elem) !important; }
  .trackVersionColorType .ColorTypeTwo span {
    background: var(--track-new-version) !important; }
  .trackVersionColorType .ColorTypeThree span {
    background: var(--track-default-elem) !important; }
  .trackVersionColorType .ColorTypeFour span {
    background: var(--track-DIY-Versions-elem) !important; }
  .trackVersionColorType .greenButton {
    background-color: #023010 !important;
    color: #ffffff !important;
    border: none !important; }

.DownloadButtonInfo {
  position: relative; }

.trackName {
  padding-top: 0.8rem !important; }

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10%); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0); }
  to {
    opacity: 0;
    transform: translateY(-10%); } }

.openEffect {
  transition: slideDown 0.2s ease-in forwards; }

.closeEffect1 {
  transition: max-height 0.2s ease-in-out; }

.closeEffect2 {
  transition: max-height 0.3s ease-in-out; }

.closeEffect3 {
  transition: max-height 0.4s ease-in-out; }

.closeEffect4 {
  transition: max-height 0.5s ease-in-out; }

.closeEffect5 {
  transition: max-height 0.7s ease-in-out; }

.closeEffect6 {
  transition: max-height 0.9s ease-in-out; }

.closeEffect7 {
  transition: max-height 1.1s ease-in-out; }

.closeEffect8 {
  transition: max-height 1.4s ease-in-out; }

.panel {
  max-height: 0;
  overflow-y: clip; }

.trackContainer.billboardTracks {
  grid-template-columns: 3rem 42.875rem; }

.trackContainer.billboardTracks .rightcolumn .trackinfonew .bpmandkeyinfo {
  margin-top: 0px !important; }

.trackContainer.billboardTracks .rightcolumn, .trackContainer.billboardTracks .rightcolumn .dateOrbtn {
  padding-top: 0px !important; }

.rightcolumn {
  position: absolute;
  width: 100%;
  right: 0;
  max-width: 45%;
  align-items: flex-start !important;
  height: auto !important;
  padding-top: 15px !important;
  margin-top: 0 !important;
  top: 0; }

.rightcolumn .trackinfonew {
  width: 100%;
  display: block !important;
  text-align: right !important;
  padding: 0 12px 0 0 !important; }

.rightcolumn .trackinfonew .buttons > div {
  right: -141px !important;
  left: unset; }

.rightcolumn .bpmandkeyinfo div {
  color: #646f7c !important; }

.rightcolumn .bpmandkeyinfo {
  justify-content: flex-end !important;
  font-weight: 600 !important; }

.rightcolumn .bpmandkeyinfo * {
  font-weight: 600 !important; }

.rightcolumn .bpmandkeyinfo .bpmtxt,
.rightcolumn .bpmandkeyinfo .keytxt {
  color: var(--theme-third-color);
  font-weight: 600 !important;
  padding-left: 5px; }

.rightcolumn .trackinfonew .moodinfo {
  color: #646f7c;
  justify-content: end;
  font-weight: 600 !important; }

.rightcolumn .trackinfonew .genreinfo .genretxt {
  display: block !important;
  color: var(--track-new-version);
  line-height: 1 !important;
  height: unset;
  font-weight: bold !important; }

.rightcolumn .trackinfonew .genreinfo {
  font-weight: 600 !important;
  justify-content: end;
  gap: 6px;
  color: #646f7c !important; }

.rightcolumn .trackinfonew .moodinfo .moodtxt {
  font-weight: 600 !important;
  color: var(--theme-third-color);
  padding-left: 5px;
  text-transform: capitalize; }

.rightcolumn .dateOrbtn {
  flex-direction: column !important;
  height: auto !important;
  justify-content: space-between !important;
  min-width: 120px; }

.rightcolumn .dateOrbtn .date {
  width: 100%;
  text-align: right;
  flex-direction: row-reverse;
  height: auto !important;
  padding-right: 5px;
  color: #646f7c;
  font-weight: 600 !important; }

.bpmandkeyinfo span {
  color: #646f7c !important;
  padding: 0 3px !important; }

.displayVersion .rightcolumn .dateOrbtn .date {
  padding-bottom: 5px !important; }

.displayVersion .rightcolumn .dateOrbtn .buttons {
  margin: 0;
  margin-left: auto; }

.albums {
  display: block; }
  .albums + .displayVersion.panel {
    padding-bottom: 30px !important; }
    .albums + .displayVersion.panel .trackVersion {
      grid-template-rows: 0 4rem !important; }
      .albums + .displayVersion.panel .trackVersion:last-child {
        padding-bottom: 0 !important; }
  .albums + .displayVersion.panel.hidden {
    padding-bottom: 0px !important; }
  .albums .trackContainer .buttons svg {
    width: 28px !important;
    height: 24px !important;
    border-radius: 40% !important; }

.downloadAll {
  background: #8b8000;
  padding: 1px 0 0 0 !important;
  border-radius: 4px;
  margin: 10px 4px 0px auto !important;
  letter-spacing: 0.35px !important;
  width: 100%;
  font-size: 0.6rem !important;
  cursor: pointer;
  border: none;
  justify-content: center !important;
  color: white !important;
  max-height: 18px;
  max-width: 90px;
  font-weight: bold !important;
  height: 18px !important;
  line-height: 19px !important; }

.trackContainer {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-areas: ".        .       .          .    .      .        .       " "idNumber play    genre      bpm  key    released buttons " ".        vers    vers       vers vers   vers     vers    " ".        .       .          .    .      .        .       ";
  grid-template-columns: 3rem 18.5rem 5.125rem 2.5rem 3.5rem 6rem 120px;
  grid-template-rows: 0.8615rem 3.5rem auto 0.75rem;
  border-bottom: 1px solid var(--theme-muffled-color);
  position: relative;
  grid-template-areas: ".    .          .          .    .      .        .       " "play play       genre      bpm  key    released buttons " ".    vers   vers   vers    vers      vers          vers      ";
  grid-template-columns: 3rem 18.875rem 5.625rem 2rem 3rem 6rem 120px;
  grid-template-rows: 0.8615rem 3.5rem auto 0.75rem; }
  .trackContainer > * {
    text-align: left;
    font-size: 0.625rem; }
  .trackContainer .index {
    grid-area: idNumber;
    padding-bottom: 0.5rem;
    justify-self: center;
    align-items: center; }
  .trackContainer > *:nth-child(2) {
    grid-area: play; }
    .trackContainer > *:nth-child(2) > :first-child > :first-child {
      margin-top: -1.5rem; }
    .trackContainer > *:nth-child(2) > :nth-child(2) {
      padding-top: 0.375rem; }
  .trackContainer .genre,
  .trackContainer .bpm,
  .trackContainer .key,
  .trackContainer .released {
    opacity: var(--theme-changed-opacity); }
  .trackContainer .genre {
    grid-area: genre;
    margin-top: 0.125rem; }
  .trackContainer .bpm {
    grid-area: bpm;
    margin-top: 0.125rem; }
  .trackContainer .key {
    grid-area: key;
    margin-top: 0.125rem; }
  .trackContainer .released {
    grid-area: released;
    margin-top: 0.125rem; }
  .trackContainer .buttons {
    margin-top: 0.125rem;
    padding-right: 0.25rem;
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
    width: 100%; }
    .trackContainer .buttons > * {
      margin-left: 0.25rem; }
  .trackContainer * {
    height: 1.5rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    font-size: 12px;
    letter-spacing: normal;
    color: var(--theme-main-color);
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    .trackContainer * :first-child {
      height: 100%;
      font-size: 0.75rem;
      font-weight: normal; }
  .trackContainer .bpm,
  .trackContainer .key {
    color: var(--theme-third-color);
    opacity: 1; }
  .trackContainer > :first-child {
    height: auto;
    font-size: 0.75rem;
    font-weight: bold; }
  .trackContainer .playInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
    max-width: 412px; }
    .trackContainer .playInfo > *:first-child {
      width: auto;
      height: 72px;
      min-width: 50px; }
      .trackContainer .playInfo > *:first-child > :first-child {
        height: 3rem; }
    .trackContainer .playInfo .playIcon {
      height: 41px !important;
      width: 40px !important; }
    .trackContainer .playInfo .nameAndArtist.album {
      height: 3.5rem !important;
      max-width: 240px; }
    .trackContainer .playInfo .nameAndArtist {
      height: 4.9rem;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
      .trackContainer .playInfo .nameAndArtist > *:first-child {
        color: var(--theme-main-color);
        font-size: 0.875rem;
        height: 1.6rem;
        line-height: 1.31;
        overflow: hidden;
        width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 0.5rem; }
      .trackContainer .playInfo .nameAndArtist > *:last-child {
        line-height: 1;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        color: var(--theme-third-color);
        opacity: 1; }
      .trackContainer .playInfo .nameAndArtist > *:nth-child(2) {
        line-height: 1;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        color: var(--theme-third-color);
        opacity: 1; }
      .trackContainer .playInfo .nameAndArtist .buttons {
        min-width: 140px; }
  .trackContainer .versions {
    z-index: 1000;
    max-width: 100%;
    height: auto;
    min-height: 0.865rem;
    grid-area: vers;
    gap: 8px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;
    padding-left: 3.1rem;
    padding-bottom: 0.2rem; }
    .trackContainer .versions.longVersion {
      grid-area: vers;
      position: relative; }
      .trackContainer .versions.longVersion > p {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block; }
    .trackContainer .versions .smallVersionContainer button {
      height: 1rem !important;
      margin-right: 0.5rem;
      margin-top: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      height: 23px !important;
      padding: 4px 8px !important;
      border-radius: 4px !important;
      border: 1px solid var(--theme-track-version-color);
      cursor: pointer;
      margin: 0 !important; }
      .trackContainer .versions .smallVersionContainer button:hover {
        transition: 0.25s;
        transform: scale(1.04, 1.04); }
      .trackContainer .versions .smallVersionContainer button:focus {
        outline: none; }
      .trackContainer .versions .smallVersionContainer button span {
        font-size: 0.695rem;
        color: var(--theme-muffled-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 9rem;
        display: inline-block;
        line-height: 0.875rem; }
      .trackContainer .versions .smallVersionContainer button.activeVersion {
        background-color: var(--active-elem);
        border: 1px solid var(--active-elem); }
        .trackContainer .versions .smallVersionContainer button.activeVersion span {
          color: var(--white); }
  .trackContainer .versions {
    position: relative;
    margin-left: 0.8rem;
    padding-left: 2.75rem; }
    @media (max-width: 750px) {
      .trackContainer .versions {
        flex-wrap: nowrap; } }
  .trackContainer .playInfo {
    grid-area: play;
    position: relative;
    height: 100%;
    padding-left: 0;
    position: relative; }
    .trackContainer .playInfo > *:first-child {
      width: 72px !important;
      height: 70px !important;
      margin: 0 10px; }
    .trackContainer .playInfo > :first-child {
      display: flex;
      justify-content: center;
      margin-left: 0.5rem;
      flex-shrink: 0;
      width: 65vw; }
      .trackContainer .playInfo > :first-child > :first-child {
        margin-top: 0.25rem;
        padding-left: 0.25rem; }
    .trackContainer .playInfo:after {
      content: attr(data-rank);
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0rem;
      left: 0.5rem;
      color: var(--white);
      font-weight: 900;
      text-shadow: 1px 1px black; }
  @media (max-width: 720px) {
    .trackContainer {
      width: calc(100vw - 1rem);
      grid-template-areas: ". .        .        .      ." ". play     play     play   ." ". .        vers     vers    ." ". genre    released   bpm  ." ". key  buttons buttons ." ". .        .       .         .";
      grid-template-columns: 0.5rem calc(100vw - 13.5rem) 10rem 1.5rem 0.5rem;
      grid-template-rows: 1rem 3rem 1.5rem 2rem 2.5rem 1rem; }
      .trackContainer .playInfo {
        width: auto;
        max-width: calc(100vw - 2rem); }
        .trackContainer .playInfo > :first-child {
          display: flex;
          justify-content: center;
          margin-left: 0rem; }
          .trackContainer .playInfo > :first-child > :first-child {
            margin-top: 0rem;
            padding-left: 0rem; }
        .trackContainer .playInfo:after {
          left: 0rem; }
      .trackContainer .versions {
        justify-content: flex-end; } }

.disabledButton {
  cursor: initial !important;
  opacity: 0.8; }

.isVersionsVisiable {
  border-top: 1px var(--theme-background-changed-color) solid;
  margin-top: -1px;
  border-bottom: 0 !important;
  background-color: var(--theme-background-changed-color); }
  @media (max-width: 720px) {
    .isVersionsVisiable {
      margin: 0 0.5rem; } }

.smallVersionContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem !important; }

.downloadAll {
  background: #8b8000;
  padding: 1px 0 0 0 !important;
  border-radius: 4px;
  margin: 10px 4px 0px auto !important;
  letter-spacing: 0.35px !important;
  width: 100%;
  font-size: 0.6rem !important;
  cursor: pointer;
  border: none;
  justify-content: center !important;
  color: white !important;
  max-height: 18px;
  max-width: 90px;
  font-weight: bold !important;
  height: 18px !important;
  line-height: 19px !important; }
