/*
    Fonts
*/
.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 6.9375rem;
  opacity: 1;
  display: grid;
  display: grid;
  grid-template-rows: 9rem auto 18rem;
  grid-template-columns: auto 7rem minmax(auto, calc(1432px - 14rem)) 7rem auto;
  overflow: hidden;
  grid-template-rows: 1.5rem auto 1.1875rem;
  background-color: var(--theme-background-changed-color);
  transform: translateY(100%);
  transition: 500ms ease-out; }
  @media (max-width: 1432px) {
    .container {
      grid-template-columns: auto 0rem minmax(auto, calc(1432px - 14rem)) 0rem auto; } }
  @media (max-width: 700px) {
    .container {
      grid-template-columns: 1rem 0rem calc(100vw - 2rem) 0rem 1rem; } }
  @media (max-width: 700px) {
    .container {
      height: auto; } }
  .container.show {
    transform: translateY(0%); }
  .container .content {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 3;
    grid-column-end: 4;
    padding-left: 2.5rem;
    display: grid;
    grid-template-columns: 4rem 1.5rem 504px 112px 1.9rem 5rem 1rem 86px 1.5rem;
    grid-template-rows: auto 1.5rem;
    grid-template-areas: "a . b c . . j . . i k m " "a . h h . f . e e e . . "; }
    @media (max-width: 960px) {
      .container .content {
        padding-left: 2rem;
        grid-template-columns: 4rem 1rem 220px auto 112px 1.9rem 5rem 1rem 86px 18px;
        grid-template-rows: auto 1.5rem;
        grid-template-areas: "a . b . c . j . i k . m " "a . h h h . f . e e e . "; } }
    @media (max-width: 700px) {
      .container .content {
        padding-left: 0.5rem;
        grid-template-columns: 4rem 1.5rem calc(100vw - 10rem) 1.5rem;
        grid-template-rows: auto 0.5rem auto auto 0.5rem auto 0.5rem 1.5rem;
        grid-template-areas: "b b b m" ". . . ." "a . c c" "a . e e" ". . . ." "j j j j " ". . . ." "f . h h"; }
        .container .content .rating,
        .container .content .volume {
          display: flex;
          justify-content: center;
          align-items: center; } }
    .container .content .playButton {
      padding: 0;
      grid-area: a;
      width: 4rem; }
    .container .content .closeButton {
      display: flex;
      justify-content: flex-end;
      grid-area: m;
      margin-top: -3rem; }
      @media (max-width: 700px) {
        .container .content .closeButton {
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 0rem;
          margin-right: 1rem;
          display: flex;
          padding: 0;
          flex-direction: row;
          justify-content: flex-start; } }
    .container .content .trackInfo {
      width: 100%;
      position: relative;
      grid-area: b;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: var(--theme-main-color); }
      .container .content .trackInfo > span:first-child {
        display: block;
        white-space: nowrap;
        width: calc(100% - 1rem);
        font-size: 1rem;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 1rem); }
      .container .content .trackInfo > span:nth-child(2) {
        max-width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.625rem;
        line-height: 2.1;
        white-space: nowrap; }
    .container .content .timeline {
      grid-area: h;
      cursor: pointer; }
    .container .content .timeline,
    .container .content .volume {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .container .content .timeline > *,
      .container .content .volume > * {
        width: 1.5rem;
        font-size: 0.5rem;
        color: var(--theme-main-color);
        height: auto; }
      .container .content .timeline > *:nth-child(2),
      .container .content .volume > *:nth-child(2) {
        width: calc(100% - 4rem);
        margin: 0 0.5rem; }
    .container .content .fullTrackTime {
      color: var(--active-elem);
      font-weight: bold;
      font-size: 0.6rem;
      padding-bottom: 0.1rem; }
    .container .content .version {
      cursor: pointer;
      grid-area: c;
      height: 1.5rem;
      color: var(--theme-muffled-color);
      border: 1px solid var(--theme-muffled-color);
      border-radius: 2px;
      padding-left: 0.5rem;
      font-size: 0.625rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      transition: 300ms ease-out;
      will-change: color, background-color; }
      .container .content .version.active {
        color: var(--white);
        background-color: var(--active-elem); }
    .container .content .rating {
      grid-area: j; }
    .container .content .download {
      grid-area: i; }
    .container .content .add {
      grid-area: k; }
    .container .content .volume {
      grid-area: e; }
    .container .content .buttons, .container .content .previewIcon {
      grid-area: f; }
  .container .buttons {
    display: flex; }
    .container .buttons .container .stars {
      height: 1.5rem;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.75;
      font-size: 12px; }
    .container .buttons .stars a {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: normal;
      height: 100%; }
  .container .previewIcon {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 5rem;
    border: 1px solid var(--theme-muffled-color); }
    .container .previewIcon:before {
      content: "Preview";
      color: var(--theme-muffled-color);
      font-size: 0.75rem; }

.chooseVersionContainer {
  display: block;
  overflow: visible;
  height: auto;
  width: 320px;
  z-index: 10;
  bottom: 6.9375rem;
  left: 45vw;
  background-color: var(--theme-background-color);
  opacity: 0;
  pointer-events: none;
  transform: translateY(4rem);
  transition: opacity 200ms ease-in-out, transform 50ms 150ms; }
  @media (max-width: 700px) {
    .chooseVersionContainer {
      left: calc(100vw - 330px);
      bottom: 13.6875rem; } }
  .chooseVersionContainer.active {
    transform: translateY(0%);
    transition: transform 300ms ease-in-out, opacity 100ms 200ms;
    opacity: 1;
    pointer-events: all; }
  .chooseVersionContainer .header {
    border-bottom: 1px solid var(--theme-muffled-color);
    display: grid;
    grid-template-rows: 9rem auto 18rem;
    grid-template-columns: auto 7rem minmax(auto, calc(1432px - 14rem)) 7rem auto;
    overflow: hidden; }
    @media (max-width: 1432px) {
      .chooseVersionContainer .header {
        grid-template-columns: auto 0rem minmax(auto, calc(1432px - 14rem)) 0rem auto; } }
    @media (max-width: 700px) {
      .chooseVersionContainer .header {
        grid-template-columns: 1rem 0rem calc(100vw - 2rem) 0rem 1rem; } }
    .chooseVersionContainer .header button {
      justify-self: end;
      margin-right: 0.5rem; }

.wavePlayerContainer {
  width: 100%; }
