@mixin mainContainerForHome() {
    .mainContainer_forHome {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        width: 592px;
        max-width: 100vw;

        @media (max-width: 67rem) {
            justify-content: center;
        }

        * {
            line-height: 1;
        }

        &:first-child {
            padding-right: rem(20px);
        }

        &:last-child {
            padding-left: rem(20px);
        }

        .tracksColumnContainer {
            overflow: visible;
            width: 100%;
            max-width: 100vw;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-startTrack;

            .trackContainer {
                * {
                    font-size: 11px;
                }

                max-width: 100vw;
                width: 100%;
                display: grid;
                grid-template-areas: ".        .       .          .    .      .         " "idNumber play    genre      bpm  key    released " ".        vers    vers       vers vers    vers ";

                grid-template-columns: 1.5rem calc(550px - 7.5rem - 184px) 5rem 2.25rem 3.5rem 6rem !important;
                grid-template-rows: 1rem 3rem auto;

                & > *:first-child {
                    display: flex !important;
                    padding-bottom: 0 !important;
                }

                @media (max-width: 600px) {
                    grid-template-areas: ".       .    .    . " "play    play play play" "genre   bpm  key  released" "vers    vers     vers           vers" ".       .    .    . " !important;
                    grid-template-columns: calc(100vw - 12rem) 2rem 3rem !important;
                    grid-template-rows: 1.5rem 4rem 2rem 2rem 1rem !important;

                    & > *:first-child {
                        display: none !important;
                    }
                }

                & > *:nth-child(2) {
                    * {
                        font-size: 12px !important;
                        overflow: hidden;
                    }

                    @media (max-width: 600px) {
                        width: 90vw;
                    }

                    .nameAndArtist {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .versions {
                    width: 100%;
                    height: 2.75rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    grid-area: vers !important;
                    padding-left: 3.25rem !important;
                    @media (max-width: 600px) {
                        padding-left: 0 !important;
                    }

                    @media (max-width: 600px) {
                        grid-area: vers !important;
                    }
                }
            }

            .tracksColumnHeader {
                @media (max-width: 600px) {
                    display: none !important;
                }
            }
        }
    }
}
