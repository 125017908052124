.spin-sets-wrap{
    background-image: url('../../../../assets/images/spins-bg.jpg');
    background-repeat:no-repeat;
    background-size: cover;
    height: 600px;
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 30px 30px;

}

.spin-sets-wrap:after {
    position: absolute;
    background-color: rgba(0,0,0,0.8);
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    right: 0;
}

.spin-sets-wrap .spin-sets-content .sec-title{
    color: #fff;
    margin: 20px 0px;
}

.spin-sets-wrap .spin-sets-content .sec-title.main-title{
    font-size: 34px;
    font-weight: 600;
    color: #f9870b;
    margin-bottom: 20px;
}
.spin-sets-wrap .sub-head-title{
    font-size: 28px;
    margin-bottom: 20px;
}
.spin-sets-wrap .hip-hop-title {
    font-size: 22px;
    font-weight: 500;
}

.spin-sets-wrap  .spin-sets-content .download-title{
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.spin-sets-wrap  .btn-wrap .btn:hover {
    background-color: #b32378;
}

.btn-wrap .btn{
    border: 1px solid #b32378;
    background: transparent;
    padding: 11px 50px;
    border-radius: 10px;
    color: #fff;
    font-size: 19px;
    font-weight: 600;
    cursor: pointer;
}
.btn-wrap .btn:first-child{
    margin-right: 20px;
}

.spin-sets-wrap .main-title span{color: #fff;}


@media (max-width:576px){
    .spin-sets-wrap br{
        display: none;
    }
    div.spin-sets-wrap  .spin-sets-content .download-title{
        font-size: 20px;
    }
    div.spin-sets-wrap .hip-hop-title{
        font-size: 18px;
    }
    div.spin-sets-wrap .spin-sets-content .sec-title.main-title{
        font-size: 26px;
    }
    div.spin-sets-wrap{
        height: 650px;
        padding: 20px 16px;
    }
    
}

.downloadBtnWrap{
    margin: -30px 0px 0 auto ! important;
}
.downloadBtn {
    cursor: pointer;
    z-index: 9999;
    gap: 0 10px;
    width: auto;
    padding: 0 !important;
    font-size: 0.75rem !important;
    border-radius: 4px !important;
    background: none !important;
    color: #f1c2889c !important;
    border: none;
    position: relative;
}
.downloadBtn svg {
    width: 18px;
    cursor: pointer;
    line-height: normal;
    display: inline-block;
    height: 34px;
}
/* .downloadBtn:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 1px;
    background: #f1c2889c;
} */
