@mixin discoBall() {
    background-image: linear-gradient(
            var(--theme-background-transparent-color),
            var(--theme-second-color) 650px
        ),
        url(assets/images/darkAuthBack.jpg);
    background-color: var(--theme-background-color);

    &.light {
        background-image: linear-gradient(
                var(--theme-background-highttransparent-color),
                var(--theme-second-color) 850px
            ),
            url(assets/images/lightAuthBack.jpg);
    }
}

@mixin dj() {
    background-image: linear-gradient(
            to bottom,
            var(--theme-background-transparent-color),
            var(--theme-background-color) 50%
        ),
        url(assets/images/darkLabelsBack.png);
    background-repeat: no-repeat;
    background-size: 100% auto;

    &.light {
        background-image: linear-gradient(
                to bottom,
                var(--theme-background-highttransparent-color),
                var(--theme-background-color) 50%
            ),
            url(assets/images/lightLabelsBack.jpg);
    }
}
