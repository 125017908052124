/*
    Fonts
*/
@keyframes PlaceholderAnimation {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

h3 {
  color: var(--theme-main-color);
  font-weight: 600; }

.loader {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 6rem;
  width: 100%;
  min-width: 6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  background-color: var(--theme-loader-background-color);
  opacity: 0.8; }
  .loader.autoWidth {
    width: 100%; }

.noTracks {
  margin: 7rem auto;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center; }
  .noTracks.autoWidth {
    width: 100%; }

.mainContainer {
  margin-bottom: 2.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  @media (max-width: 67rem) {
    .mainContainer {
      justify-content: center; } }
  .mainContainer * {
    line-height: 1; }
  .mainContainer .tracksColumnContainer {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .mainContainer .tracksColumnContainer .tracksColumnHeader {
      border-bottom: 1px solid var(--theme-muffled-color);
      width: 100%;
      display: grid;
      grid-template-columns: 5.25rem 25.25rem 5rem 6rem 2rem 2.5rem;
      grid-template-rows: 1rem 4rem 1rem; }
      @media (max-width: 1100px) {
        .mainContainer .tracksColumnContainer .tracksColumnHeader {
          grid-template-columns: 11.25rem calc(100% - 26.75rem) 5rem 6rem 2rem 2.5rem; } }
      @media (max-width: 600px) {
        .mainContainer .tracksColumnContainer .tracksColumnHeader {
          display: none !important; } }
    .mainContainer .tracksColumnContainer .trackContainer {
      width: 100%;
      height: 7.5rem;
      display: grid;
      grid-template-areas: ".           .          .     .        .              .        " "cover       name       genre released downloadTrack  addToList" ".           .          .     .        .              .        ";
      grid-template-columns: 11.25rem 19.25rem 5rem 6rem 2rem 2.5rem;
      grid-template-rows: 1rem 6rem 1rem; }
      @media (max-width: 1100px) {
        .mainContainer .tracksColumnContainer .trackContainer {
          grid-template-columns: 11.25rem calc(100% - 26.75rem) 5rem 6rem 2rem 2.5rem; } }
      @media (max-width: 600px) {
        .mainContainer .tracksColumnContainer .trackContainer {
          height: auto;
          width: calc(100vw - 2rem);
          padding-top: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid var(--theme-muffled-color);
          grid-template-areas: "cover      cover        cover          cover   " "name       name         name           name" "genre      released     downloadTrack  addToList";
          grid-template-columns: 8rem 8rem 2.5rem 5.5rem;
          grid-template-rows: auto 6rem 2rem; }
          .mainContainer .tracksColumnContainer .trackContainer > * {
            border-bottom: 0 !important;
            padding: 0 !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: auto !important; } }
      .mainContainer .tracksColumnContainer .trackContainer > * {
        text-align: left;
        font-size: 0.625rem;
        grid-row-start: 2;
        grid-row-end: 3;
        border-bottom: 1px solid var(--theme-muffled-color); }
      .mainContainer .tracksColumnContainer .trackContainer > *:first-child {
        grid-area: cover;
        padding-top: 1rem;
        border-bottom: 0; }
      .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(2) {
        grid-area: name;
        padding-top: 0.5rem;
        height: 7rem; }
      .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(3) {
        grid-area: genre;
        height: 7rem;
        padding-top: 2.5rem;
        font-weight: 500;
        font-size: 10px;
        color: var(--theme-main-color); }
        .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(3) p {
          font-weight: 500;
          font-size: 10px; }
      .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(4) {
        grid-area: released;
        padding-top: 2.5rem;
        font-weight: 500;
        font-size: 10px;
        color: var(--theme-main-color);
        height: 7rem; }
      .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(5) {
        grid-area: downloadTrack;
        height: 7rem;
        padding-top: 2rem; }
      .mainContainer .tracksColumnContainer .trackContainer > *:nth-child(6) {
        grid-area: addToList;
        height: 7rem;
        padding-top: 2rem; }
      .mainContainer .tracksColumnContainer .trackContainer .playInfo {
        display: block;
        font-weight: 700;
        font-size: 14px;
        color: var(--theme-main-color); }
        .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist {
          margin-right: 16px;
          overflow: hidden;
          text-overflow: ellipsis; }
          .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist p {
            max-width: calc(100vw - 2rem);
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.875rem; }
          .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist > *:first-child {
            padding: 0.75rem 0 0 0;
            font-weight: bold;
            font-size: 0.875rem; }
          .mainContainer .tracksColumnContainer .trackContainer .playInfo .nameAndArtist > *:last-child {
            font-weight: 500;
            font-size: 10px; }
    .mainContainer .tracksColumnContainer .tracksColumnHeader {
      height: 2.9375rem;
      width: 100%;
      grid-template-rows: 1rem 1.9375rem; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader * {
        font-size: 0.625rem;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: var(--active-elem);
        text-align: left;
        grid-row-start: 1;
        grid-row-end: 2; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader > *:last-child {
        padding-left: 3px; }
      .mainContainer .tracksColumnContainer .tracksColumnHeader > *:nth-child(2) {
        padding-left: 5.625rem; }

.mainContainerShort .tracksColumnContainer .tracksColumnHeader {
  margin-bottom: -1.75rem;
  width: 100%;
  display: grid;
  grid-template-areas: ".           .          .     .        " "cover       name       genre released " ".           .          .     .        ";
  grid-template-columns: 11.25rem 16.75rem 5rem 5rem;
  grid-template-rows: 1rem 4rem 1rem; }

@media (max-width: 1220px) {
  .mainContainerShort .tracksColumnContainer .hideHeaders {
    display: none !important; } }

.mainContainerShort .tracksColumnContainer .trackContainer {
  width: 100%;
  display: grid;
  grid-template-areas: "cover       name       genre released ";
  grid-template-columns: 11.25rem 16.75rem 5rem 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .mainContainerShort .tracksColumnContainer .trackContainer > * {
    border-bottom: 1px solid var(--theme-muffled-color);
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto !important; }
  @media (max-width: 700px) {
    .mainContainerShort .tracksColumnContainer .trackContainer {
      display: grid;
      height: auto;
      width: calc(100vw - 2rem);
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--theme-muffled-color);
      grid-template-areas: "cover      cover        cover          cover   " "name       name         name           name" "genre      genre        released       released";
      grid-template-columns: 8rem 6.25rem 2.5rem 5.5rem;
      grid-template-rows: auto 6rem 2rem; }
      .mainContainerShort .tracksColumnContainer .trackContainer > * {
        border-bottom: 0 !important;
        padding: 0 !important;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: auto !important; } }
  .mainContainerShort .tracksColumnContainer .trackContainer > * {
    text-align: left;
    font-size: 0.625rem;
    grid-row-start: 2;
    grid-row-end: 3; }
  .mainContainerShort .tracksColumnContainer .trackContainer > *:first-child {
    width: 100%;
    border-bottom: 0 !important;
    grid-area: cover; }
  .mainContainerShort .tracksColumnContainer .trackContainer > *:nth-child(2) {
    padding-top: 0.5rem;
    grid-area: name;
    height: 7rem; }
  .mainContainerShort .tracksColumnContainer .trackContainer > *:nth-child(3) {
    grid-area: genre;
    height: 7rem;
    padding-top: 2.5rem;
    font-weight: 500;
    font-size: 10px;
    color: var(--theme-main-color); }
    .mainContainerShort .tracksColumnContainer .trackContainer > *:nth-child(3) p {
      font-weight: 500;
      font-size: 10px; }
  .mainContainerShort .tracksColumnContainer .trackContainer > *:nth-child(4) {
    padding-top: 2.5rem;
    font-weight: 500;
    font-size: 10px;
    color: var(--theme-main-color);
    grid-area: released;
    height: 7rem; }
  .mainContainerShort .tracksColumnContainer .trackContainer .playInfo {
    display: block;
    font-weight: 700;
    font-size: 14px;
    color: var(--theme-main-color); }
    .mainContainerShort .tracksColumnContainer .trackContainer .playInfo .nameAndArtist {
      margin-right: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .mainContainerShort .tracksColumnContainer .trackContainer .playInfo .nameAndArtist p {
        max-width: calc(100vw - 2rem);
        overflow: hidden;
        text-overflow: ellipsis; }
      .mainContainerShort .tracksColumnContainer .trackContainer .playInfo .nameAndArtist > *:first-child {
        padding: 0.75rem 0 0 0;
        font-weight: bold;
        font-size: 0.875rem; }
      .mainContainerShort .tracksColumnContainer .trackContainer .playInfo .nameAndArtist > *:last-child {
        font-weight: 500;
        font-size: 10px; }

.mainContainerShort .tracksColumnContainer .tracksColumnHeader {
  height: 2.9375rem;
  width: 100%;
  grid-template-rows: 1rem 1.9375rem; }
  @media (max-width: 750px) {
    .mainContainerShort .tracksColumnContainer .tracksColumnHeader {
      display: none; } }
  .mainContainerShort .tracksColumnContainer .tracksColumnHeader * {
    font-size: 0.625rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: var(--theme-muffled-color);
    grid-row-start: 1;
    grid-row-end: 2; }
  .mainContainerShort .tracksColumnContainer .tracksColumnHeader > *:last-child {
    padding-left: 3px; }
