.container {
    height: 2rem;
    width: 100%;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .button {
        height: 2rem;
        width: 2rem;
    }
}
