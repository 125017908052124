/*
    Fonts
*/
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12.5rem;
  background-image: url(assets/images/splashBack1.png), url(assets/images/splashBack2.png), url(assets/images/splashBack2.png), url(assets/images/splashBack3.png), url(assets/images/splashBack2.png);
  background-position: -300px 300px, -188px -99px, calc(50vw - 350px) 400px, calc(100vw - 400px) 80px, calc(50vw - 450px) calc(100% + 550px);
  background-size: 600px 400px, 690px 690px, 700px 700px, 600px 600px, 900px 900px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-blend-mode: hard-light; }
  .container .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .container .info > h2 {
      margin-bottom: 1.25rem;
      max-width: 80vw;
      font-size: 6rem;
      letter-spacing: 0px;
      line-height: 1;
      text-align: center;
      font-weight: 800;
      background-image: url(assets/images/splashTextMask.png);
      background-size: 100% 250%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: rgba(250, 250, 250, 0.4); }
      @media (max-width: 720px) {
        .container .info > h2 {
          margin-top: 6rem;
          font-size: 3rem; } }
    .container .info .subText {
      margin-bottom: 4.5rem;
      color: #e60039;
      line-height: 1.34;
      font-stretch: condensed;
      letter-spacing: 0.4px;
      font-size: 2.5rem; }
      @media (max-width: 720px) {
        .container .info .subText {
          margin-top: 3.5rem;
          margin-bottom: 2rem;
          font-size: 1.5rem;
          letter-spacing: -0.5px; } }
    .container .info .enterButton {
      margin-bottom: 1rem;
      height: 45px;
      font-size: 1.125rem; }
  .container .youtube {
    margin-bottom: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }
    .container .youtube > div {
      margin: auto;
      width: 1100px;
      height: 560px;
      max-width: 100vw;
      max-height: 56vw; }
  .container .featuresContainer {
    margin: 1rem;
    margin-bottom: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 720px) {
      .container .featuresContainer {
        margin-top: 0; } }
    .container .featuresContainer .feature {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 0 2rem;
      margin-top: 5rem;
      margin-bottom: 10rem;
      max-width: 350px;
      position: relative;
      text-align: center;
      justify-content: center; }
      @media (max-width: 720px) {
        .container .featuresContainer .feature {
          margin-top: 3rem;
          margin-bottom: 4rem; } }
      .container .featuresContainer .feature > img {
        height: 15rem !important;
        max-height: 50vw !important;
        max-width: 70vw; }
      .container .featuresContainer .feature > div {
        position: absolute;
        top: 16rem; }
        @media (max-width: 720px) {
          .container .featuresContainer .feature > div {
            position: initial;
            top: initial; } }
        .container .featuresContainer .feature > div > h3 {
          color: #e60039;
          font-weight: bold;
          font-size: 1.5rem; }
          @media (max-width: 720px) {
            .container .featuresContainer .feature > div > h3 {
              margin: 0.75rem 0; } }
        .container .featuresContainer .feature > div > span {
          display: block;
          max-width: 15.625rem;
          font-size: 0.725rem;
          font-weight: 600;
          color: var(--theme-main-color);
          line-height: 1.75; }
