/*
    Fonts
*/
.mainContainer {
  position: relative;
  height: 1.4rem;
  width: 10rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: var(--theme-main-color);
  flex-shrink: 0; }
  .mainContainer * {
    font-size: 0.75rem;
    font-weight: bold;
    white-space: nowrap; }
  .mainContainer .showButton {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0; }
    .mainContainer .showButton * {
      color: var(--theme-main-color);
      font-size: 0.75rem;
      font-weight: bold;
      white-space: nowrap; }
    .mainContainer .showButton > div {
      padding-left: 0.5rem; }
    .mainContainer .showButton .arrow {
      transform: rotateZ(0);
      transition: 300ms ease-in-out; }
      .mainContainer .showButton .arrow.rotated {
        transform: rotateZ(-180deg); }
  .mainContainer .list {
    background-color: transparent;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: 0;
    color: var(--theme-main-color);
    font-family: var(--montserrat), sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 10;
    transition: 300ms ease-in-out;
    will-change: height; }
    .mainContainer .list button {
      padding: 0 !important;
      padding-left: 0.375rem !important;
      cursor: pointer;
      max-height: 100%;
      overflow: hidden;
      background-color: var(--theme-background-changed-color);
      font-family: var(--montserrat), sans-serif;
      font-weight: bold;
      outline: none;
      color: var(--theme-main-color);
      width: 100%;
      height: 2rem;
      outline: none;
      text-align: left;
      border: 0px solid var(--theme-muffled-color); }
      .mainContainer .list button.active {
        color: var(--white);
        background-color: var(--active-elem); }
    .mainContainer .list.show {
      height: 3.5rem; }
      .mainContainer .list.show button {
        border: 1px solid var(--theme-background-light-color); }
