@import url(./montserrat.css);
@import url(./azonix.css);
/*
    Fonts
*/
html {
  box-sizing: border-box; }

html,
body,
.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-size: 16px;
  font-family: var(--montserrat), sans-serif; }

:root {
  --theme-main-color: rgba(grey, 0.8);
  --theme-white-color: rgba(255,255,255,1);
  --theme-second-color: rgba(grey, 0.3);
  --theme-muffled-color: #aeb0bd;
  --theme-track-version-color: #6b7c8f;
  --theme-changed-color: #aeb0bd;
  --theme-background-light-color: rgba(grey, 0.1);
  --theme-background-transparent-color: rgba(255, 255, 255, 0.5);
  --theme-background-color: #fff;
  --theme-background-changed-color: #f0f1f2;
  --montserrat: "Montserrat";
  --font-family-base: var(--montserrat), sans-serif;
  --font-size-base: 16px;
  --rem-baseline: var(--font-size-base);
  --rem-fallback: true;
  --header-font: "Quicksand";
  --white: #fff;
  --black: #0e1521;
  --active-elem: #bb1d7e;
  --track-active-elem: #631942;
  --track-new-version: #694020;
  --track-default-elem: #1f4778;
  --track-DIY-Versions-elem: #283d16;
  --new-elem: #e28820;
  --grey: #aeb0bd;
  --light-grey: rgba(255, 255, 255, 0.3);
  --dark-grey: #213044;
  --background-color: #0d1c2d;
  --background-transparent-color: rgba(13, 28, 45, 0.3);
  --error-color: rgba(255, 0, 0, 0.5); }

body {
  overflow: hidden;
  background-color: var(--dark-grey); }

iframe {
  max-height: 100vh;
  max-width: 100vw; }

*,
*:before,
*:after {
  box-sizing: inherit; }

a {
  text-decoration: none; }

* {
  font-family: var(--montserrat), sans-serif; }

h1 {
  margin: 0;
  font-size: 4rem;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--theme-main-color); }

h2 {
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--theme-main-color);
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

button::-moz-focus-inner {
  border: none; }
