@import "rem";

/*
    Fonts
*/

$montserrat: "Montserrat";
$font-family-base: var(--montserrat), sans-serif;

$font-size-base: 16px;
$rem-baseline: 16px;
$rem-fallback: true;

$header-font: "Quicksand";
