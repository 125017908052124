/*
    Fonts
*/
.container {
  margin-top: 0.25rem;
  height: auto;
  position: relative;
  cursor: pointer;
  color: var(--theme-muffled-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 2px; }
  .container .center {
    display: flex;
    flex-direction: column; }
  .container > *:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .container > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .container p {
    color: var(--theme-main-color);
    opacity: 0.5;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase; }

.ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0.625rem; }
  .ButtonContainer button {
    width: 100%; }

.mediaList {
  max-height: 400px;
  margin: 0.75rem 0.5rem 0 1rem; }
  .mediaList::-webkit-scrollbar {
    width: 0; }
  .mediaList div {
    display: flex;
    justify-content: space-between; }
    .mediaList div div {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.25rem; }
      .mediaList div div p {
        margin: 0;
        font-size: 10px;
        font-weight: 500;
        width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white; }
      .mediaList div div div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 2px; }
        .mediaList div div div .version {
          margin-right: 0.25rem;
          font-size: 0.625rem;
          font-weight: 500;
          color: var(--theme-muffled-color);
          background-color: transparent;
          height: auto;
          padding: 0 3px;
          border-radius: 2px;
          border: 1px solid var(--theme-muffled-color); }
