/*
    Fonts
*/
.slick__slider {
  width: 100%;
  overflow: hidden;
  border-radius: 8px !important; }
  .slick__slider .slickArrowPrev {
    position: absolute;
    top: 131px;
    left: 10px;
    height: 40px;
    z-index: 1;
    text-align: center;
    padding: 9px;
    border-radius: 50px;
    opacity: 0.6; }
  .slick__slider .slickArrowPrev:hover, .slick__slider .slickArrowNext:hover {
    opacity: 1;
    cursor: pointer; }
  .slick__slider .slickArrowNext {
    position: absolute;
    top: 131px;
    right: 10px;
    height: 40px;
    z-index: 1;
    text-align: center;
    padding: 9px;
    border-radius: 50px;
    opacity: 0.6; }
  .slick__slider div {
    max-height: 300px; }
    .slick__slider div a {
      outline: none; }
    .slick__slider div img:hover {
      cursor: pointer; }
    .slick__slider div img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 8px !important; }
    .slick__slider div .slideAiBG {
      background: url("../../../assets/images/banner/AI_bg.jpg") center/cover no-repeat;
      padding: 32px 15px 60px; }
      .slick__slider div .slideAiBG .logoWrap img {
        margin: 0 auto 35px;
        max-width: 80px; }
      .slick__slider div .slideAiBG .slideTitle h2 {
        font-size: 19px;
        line-height: 1.4em; }
      .slick__slider div .slideAiBG .slideTitle h4 {
        color: var(--white);
        font-size: 12px; }
      .slick__slider div .slideAiBG .slideTitle h3 {
        font-size: 12px;
        font-weight: 700; }
    .slick__slider div .slideBG {
      background: url("../../../assets/images/banner/222bgMain.jpg") center/cover no-repeat;
      padding: 80px 40px; }
      .slick__slider div .slideBG .logoWrap {
        text-align: center; }
        .slick__slider div .slideBG .logoWrap img {
          margin: 0 auto; }
      .slick__slider div .slideBG .slideTitle .titleText {
        font-size: 48px;
        font-weight: 700;
        margin: 40px 0; }
      .slick__slider div .slideBG .benefitListWrap .benefitList {
        margin: 0;
        padding: 0; }
        .slick__slider div .slideBG .benefitListWrap .benefitList .listItem {
          list-style: none;
          display: flex;
          position: relative; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem:not(:last-child) {
            margin: 0 0 25px; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap {
            background: #9755ff;
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0 20px 0 0; }
            .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap svg path {
              fill: #fff; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem span {
            color: #fff;
            font-size: 30px;
            font-weight: 600;
            line-height: 1;
            display: flex;
            align-items: center;
            flex: 1; }
      .slick__slider div .slideBG .downloadWrap .banner {
        display: block;
        height: auto !important;
        background: none; }
        .slick__slider div .slideBG .downloadWrap .banner .banner__footer {
          height: auto !important;
          padding: 0 !important;
          background: none !important;
          backdrop-filter: none !important;
          margin: 50px 0 60px 0; }
          .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__title {
            font-size: 36px;
            display: inline-block !important;
            line-height: normal !important; }
          .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons {
            margin-top: 25px !important;
            justify-content: center;
            gap: 35px; }
            .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button {
              width: 90px;
              height: 80px; }
              .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button .button__icon {
                width: 30px;
                height: 30px; }
              .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button .button__text {
                font-size: 16px;
                margin: 5px 0 0 0; }
      .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap {
        text-align: center; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .downloadTitle {
          text-align: center;
          font-size: 36px;
          display: inline-block !important;
          line-height: normal !important;
          margin: 0 0 25px;
          color: #fff; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform a {
          display: flex;
          justify-content: center; }
          .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform a img {
            max-width: fit-content; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform:not(:last-child) {
          margin: 0 0 25px; }
  .slick__slider img {
    max-width: 100%; }

.banner {
  width: 100%;
  height: 30.625rem;
  display: flex;
  flex-flow: column;
  position: relative;
  margin: 0 auto; }
  .banner .banner__bg {
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    z-index: 0; }
  .banner .banner__content {
    flex: 1;
    z-index: 1;
    padding: 1.875rem 0rem 0rem 1.25rem; }
    .banner .banner__content .content__header {
      display: flex;
      align-items: center;
      margin-bottom: 0.9375rem; }
      .banner .banner__content .content__header .header__logoContainer {
        width: 1.3125rem;
        height: 1.3125rem;
        background-color: var(--white);
        box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        border-radius: 0.4375rem;
        padding: 0.1875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.375rem; }
        .banner .banner__content .content__header .header__logoContainer .logoContainer__logo {
          width: 100%;
          height: 100%;
          background-image: url("assets/images/spinsLogoNew.svg");
          background-size: 100% 100%; }
      .banner .banner__content .content__header .header__text {
        font-family: var(--montserrat), sans-serif;
        font-weight: 800;
        font-size: 1.625rem;
        line-height: 1.5rem;
        color: var(--white); }
    .banner .banner__content .content__folderIcon {
      width: 3.5rem;
      height: 4.0625rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% 100%;
      background-image: url("assets/images/spinsAppFolderIcon.svg");
      margin-bottom: 1.25rem; }
    .banner .banner__content .content__title {
      font-family: var(--montserrat), sans-serif;
      font-weight: 800;
      font-size: 1.125rem;
      line-height: 1.3125rem;
      color: var(--white); }
    .banner .banner__content .content__featuresList {
      margin-top: 0.75rem; }
      .banner .banner__content .content__featuresList .featuresList__item {
        display: flex;
        align-items: center; }
        .banner .banner__content .content__featuresList .featuresList__item .item__check {
          width: 0.75rem;
          height: 0.75rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ff056e;
          border-radius: 1.875rem;
          margin-right: 0.25rem; }
          .banner .banner__content .content__featuresList .featuresList__item .item__check .check__icon {
            width: 0.625rem;
            height: 0.625rem;
            background-image: url("assets/images/filledCheckbox.svg");
            background-size: 100% 100%; }
            .banner .banner__content .content__featuresList .featuresList__item .item__check .check__icon path {
              color: var(--white); }
        .banner .banner__content .content__featuresList .featuresList__item .item__text {
          font-family: var(--montserrat), sans-serif;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color: var(--white); }
      .banner .banner__content .content__featuresList .featuresList__item:not(:last-of-type) {
        margin-bottom: 0.625rem; }
  .banner .banner__footer {
    width: 100%;
    height: 7.4375rem;
    background: rgba(23, 23, 32, 0.6);
    backdrop-filter: blur(120px);
    text-align: center;
    padding: 1.0625rem 0.75rem 0rem; }
    .banner .banner__footer .footer__title {
      font-family: var(--montserrat), sans-serif;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: var(--white); }
    .banner .banner__footer .footer__buttons {
      margin-top: 0.75rem;
      display: flex;
      flex-flow: row;
      justify-content: space-between; }
      .banner .banner__footer .footer__buttons .buttons__button {
        width: 3.4375rem;
        height: 3.125rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
        background: var(--white);
        border-radius: 0.75rem;
        text-decoration: none; }
        .banner .banner__footer .footer__buttons .buttons__button .button__text {
          font-family: var(--montserrat), sans-serif;
          font-weight: 400;
          font-size: 0.625rem;
          line-height: 1.125rem;
          color: #171720; }
        .banner .banner__footer .footer__buttons .buttons__button .button__icon {
          width: 1.25rem;
          height: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-size: 100% 100%; }
        .banner .banner__footer .footer__buttons .buttons__button .button__icon.mac {
          background-image: url("assets/images/appleIcon.svg"); }
        .banner .banner__footer .footer__buttons .buttons__button .button__icon.win {
          background-image: url("assets/images/winIcon.svg"); }
      .banner .banner__footer .footer__buttons .buttons__button:hover {
        opacity: 0.8; }
      .banner .banner__footer .footer__buttons .buttons__button.mac {
        background-color: var(--white);
        border: 0.0625rem solid var(--white); }
      .banner .banner__footer .footer__buttons .buttons__button.win {
        background-color: #3988ff;
        border: 0.0625rem solid #3988ff; }

@media (max-width: 1000px) {
  .banner {
    display: none; } }

@media (max-width: 629px) {
  .slick__slider {
    width: 100%;
    overflow: hidden; }
    .slick__slider div .slideBG {
      background: url("../../../assets/images/banner/222bgMain.jpg") center/cover no-repeat;
      padding: 20px 15px; }
      .slick__slider div .slideBG .logoWrap {
        text-align: center; }
        .slick__slider div .slideBG .logoWrap img {
          margin: 0 auto;
          max-width: 80px; }
      .slick__slider div .slideBG .slideTitle .titleText {
        font-size: 20.5px;
        margin: 20px 0; }
      .slick__slider div .slideBG .benefitListWrap .benefitList {
        margin: 0;
        padding: 0; }
        .slick__slider div .slideBG .benefitListWrap .benefitList .listItem {
          list-style: none;
          display: flex;
          position: relative; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem:not(:last-child) {
            margin: 0 0 10px; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap {
            width: 22px;
            height: 22px;
            margin: 0 8px 0 0; }
            .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap svg {
              width: 10px !important; }
              .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap svg path {
                fill: #fff; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem span {
            font-size: 13px;
            width: calc(100% - 30px); }
      .slick__slider div .slideBG .downloadWrap .banner {
        display: block;
        height: auto !important;
        background: none; }
        .slick__slider div .slideBG .downloadWrap .banner .banner__footer {
          margin: 35px 0 30px 0; }
          .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__title {
            font-size: 20px; }
          .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons {
            margin-top: 15px !important;
            justify-content: center;
            gap: 15px; }
            .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button {
              width: 60px;
              height: 56px; }
              .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button .button__icon {
                width: 20px;
                height: 20px; }
              .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button .button__text {
                font-size: 10px;
                margin: 0 0 0 0; }
      .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap {
        text-align: center; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .downloadTitle {
          font-size: 20px;
          margin: 0 0 15px; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform a {
          display: flex;
          justify-content: center; }
          .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform a img {
            max-width: 180px; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform:not(:last-child) {
          margin: 0 0 13px; }
    .slick__slider img {
      max-width: 100%; } }

@media (min-width: 751px) {
  .slick__slider {
    width: 100%;
    overflow: hidden; }
    .slick__slider div .slideBG {
      background: url("../../../assets/images/banner/222bgMain.jpg") center/cover no-repeat;
      padding: 15px 15px; }
      .slick__slider div .slideBG .logoWrap {
        text-align: center; }
        .slick__slider div .slideBG .logoWrap img {
          margin: 0 auto;
          max-width: 80px; }
      .slick__slider div .slideBG .slideTitle .titleText {
        font-size: 16.2px;
        font-weight: 700;
        margin: 20px 0; }
      .slick__slider div .slideBG .benefitListWrap .benefitList {
        margin: 0;
        padding: 0; }
        .slick__slider div .slideBG .benefitListWrap .benefitList .listItem {
          list-style: none;
          display: flex;
          position: relative; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem:not(:last-child) {
            margin: 0 0 10px; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap {
            background: #9755ff;
            width: 19px;
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0 8px 0 0; }
            .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap svg {
              width: 10px !important; }
              .slick__slider div .slideBG .benefitListWrap .benefitList .listItem .iconWrap svg path {
                fill: #fff; }
          .slick__slider div .slideBG .benefitListWrap .benefitList .listItem span {
            color: #fff;
            font-size: 10px;
            font-weight: 600;
            line-height: 1;
            display: flex;
            align-items: center;
            flex: 1 1;
            width: calc(100% - 30px); }
      .slick__slider div .slideBG .downloadWrap .banner {
        display: block;
        height: auto !important;
        background: none; }
        .slick__slider div .slideBG .downloadWrap .banner .banner__footer {
          height: auto !important;
          padding: 0 !important;
          background: none !important;
          -webkit-backdrop-filter: none !important;
          backdrop-filter: none !important;
          margin: 20px 0 20px 0; }
          .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__title {
            font-size: 16px;
            display: inline-block !important;
            line-height: normal !important; }
          .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons {
            margin-top: 15px !important;
            justify-content: center;
            gap: 15px; }
            .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button {
              width: 50px;
              height: 46px; }
              .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button .button__icon {
                width: 18px;
                height: 15px; }
              .slick__slider div .slideBG .downloadWrap .banner .banner__footer .footer__buttons .buttons__button .button__text {
                font-size: 9px;
                margin: 0 0 0 0; }
      .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap {
        text-align: center; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .downloadTitle {
          text-align: center;
          font-size: 16px;
          display: inline-block !important;
          line-height: normal !important;
          margin: 0 0 15px;
          color: #fff; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform a {
          display: flex;
          justify-content: center; }
          .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform a img {
            max-width: 150px; }
        .slick__slider div .slideBG .downloadWrap .mobileDownloadWrap .singlePlatform:not(:last-child) {
          margin: 0 0 13px; }
    .slick__slider img {
      max-width: 100%; } }
