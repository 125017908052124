/*
    Fonts
*/
.rootContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--background-transparent-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20; }
  .rootContainer .container {
    margin: 1rem;
    overflow: hidden;
    position: relative;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    min-width: 10vw;
    min-height: 10vh;
    background-color: var(--white);
    border-radius: 2px;
    opacity: 0.9;
    transition: 300ms ease-in-out;
    will-change: transform, opacity, height, width; }
    .rootContainer .container.active {
      opacity: 1;
      transform: translateY(0); }
    .rootContainer .container.width {
      width: 49.5rem; }
    .rootContainer .container.widest {
      width: 75rem;
      max-width: 95vw; }
    .rootContainer .container.tallest {
      height: 51.25rem;
      max-height: 95vh; }
    .rootContainer .container.tall {
      height: 32.5rem; }
    .rootContainer .container.short {
      height: 18.5rem; }
    .rootContainer .container.tiny {
      height: 3rem; }
    .rootContainer .container.short-up {
      height: 14.375rem !important; }
    .rootContainer .container.alert {
      width: 36.5rem;
      height: 3rem;
      padding: 1.375rem;
      padding-right: 3.125rem;
      border-radius: 3px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
      background-color: var(--white);
      font-size: 0.875rem;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #bb1d7e;
      transform: translateY(-2rem); }
      .rootContainer .container.alert .buttonContainer {
        display: inline;
        position: absolute;
        right: 0; }
    .rootContainer .container.video {
      transition: 300ms ease-in-out;
      will-change: transform, opacity;
      z-index: 1001;
      padding: 15vh 10vw 0 15vw;
      min-width: 1000px;
      width: 100vw;
      min-height: 740px;
      height: 100vh;
      display: block;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0); }
      @media (max-width: 700px) {
        .rootContainer .container.video {
          padding: 0;
          min-width: 100px;
          min-height: 140px; } }
      .rootContainer .container.video .button {
        position: absolute;
        z-index: 9999;
        left: -40px;
        top: 10px; }
      .rootContainer .container.video .buttonContainer {
        position: absolute;
        display: inline;
        right: 0;
        top: 0; }
    .rootContainer .container .whiteBackground {
      border-radius: 6px;
      background-color: rgba(255, 255, 255, 0.2); }
  .rootContainer .buttonContainer {
    position: absolute;
    display: inline;
    right: 0;
    top: 0; }
  .rootContainer .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
    opacity: 0.8;
    z-index: 9999; }
