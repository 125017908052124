$base-animation-duration: 500ms;
$base-animation-delay-0: 400ms;
$base-animation-delay-1: 300ms;
$base-animation-delay-2: 200ms;
$base-animation-delay-3: 100ms;
$base-animation-delay-4: 0ms;

$offset-1: 100;
$offset-2: 200;
$offset-3: 300;
$offset-4: 400;
$offset-5: 500;
$offset-6: 600;

@mixin animationParams($delay) {
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-duration: 1000ms;
    animation-delay: $delay;
    -moz-animation-timing-function: ease;
}

.container {
    .label {
        display: block;
        text-align: center;
        width: 100%;
        height: 18px;
        font-family: Montserrat;
        font-size: 0.75rem;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: var(--theme-muffled-color);
        text-shadow: 1px 1px 1px var(--theme-main-color);
    }
    .animationContainer {
        svg {
            path {
                opacity: 0;
            }
            margin-left: 2px;
            .path0 {
                animation-name: hide0 !important;
            }
            .path1 {
                animation-name: hide1 !important;
            }
            .path2 {
                animation-name: hide2 !important;
            }
            .path3 {
                animation-name: hide3 !important;
            }
            .path4 {
                animation-name: hide4 !important;
            }
            .path5 {
                opacity: 1;
            }
        }
    }
}

.svg1 {
    .path0 {
        @include animationParams(base-animation-delay-0);
    }

    .path1 {
        @include animationParams($base-animation-delay-1);
    }

    .path2 {
        @include animationParams($base-animation-delay-2);
    }

    .path3 {
        @include animationParams($base-animation-delay-3);
    }

    .path4 {
        @include animationParams($base-animation-delay-4);
    }
}

.svg2 {
    .path0 {
        @include animationParams($base-animation-delay-0 + $offset-1);
    }

    .path1 {
        @include animationParams($base-animation-delay-1 + $offset-1);
    }

    .path2 {
        @include animationParams($base-animation-delay-2 + $offset-1);
    }

    .path3 {
        @include animationParams($base-animation-delay-3 + $offset-1);
    }

    .path4 {
        @include animationParams($base-animation-delay-4 + $offset-1);
    }
}

.svg3 {
    .path0 {
        @include animationParams($base-animation-delay-0 + $offset-2);
    }

    .path1 {
        @include animationParams($base-animation-delay-1 + $offset-2);
    }

    .path2 {
        @include animationParams($base-animation-delay-2 + $offset-2);
    }

    .path3 {
        @include animationParams($base-animation-delay-3 + $offset-2);
    }

    .path4 {
        @include animationParams($base-animation-delay-4 + $offset-2);
    }
}
.svg4 {
    .path0 {
        @include animationParams($base-animation-delay-0 + $offset-3);
    }

    .path1 {
        @include animationParams($base-animation-delay-1 + $offset-3);
    }

    .path2 {
        @include animationParams($base-animation-delay-2 + $offset-3);
    }

    .path3 {
        @include animationParams($base-animation-delay-3 + $offset-3);
    }

    .path4 {
        @include animationParams($base-animation-delay-4 + $offset-3);
    }
}
.svg5 {
    .path0 {
        @include animationParams($base-animation-delay-0 + $offset-4);
    }

    .path1 {
        @include animationParams($base-animation-delay-1 + $offset-4);
    }

    .path2 {
        @include animationParams($base-animation-delay-2 + $offset-4);
    }

    .path3 {
        @include animationParams($base-animation-delay-3 + $offset-4);
    }

    .path4 {
        @include animationParams($base-animation-delay-4 + $offset-4);
    }
}
.svg6 {
    .path0 {
        @include animationParams($base-animation-delay-0 + $offset-5);
    }

    .path1 {
        @include animationParams($base-animation-delay-1 + $offset-5);
    }

    .path2 {
        @include animationParams($base-animation-delay-2 + $offset-5);
    }

    .path3 {
        @include animationParams($base-animation-delay-3 + $offset-5);
    }

    .path4 {
        @include animationParams($base-animation-delay-4 + $offset-5);
    }
}
@keyframes hide0 {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    60% {
        opacity: 0;
    }
}
@keyframes hide1 {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    50% {
        opacity: 0.3;
    }
    70% {
        opacity: 0;
    }
}
@keyframes hide2 {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    50% {
        opacity: 0.4;
    }
    80% {
        opacity: 0;
    }
}
@keyframes hide3 {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    90% {
        opacity: 0;
    }
}
@keyframes hide4 {
    0% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.7;
    }
}
